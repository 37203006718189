import React from "react";

function RadioButtonComponent({
  queData,
  selectOptions,
  selected,
  findSelectedOptions,
}) {
  return (
    <div className="surveyRadioButtonComponent">
      {queData?.answerOption?.map((ansData) => {
        return (
          <div className="answerOptionsList">
            {queData?.respondAnswer?.length <= 0 && (
              <article
                onClick={() => {
                  selectOptions(ansData, queData);
                }}
              >
                {selected.includes(ansData?.id) ? (
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="8"
                      cy="8"
                      r="7.5"
                      fill="white"
                      stroke="#1296B0"
                      strokeWidth="1.5"
                    />
                    <circle cx="8" cy="8" r="5" fill="#1296B0" />
                  </svg>
                ) : (
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="8"
                      cy="8"
                      r="7.5"
                      fill="white"
                      stroke="#BFBFBF"
                      strokeWidth="1.5"
                    />
                    <circle
                      cx="8.00036"
                      cy="8.00036"
                      r="5.30993"
                      fill="white"
                    />
                  </svg>
                )}
              </article>
            )}
            {queData?.respondAnswer?.length > 0 && (
              <article>
                {findSelectedOptions(ansData?.id, queData?.respondAnswer) ? (
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="8"
                      cy="8"
                      r="7.5"
                      fill="white"
                      stroke="#1296B0"
                      strokeWidth="1.5"
                    />
                    <circle cx="8" cy="8" r="5" fill="#1296B0" />
                  </svg>
                ) : (
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="8"
                      cy="8"
                      r="7.5"
                      fill="white"
                      stroke="#BFBFBF"
                      strokeWidth="1.5"
                    />
                    <circle
                      cx="8.00036"
                      cy="8.00036"
                      r="5.30993"
                      fill="white"
                    />
                  </svg>
                )}
              </article>
            )}
            <span className="answerOption">{ansData?.answerText}</span>
          </div>
        );
      })}
    </div>
  );
}

export default RadioButtonComponent;
