import { Table, TableBody, TableHead, TableRow } from "@mui/material";
import { FieldArray, Form, Formik } from "formik";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { FileUploadFormik } from "../../../../Components/FormComponent/FileUploadFormik";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import { Loader } from "../../../../Components/FormComponent/Loader";
import * as Yup from "yup";
import {
  StyledTableCell,
  StyledTableCont,
} from "../../../../Components/FormComponent/Repeated";
import { isLoading, showToast } from "../../../../Features";
import { getRequest, postRequestForm } from "../../../../Services/axios";
export const UploadTicketOptions = ({ id, handleClose, getListData }) => {
  const [detailsform, setDetailsform] = useState(false);

  useEffect(() => {
    document.title = `PeopleSol - Upload Ticket Option`;  
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);

  const data = id;

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const getFormDetails = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(
        `TravelHospitalityRequests/Details/${data}`
      );
      setDetailsform(result.data.data);
      // //console.log("result.data", result.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };

  useEffect(() => {
    if (data) getFormDetails();
  }, []);

  const create = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequestForm(
        "TravelHospitalityRequests/Upload",
        values
      );
      if (result)
        dispatch(
          showToast({ text: "Successfully Created", severity: "success" })
        );
      handleClose();
      getListData();
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  useEffect(() => {
    if (data) getFormDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  const getInitalValues = () => {
    let returnArray = [
      {
        Remarks: "",
        Amount: "",
        Upload: null,
      },
    ];
    if (detailsform)
      if (detailsform.bookinglist)
        if (detailsform.bookinglist.length > 0) {
          returnArray = [];
          detailsform?.bookinglist?.map((item) => {
            return returnArray.push({
              Id: item.id,
              Remarks: item.remarks,
              Amount: item.amount,
              Upload: item.attachment
                ? `TravelBookings/${item.attachment}`
                : null,
            });
          });
        }
    return returnArray;
  };
  const initialvalue = {
    Id: data,
    Bookinglist: getInitalValues(),
  };
  if (loading) return <Loader />;
  else
    return (
      <Formik
        initialValues={initialvalue}
        validationSchema={Yup.object({
          Bookinglist: Yup.array().of(
            Yup.object().shape({
              Remarks: Yup.string().required("Option Remarks Is Required"),
              Amount: Yup.string().required("Amount Is Required"),
            })
          ),
        })}
        enableReinitialize
        onSubmit={(values) => {
          const formData = new FormData();
          formData.append("Id", values.Id);
          if (values?.Bookinglist.length > 0) {
            values?.Bookinglist.forEach(function (object, index) {
              if (object.Id)
                formData.append(`Bookinglist[${index}].Id`, object.Id);
              formData.append(`Bookinglist[${index}].Remarks`, object.Remarks);
              formData.append(`Bookinglist[${index}].Amount`, object.Amount);
              if (typeof object.Upload === "object")
                formData.append(`Bookinglist[${index}].Upload`, object.Upload);
            });
          }
          create(formData);
        }}
      >
        {(formik) => {
          return (
            <Form>
              <div style={{ width: "100%" }}>
                <div style={{ display: "grid" }}>
                  <FieldArray name="Bookinglist">
                    {(fieldArrayProps) => {
                      const { push, remove, form } = fieldArrayProps;
                      const { values } = form;
                      const { Bookinglist } = values;

                      return (
                        <StyledTableCont>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <StyledTableCell
                                  padding="0"
                                  style={{
                                    fontSize: "12px",
                                  }}
                                  align="center"
                                >
                                  <LabelCustom labelName={"S. No."} />
                                </StyledTableCell>
                                <StyledTableCell
                                  padding="0"
                                  style={{
                                    fontSize: "12px",
                                  }}
                                  align="center"
                                >
                                  <LabelCustom
                                    labelName={"Option Remark"}
                                    mandatory={true}
                                  />
                                </StyledTableCell>

                                <StyledTableCell
                                  style={{
                                    fontSize: "12px",
                                  }}
                                  align="center"
                                >
                                  <LabelCustom labelName={"Attachment"} />
                                </StyledTableCell>
                                <StyledTableCell
                                  style={{
                                    fontSize: "12px",
                                  }}
                                  align="center"
                                >
                                  <LabelCustom
                                    labelName={"Amount"}
                                    mandatory={true}
                                  />
                                </StyledTableCell>

                                <StyledTableCell
                                  style={{
                                    fontSize: "12px",
                                  }}
                                  align="center"
                                >
                                  <LabelCustom labelName={"Add/Remove"} />
                                </StyledTableCell>
                              </TableRow>
                            </TableHead>
                            {Bookinglist.map((Bookinglists, index) => {
                              return (
                                <>
                                  <TableBody key={index}>
                                    <StyledTableCell align="center">
                                      {index + 1}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      <InputCustomFormik
                                        name={`Bookinglist[${index}].Remarks`}
                                      />
                                    </StyledTableCell>

                                    <StyledTableCell>
                                      <FileUploadFormik
                                        name={`Bookinglist[${index}].Upload`}
                                      />
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      <InputCustomFormik
                                        name={`Bookinglist[${index}].Amount`}
                                        type="number"
                                        step="0.01"
                                      />
                                    </StyledTableCell>

                                    <StyledTableCell
                                      sx={{
                                        textAlign: "center",
                                      }}
                                    >
                                      <button
                                        type="button"
                                        className="remove-btn"
                                        onClick={() =>
                                          Bookinglist.length > 1
                                            ? remove(index)
                                            : dispatch(
                                                showToast({
                                                  text: "Atleast One Item is Required ",
                                                  severity: "error",
                                                })
                                              )
                                        }
                                      >
                                        -
                                      </button>
                                      <button
                                        type="button"
                                        className="add-btn"
                                        onClick={() =>
                                          push({
                                            Remarks: "",
                                            Amount: "",
                                            Upload: null,
                                          })
                                        }
                                      >
                                        +
                                      </button>
                                    </StyledTableCell>
                                  </TableBody>
                                </>
                              );
                            })}
                          </Table>
                        </StyledTableCont>
                      );
                    }}
                  </FieldArray>
                </div>
                <div className="createFootCust">
                  <div>
                    <button className="button primaryButton" type="submit">
                      Update Preferences
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    );
};
