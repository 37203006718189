import React, { useEffect, useRef, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "../../../../Assets/DeleteIcon.svg";

import { TextAreaCustom } from "../../../../Components/FormComponent/TextAreaCustom";
import { IconButton } from "@mui/material";

const DocumentPreviewContainer = ({ selectedFiles, handleDocsSelect }) => {
  const [selectedDocsInside, setSelectedDocsInside] = useState([]);
  const [arrSelectedFiles, setarrSelectedFiles] = useState([...selectedFiles]);
  const handleDocsSelectInside = (files) => {
    setSelectedDocsInside(files);
  };

  const addImages = () => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = "*/*"; // Allow all file types
    fileInput.multiple = true; // Enable multiple file selection

    fileInput.addEventListener("change", (event) => {
      const selected = event.target.files;
      // Handle file selection logic here
      console.log("Selected file:", selected);
      setarrSelectedFiles((prev) => [...prev, ...selected]);
      handleDocsSelectInside(selected, "document");
    });
    fileInput.click();
  };
  const [active, setActive] = useState({ index: 0, item: arrSelectedFiles[0] });
  console.log("object active", active);
  console.log("object selectedFiles", selectedFiles);
  console.log("object arrSelectedFiles", arrSelectedFiles);
  console.log("object selectedDocsInside", ...selectedDocsInside);

  function removeItemAtIndex(array, index) {
    // Using filter to return a new array without the item at the specified index
    const newArray = array.filter((_, i) => i !== index);
    setarrSelectedFiles(newArray);
    if (index === array?.length - 1) {
      setActive({
        index: index - 1,
        item: arrSelectedFiles[index - 1],
      });
    } else {
      setActive({
        index: index,
        item: arrSelectedFiles[index + 1],
      });
    }
  }

  return (
    <div className="document-preview-container">
      <div className="cover-chat-document">
        <div style={{ display: "flex", flexDirection: "column", flex: "1" }}>
          <div className="header">
            <div className="file-name">
              {active?.item && active?.item?.name}
            </div>
            <div className="selected-file-icon">
              <CloseIcon
                onClick={() => {
                  handleDocsSelect(false);
                }}
              />
            </div>
          </div>
          <div className="preview">
            <div
              style={{ width: "100%", height: "423px", textAlign: "center" }}
            >
              <img
                style={{
                  maxWidth: "100%",
                  maxHeight: "100%",
                  objectFit: "contain",
                }}
                src={URL.createObjectURL(active?.item)}
                alt=""
              />
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "20px 0px",
          }}
        >
          <input
            placeholder="Type your text here..."
            className="input-textarea-chat "
          />
        </div>
      </div>
      <div
        style={{
          borderTop: "0.5px solid #BFBFBF",
          height: "107px",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          paddingLeft: "17px",
          backgroundColor: "white",
        }}
      >
        <div
          style={{
            width: "calc(100% - 80px)",
            height: "80%",
            // backgroundColor: "blanchedalmond",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "start",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                // alignItems: "center",
                // justifyContent: "center",
                height: "93px",
                overflowX: "auto",
                maxWidth: "calc(100% - 60px)",
              }}
            >
              <div
                className="custom-scrollbar-chat"
                style={{
                  display: "flex",
                  // alignItems: "center",
                  // justifyContent: "center",
                  overflowX: "auto",
                  overflowY: "hidden",
                  width: "100%",
                }}
              >
                {arrSelectedFiles?.map((item, index) => {
                  return (
                    <div className={"eachImageHoverCover"}>
                      <div
                        onClick={() => setActive({ index: index, item: item })}
                        className={
                          active?.index === index
                            ? "activeSerlectedFile eachImageHover"
                            : "eachImageHover"
                        }
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "start",
                          justifyContent: "center",
                          width: "58px",
                          height: "58px",
                          borderRadius: "3px",
                          border: "0.46px solid #BFBFBF",
                          backgroundColor: "#FAFAFA",
                          margin: "6px 6px 0px 6px",
                          flexShrink: "0", // Prevent shrinking
                          flexGrow: "0", // Prevent growing
                        }}
                      >
                        <div
                          className=""
                          style={{
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <svg
                            width="22"
                            height="24"
                            viewBox="0 0 22 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M8.13598 1.14551H19.2782C20.3565 1.14551 21.315 2.02551 21.315 3.01551V20.9455C21.315 22.1555 20.2367 23.1455 18.9188 23.1455H3.22382C1.90592 23.1455 0.827637 22.1555 0.827637 20.9455V7.1845"
                              fill="#DAF0F3"
                            />
                            <path
                              d="M8.13598 1.14551H19.2782C20.3565 1.14551 21.315 2.02551 21.315 3.01551V20.9455C21.315 22.1555 20.2367 23.1455 18.9188 23.1455H3.22382C1.90592 23.1455 0.827637 22.1555 0.827637 20.9455V7.1845"
                              stroke="#6ABCCC"
                            />
                            <path
                              d="M8.08473 1.14551V7.33188H0.827637L8.08473 1.14551Z"
                              fill="#DAF0F3"
                              stroke="#6ABCCC"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </div>
                      </div>
                      <div
                        className="eachImageHoverDelete cursor"
                        onClick={() => {
                          removeItemAtIndex(arrSelectedFiles, index);
                        }}
                      >
                        {/* <img
                          style={{ height: "100%" }}
                          src={DeleteIcon}
                          alt=""
                        /> */}
                        <p style={{ fontSize: "10px" }}>Remove</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div
              onClick={addImages}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "58px",
                height: "58px",
                borderRadius: "3px",
                border: "0.46px solid #BFBFBF",
                backgroundColor: "#FAFAFA",
                margin: "6px",
                flexShrink: "0", // Prevent shrinking
                flexGrow: "0", // Prevent growing
                // marginTop: "17px",
              }}
            >
              <svg
                width="23"
                height="23"
                viewBox="0 0 23 23"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <line
                  x1="10.8672"
                  y1="0.645508"
                  x2="10.8672"
                  y2="21.6455"
                  stroke="#BFBFBF"
                  stroke-linecap="round"
                />
                <line
                  x1="22.0903"
                  y1="10.4219"
                  x2="1.09033"
                  y2="10.4219"
                  stroke="#BFBFBF"
                  stroke-linecap="round"
                />
              </svg>
            </div>
          </div>
        </div>
        <IconButton style={{ marginLeft: "10px" }}>
          <svg
            width="43"
            height="43"
            viewBox="0 0 43 43"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="21.2598" cy="21.8857" r="21" fill="#1296B0" />
            <path
              d="M13.5646 15.4038L25.2656 11.5494C30.4967 9.75982 33.3875 12.6507 31.5979 17.8817L27.7435 29.5827C25.128 37.4293 20.8605 37.4293 18.245 29.5827L17.1437 26.1413L13.5646 24.9023C5.71805 22.2868 5.71805 18.0194 13.5646 15.4038Z"
              stroke="white"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M17.2812 25.4538L22.237 20.498"
              stroke="white"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </IconButton>
      </div>
    </div>
  );
};

export default DocumentPreviewContainer;
