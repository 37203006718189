import React from "react";
import { useNavigate } from "react-router-dom";
import {
  getContentLength,
  getFormattedCount,
  getUserInitials,
} from "../../../../../Utilities/socialAllFunctions";
import getDisplayName from "../../../../../Utilities/displayNameFormat";
import {
  communityProfileImages,
  profileImages,
} from "../../../../../Utilities/profileAndUploadImages";
import ProfileAvatar from "../../../../../Assets/ProfileAvatar.svg";
import SocialPostTeamIcon from "../../../../../Assets/SocialPostTeamIcon.svg";
import SocialPostViewsEyeIcon from "../../../../../Assets/SocialPostViewsEyeIcon.svg";
import SocialCommunityGroupIcon from "../../../../../Assets/SocialCommunityGroupIcon.svg";
import dateFormat from "dateformat";
import { useState } from "react";
import { useSelector } from "react-redux";
import { IconButton } from "@mui/material";
import PostActivityContainer from "./PostActivityContainer";
import ModeratorPostActivityContainer from "./ModeratorPostActivityContainer";
import ContentSocialDisplay from "./ContentSocialDisplay";

function SharedEmployeeComponent({
  d,
  getData,
  setIsSocialPostDeletePopupActive,
  setEditDeleteSocialPostId,
  setIsSharePosEditActive,
  setIsMyPosEditActive,
  setViewsPostId,
  setViewsListActive,
  setEditDeleteSocialPostData,
}) {
  const navigate = useNavigate();
  const { LoggedInUser } = useSelector((state) => state.auth);
  const [showContent, setShowContent] = useState(false);
  const [canManageSocial, setCanManageSocial] = useState(
    LoggedInUser?.permissions?.includes("Can Manage Social?")
  );
  const handleUserProfileClick = (userId) => {
    navigate(`/home/social/userprofile/${userId}`);
  };
  return (
    <>
      <div
        className={`sharedAndPostTypeContainer ${
          d?.postType !== "organisation" ? "sharedHeader" : ""
        }`}
      >
        <div
          className="postSharedContainer"
          style={{ boxShadow: "none", padding: "0px 0px" }}
        >
          {d?.postType === "organisation" && (
            <div
              className="leftPostHeaderContainer"
              onClick={(e) => {
                e.stopPropagation();
                handleUserProfileClick(d?.sharedBy);
              }}
            >
              <div
               
                className={`${
                  d?.sharedEmployee?.image
                    ? "socialProfilePicContainer"
                    : "socialCustomProfilePic"
                }`}
              >
                {!d?.sharedEmployee?.image ? (
                  getUserInitials(
                    getDisplayName(d?.sharedEmployee?.nameWithPersonNumber)
                  )
                ) : (
                  <img
                    className="socialProfilePic"
                    src={
                      process.env.REACT_APP_.BASE_URL +
                      profileImages +
                      d?.sharedEmployee?.image
                    }
                    alt="Profile"
                    onError={(e) => {
                      e.target.src = ProfileAvatar;
                    }}
                  />
                )}
              </div>

              <div className="postMainTextContainer">
                <label className="postMainName">
                  {getDisplayName(d?.sharedEmployee?.nameWithPersonNumber)}
                </label>
                <label>{d?.roleName}</label>
                <label className="dateAndViewsContainer">
                  {d?.sharedEmployee?.locationName}
                  <span className="postHrTime">
                    &nbsp; •{" "}
                    {dateFormat(d?.lastUpdatedOn, "hh:MM TT | dd mmm yyyy")}
                  </span>
                  <article
                    onClick={(e) => {
                      e.stopPropagation();
                      setViewsListActive(true);
                      setViewsPostId(d?.id);
                    }}
                  >
                    <img
                      src={SocialPostViewsEyeIcon}
                      alt=""
                      className="socialViewsImgContainer"
                    />
                    <span className="socialViewsSpanContainer">
                      {d?.views < 10
                        ? "0" + d?.views
                        : getFormattedCount(d?.views)}{" "}
                      Views
                    </span>
                  </article>
                </label>
              </div>
            </div>
          )}
          {d?.postType === "community" && (
            <div
              className="leftPostHeaderContainer"
              onClick={(e) => {
                e.stopPropagation();
                // handleUserProfileClick(d?.sharedBy);
              }}
            >
              
              <div
                onClick={(e) => {
                  navigate(`/home/social/community/${d?.community?.id}`);
                }}
                className={`${
                  d?.community?.communityImage
                    ? "socialConnectProfilePicContainer"
                    : "customCommunityProfilePicInSocial"
                }`}
              >
                {!d?.community?.communityImage &&
                d?.community?.communityName ? (
                  getUserInitials(d?.community?.communityName)
                ) : (
                  <img
                    className="socialProfilePic"
                    src={
                      process.env.REACT_APP_.BASE_URL +
                      communityProfileImages +
                      d?.community?.communityImage
                    }
                    style={{ marginRight: "10px", marginBottom: "5px" }}
                    alt="Profile"
                    onError={(e) => {
                      e.target.src = SocialCommunityGroupIcon;
                    }}
                  />
                )}
              </div>

              <div
                onClick={(e) => {
                  e.stopPropagation();
                  handleUserProfileClick(d?.sharedBy);
                }}
                className={`${
                  d?.sharedEmployee?.image
                    ? "socialProfilePicContainer"
                    : "socialCustomProfilePicShareCommunity  "
                }`}
              >
                {!d?.sharedEmployee?.image ? (
                  getUserInitials(
                    getDisplayName(d?.sharedEmployee?.nameWithPersonNumber)
                  )
                ) : (
                  <img
                    className={`socialProfilePic ${
                      d?.postType !== "organisation"
                        ? "prTeamCommunityShared"
                        : ""
                    }`}
                    src={
                      process.env.REACT_APP_.BASE_URL +
                      profileImages +
                      d?.sharedEmployee?.image
                    }
                    alt="Profile"
                    onError={(e) => {
                      e.target.src = ProfileAvatar;
                    }}
                  />
                )}
              </div>

              <div className="postMainTextContainer">
                <label className="postMainName">
                  {d?.community?.communityName}
                </label>
                <label className="dateAndViewsContainer">
                  Post from &nbsp;
                  <span className="nameofposter">
                    {getDisplayName(d?.sharedEmployee?.nameWithPersonNumber)}
                  </span>
                  <span className="postHrTime">
                    &nbsp; •{" "}
                    {dateFormat(d?.lastUpdatedOn, "hh:MM TT | dd mmm yyyy")}
                  </span>
                  <article
                    onClick={(e) => {
                      e.stopPropagation();
                      setViewsListActive(true);
                      setViewsPostId(d?.id);
                    }}
                  >
                    <img
                      src={SocialPostViewsEyeIcon}
                      alt=""
                      className="socialViewsImgContainer"
                    />
                    <span className="socialViewsSpanContainer">
                      {d?.views < 10
                        ? "0" + d?.views
                        : getFormattedCount(d?.views)}{" "}
                      Views
                    </span>
                  </article>
                </label>
              </div>
            </div>
          )}
          {d?.postType === "team" && (
            <div
              className="leftPostHeaderContainer"
              onClick={(e) => {
                e.stopPropagation();
                handleUserProfileClick(d?.sharedBy);
              }}
            >
              <img
                src={SocialPostTeamIcon}
                alt=""
                style={{
                  height: "50px",
                  width: "50px",
                }}
              />
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  handleUserProfileClick(d?.sharedBy);
                }}
                className={`${
                  d?.sharedEmployee?.image
                    ? "socialProfilePicContainer"
                    : "socialCustomProfilePicShareCommunity"
                }`}
              >
                {!d?.sharedEmployee?.image ? (
                  getUserInitials(
                    getDisplayName(d?.sharedEmployee?.nameWithPersonNumber)
                  )
                ) : (
                  <img
                    className={`socialProfilePic ${
                      d?.postType !== "organisation"
                        ? "prTeamCommunityShared"
                        : ""
                    }`}
                    src={
                      process.env.REACT_APP_.BASE_URL +
                      profileImages +
                      d?.sharedEmployee?.image
                    }
                    alt="Profile"
                    onError={(e) => {
                      e.target.src = ProfileAvatar;
                    }}
                  />
                )}
              </div>

              <div className="postMainTextContainer">
                <label className="postMainName">Team</label>
                <label className="dateAndViewsContainer">
                  Post from &nbsp;
                  <span className="nameofposter">
                    {getDisplayName(d?.sharedEmployee?.nameWithPersonNumber)}
                  </span>
                  <span className="postHrTime">
                    &nbsp; •{" "}
                    {dateFormat(d?.lastUpdatedOn, "hh:MM TT | dd mmm yyyy")}
                  </span>
                  <article
                    onClick={(e) => {
                      e.stopPropagation();
                      setViewsListActive(true);
                      setViewsPostId(d?.id);
                    }}
                  >
                    <img
                      src={SocialPostViewsEyeIcon}
                      alt=""
                      className="socialViewsImgContainer"
                    />
                    <span className="socialViewsSpanContainer">
                      {d?.views < 10
                        ? "0" + d?.views
                        : getFormattedCount(d?.views)}{" "}
                      Views
                    </span>
                  </article>
                </label>
              </div>
            </div>
          )}
          {d?.sharedEmployee?.nameWithPersonNumber ===
            LoggedInUser.nameWithPersonNumber && (
            <div>
              <IconButton>
                <PostActivityContainer
                  setIsSocialPostDeletePopupActive={
                    setIsSocialPostDeletePopupActive
                  }
                  setIsSharePosEditActive={setIsSharePosEditActive}
                  setIsMyPosEditActive={setIsMyPosEditActive}
                  isMyPosEditActive={false}
                  isSharePosEditActive={true}
                  setEditDeleteSocialPostId={setEditDeleteSocialPostId}
                  editDeleteSocialPostId={d?.id}
                  editDeleteSocialPostData={d}
                  setEditDeleteSocialPostData={setEditDeleteSocialPostData}
                />
              </IconButton>
            </div>
          )}
          {d?.sharedEmployee?.nameWithPersonNumber !==
            LoggedInUser.nameWithPersonNumber &&
            canManageSocial && (
              <div>
                <IconButton>
                  <ModeratorPostActivityContainer
                    setIsSocialPostDeletePopupActive={
                      setIsSocialPostDeletePopupActive
                    }
                    setEditDeleteSocialPostId={setEditDeleteSocialPostId}
                    setEditDeleteSocialPostData={setEditDeleteSocialPostData}
                    editDeleteSocialPostId={d?.id}
                    editDeleteSocialPostData={d}
                  />
                </IconButton>
              </div>
            )}
        </div>
      </div>
      {d?.sharedContent !== null && (
        <div className="postBodyTextContainer">
          {d?.content?.split(/\r\n|\r|\n/).length > 4 && !showContent ? (
            <ContentSocialDisplay
              content={getContentLength(d?.sharedContent)}
            />
          ) : (
            <ContentSocialDisplay content={d?.sharedContent?.trim()} />
          )}
          {d?.sharedContent?.split(/\r\n|\r|\n/).length > 4 && !showContent && (
            <span
              onClick={() => {
                setShowContent(true);
              }}
            >
              ...View More
            </span>
          )}
        </div>
      )}
    </>
  );
}

export default SharedEmployeeComponent;
