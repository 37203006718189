import React from "react";
import { LabelCustom } from "../../../Components/FormComponent/LabelCustom";
import { Form, Formik } from "formik";
import { QuestionRadioFormik } from "./QuestionRadioFormik";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { showToast } from "../../../Features";
import WrongIcon from "../../../Assets/WrongIcon.svg";
import CorrectIcon from "../../../Assets/CorrectIcon.svg";

import { postRequest } from "../../../Services/axios";
import * as Yup from "yup";
import { Loader } from "../../../Components/FormComponent/Loader";

export const QuestionSet = ({
  currQuestion,
  pauseIndex,
  detailsVideo,
  closeAd,
  chapteridState,
  index,
  resubmitProp,
}) => {
  const dispatch = useDispatch();
  const [initialValue, setInitialValue] = useState({});
  const [RResults, setResults] = useState({});
  const [formValues, setFormValues] = useState(null);
  const [loadingForm, setLoadingForm] = useState(false);
  const [secondbutton, setsecondbutton] = useState(false);
  const [allCorrect, setAllCorrect] = useState(false);
  const [keyobjstate, setKeyobjstate] = useState({});
  // console.log("currQuestion----", currQuestion);
  // console.log("closeAd----", closeAd);
  // console.log("chapteridState----", chapteridState);
  console.log("keyobjstate----", keyobjstate);
  const createPageData = async (createvalues) => {
    try {
      await postRequest(`LMSController/Submitanswers`, createvalues);
      dispatch(
        showToast({ text: "Successfully Submitted", severity: "success" })
      );
    } catch (error) {
      dispatch(
        showToast({ text: error?.response?.data?.title, severity: "error" })
      );
    } finally {
    }
  };

  const chooseFormValues = (values) => {
    setFormValues(values);
  };
  const FormLoading = () => {
    setsecondbutton(false);
    setLoadingForm(true);
    setTimeout(() => {
      setLoadingForm(false);
    }, 5);
  };

  // console.log("values 111 111 first", formValues);
  const checkForSubmit = () => {
    // console.log("values 111 111 first", formValues);
    const keyobj = {};
    for (const key in RResults) {
      let sameElements;
      if (RResults[key]?.length > 1) {
        const sameLength = formValues[key]?.length === RResults[key]?.length;

        sameElements =
          sameLength &&
          formValues[key]
            ?.slice()
            ?.sort()
            ?.every(
              (value, index) => value === RResults[key]?.slice()?.sort()[index]
            );
      } else {
        if (RResults[key][0] === formValues[key]) {
          sameElements = true;
        } else sameElements = false;
      }
      if (sameElements) {
        keyobj[key] = true;
      } else {
        keyobj[key] = false;
      }
    }
    // console.log("keyobj --- jui  ", keyobj);
    setKeyobjstate(keyobj);
    setsecondbutton(true);
    if (
      Object.keys(RResults).length === Object.keys(formValues).length &&
      Object.values(keyobj).every((value) => value === true)
    ) {
      setAllCorrect(true);
      return false;
    } else {
      setAllCorrect(false);
      return false;
    }
  };
  const correctAnswersFinder = () => {
    const result = {};

    currQuestion?.questions?.forEach((item) => {
      if (item.answers) {
        const correctAnswer = item.answers.filter(
          (answer) => answer.isCorrectAnswer === true
        );
        if (correctAnswer?.length > 0) {
          result[item.id] = correctAnswer.map((item) => {
            return item?.id?.toString();
          });
        }
      }
    });
    setResults(result);
    return result;
  };
  // console.log("=== RResults ===", RResults);
  // console.log("=== formValues ===", formValues);
  useEffect(() => {
    let initialValues = {};

    currQuestion?.questions?.map((item) => {
      return (initialValues[`${item.id}`] = "");
    });

    setInitialValue(initialValues);
    correctAnswersFinder();
  }, []);
  const questionIds = currQuestion?.questions?.map((item) => item.id);

  const questionSchemas = {};
  questionIds.forEach((questionId) => {
    // console.log("RResults[questionId] .....", RResults[questionId]);
    if (RResults[questionId]?.length > 1) {
      questionSchemas[questionId] = Yup.array()
        .min(1, "Please select atleast one answer")
        .required("Please select an answer");
    } else {
      questionSchemas[questionId] = Yup.string().required(
        "Please select an answer"
      );
    }
  });
  // console.log("keyobjstate first", keyobjstate);
  const validationSchema = Yup.object().shape(questionSchemas);

  const onSubmit = (values) => {
    // console.log("object values", values);
    let data = { ...values };

    for (let key in data) {
      if (data.hasOwnProperty(key)) {
        if (!Array.isArray(data[key])) {
          data[key] = [data[key]];
        }
      }
    }

    // console.log(data);
    const create = {
      ChapterID: chapteridState,
      questionSetId: currQuestion?.questionSetId,
      questionAnswerDictionary: {
        ...data,
      },
    };
    if (secondbutton) {
      createPageData(create);
    } else if (checkForSubmit()) {
      createPageData(create);
    } else {
      return;

      // const result = checkForSubmit();
      // if (result) createPageData(create);
    }
    closeAd();
    // console.log("first submit----", values);
  };
  return (
    <div className=" questionpopupstyle">
      {loadingForm ? (
        <Loader />
      ) : (
        <Formik
          enableReinitialize={true}
          initialValues={initialValue}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {(formik, form) => {
            chooseFormValues(formik?.values);
            return (
              <Form style={{ width: "100%" }}>
                {currQuestion?.questions?.map((item, index) => (
                  <div style={{ width: "100%" }}>
                    {/* {currQuestion?.id} */}
                    <div style={{ width: "100%" }}>
                      <div style={{ width: "100%", padding: "10px" }}>{`${
                        index + 1
                      }. ${item?.questionText} `}</div>
                      {item?.answers?.filter(
                        (item) => item?.isCorrectAnswer === true
                      )?.length > 1 ? (
                        <div style={{ padding: "0px 10px 10px 10px" }}>
                          ( This question has multiple correct answers. Select
                          all the correct once)
                        </div>
                      ) : (
                        <div style={{ padding: "0px 10px 10px 10px" }}>
                          ( This question has a single correct answer.)
                        </div>
                      )}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "flex-start",
                        flexDirection: "column",
                      }}
                    >
                      <QuestionRadioFormik
                        isMultiSelect={
                          item?.answers?.filter(
                            (item) => item?.isCorrectAnswer === true
                          )?.length > 1
                        }
                        name={item?.id}
                        options={item?.answers}
                        displayCheck={secondbutton}
                        keyobjstateProp={keyobjstate[item?.id]}
                      />
                      {/* {Object.keys(keyobjstate).length !== 0 && secondbutton ? ( */}
                      {/* {secondbutton ? (
                        keyobjstate[item?.id] === true ? (
                          <img
                            className="IconSizeLdForm"
                            src={CorrectIcon}
                            alt="Correct Icon"
                          />
                        ) : (
                          <img
                            className="IconSizeLdForm"
                            src={WrongIcon}
                            alt="Wrong Icon"
                          />
                        )
                      ) : (
                        ""
                      )} */}
                    </div>
                  </div>
                ))}
                <div style={{ height: "70px" }}></div>
                <div
                  className="createFootCust"
                  style={{
                    borderTop: "1px solid #555",
                    position: "absolute",
                    bottom: "0px",
                    right: "1px",
                  }}
                >
                  {secondbutton === true ? (
                    <div>
                      {resubmitProp === true && !allCorrect ? (
                        <button
                          className="button primaryButton"
                          type="button"
                          onClick={() => FormLoading()}
                          style={{
                            background: "var(--primary)",
                            color: "white",
                          }}
                        >
                          Re-Submit
                        </button>
                      ) : (
                        ""
                      )}

                      <button
                        className="button primaryButton"
                        type="submit"
                        style={{ background: "var(--primary)", color: "white" }}
                      >
                        Continue
                      </button>
                    </div>
                  ) : (
                    <button
                      className="button primaryButton"
                      type="submit"
                      // onClick={() => checkForSubmit()}
                      style={{ background: "var(--primary)", color: "white" }}
                    >
                      Submit
                    </button>
                  )}
                </div>
              </Form>
            );
          }}
        </Formik>
      )}
    </div>
  );
};
