import React, { useState } from "react";
import { Popup } from "../../../../../Components/FormComponent/PopupComponent/Popup";
import "./ShowFullAttachments.css";
import { albumPostImages } from "../../../../../Utilities/profileAndUploadImages";
import SocialPdfLeftArrow from "../../../../../Assets/SocialPdfLeftArrow.svg";
import SocialPdfRightArrow from "../../../../../Assets/SocialPdfRightArrow.svg";
import { getRequest } from "../../../../../Services/axios";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { showToast } from "../../../../../Features";

function ShowGalleryFullImages() {
  const [galleryData, setGalleryData] = useState(null);
  const [fullAtachmentData, setFullAtachmentData] = useState(null);
  const [numPages, setNumPages] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);
  const [index, setIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const { postid } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getData = async () => {
    setLoading(true);
    try {
      const result = await getRequest(`Albums/Details/${postid}`);
      setFullAtachmentData(result?.data?.albumsPhotos);
      setNumPages(result?.data?.albumsPhotos?.length);
      setGalleryData(result?.data);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getData();
  }, []);
  function nextSlide(e) {
    e.stopPropagation();
    if (index < numPages - 1) {
      setIndex(index + 1);
      setPageNumber(pageNumber + 1);
    }
  }
  function prevSlide(e) {
    e.stopPropagation();
    if (index > 0) {
      setIndex(index - 1);
      setPageNumber(pageNumber - 1);
    }
  }
  return (
    <div className="showFullAttachmentWrapper">
      {!loading && fullAtachmentData && (
        <Popup
          firstClassName="galleryFullAttachmentPopupBox1"
          secondClassName="galleryFullAttachmentPopupBox2"
          bodyClassName="showFullAttachmentPopupBox2Body socialPopupBody2"
          popupHeading={galleryData?.name}
          content={
            <>
              {fullAtachmentData?.length > 0 && (
                <div style={{ width: "100%", height: "450px" }}>
                  <div style={{ width: "100%", height: "100%" }}>
                    <div>
                      <img
                        style={{
                          height: "auto",
                          maxHeight: "90%",
                          width: "auto",
                          maxWidth: "100%",
                        }}
                        src={
                          process.env.REACT_APP_.BASE_URL +
                          albumPostImages +
                          fullAtachmentData[index].image
                        }
                        alt=""
                      />
                    </div>
                  </div>
                  <div style={{ width: "100%" }}>
                    <div
                      style={{
                        display: "flex",
                        alignContent: "center",
                        justifyContent: "center",
                        marginTop: "0px",
                      }}
                    >
                      <a
                        href={
                          process.env.REACT_APP_.BASE_URL +
                          albumPostImages +
                          fullAtachmentData[index].image
                        }
                        target="_blank"
                        rel="noreferrer"
                        style={{
                          fontSize: "16px",
                          fontWeight: "500",
                          color: "#1296b0",
                        }}
                      >
                        {fullAtachmentData[index]?.description}
                      </a>
                    </div>
                  </div>
                </div>
              )}
              {fullAtachmentData?.length <= 0 && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div style={{ color: "#bfbfbf" }}>No Attachments Founs!</div>
                </div>
              )}
              {fullAtachmentData?.length > 0 && (
                <>
                  <div
                    className="leftPdfArrow"
                    style={{ opacity: " 0", visibility: "hidden" }}
                  >
                    {index === 0 || (
                      <img
                        src={SocialPdfLeftArrow}
                        alt=""
                        onClick={(e) => {
                          prevSlide(e);
                        }}
                      />
                    )}
                  </div>
                  <div
                    className="rightPdfArrow"
                    style={{ opacity: " 0", visibility: "hidden" }}
                  >
                    {numPages - 1 === index || (
                      <img
                        src={SocialPdfRightArrow}
                        alt=""
                        onClick={(e) => {
                          nextSlide(e);
                        }}
                      />
                    )}
                  </div>
                </>
              )}
            </>
          }
          handleClose={() => {
            navigate("/home/social/gallery");
          }}
        />
      )}
    </div>
  );
}

export default ShowGalleryFullImages;
