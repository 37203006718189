import React , {useState, useEffect} from "react";
import { getRequest } from '../../../Services/axios';
import { InputCustomFormik } from "../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../Components/FormComponent/LabelCustom";
import { SelectForm } from "../../../Components/FormComponent/SelectForm";
import { showToast, isLoading } from '../../../Features';
import { useDispatch } from 'react-redux';

const opt=[
  {label:"select",value:null}
]

export const CreateTravelEligibilityComp = ({data}) => {
  useEffect(() => {
    document.title = `PeopleSol - Create Travel Eligibility`;  
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  const dispatch = useDispatch();
  const [roleList, setRoleList] = useState([]);
  const getDesignation = async () => {
    try {
        
        const result = await getRequest('Bands/GetBands');
        let listData = [];
        result.data.map((item) => { 
            listData.push({ label: item.name, value: item.id })
        })
        setRoleList(listData);
      
    } catch (error) {
        dispatch(showToast({ text: "some error occured in fetching data", severity: "error" }));
    } finally {
    }
  }

  useEffect(() => {
    getDesignation();
  }, []);

  return (
    <div>
      <div>
        <LabelCustom labelName={"Band"} mandatory={true} />
        
        <SelectForm 
        placeholder={" Select Band"}
            name="bandId"
           disabled={data?true:false}
            options={roleList} 
            values={
              data
                ? roleList.filter(
                    (options) => options.label === data?.Name   
                  )
                : opt
            }
            />
      </div>
      <div>
        <LabelCustom labelName={"Per Day Eligibility"} mandatory={true} />
        <InputCustomFormik
          name="perdayEligibilty"
          type={"text"}
          placeholder={"Enter Per Day Eligibility"}
        />
      </div>
    
    </div>
  );
};
