import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { showToast } from "../../../../../Features";
import { postRequest } from "../../../../../Services/axios";
import AnnouncementHeaderIcon from "../../../../../Assets/SocialAnnouncementHeaderIcon.svg";
import SocialPostTypeTag from "../../../../../Assets/SocialAnnouncementTag.svg";
import HeaderComponent from "./HeaderComponent";
import { useSelector } from "react-redux";
import SocialPostViewsEyeIcon from "../../../../../Assets/SocialPostViewsEyeIcon.svg";
import SocialPostNotFoundIcon from "../../../../../Assets/SocialPostNotFoundIcon.svg";
import { Loader } from "../../../../../Components/FormComponent/Loader";
import SharePost from "./SharePost";
import dateFormat from "dateformat";
import { socialPostImages } from "../../../../../Utilities/profileAndUploadImages";
import LikeDislike from "./LikeDislike";
import { setAnnouncementData } from "../../../../../Features/SocialSlice";
import {
  getAttachments,
  getContentLength,
  getExtension,
  getFormattedCount,
  imgArray,
} from "../../../../../Utilities/socialAllFunctions";
import ShareDetails from "./ShareDetails";
import ShowViewsList from "./ShowViewsList";
import ContentSocialDisplay from "./ContentSocialDisplay";
import AnnouncementPostDetails from "./AnnouncementPostDetails";
import AnnouncementLikeAndDislikeComponent from "./AnnouncementLikeAndDislikeComponent";
const Announcements = ({ unreadPostCount, setReRefreshProp, reRefresh }) => {
  const [totalLikesWatching, setTotalLikesWatching] = useState(false);
  const [totalDisLikesWatching, setTotalDisLikesWatching] = useState(false);
  const [likeId, setLikeId] = useState(0);
  const [dislikeId, setDislikeId] = useState(0);
  const [shareDetailsActive, setShareDetailsActive] = useState(false);
  const navigate = useNavigate();
  const [viewsPostId, setViewsPostId] = useState(0);
  const [viewsListActive, setViewsListActive] = useState(false);
  const dispatch = useDispatch();
  const { announcementData } = useSelector((state) => state.social);
  const [showContent, setShowContent] = useState(false);
  const [dataFound, setDataFound] = useState(true);
  const [sharePostData, setSharePostData] = useState();
  const [sharePostId, setSharePostId] = useState(0);
  const [loading, setLoading] = useState(false);
  const [postDataCount, setPostDataCount] = useState(-1);
  const [scrollEventFired, setScrollEventFired] = useState(0);
  const [totaloffset, setTotalOffset] = useState();
  const [offset, setOffset] = useState();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [paginationData, setPaginationData] = useState({
    total: 0,
    per_page: 2,
  });

  const scrollEventHandler = useCallback(() => {
    let innerHeight = window.innerHeight;
    let scrollTop = document.querySelector(
      ".homeEngageMainCenterContainer"
    ).scrollTop;
    let scrollHeight = document.querySelector(
      ".homeEngageMainCenterContainer"
    ).scrollHeight;
    if (
      innerHeight + scrollTop < scrollHeight - 10 ||
      loading ||
      (postDataCount !== 0 && announcementData?.length >= postDataCount)
    ) {
      return;
    }
    setOffset((offset) => offset + 2);
  }, [loading, announcementData, postDataCount]);

  useEffect(() => {
    const handleScrollEvent = () => {
      setScrollEventFired(new Date().getTime());
    };
    document
      .querySelector(".homeEngageMainCenterContainer")
      .addEventListener("scroll", handleScrollEvent);
    return () => {
      window.removeEventListener("scroll", handleScrollEvent);
    };
  }, []);

  useEffect(() => {
    const debounce = setTimeout(scrollEventHandler, 500);
    return () => clearTimeout(debounce);
  }, [scrollEventFired, scrollEventHandler]);

  useEffect(() => {
    if (
      ((typeof offset === "number" &&
        typeof totaloffset === "undefined" &&
        offset === 0) ||
        (typeof offset === "number" &&
          typeof totaloffset === "number" &&
          offset === totaloffset + 2)) &&
      !loading &&
      (postDataCount === -1 || announcementData?.length < postDataCount)
    ) {
      if (!reRefresh) {
        getData();
      }
    } else if (offset > totaloffset + 2) {
      setOffset(totaloffset + 2);
    }
  }, [offset, totaloffset]);

  useEffect(() => {
    setOffset(0);
    return () => dispatch(setAnnouncementData([]));
  }, []);

  const getData = async (freshLoad = false) => {
    setLoading(true);
    try {
      const result = await postRequest(`SocialPost/AllAnnouncementPostList`, {
        createdOn: null,
        lastUpdatedOn: null,
        dataTableRequest: {
          iDisplayLength:
            postDataCount === -1 || offset + 2 <= postDataCount || freshLoad
              ? 2
              : postDataCount - offset,
          iDisplayStart: freshLoad ? 0 : offset,
          sSearch: null,
          sorting: "Id",
          sSortDir_0: "descending",
          isExport: false,
        },
      });
      if (!result?.data?.isSuccess || result?.data?.data?.length <= 0) {
        setDataFound(false);
        setAnnouncementData([]);
      } else {
        let listData = [];
        result?.data?.data?.map((item) => {
          listData.push(item?.item);
        });

        setPostDataCount(result?.data?.summary?.allCount);
        setTotalOffset(offset);

        if (freshLoad) {
          document.querySelector(
            ".homeEngageMainCenterContainer"
          ).scrollTop = 0;
          dispatch(setAnnouncementData(listData));
          setOffset(0);
          setTotalOffset(0);
        } else {
          dispatch(setAnnouncementData([...announcementData, ...listData]));
        }

        setCurrentIndex((currentIndex) => currentIndex + 2);
        setPaginationData({
          ...paginationData,
          total: result?.data?.summary.allCount,
          per_page: paginationData.per_page,
        });
      }
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
      setReRefreshProp();
    }
  };

  const location = useLocation();
  useEffect(() => {
    document.title = `PeopleSol - Announcement`;
    return () => {
      document.title = "PeopleSol";
    };
  }, [location.pathname]);
  useEffect(() => {
    if (reRefresh) {
      getData(true);
    }
  }, [reRefresh]);
  return (
    <>
      <div className="engageMainContentContainer">
        <div className="greetingEngageHeaderContainer">
          <HeaderComponent
            icon={AnnouncementHeaderIcon}
            title={"Announcement"}
          />
        </div>
        <div className="homeEngageMainCenterContainer">
          <div className="homeEngagePostContainer">
            {announcementData.map((d, i) => {
              return (
                <div
                  className="socialAllPostContainer"
                  key={"social-item-" + i}
                >
                  <div className="engagePostContentHolder">
                    <div>
                      <div className="postHeaderContainer">
                        <div className="leftPostHeaderContainer">
                          <div className="announcementHeaderContainer">
                            <label>{d?.announcementTitle ?? "--"}</label>

                            <div className="announcementViewsContainer">
                              <span className="postHrTime">
                                •{" "}
                                {dateFormat(
                                  d?.createdOn,
                                  "hh:MM TT | dd mmm yyyy"
                                )}
                              </span>
                              <article
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setViewsListActive(true);
                                  setViewsPostId(d?.id);
                                }}
                              >
                                <img src={SocialPostViewsEyeIcon} alt="" />
                                <span style={{ marginLeft: "5px" }}>
                                  {d?.views < 10
                                    ? "0" + d?.views
                                    : getFormattedCount(d?.views)}{" "}
                                  Views
                                </span>
                              </article>
                            </div>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <div className="announcementTypeContainer">
                            <span>Announcement</span>
                            <article></article>
                            <img src={SocialPostTypeTag} alt="" />
                          </div>
                        </div>
                      </div>
                      <div className="postBodyContainer">
                        <div className="postBodyTextContainer">
                          {d?.content?.split(/\r\n|\r|\n/).length > 4 &&
                          !showContent ? (
                            <ContentSocialDisplay
                              content={getContentLength(d?.content)}
                            />
                          ) : (
                            <ContentSocialDisplay
                              content={d?.content?.trim()}
                            />
                          )}
                          {d?.content?.split(/\r\n|\r|\n/).length > 4 &&
                            !showContent && (
                              <span
                                onClick={() => {
                                  setShowContent(true);
                                }}
                              >
                                ...View More
                              </span>
                            )}
                        </div>
                        {d?.attachment &&
                          d?.attachment?.length > 0 &&
                          imgArray.includes(
                            getExtension(
                              getAttachments(d?.attachment)
                                ? getAttachments(d?.attachment)[0]
                                : ""
                            )
                          ) && (
                            <div className="">
                              <div
                                className={"newPostBodyImgMainWrapper"}
                                style={{ margin: "0" }}
                                onClick={() => {
                                  navigate("details/" + d?.id);
                                }}
                              >
                                {getAttachments(d?.attachment)?.length ===
                                  1 && (
                                  <div className="newPostBodyImgContainer">
                                    <img
                                      src={
                                        process.env.REACT_APP_.BASE_URL +
                                        socialPostImages +
                                        getAttachments(d?.attachment)[0]
                                      }
                                      alt=""
                                      className="announcementImg"
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                      </div>
                    </div>
                    <AnnouncementLikeAndDislikeComponent
                      d={d}
                      setSharePostData={setSharePostData}
                      setSharePostId={setSharePostId}
                      setLikeId={setLikeId}
                      setTotalLikesWatching={setTotalLikesWatching}
                      setDislikeId={setDislikeId}
                      setTotalDisLikesWatching={setTotalDisLikesWatching}
                      setShareDetailsActive={setShareDetailsActive}
                      postData={announcementData}
                      setPostData={setAnnouncementData}
                      getData={getData}
                    />
                  </div>
                </div>
              );
            })}
            {!dataFound && announcementData?.length <= 0 && (
              <div className="noPostfoundContainer">
                <div className="noPostfoundWrapper">
                  <img src={SocialPostNotFoundIcon} alt="" />
                  <h5>No Post Found</h5>
                  <p>No one has posted something in social</p>
                </div>
              </div>
            )}
          </div>
          {loading && <Loader />}
        </div>
      </div>
      {totalLikesWatching && (
        <LikeDislike
          id={likeId}
          setTotalLikesWatching={setTotalLikesWatching}
          totalLikesWatching={totalLikesWatching}
        />
      )}
      {totalDisLikesWatching && (
        <LikeDislike
          id={dislikeId}
          setTotalDisLikesWatching={setTotalDisLikesWatching}
          totalDisLikesWatching={totalDisLikesWatching}
        />
      )}
      <Routes>
        <Route
          path="/post/share/:postid"
          element={
            <SharePost
              sharePostData={sharePostData}
              sharePostId={sharePostId}
              getData={getData}
              getExtension={getExtension}
              getAttachments={getAttachments}
            />
          }
        />
      </Routes>
      <Routes>
        <Route path="details/:postid" element={<AnnouncementPostDetails />} />
      </Routes>
      {shareDetailsActive && (
        <ShareDetails
          setShareDetailsActive={setShareDetailsActive}
          sharePostId={sharePostId}
          setSharePostId={setSharePostId}
        />
      )}
      {viewsListActive && (
        <ShowViewsList
          viewsPostId={viewsPostId}
          module={"Announcement"}
          setViewsListActive={setViewsListActive}
          setViewsPostId={setViewsPostId}
        />
      )}
    </>
  );
};
export { Announcements };
