import React, { useState, useEffect } from "react";
import { getRequest, postRequest } from "../../../Services/axios";
import { useDispatch } from "react-redux";
import { showToast, isLoading } from "../../../Features";
import {
  IconButton,
  Table,
  TableBody,
  TableHead,
  TableRow,
} from "@mui/material";
import EditPencilIcon from "../../../Assets/EditPencilIcon.svg";
import { FieldArray, Form, Formik } from "formik";
import { LabelCustom } from "../../../Components/FormComponent/LabelCustom";
import { InputCustomFormik } from "../../../Components/FormComponent/InputCustomFormik";
import {
  StyledTableCell,
  StyledTableCont,
} from "../../../Components/FormComponent/Repeated";
import { Loader } from "../../../Components/FormComponent/Loader";
import * as Yup from "yup";
import { SlabNewTax } from "../../Tax/DeclarationComp/SlabNewTax";
import { SlabOldTax } from "../../Tax/DeclarationComp/SlabOldTax";
import { Popup } from "../../../Components/FormComponent/PopupComponent/Popup";
import { TaxSlab } from "./Forms/TaxSlab";
const PTTab = (props) => {
  const dispatch = useDispatch();
  const [editActive, setEditActive] = useState(false);
  const [initialData, setInitialData] = useState(false);
  const [loading, setLoading] = useState(true);
  const [ePFData, setEPFData] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  // const [slabs, setSlabs] = useState("New Tax Regime");
  const togglePoppup = (data) => {
    setEPFData(data);
    setIsOpen((prev) => !prev);
    // setSlabs("New Tax Regime");
  };
  const getPageData = async () => {
    try {
      setLoading(true);
      const result = await getRequest(
        "StatutoryCompliance/ProfessionalTaxList"
      );
      setInitialData(result?.data?.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getPageData();
  }, []);
  const createPageData = async (values) => {
    try {
      setLoading(true);
      const result = await postRequest(
        "StatutoryCompliance/EditProfessionalTax",
        values
      );
      getPageData();
      setEditActive(false);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - PTT `;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const submitEditCompany = (values) => {
    createPageData(values);
  };
  const activateEdit = async (id, type) => {
    try {
      setLoading(true);
      const result = await getRequest(
        `StatutoryCompliance/ProfessionalTaxDetails/${id}`
      );
      // console.log("res", result?.data);
      setEPFData(result?.data);
      if (type == "edit") setEditActive((prev) => !prev);
      else if (type == "pop") togglePoppup(result?.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };
  // console.log("values", ePFData);

  return (
    <div className="fullTableContainer centerContent">
      <div
        className="companyCard"
       
      >
        <div className="headingContainer">
          <h3>Professional Tax</h3>
        </div>
        <p
          style={{
            margin: "24px 12px 6px 12px",
            height: "21px",
            maxHeight: "21px",
          }}
        >
          This tax is levied on an employee’s income by the State Government.
          Tax slabs differ in each state.
        </p>
        {loading ? (
          <div className="noDataFound">
            <Loader />
          </div>
        ) : (
          <div
            className="moduleMainContentHolder"
            style={{ height: "calc(100% - 150px)",background:editActive?'var(--form-background)':'white' }}
          >
            {editActive ? (
              <div style={{ width: "100%" }}>
                <Formik
                  enableReinitialize={true}
                  initialValues={{
                    ...ePFData,
                    statutoryProfessionalTaxSlabs: ePFData
                      .statutoryProfessionalTaxSlabs.length
                      ? ePFData.statutoryProfessionalTaxSlabs.map((item) => {
                          return {
                            StartRange: item.startRange,
                            EndRange: item.endRange,
                            PTAmount: item.ptAmount,
                          };
                        })
                      : [
                          {
                            startRange: "0",
                            endRange: "1000",
                            PTAmount: "200",
                          },
                        ],
                  }}
                  onSubmit={submitEditCompany}
                  validationSchema={Yup.object({
                    statutoryProfessionalTaxSlabs: Yup.array().of(
                      Yup.object().shape({
                        StartRange: Yup.number()
                          .max(9999999)
                          .required("Please Enter Start Range"),
                        EndRange: Yup.number()
                          .max(9999999)
                          .required("Please Enter End range"),
                        PTAmount: Yup.number()
                          .max(9999999)
                          .required("Please Enter Amount"),
                      })
                    ),
                  })}
                >
                  <Form>
                    <div className="formcustom twoColumn">
                      <div>
                        <LabelCustom labelName={"Work Location"} />
                        <InputCustomFormik
                          name="workLocationName"
                          disabled={true}
                        />
                      </div>
                      {/* <div>
                        <LabelCustom labelName={"Deduction Cycle"} />
                        <InputCustomFormik
                          name="deductionCycle"
                          disabled={true}
                        />
                      </div> */}
                      <div>
                        <LabelCustom labelName={"PT Number"} />
                        <InputCustomFormik name="ptNumber" />
                      </div>
                    </div>
                    <div>
                      <div className="headingForm">
                        {" "}
                        Tax Slabs based on Monthly Gross Salary
                      </div>
                      <div className="repeatedSectionContainer">
                        <FieldArray name="statutoryProfessionalTaxSlabs">
                          {(fieldArrayProps) => {
                            const { insert, remove, form, replace } =
                              fieldArrayProps;
                            const { values } = form;
                            const { statutoryProfessionalTaxSlabs } = values;
                            return (
                              <StyledTableCont>
                                <Table>
                                  <TableHead>
                                    <TableRow>
                                      <StyledTableCell
                                        padding="0"
                                        style={{
                                          fontSize: "12px",
                                        }}
                                        align="center"
                                      >
                                        START RANGE (₹)
                                      </StyledTableCell>
                                      <StyledTableCell
                                        padding="0"
                                        style={{
                                          fontSize: "12px",
                                        }}
                                        align="center"
                                      >
                                        END RANGE (₹)
                                      </StyledTableCell>

                                      <StyledTableCell
                                        style={{
                                          fontSize: "12px",
                                        }}
                                        align="center"
                                      >
                                        MONTHLY TAX AMOUNT (₹)
                                      </StyledTableCell>
                                      <StyledTableCell
                                        style={{
                                          fontSize: "12px",
                                        }}
                                        align="center"
                                      >
                                        ADD/REMOVE
                                      </StyledTableCell>
                                    </TableRow>
                                  </TableHead>
                                  {statutoryProfessionalTaxSlabs?.map(
                                    (statutoryProfessionalTaxSlabs, index) => (
                                      <TableBody key={index}>
                                        <StyledTableCell>
                                          <InputCustomFormik
                                            type="number"
                                            name={`statutoryProfessionalTaxSlabs[${index}].StartRange`}
                                          />
                                        </StyledTableCell>
                                        {/* 3rd column */}
                                        <StyledTableCell>
                                          <InputCustomFormik
                                            type="number"
                                            name={`statutoryProfessionalTaxSlabs[${index}].EndRange`}
                                          />
                                        </StyledTableCell>
                                        {/* 4th column */}
                                        <StyledTableCell>
                                          <InputCustomFormik
                                            type="number"
                                            name={`statutoryProfessionalTaxSlabs[${index}].PTAmount`}
                                          />
                                        </StyledTableCell>

                                        <StyledTableCell
                                          sx={{ textAlign: "center" }}
                                        >
                                          {index > 0 && (
                                            <button
                                              type="button"
                                              className="remove-btn"
                                              onClick={() => remove(index)}
                                            >
                                              -
                                            </button>
                                          )}
                                          <button
                                            type="button"
                                            className="add-btn"
                                            onClick={() => {
                                              insert(index, {
                                                StartRange:
                                                  values
                                                    .statutoryProfessionalTaxSlabs[
                                                    index
                                                  ].StartRange,
                                                EndRange: values
                                                .statutoryProfessionalTaxSlabs[
                                                index
                                              ].EndRange,
                                                PTAmount:
                                                  values
                                                    .statutoryProfessionalTaxSlabs[
                                                    index
                                                  ].PTAmount,
                                              });
                                              replace(index + 1, {
                                                EndRange: "0",
                                                StartRange:
                                                  values
                                                    .statutoryProfessionalTaxSlabs[
                                                    index
                                                  ].EndRange+1,
                                                PTAmount:
                                                  values
                                                    .statutoryProfessionalTaxSlabs[
                                                    index
                                                  ].PTAmount,
                                              });
                                            }}
                                          >
                                            +
                                          </button>
                                        </StyledTableCell>
                                      </TableBody>
                                    )
                                  )}
                                </Table>
                              </StyledTableCont>
                            );
                          }}
                        </FieldArray>
                      </div>
                      <div
                        className="createFootCust"
                        style={{ bottom: "-6px", position: "absolute" }}
                      >
                        {editActive && (
                          <button
                            className="button secondaryButton"
                            type="button"
                            onClick={() => setEditActive((prev) => !prev)}
                          >
                            Cancel
                          </button>
                        )}
                        {editActive && (
                          <button
                            className="button primaryButton"
                            type="submit"
                          >
                            Update
                          </button>
                        )}
                      </div>
                    </div>
                  </Form>
                </Formik>
              </div>
            ) : (
              <div
                className="homeCardsHolderContainer"
                style={{ width: "100%" }}
              >
                <div
                  className="homeDashboardCardHolder"
                  style={{ height: "fit-content" }}
                >
                  {initialData?.length > 0 &&
                    initialData?.map((item) => {
                      return (
                        <div
                          className="dashboardCard internalJobCard"
                          key={item.id}
                        >
                          <div className="cardContainer">
                            <div className="headingContainer">
                              <h3>{item.workLocationName}</h3>
                              <IconButton
                                onClick={() => activateEdit(item.id, "edit")}
                                className={`${
                                  editActive && "pencilBackground"
                                }`}
                              >
                                <img
                                  src={EditPencilIcon}
                                  alt="Edit Pencil"
                                  className="editPencilIcon"
                                />
                              </IconButton>
                            </div>
                            <div className="jobDetailContainerHolder">
                              <div className="jobDetailContainer">
                                <div className="verticalLine"></div>
                                <label className="jobDesignation">
                                  PT Number
                                </label>
                                <label>{item.ptNumber ?? "NA"}</label>
                              </div>
                              {/* <div className="jobDetailContainer">
                                <div className="verticalLine"></div>
                                <label className="jobDesignation">
                                  Deduction Cycle
                                </label>
                                <label>{item.deductionCycle ?? "NA"}</label>
                              </div> */}
                            </div>
                            <div className="jobDetailContainerHolder">
                              <div className="jobDetailContainer">
                                <div className="verticalLine"></div>
                                <label className="jobDesignation">
                                  PT Slabs
                                </label>
                                <label
                                  style={{ color: "var(--primary)" }}
                                  className="cursor"
                                  onClick={() => activateEdit(item.id, "pop")}
                                >
                                  {"View Tax Slabs"}
                                </label>
                              </div>
                            </div>
                            {/* <div className="applyJobButtonContainer">
                              <div
                                style={{
                                  margin: "12px",
                                  display: "flex",
                                  gap: "6px",
                                }}
                              >
                                <label>PT Slabs</label>
                                <label
                                  style={{ color: "blue" }}
                                  className="cursor"
                                >
                                  {"View Tax Slabs"}
                                </label>
                              </div>
                            </div> */}
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      {isOpen && (
        <Popup
          contentClassName="popsC"
          handleClose={togglePoppup}
          popupHeading={"Tax Slab For Head Office"}
          content={<TaxSlab handleClose={togglePoppup} data={ePFData} />}
        />
      )}
    </div>
  );
};
export { PTTab };
