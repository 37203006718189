import React, { useEffect, useRef } from "react";
import ReactPlayer from "react-player";

const VideoPlayerComponent = ({
  chapterActive,
  clickFullscreen,
  playerRef,
  chapterCoverimg,
  modalStatus,
  playingcontroller,
  setPlayedSecs,
  checkQuestionDisplay,
  detailsVideo,
  onceOnReady,
  handlePlayPause,
  setDurationfullvideo,
  playFunction,
  sendprogress1,
  setPlayingcontroller,
  getAllAssignmentSecond,
}) => {
  const intervalRef = useRef(null); // Use a ref to store the interval ID
  const playedSecondsRef = useRef(0); // Use a ref to store the last played seconds

  // Set up the interval
  // useEffect(() => {
  //     intervalRef.current = setInterval(() => {
  //       checkQuestionDisplay(playedSecondsRef.current);
  //     }, 1500); // Call checkQuestionDisplay every 2 seconds

  //   // Clear the interval when the component unmounts or when dependencies change
  //   return () => {
  //     if (intervalRef.current) {
  //       clearInterval(intervalRef.current);
  //     }
  //   };
  // }, [playingcontroller, modalStatus]);
  console.log("playingcontroller", playingcontroller);
  console.log("modalStatus", modalStatus);
  return (
    <ReactPlayer
      // id={chapterActive}
      key={chapterActive}
      style={
        clickFullscreen
          ? {
              backgroundColor: "black",
              position: "absolute",
              left: "0px",
              top: "0px",
              bottom: "0px",
              right: "0px",
              zIndex: "10",
            }
          : {
              backgroundColor: "black",
              width: "100%",
              height: "570px",
            }
      }
      ref={playerRef}
      light={
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "black",
          }}
        >
          <img
            style={{
              objectFit: "contain",
              maxWidth: "100%",
              maxHeight: "100%",
            }}
            src={chapterCoverimg}
            alt="Cover-img"
          />
        </div>
      }
      // playing={!showAd}
      // playing={computePlayingValue()}
      onEnded={() => {
        sendprogress1(detailsVideo?.summary?.trainingCourseChapters?.duration);
        // getAllAssignmentSecond();
      }}
      playing={!modalStatus ? playingcontroller : false}
      // playing={
      //   !modalStatus
      //     ? playingcontroller
      //       ? playingcontroller
      //         ? true
      //         : false
      //       : true
      //     : false
      // }
      // onProgress={(e) => {
      //   setPlayedSecs(e.playedSeconds);
      //   if (!modalStatus) checkQuestionDisplay(e.playedSeconds);
      // }}
      onProgress={(e) => {
        setPlayedSecs(e.playedSeconds);
        if (!modalStatus) checkQuestionDisplay(e.playedSeconds);
        // playedSecondsRef.current = e.playedSeconds; // Update the current played seconds
      }}
      url={detailsVideo?.summary?.trainingCourseChapters?.videoUrl}
      controls={true}
      config={{
        file: {
          attributes: {
            controlsList: "nodownload",
          },
        },
      }}
      onReady={() => {
        onceOnReady && handlePlayPause(true);
      }}
      loop={false}
      playbackRate={1}
      volume={1}
      muted={false}
      // onError={(error) => console.error("Video error:", error)}
      onDuration={(duration) => {
        setDurationfullvideo(duration);
      }}
      onPlay={() => {
        if (!modalStatus) setPlayingcontroller(true);
      }}
      onPause={() => {
        if (!modalStatus) setPlayingcontroller(false);
      }}
      width="100%"
      height="100%"
    />
  );
};

export default VideoPlayerComponent;
