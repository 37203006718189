// AddDeductionPopup.jsx

import React, { useState } from "react";
import { CheckBox } from "../../../../Components/FormComponent/RadioGroupInput/InputStyles";

function AddDeductionPopup({
    formValues,
  onClose,
  allDeductionTypes,
  selectedDeductionTypes,
  onClick,
}) {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);
  const shouldVisible = (deductionType) => {
    if (deductionType=="esi") {
  if(formValues.annualCTC < 252000){
    if (selectedDeductionTypes.includes(deductionType)) return false;
    if (searchTerm.trim() === "") return true;

    // Case-insensitive search
    const lowerCaseDeductionType = deductionType.toLowerCase();
    const lowerCaseSearchTerm = searchTerm.toLowerCase();
    return lowerCaseDeductionType.includes(lowerCaseSearchTerm);
  }
  else return false
    }
    else{
        if (selectedDeductionTypes.includes(deductionType)) return false;
        if (searchTerm.trim() === "") return true;
    
        // Case-insensitive search
        const lowerCaseDeductionType = deductionType.toLowerCase();
        const lowerCaseSearchTerm = searchTerm.toLowerCase();
        return lowerCaseDeductionType.includes(lowerCaseSearchTerm);
    }

  };
  const toggleItem = (earningType) => {
    const updatedSelectedItems = [...selectedItems];
    const index = updatedSelectedItems.indexOf(earningType);

    if (index === -1) {
      // Item not found, add it
      updatedSelectedItems.push(earningType);
    } else {
      // Item found, remove it
      updatedSelectedItems.splice(index, 1);
    }
    setSelectedItems(updatedSelectedItems);
  };
  const earningTypeClicked = () => {
    onClick(selectedItems);
    onClose();
  };

console.log('formValues.annualCTC',formValues.annualCTC)
  return (
    <div className="alertContainer" style={{ maxHeight: "600px", padding: "16px", display:'flex',flexDirection:'column' }}>
      <h2 style={{ fontFamily: "Poppins" }}>Add Deduction</h2>

      <div className="search-box" style={{marginLeft:'14px',height:'80px'}}>
        <input
          className="search-text"
          type="text"
          placeholder="Search"
          onChange={(e) => setSearchTerm(e.target.value)}
        />
         <a className="search-btn">
            <svg
              width="17"
              height="17"
              viewBox="0 0 17 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M11.7514 11.1946L16.0236 15.4668C16.1737 15.6171 16.258 15.8209 16.258 16.0333C16.2579 16.2457 16.1734 16.4494 16.0232 16.5996C15.8729 16.7498 15.6691 16.8341 15.4567 16.834C15.2443 16.8339 15.0406 16.7495 14.8904 16.5992L10.6183 12.3269C9.34114 13.3161 7.73517 13.7816 6.12703 13.6288C4.5189 13.4759 3.02941 12.7161 1.96157 11.504C0.893729 10.2918 0.327755 8.71841 0.378784 7.10379C0.429812 5.48917 1.09401 3.95463 2.23626 2.81236C3.37851 1.67008 4.91301 1.00586 6.52758 0.954834C8.14216 0.903804 9.71554 1.46979 10.9276 2.53766C12.1397 3.60553 12.8995 5.09505 13.0524 6.70323C13.2053 8.3114 12.7406 9.91742 11.7514 11.1946ZM6.72885 12.0689C7.99247 12.0689 9.20433 11.5669 10.0978 10.6733C10.9914 9.77981 11.4933 8.56791 11.4933 7.30426C11.4933 6.04061 10.9914 4.82871 10.0978 3.93518C9.20433 3.04164 7.99247 2.53966 6.72885 2.53966C5.46523 2.53966 4.25336 3.04164 3.35985 3.93518C2.46633 4.82871 1.96436 6.04061 1.96436 7.30426C1.96436 8.56791 2.46633 9.77981 3.35985 10.6733C4.25336 11.5669 5.46523 12.0689 6.72885 12.0689Z"
                fill="#1296B0"
              />
            </svg>
          </a>
      </div>
      <p>{''}</p>
      <p className="sety">Select Deduction Types</p>
      <div className="deductionTypeListContainer" style={{fontFamily:'Poppins'}}>
      {allDeductionTypes?.map(
          (item, index) =>
            shouldVisible(item) && (
              <div key={index} className="earningTypeItem">
                <CheckBox
                   type="checkbox"
                   id={item}
                   checked={selectedItems.includes(item)}
                  onChange={() => toggleItem(item)}
                />
                <label htmlFor={item}>{item}</label>
              </div>
            )
        )}
      </div>
      <button className="button primaryButton" onClick={earningTypeClicked}>
        Add Selected Deductions
      </button>
    </div>
  );
}

export default AddDeductionPopup;
