import React from 'react';
import { TravelEligibilityTab } from './TravelEligibilityTab';
import { useEffect } from 'react';

const TravelEligibility = () => {
    useEffect(() => {
        document.title = `PeopleSol - Travel Eligibility`;  
        return () => {
          document.title = 'PeopleSol';
        };
      }, []);
    return (
        <div className="moduleMainContainer">
                    <TravelEligibilityTab title="Travel Advance Eligibility"/>

        </div>
    )
}

export { TravelEligibility }