import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { FormCustomFormik } from "../../../Components/FormComponent/FormCustomFormik";
import { useDispatch } from "react-redux";
import { showToast, isLoading } from "../../../Features";
import { getRequest, postRequest } from "../../../Services/axios";
import { useLocation } from "react-router-dom";
import { CreateTravelBookingComp } from "./FormComp/CreateTravelBookingComp";
import { useSelector } from "react-redux";
import { calculateAge } from "../../../Utilities/calculateAge";

const CreateTravelBooking = (props) => {
  const { state } = useLocation();
  useEffect(() => {
    document.title = `PeopleSol - Create Travel Booking`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const [detailsform, setDetailsform] = useState(false);

  const data = state?.id;

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState(null);

  const getFormDetails = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(
        `TravelHospitalityRequests/Details/${data}`
      );
      setDetailsform(result.data.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };

  useEffect(() => {
    if (data) getFormDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const create = async (values, url) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest(url, values);
      if (result)
        dispatch(
          showToast({ text: "Successfully Created", severity: "success" })
        );
      returnPage();
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const Edit = async (values, url) => {
    try {
      const result = await postRequest(url, values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
        returnPage();
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "some error occured",
          severity: "error",
        })
      );
    }
  };

  const getInitialValues = (item) => {
    let returnArray = [
      { name: "", phone: "", email: "", age: "", gender: "", remarks: "" },
    ];
    if (data && item) {
      if (item.length > 0) {
        returnArray = [];
        item?.map((item) => {
          return returnArray.push({
            name: item.name ?? item.employeeId,
            phone: item.phoneNumber,
            email: item.emailId,
            age: item.age,
            gender: item.gender,
            remarks: item.remarks,
          });
        });
      }
    }
    return returnArray;
  };
  const { LoggedInUser } = useSelector((state) => state.auth);
  const initialvalue = {
    Mode: detailsform ? detailsform?.mode : "Flight",
    Type: detailsform ? detailsform?.type : "Self",
    departure: detailsform
      ? detailsform?.fromDate
        ? new Date(detailsform?.fromDate)
        : ""
      : "",
    fromLocation: detailsform ? detailsform?.pickupLocation : "",
    toLocation: detailsform ? detailsform?.dropLocation : "",
    age: detailsform
      ? detailsform?.travelHospitalityEmployees[0]?.age
      : LoggedInUser?.dob
      ? calculateAge(LoggedInUser?.dob)
      : null,
    gender: detailsform
      ? detailsform?.travelHospitalityEmployees[0]?.gender
      : LoggedInUser?.gender,
    budget: detailsform ? detailsform?.budget : "",
    comments: detailsform ? detailsform?.comments : "",
    candidate: detailsform ? detailsform?.candidateId : "",
    noofDay: detailsform ? detailsform?.noofDay : null,
    duration: detailsform ? detailsform?.type : null,
    groupItems: getInitialValues(detailsform?.travelHospitalityEmployees),
  };
  const getValidationSchema = () => {
    if (formValues) {
      if (formValues.Type === "Self") return flightSelfSchema;
      else if (formValues.Type === "Group") return flightGroupSchema;
      else if (formValues.Type === "Vendor" || formValues.Type === "Guest")
        return flightVendorSchema;
      else return flightCandidateSchema;
    }
    return Yup.object({
      departure: Yup.string().required("Select Date."),
    });
  };
  const flightSelfSchema = Yup.object({
    departure: Yup.string().required(" Please Select Date."),
    fromLocation: Yup.string()
      .max(50, "Location must be less than 50 characters")
      .required("Please Enter the From Location."),
    toLocation: Yup.string()
      .max(50, "Location must be less than 50 characters")
      .required(" Please Enter the To Location."),
    budget: Yup.string()
      .max(10, "budget must be less than 10 characters")
      .required("Please Enter the Budget"),
    age: Yup.string()
      .max(2, "Age must be less than 3 characters")
      .nullable()
      .notRequired(),
    comments: Yup.string()
      .max(499, "Comments must be less than 500 characters")
      .nullable()
      .notRequired(),
  });
  const flightGroupSchema = Yup.object({
    departure: Yup.string().required("Please Select Date."),
    fromLocation: Yup.string()
      .max(50, "Location must be less than 50 characters")
      .required(" Please Enter the From Location."),
    toLocation: Yup.string()
      .max(50, "Location must be less than 50 characters")
      .required(" Please Enter the To Location."),
    budget: Yup.string()
      .max(10, "budget must be less than 10 characters")
      .required(" Please Enter the Budget."),
    comments: Yup.string()
      .max(499, "Comments must be less than 500 characters")
      .nullable()
      .notRequired(),
    groupItems: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required("Please Select Employee"),
        phone: Yup.string()
          .max(10, "Number must be equal to 10 characters")
          .required(" Please Enter Phone No. "),
        email: Yup.string()
          .email()
          .max(319, "Email must be less than 320 characters")
          .nullable()
          .notRequired(),
        remarks: Yup.string()
          .max(299, "Remarks must be less than 300 characters")
          .nullable()
          .notRequired(),
      })
    ),
  });
  const flightVendorSchema = Yup.object({
    departure: Yup.string().required("Please Select Date."),
    fromLocation: Yup.string()
      .max(50, "Location must be less than 50 characters")
      .required(" Please Enter the From Location."),
    toLocation: Yup.string()
      .max(50, "Location must be less than 50 characters")
      .required(" Please Enter the To Location."),
    budget: Yup.string()
      .max(10, "budget must be less than 10 characters")
      .required(" Please Enter the Budget."),
    comments: Yup.string()
      .max(499, "Comments must be less than 500 characters")
      .nullable()
      .notRequired(),
    groupItems: Yup.array().of(
      Yup.object().shape({
        name: Yup.string()
          .max(150, "Name must be less than 150 characters")
          .required("Please Enter Name"),
        phone: Yup.string()
          .max(10, "Number must be equal to 10 characters")
          .required(" Please Enter Phone No. "),
        email: Yup.string()
          .email()
          .max(319, "Email must be less than 320 characters")
          .nullable()
          .notRequired(),
        remarks: Yup.string()
          .max(299, "Remarks must be less than 300 characters")
          .nullable()
          .notRequired(),
      })
    ),
  });
  const flightCandidateSchema = Yup.object({
    departure: Yup.string().required("Please Select Date."),
    candidate: Yup.string().required("Please Select Candidate"),
    fromLocation: Yup.string()
      .max(50, "Location must be less than 50 characters")
      .required("Please Enter the From Location."),
    toLocation: Yup.string()
      .max(50, "Location must be less than 50 characters")
      .required(" Please Enter the To Location."),
    budget: Yup.string()
      .max(10, "budget must be less than 10 characters")
      .required("Please Enter the Budget"),
    age: Yup.string()
      .max(2, "Age must be less than 3 characters")
      .nullable()
      .notRequired(),
    comments: Yup.string()
      .max(499, "Comments must be less than 500 characters")
      .nullable()
      .notRequired(),
  });
  const onSubmit = (values) => {
    // //console.log("values", values);
    // confirmAlert({
    //   title: "Confirm to Submit",
    //   message: "You have successfully completed your Travel Request Form",
    //   buttons: [
    //     {
    //       label: "Submit and Book Return",
    //       onClick: () =>
    //         alert(
    //           "Request submitted, You will be redirected to Return ticket booking Page"
    //         ),
    //     },
    //     {
    //       label: "Submit and Book Hotel",
    //       onClick: () =>
    //         alert(
    //           "Request submitted, You will be redirected to Hotel booking Page"
    //         ),
    //     },
    //   ],
    // });
    // const edit = {
    //   ...values,
    //   id: detailsform?.id
    // };
    const payload = {
      id: data ? detailsform?.id : 0,
    };
    if (values) {
      payload["Type"] = values.Type;
      payload["Budget"] = values.budget;
      payload["Mode"] = values.Mode;
      var tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
      payload["FromDate"] = new Date(values.departure - tzoffset)
        .toISOString()
        .slice(0, -1);
      payload["PickupLocation"] = values.fromLocation;
      payload["DropLocation"] = values.toLocation;
      if (values.candidate !== "") payload["CandidateId"] = values.candidate;
      if (values.Type === "Self") {
        if (values.age !== "") payload["SelfAge"] = values.age;
        if (values.gender !== "") payload["SelfGender"] = values.gender;
      } else if (values.Type === "Candidate") {
        if (values.age !== "") payload["CandidateAge"] = values.age;
        if (values.gender !== "") payload["CandidateGender"] = values.gender;
      } else if (values.Type === "Group") {
        payload["EmployeeItems"] = getGroupRequest(values.groupItems);
      } else {
        payload["Items"] = getVendorGuestRequest(values.groupItems);
      }
      if (values.Mode === "Flight") {
        if (values.comments) payload["FlightComments"] = values.comments;
      }
      if (values.Mode === "Train") {
        if (values.comments) payload["TrainComments"] = values.comments;
      }
      if (values.Mode === "Bus") {
        if (values.comments) payload["BusComments"] = values.comments;
      }
      if (values.Mode === "Cab") {
        if (values.comments) payload["CabComments"] = values.comments;
        if (values.noofDay !== "") payload["NoofDay"] = String(values.noofDay);
      }
    }
    if (data) {
      if (values.Mode === "Train")
        Edit(payload, "TravelHospitalityRequests/Edit");
      else Edit(payload, `TravelHospitalityRequests/Edit${values.Mode}Request`);
    } else {
      if (values.Mode === "Train")
        create(payload, "TravelHospitalityRequests/Create");
      else
        create(
          payload,
          `TravelHospitalityRequests/Create${values.Mode}Request`
        );
    }
  };
  const getGroupRequest = (groupData) => {
    let returnArray = [];
    groupData?.map((item) => {
      let employee = {};
      employee["EmployeeId"] = item.name.toString();
      employee["PhoneNumber"] = item.phone.toString();
      if (item.email)
        if (item.email !== "") employee["EmailId"] = item.email.toString();
      if (item.remarks)
        if (item.remarks !== "") employee["Remarks"] = item.remarks.toString();
      if (item.age) if (item.age !== "") employee["Age"] = item.age.toString();
      if (item.gender)
        if (item.gender !== "") employee["Gender"] = item.gender.toString();
      return returnArray.push(employee);
    });
    return returnArray;
  };
  const getVendorGuestRequest = (groupData) => {
    let returnArray = [];
    groupData?.map((item) => {
      let employee = {};
      employee["Name"] = item.name.toString();
      employee["PhoneNumber"] = item.phone.toString();
      if (item.email)
        if (item.email !== "") employee["EmailId"] = item.email.toString();
      if (item.remarks)
        if (item.remarks !== "") employee["Remarks"] = item.remarks.toString();
      if (item.age) if (item.age !== "") employee["Age"] = item.age.toString();
      if (item.gender)
        if (item.gender !== "") employee["Gender"] = item.gender.toString();
      return returnArray.push(employee);
    });
    return returnArray;
  };
  const navigate = useNavigate();

  const returnPage = () => {
    if (state?.returnUrl) navigate(state?.returnUrl);
    else navigate(-1);
  };
  const chooseFormValues = (values) => {
    setFormValues(values);
  };
  return (
    <FormCustomFormik
      loading={loading}
      FormHeading={data ? "Edit Travel Booking" : "Create Travel Booking"}
      chooseFormValues={chooseFormValues}
      ButtonLabel={data ? "Edit Travel Booking" : "Create Travel Booking"}
      onSubmit={onSubmit}
      initialvalue={initialvalue}
      returnPage={returnPage}
      validationSchema={getValidationSchema}
      styles={{ maxWidth: "90%", width: "90%" }}
      propsDiv={
        <CreateTravelBookingComp
          setLoading={setLoading}
          loading={loading}
          formValues={formValues}
          editTrue={data ? true : false}
        />
      }
    />
  );
};

export { CreateTravelBooking };
