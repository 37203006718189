import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { useLocation } from "react-router-dom";
import { FormCustomFormik } from "../../../../../../../Components/FormComponent/FormCustomFormik";
import CreateSurveyComp from "./CreateSurveyComp";
import SelectCategory from "./SelectCategory";
import {
  getRequest,
  postRequestForm,
} from "../../../../../../../Services/axios";
import { useDispatch } from "react-redux";
import { showToast } from "../../../../../../../Features";

const EditSurvey = () => {
  const { state } = useLocation();
  const { id } = useParams();
  const [surveyDetailsData, setSurveyDetailsData] = useState({});
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getSurveyDetails = async () => {
    setLoading(true);
    try {
      const result = await getRequest("Survey/Details/" + id);
      setSurveyDetailsData(result?.data?.summary);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };

  const editSurveyPost = async (values) => {
    setLoading(true);
    let result = null;
    try {
      if (typeof values?.Upload === "object") {
        result = await postRequestForm("Survey/Edit", {
          id: id,
          ...values,
          Category: surveyDetailsData?.survey?.category,
          CategoryType: surveyDetailsData?.survey?.categoryType,
          SubCategoryType: surveyDetailsData?.survey?.subCategoryType ?? null,
        });
      } else {
        result = await postRequestForm("Survey/Edit", {
          id: id,
          SurveyTitle: values?.SurveyTitle,
          Description: values?.Description,
          SurveyQuestion: values?.SurveyQuestion,
          EndOn: values?.EndOn,
          StartOn: values?.StartOn,
          attachment: surveyDetailsData?.survey?.attachment,
          Category: surveyDetailsData?.survey?.category,
          CategoryType: surveyDetailsData?.survey?.categoryType,
          SubCategoryType: surveyDetailsData?.survey?.subCategoryType ?? null,
        });
      }
      navigate(-1);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - Create Survey`;
    getSurveyDetails();
    return () => {
      document.title = "PeopleSol";
    };
  }, []);

  const validationSchema = Yup.object({
    SurveyTitle: Yup.string().required("SurveyTitle Is required"),
    StartOn: Yup.string().required("StartOn Is required"),
    EndOn: Yup.string().required("EndOn Is required"),
    Description: Yup.string().required("Description Is required"),
  });

  const onSubmit = (values) => {
    editSurveyPost(values);
  };

  const returnPage = () => {
    if (state?.returnUrl) navigate(state?.returnUrl);
    else navigate(-1);
  };
  const convertDate = (d) => {
    const endOnString = surveyDetailsData?.survey?.endOn;
    const endDate = endOnString ? new Date(endOnString) : null;

    if (endDate instanceof Date && !isNaN(endDate)) {
      // Date is valid, proceed with formatting
      const formattedEndDate = new Date(
        endDate.getFullYear(),
        endDate.getMonth(),
        endDate.getDate()
      );

      return formattedEndDate;
    } else {
    }
  };

  return (
    <FormCustomFormik
      styles={{ minWidth: "80%" }}
      loading={loading}
      FormHeading={"Edit Survey"}
      ButtonLabel={"Edit Survey"}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      initialvalue={{
        SurveyTitle: surveyDetailsData?.survey?.surveyTitle,
        Description: surveyDetailsData?.survey?.description,
        Upload: surveyDetailsData?.survey?.attachment,
        StartOn: convertDate(surveyDetailsData?.survey?.startOn),
        EndOn: convertDate(surveyDetailsData?.survey?.endOn),
        SurveyQuestion: surveyDetailsData?.survey?.surveyQuestions?.map(
          (question) => ({
            QuestionText: question.questionText,
            Answers: question.surveyQuestionAnswers.map((answer) => ({
              AnswerText: answer.answerText,
            })),
            AllowMultipleAnswer: question.allowMultipleAnswer,
          })
        ),
      }}
      returnPage={returnPage}
      propsDiv={<CreateSurveyComp loading={loading} />}
    />
  );
};

export default EditSurvey;
