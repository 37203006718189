import React, { useEffect, useState } from "react";
import "./HomeEngage.css";
import {
  Connect,
  Greetings,
  Announcements,
  News,
  Events,
  Gallery,
} from "./Components/index";
import SocialPostNoticeBoardIcon from "../../../../Assets/SocialPostNoticeBoardIcon.svg";
import SocialSideBarIcon from "../../../../Assets/SocialSideBarIcon.svg";
import SocialPostSurveyIcon from "../../../../Assets/SocialPostSurveyIcon.svg";
import SocialNewsSideBarIcon from "../../../../Assets/SocialNewsSideBarIcon.svg";
import SocialEventsSideBarIcon from "../../../../Assets/SocialEventsSideBarIcon.svg";
import SocialGallerySideBarIcon from "../../../../Assets/SocialGallerySideBarIcon.svg";
import SocialGreetingsSideBarIcon from "../../../../Assets/SocialGreetingsSideBarIcon.svg";
import SocialAnnouncementSideBarIcon from "../../../../Assets/SocialAnnouncementSideBarIcon.svg";
import { Route, Routes, NavLink, useLocation } from "react-router-dom";
import Community from "./Components/Community";
import CommunityList from "./Components/CommunityList";
import CommunityViewPage from "./Components/CommunityViewPage";
import UserProfile from "./Components/UserProfile";
import CommunityAdminMemberDetails from "./Components/CommunityAdminMemberDetails";
import NoticeBoard from "./Components/NoticeBoard";
import SearchByWords from "./Components/SearchByWords";
import { getRequest } from "../../../../Services/axios";
import { useSelector } from "react-redux";
import Survey from "./Components/SurveyComp/Survey";
import SurveyDetailsPage from "./Components/SurveyComp/Components/SurveyDetailsPage";
import SurveyReportPage from "./Components/SurveyComp/Components/SurveyReportPage";
import CreateSurvey from "./Components/SurveyComp/Components/CreateSurvey";
import EditSurvey from "./Components/SurveyComp/Components/EditSurvey";
import Polls from "./Components/SurveyComp/Polls";

const HomeEngage = () => {
  const [unreadCount, setUnreadCount] = useState(0);
  const [homeLoading, setHomeLoading] = useState(false);
  const [reRefresh, setReRefresh] = useState(true);
  const [socialReRefresh, setSocialReRefresh] = useState(true);
  const [fitstsocialRedDotCounter, setfitstsocialRedDotCounter] = useState(0);
  const [socialRedDotCounter, setsocialRedDotCounter] = useState(0);
  const [firstTimeState, setfirstTimeState] = useState(false);
  const [redDotVisibility, setRedDotVisibility] = useState(false);
  const { redDotSocialData } = useSelector((state) => state.social);
  const location = useLocation();
  const unreadPostCount = async (id) => {
    try {
      const result = await getRequest(`Social/UnreadPostCount`);
      setUnreadCount(result?.data?.summary);
      setsocialRedDotCounter(result?.data?.summary?.socialCount);
      if (!firstTimeState) {
        setfitstsocialRedDotCounter(result?.data?.summary?.socialCount);
      }
      setfirstTimeState(true);
    } catch (error) {
    } finally {
    }
  };
  useEffect(() => {
    if (socialRedDotCounter - fitstsocialRedDotCounter > 0) {
      setRedDotVisibility(true);
    }
  }, [socialRedDotCounter]);

  useEffect(() => {
    setHomeLoading(true);
    unreadPostCount();
    setTimeout(() => {
      setHomeLoading(false);
    }, 500);
  }, []);
  useEffect(() => {
    const regularInterval = setInterval(() => {
      unreadPostCount();
    }, 20000);
    //calling api in every 20 seconds
    if (!location?.pathname.includes("/home/social/social")) {
      setSocialReRefresh(true);
    }
    return () => {
      clearInterval(regularInterval);
    };
  }, []);

  return (
    <div className="socialEngageContainer">
      <div className="socialEngageAside">
        <div className="socialEngageAsideMenuContainer">
          <NavLink
            className="socialMenuOptions"
            onClick={() => {
              setSocialReRefresh(true);
              setRedDotVisibility(false);
            }}
            to="/home/social/social"
          >
            <div className="socialVerticalLine"></div>
            <div className="socialMenuOptionsImg">
              <img src={SocialSideBarIcon} alt="TempIcon" />
            </div>
            <label>Social</label>{" "}
            {redDotVisibility && <span className="NoticountCss"> </span>}
          </NavLink>
          <NavLink
            onClick={() => {
              setReRefresh(true);
            }}
            className="socialMenuOptions"
            to="/home/social/announcement"
          >
            <div className="socialVerticalLine"></div>
            <div className="socialMenuOptionsImg">
              <img src={SocialAnnouncementSideBarIcon} alt="TempIcon" />
            </div>
            <label>Announcement</label>{" "}
            {!homeLoading && unreadCount?.announcementCount !== 0 && (
              <span className="NoticountCss">
                {" "}
                {unreadCount?.announcementCount}
              </span>
            )}
          </NavLink>
          <NavLink
            onClick={() => {
              setReRefresh(true);
            }}
            className="socialMenuOptions"
            to="/home/social/greetings"
          >
            <div className="socialVerticalLine"></div>
            <div className="socialMenuOptionsImg">
              <img src={SocialGreetingsSideBarIcon} alt="TempIcon" />
            </div>
            <label>Greetings</label>
          </NavLink>
          <NavLink
            onClick={() => {
              setReRefresh(true);
            }}
            className="socialMenuOptions"
            to="/home/social/event"
          >
            <div className="socialVerticalLine"></div>
            <div className="socialMenuOptionsImg">
              <img src={SocialEventsSideBarIcon} alt="TempIcon" />
            </div>
            <label>Events</label>{" "}
            {!homeLoading && unreadCount?.eventCount !== 0 && (
              <span className="NoticountCss"> {unreadCount?.eventCount}</span>
            )}{" "}
          </NavLink>
          <NavLink
            onClick={() => {
              setReRefresh(true);
            }}
            className="socialMenuOptions"
            to="/home/social/gallery"
          >
            <div className="socialVerticalLine"></div>
            <div className="socialMenuOptionsImg">
              <img src={SocialGallerySideBarIcon} alt="TempIcon" />
            </div>
            <label>Gallery</label>{" "}
            {!homeLoading && unreadCount?.galleryCount !== 0 && (
              <span className="NoticountCss"> {unreadCount?.galleryCount}</span>
            )}{" "}
          </NavLink>
          <NavLink
            onClick={() => {
              setReRefresh(true);
            }}
            className="socialMenuOptions"
            to="/home/social/news"
          >
            <div className="socialVerticalLine"></div>
            <div className="socialMenuOptionsImg">
              <img src={SocialNewsSideBarIcon} alt="TempIcon" />
            </div>
            <label>News</label>{" "}
            {!homeLoading && unreadCount?.newsCount !== 0 && (
              <span className="NoticountCss"> {unreadCount?.newsCount}</span>
            )}
          </NavLink>
          <NavLink
            onClick={() => {
              setReRefresh(true);
            }}
            className="socialMenuOptions"
            to="/home/social/noticeboard"
          >
            <div className="socialVerticalLine"></div>
            <div className="socialMenuOptionsImg">
              <img src={SocialPostNoticeBoardIcon} alt="TempIcon" />
            </div>
            <label>Notice Board</label>{" "}
            {!homeLoading && unreadCount?.noticeCount !== 0 && (
              <span className="NoticountCss"> {unreadCount?.noticeCount}</span>
            )}{" "}
          </NavLink>
          <NavLink
            onClick={() => {
              setReRefresh(true);
            }}
            className="socialMenuOptions"
            to="/home/social/survey"
          >
            <div className="socialVerticalLine"></div>
            <div className="socialMenuOptionsImg">
              <img src={SocialPostSurveyIcon} alt="TempIcon" />
            </div>
            <label>Survey & Polls</label>{" "}
            {!homeLoading && unreadCount?.noticeCount !== 0 && (
              <span className="NoticountCss"> {unreadCount?.noticeCount}</span>
            )}{" "}
          </NavLink>
        </div>
      </div>
      <div className="socialEngageMainContainer">
        <Routes>
          <Route
            path="social/social/*"
            element={
              <Connect
                unreadPostCount={unreadPostCount}
                unreadCount={unreadCount}
                socialReRefresh={socialReRefresh}
                setReRefreshProp={(flag) => {
                  setSocialReRefresh(flag);
                }}
              />
            }
          />
          <Route
            path="social/announcement/*"
            element={
              <Announcements
                unreadPostCount={unreadPostCount}
                reRefresh={reRefresh}
                setReRefreshProp={() => {
                  setReRefresh(false);
                }}
              />
            }
          />
          <Route path="social/greetings/*" element={<Greetings />} />
          <Route
            path="social/gallery/*"
            element={
              <Gallery
                unreadPostCount={unreadPostCount}
                reRefresh={reRefresh}
                setReRefreshProp={() => {
                  setReRefresh(false);
                }}
              />
            }
          />
          <Route
            path="social/event/*"
            element={
              <Events
                unreadPostCount={unreadPostCount}
                reRefresh={reRefresh}
                setReRefreshProp={() => {
                  setReRefresh(false);
                }}
              />
            }
          />
          <Route
            path="social/news/*"
            element={
              <News
                unreadPostCount={unreadPostCount}
                reRefresh={reRefresh}
                setReRefreshProp={() => {
                  setReRefresh(false);
                }}
              />
            }
          />
          <Route
            path="social/noticeboard/*"
            element={
              <NoticeBoard
                unreadPostCount={unreadPostCount}
                reRefresh={reRefresh}
                setReRefreshProp={() => {
                  setReRefresh(false);
                }}
              />
            }
          />
          <Route path="social/survey/*" element={<Survey />} />
          <Route path="social/polls/*" element={<Polls />} />
          <Route
            path="social/surveydetails/:id/*"
            element={<SurveyDetailsPage />}
          />
          <Route
            path="social/surveyreport/:id/*"
            element={<SurveyReportPage />}
          />
          <Route path="social/surveycreate/*" element={<CreateSurvey />} />
          <Route path="social/surveyedit/:id/*" element={<EditSurvey />} />
          <Route path="social/community/*" element={<CommunityList />} />
          <Route
            path="social/community/:communityid/*"
            element={<CommunityViewPage unreadPostCount={unreadPostCount} />}
          />
          <Route
            path="social/userprofile/:userid/*"
            element={<UserProfile unreadPostCount={unreadPostCount} />}
          />
          <Route path="social/search/:word/*" element={<SearchByWords />} />
        </Routes>
      </div>
      <div className="communityHomeEngageContainer">
        <Routes>
          <Route
            path="social/community/:communityid"
            element={<CommunityAdminMemberDetails />}
          />
        </Routes>
        <Community />
      </div>
    </div>
  );
};

export { HomeEngage };
