import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { FormCustomFormik } from "../../../Components/FormComponent/FormCustomFormik";
import { useDispatch } from "react-redux";
import { showToast, isLoading } from "../../../Features";
import { getRequest, postRequestForm } from "../../../Services/axios";
import { useLocation } from "react-router-dom";
import { CreateReimbursementComp } from "./FormComp/CreateReimbursementComp";
import { format } from "date-fns";

const CreateReimbursement = () => {
  const { state } = useLocation();
  const data = state?.id;

  useEffect(() => {
    document.title = `PeopleSol - Create Reimbursement`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const [formValues, setFormValues] = useState(null);
  const [editIDData, seteditIDData] = useState(false);
  const [detailsform, setDetailsform] = useState({});
  const getInitialValues = (item) => {
    let returnArray = [
      {
        Date: "",
        ExpenseTypeId: "",
        Amount: "",
        IsReceiptAvailable: "Yes",
        Upload: "",
        Remarks: "",
      },
    ];
    if (data && item) {
      if (item.length > 0) {
        returnArray = [];
        item?.map((items) => {
          return returnArray.push({
            Id: items.id,
            Date: items.date ? new Date(items.date) : null,
            ExpenseTypeId: items.expenseTypeId,
            Amount: items.amount,
            IsReceiptAvailable: items.isReceiptAvailable,
            Upload: items.expenseTypeId
              ? items.isReceiptAvailable === "Yes"
                ? items.attachment
                  ? `TravelBookings/${items.attachment}`
                  : ""
                : items.justification ?? ""
              : items.isReceiptAvailable === "Yes"
              ? items.attachment
                ? `Expenses/${items.attachment}`
                : ""
              : items.justification ?? "",

            Remarks: items?.remarks,
          });
        });
      }
    }
    return returnArray;
  };
  const getInitialValuesRepeated = (item, date, budget, bookinglist) => {
    let returnArray = [
      {
        Date: "",
        ExpenseTypeId: "",
        Amount: "",
        IsReceiptAvailable: "Yes",
        Upload: "",
        Remarks: "",
      },
    ];
    if (item) {
      if (item.length > 0) {
        returnArray = [];
        item?.map((item) => {
          return returnArray.push({
            Date: date ? new Date(date) : null,
            ExpenseTypeId: "",
            Amount: budget ? budget : "",
            IsReceiptAvailable: bookinglist[0]?.attachment ? "Yes" : "No",
            Upload: bookinglist[0]?.attachment
              ? `TravelBookings/${bookinglist[0]?.attachment}`
              : "",
            Remarks: "",
          });
        });
      }
    }
    return returnArray;
  };
  const [initialValues, setInitialValues] = useState({
    Location: "",
    CostCentre: "",
    AdvanceRequestID: null,
    Comments: "",
    Expenselist: getInitialValues(false),
  });

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const getFormDetails = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(`Expenses/Details/${data}`);
      setDetailsform(result?.data?.data);
      setInitialValues((prev) => ({
        ...prev,
        Location: data ? result?.data?.data?.location : "",
        CostCentre: data ? result?.data?.data?.costCentre : "",
        AdvanceRequestID: data ? result?.data?.data?.advanceRequestId : null,
        TravalRequestId: data ? result?.data?.data?.travalRequestId : null,
        Comments: data ? result?.data?.data?.comments : "",
        Expenselist: getInitialValues(result?.data?.data?.expenselist),
      }));
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };

  useEffect(() => {
    data ? seteditIDData(true) : seteditIDData(false);
    if (data) getFormDetails();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createPageData = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequestForm("Expenses/Create", values);
      if (result)
        dispatch(
          showToast({ text: "Successfully Created", severity: "success" })
        );
      returnPage();
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };

  const Edit = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequestForm("Expenses/Edit", values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
      }
      returnPage();
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "some error occured",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };

  const validationSchema = Yup.object({
    Location: Yup.string().required("Location Is required"),
    Expenselist: Yup.array().of(
      Yup.object().shape({
        Date: Yup.string().required("Expense Date Is required"),
        ExpenseTypeId: Yup.string().required("Expense Type Is required"),
        Amount: Yup.string().required("Claim Amount Is required"),
        IsReceiptAvailable: Yup.string().required("Required"),
        Upload: Yup.string().required("Required"),
        Remarks: Yup.string().required("Please Enter Remarks"),
      })
    ),
  });

  const onSubmit = (values) => {
    // debugger;
    const formData = new FormData();
    formData.append("Id", data ? data : 0);
    if (values?.Location) formData.append("Location", values?.Location);
    if (values?.CostCentre) formData.append("CostCentre", values?.CostCentre);
    if (values?.TravalRequestId)
      formData.append("TravalRequestId", values?.TravalRequestId);
    if (values?.AdvanceRequestID)
      formData.append("AdvanceRequestID", values?.AdvanceRequestID);
    if (values?.Comments) formData.append("Comments", values?.Comments);
    if (values?.Expenselist.length > 0) {
      values?.Expenselist.forEach(function (object, index) {
        // debugger;
        if (object.Id) formData.append(`Expenselist[${index}].Id`, object.Id);
        if (object.IsReceiptAvailable)
          formData.append(
            `Expenselist[${index}].IsReceiptAvailable`,
            object.IsReceiptAvailable
          );
        if (object.ExpenseTypeId)
          formData.append(
            `Expenselist[${index}].ExpenseTypeId`,
            object.ExpenseTypeId
          );
        if (object.Date)
          formData.append(
            `Expenselist[${index}].Date`,
            object.Date ? `${format(object.Date, "MM/dd/yyyy")}` : ""
          );
        if (object.Amount)
          formData.append(`Expenselist[${index}].Amount`, object.Amount);
        if (object.IsReceiptAvailable === "Yes") {
          if (typeof object.Upload === "object")
            formData.append(`Expenselist[${index}].Upload`, object.Upload);
          if (typeof object.Upload === "string")
            formData.append(
              `Expenselist[${index}].Attachment`,
              object.Upload.replace("TravelBookings/", "")
            );
        } else {
          formData.append(`Expenselist[${index}].Justification`, object.Upload);
        }
        if (object.Remarks)
          formData.append(`Expenselist[${index}].Remarks`, object.Remarks);
      });
    }
    data ? Edit(formData) : createPageData(formData);
  };

  const navigate = useNavigate();

  const returnPage = () => {
    if (state?.returnUrl) navigate(state?.returnUrl);
    else navigate(-1);
  };
  const chooseFormValues = (values) => {
    setFormValues(values);
  };
  return (
    <FormCustomFormik
      styles={{ minWidth: "80%" }}
      loading={loading}
      FormHeading={data ? "Edit Reimbursement" : "Create Reimbursement"}
      ButtonLabel={data ? "Edit Reimbursement" : "Create Reimbursement"}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      chooseFormValues={chooseFormValues}
      initialvalue={initialValues}
      returnPage={returnPage}
      propsDiv={
        <CreateReimbursementComp
          editIDData={editIDData}
          seteditIDDatafunc={() => seteditIDData(false)}
          formValues={formValues}
          loading={loading}
          setInitialValues={setInitialValues}
          getInitialValuesRepeated={getInitialValuesRepeated}
        />
      }
    />
  );
};

export { CreateReimbursement };
