import React from "react";
import { useState } from "react";
import SurveyHeader from "./SurveyHeader";
import { useEffect } from "react";
import { postRequest } from "../../../../../../../Services/axios";
import { useDispatch } from "react-redux";
import { showToast } from "../../../../../../../Features";
import SurveyAndPollPostHeader from "./SurveyAndPollPostHeader";
import SurveyAndPollImages from "./SurveyAndPollImages";
import SurveyAndPollTitle from "./SurveyAndPollTitle";
import SurveyAndPollDescription from "./SurveyAndPollDescription";
import QuestionAnswerContainer from "./QuestionAnswerContainer";
import { useRef } from "react";
import { useSelector } from "react-redux";
import { setSurveyData } from "../../../../../../../Features/SocialSlice";
import PollQuestionAnswerContainer from "./PollQuestionAnswerContaner";

function SurvayMainDisplayList({ type }) {
  const [selectedData, setSelectedData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [surveyApiCall, setSurveyApiCall] = useState(
    type === "survey" ? "mySurvey" : "myPoll"
  );
  const dispatch = useDispatch();
  const { surveyData } = useSelector((state) => state.social);

  const [selectSurveyType, setSelectSurveyType] = useState([
    { label: "My Survey", value: "mySurvey" },
  ]);
  const [selectPollType, setSelectPollType] = useState([
    { label: "My Poll", value: "myPoll" },
  ]);

  const getSurveyDataList = async () => {
    setLoading(true);
    try {
      let result = null;
      if (surveyApiCall === "mySurvey") {
        result = await postRequest(`Survey/MyList`, {
          createdOn: null,
          lastUpdatedOn: null,
          dataTableRequest: {
            iDisplayLength: 100,
            iDisplayStart: 0,
            sSearch: null,
            sorting: "Id",
            sSortDir_0: "descending",
            isExport: false,
          },
        });
      } else if (surveyApiCall === "otherSurvey") {
        result = await postRequest(`Survey/OtherList`, {
          createdOn: null,
          lastUpdatedOn: null,
          dataTableRequest: {
            iDisplayLength: 100,
            iDisplayStart: 0,
            sSearch: null,
            sorting: "Id",
            sSortDir_0: "descending",
            isExport: false,
          },
        });
      } else if (surveyApiCall === "myPoll") {
        result = await postRequest(`Survey/PollMyList`, {
          createdOn: null,
          lastUpdatedOn: null,
          dataTableRequest: {
            iDisplayLength: 100,
            iDisplayStart: 0,
            sSearch: null,
            sorting: "Id",
            sSortDir_0: "descending",
            isExport: false,
          },
        });
      } else if (surveyApiCall === "otherPoll") {
        result = await postRequest(`Survey/PollOtherList`, {
          createdOn: null,
          lastUpdatedOn: null,
          dataTableRequest: {
            iDisplayLength: 100,
            iDisplayStart: 0,
            sSearch: null,
            sorting: "Id",
            sSortDir_0: "descending",
            isExport: false,
          },
        });
      }
      if (result?.data?.isSuccess) {
        let listData = [];
        result?.data?.data?.map((item) => {
          listData.push(item?.item);
        });
        dispatch(setSurveyData(listData));
      }
    } catch (error) {
      dispatch(
        showToast({
          text: "Something went wrong!",
          severity: "error",
        })
      );
      return;
    } finally {
      setLoading(false);
    }
  };

  const responseSurvey = async (data) => {
    try {
      const result = await postRequest("Survey/Respondents", data);
    } catch (error) {
      dispatch(
        showToast({
          text: "Some error occurred in fetching data",
          severity: "error",
        })
      );
    }
  };
  const updateSurveyValue = (value) => {
    setSurveyApiCall(value);
  };

  useEffect(() => {
    getSurveyDataList();
  }, [selectSurveyType, surveyApiCall]);

  useEffect(() => {
    return () => dispatch(setSurveyData([]));
  }, []);

  const selectedDataRef = useRef(selectedData);
  useEffect(() => {
    selectedDataRef.current = selectedData;
  }, [selectedData]);
  useEffect(() => {
    const cleanupFunction = () => {
      responseSurvey(selectedDataRef.current);
    };

    return cleanupFunction;
  }, []);

  return (
    <div className="survayMainDisplayListContainer">
      <SurveyHeader
        hType={type}
        selectSurveyPostType={
          type === "survey" ? selectSurveyType : selectPollType
        }
        setSelectSurveyPostType={
          type === "survey" ? setSelectSurveyType : setSelectPollType
        }
        updateSurveyValue={updateSurveyValue}
      />
      <div className="survayMainDisplayListWrapper">
        {surveyData?.map((surveyData, index) => {
          return (
            <div className="survayMainDisplayList">
              <SurveyAndPollPostHeader hData={surveyData} type={type} />
              <div>
                <SurveyAndPollTitle titleData={surveyData} type={type} />
                <SurveyAndPollImages imgData={surveyData} />
                <SurveyAndPollDescription descriptionData={surveyData} />
              </div>
              {type === "survey" && (
                <QuestionAnswerContainer
                  questionData={surveyData}
                  setSelectedData={setSelectedData}
                  selectedData={selectedData}
                />
              )}
              {type === "poll" && (
                <PollQuestionAnswerContainer
                  questionData={surveyData}
                  setSelectedData={setSelectedData}
                  selectedData={selectedData}
                />
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default SurvayMainDisplayList;
