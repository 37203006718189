/* eslint-disable react-hooks/exhaustive-deps */
import { Form, Formik } from "formik";
import React from "react";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { useLocation, useNavigate } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import { useState } from "react";
import { getRequest, postRequest } from "../../../../Services/axios";
import { GroupBy } from "../../../../Utilities";
import { useEffect } from "react";
import { SelectForm } from "../../../../Components/FormComponent/SelectForm";
import { IconButton, Tooltip } from "@mui/material";
import { useDispatch } from "react-redux";
import { showToast } from "../../../../Features";
import * as Yup from "yup";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import { GroupObjectBy } from "../../../../Utilities/GroupObjectBy";
function SalaryMapEmployee({ eId, employee }) {
  const { state } = useLocation();
  const eid = state?.id;
  const data = state?.data;
  const emp = state?.employee;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  function convertToIndianNumberingSystem(number) {
    const words = [
      "",
      "One",
      "Two",
      "Three",
      "Four",
      "Five",
      "Six",
      "Seven",
      "Eight",
      "Nine",
      "Ten",
      "Eleven",
      "Twelve",
      "Thirteen",
      "Fourteen",
      "Fifteen",
      "Sixteen",
      "Seventeen",
      "Eighteen",
      "Nineteen",
    ];
    const tens = [
      "",
      "",
      "Twenty",
      "Thirty",
      "Forty",
      "Fifty",
      "Sixty",
      "Seventy",
      "Eighty",
      "Ninety",
    ];

    if (number === 0) {
      return "Zero";
    }

    // Function to process two digits at a time
    function processTwoDigits(n) {
      let result = "";
      if (n > 19) {
        result += tens[Math.floor(n / 10)];
        if (n % 10 !== 0) {
          result += " " + words[n % 10];
        }
      } else {
        result += words[n];
      }
      return result;
    }

    const crore = Math.floor(number / 10000000);
    const lakh = Math.floor((number % 10000000) / 100000);
    const thousand = Math.floor((number % 100000) / 1000);
    const hundreds = Math.floor((number % 1000) / 100);
    const remainder = number % 100;

    let result = "";

    if (crore > 0) {
      result += processTwoDigits(crore) + " Crore ";
    }

    if (lakh > 0) {
      result += processTwoDigits(lakh) + " Lakh ";
    }

    if (thousand > 0) {
      result += processTwoDigits(thousand) + " Thousand ";
    }

    if (hundreds > 0) {
      result += processTwoDigits(hundreds) + " Hundred ";
    }

    if (remainder > 0) {
      result += processTwoDigits(remainder);
    }

    return result.trim();
  }
  const [totalYearlyEarnings, setTotalYearlyEarnings] = useState(0);
  const [totalMonthlyEarnings, setTotalMonthlyEarnings] = useState(0);
  const [loading, setLoading] = useState(false);
  const [template, setTemplate] = useState([]);
  const [formValues, setFormValues] = useState(null);
  const [detailsForm, setDetailsForm] = useState(false);
  const [earningGroup, setEarningGroup] = useState([]);
  const [reimbursementGroup, setreimbursementGroup] = useState([]);
  const [deductionGroup, setDeductionGroup] = useState([]);
  const [selectedEarningGroup, setSelectedEarningGroup] = useState([]);
  const [selectedreimbursementGroup, setSelectedreimbursementGroup] = useState(
    []
  );
  const [selectedDeductionGroup, setSelectedDeductionGroup] = useState([]);
  const [initialValues, setInitialValues] = useState({
    templateID: data ? data[0]?.templateId : "",
    annualCTC: data ? data[0]?.costToCompanyAnnual : detailsForm?.annualCTC,
    earnings: {
      Basic: {
        Type: "Basic",
        CalculationType: "Percentage of CTC",
        DisplayName: "Basic",
      },
    },
    reimbursements: {},
    deductions: {},
  });
  const getDropdownReimbData = async () => {
    try {
      setLoading(true);
      let result = await getRequest(
        "SalaryComponents/reimbursmentDropdown?state=true"
      );
      if (result) {
        let tempEarningGroup = GroupBy(result?.data?.data, "name");
        setreimbursementGroup(tempEarningGroup);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  const getDropdownDeductionData = async () => {
    try {
      setLoading(true);
      let result = await getRequest(
        "SalaryComponents/DeductionDropdown?state=true"
      );
      if (result) {
        let tempDeductionGroup = GroupObjectBy(result?.data?.summary);
        setDeductionGroup(tempDeductionGroup);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  const getFormDetails = async () => {
    setSelectedEarningGroup([]);
    try {
      setLoading(true);
      const result = await getRequest(
        `SalaryTemplate/Details/${formValues?.templateID}`
      );
      setDetailsForm(result?.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };
  const getMonthlyYearlyValueDeduction = (deduction) => {
    let name;
    if (deduction?.type === "EPF Employer Contribution") {
      name = "epf";
    } else {
      name = "esi";
    }
if(formValues?.earnings['Dearness Allowance']?.Yearly!==undefined){
  
  let calcParam = Number(formValues?.deductions[name]?.CalculationValue);
  const monthlyValue = parseFloat(
    ((formValues?.earnings?.Basic?.Yearly+formValues.earnings['Dearness Allowance'].Yearly) * calcParam) / 100 / 12
  );
  const yearlyValue = parseFloat(((formValues?.earnings?.Basic?.Yearly+formValues.earnings['Dearness Allowance'].Yearly) * calcParam) / 100);

  return {
    Monthly: isNaN(monthlyValue) ? 0 : monthlyValue,
    Yearly: isNaN(yearlyValue) ? 0 : yearlyValue,
  }
}else{
    let calcParam = Number(formValues?.deductions[name]?.CalculationValue);
    const monthlyValue = parseFloat(
      (formValues?.earnings?.Basic?.Yearly * calcParam) / 100 / 12
    );
    const yearlyValue = parseFloat((formValues?.earnings?.Basic?.Yearly * calcParam) / 100);
  
    return {
      Monthly: isNaN(monthlyValue) ? 0 : monthlyValue,
      Yearly: isNaN(yearlyValue) ? 0 : yearlyValue,
  }
}
  };
  const removeItemDeduction = (deductionType) => {
    const updatedArray = selectedDeductionGroup.filter(
      (element) => element !== deductionType
    );
    const updatedInitialValue = { ...formValues };

    const { [deductionType]: removedValue, ...remainingDeduction } =
      updatedInitialValue.deductions;

    updatedInitialValue.deductions = remainingDeduction;
    setInitialValues(updatedInitialValue);
    setSelectedDeductionGroup(updatedArray);
  };
  const getTemplateDropdown = async () => {
    try {
      setLoading(true);
      const result = await getRequest(`SalaryTemplate/GetTemplateDropdown`);
      let listData = [];
      result?.data?.results?.map((item) => {
        return listData?.push({ label: item?.text, value: item?.id });
      });
      setTemplate(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };
  const [basicMonthlyAmount, setBasicMonthlyAmount] = useState(20000);
  const getDropdownData = async () => {
    try {
      setLoading(true);
      let result = await getRequest(
        "SalaryComponents/EarningDropdown?state=true"
      );
      if (result) {
        let tempEarningGroup = GroupBy(result?.data?.data, "earningName");
        setEarningGroup(tempEarningGroup);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (formValues?.templateID) getFormDetails();
    getDropdownReimbData();
    getDropdownDeductionData();
    getDropdownData();
  }, [formValues?.templateID]);
  const returnPage = () => {
    navigate(-1);
  };
  const populateEarningsFromTemplateValues = (templateValues) => {
    const earnings = {};
    const reimbursements = {};
    const deductions = {};
    const dd = [];
    const rr = [];
    const deduc = [];
    setSelectedEarningGroup([]);
    setSelectedreimbursementGroup([]);
    setSelectedDeductionGroup([]);
    templateValues.forEach((earning) => {
    console.log('earning',earning)
     if (earning.salaryComponents === "Earnings") {
        dd.push(earning.type);
        if (earning.type === "Fixed Allowance") {
          const key = earning.type;
          earnings[key] = {
            templateId: earning.templateId,
            id: earning.id,
            Type: earning.type,
            CalculationType: "Fixed",
            DisplayName: earning.displayName,
            CalculationValue: "0",
          };
        } else {
          const key = earning.type;
          earnings[key] = {
            Type: earning.type,
            CalculationType: earning.calculationType,
            DisplayName: earning.displayName,
            Monthly: earning.monthlyAmount,
            Yearly: earning.annualAmount,
            templateId: earning.templateId,
            id: earning.id,
            CalculationValue: earning.calculationValue?.toString(),
          };
        }
      }else if (earning.salaryComponents === "Reimbursements") {
        rr.push(earning.type);
        const key = earning.type;
        reimbursements[key] = {
          Type: earning.type,
          name: earning.displayName,
          Monthly: earning.monthlyAmount,
          Yearly: earning.annualAmount,
          templateId: earning.templateId,
          id: earning.id,
          maximumReimbursementAmount: earning?.calculationValue?.toString(),
        };
      } else {
        if (earning.type === "ESI Employer Contribution") deduc.push("esi");
        if (earning.type === "EPF Employer Contribution") deduc.push("epf");

        let key;
        if (earning.type === "ESI Employer Contribution") key = "esi";
        if (earning.type === "EPF Employer Contribution") key = "epf";
        deductions[key] = {
          Type: earning.type,
          CalculationType: earning.calculationType,
          DisplayName: earning.type,
          Monthly: earning.monthlyAmount,
          Yearly: earning.annualAmount,
          templateId: earning.templateId,
          id: earning.id,
          CalculationValue: earning.calculationValue?.toString(),
        };
      }
    });

    const filteredDD = dd.filter((type) => {
      return type !== "Fixed Allowance";
    });

    setSelectedEarningGroup((prevSelectedEarningGroup) => [
      ...prevSelectedEarningGroup,
      ...filteredDD,
    ]);
    setSelectedreimbursementGroup((prevSelectedReimbursementGroup) => [
      ...prevSelectedReimbursementGroup,
      ...rr,
    ]);
    setSelectedDeductionGroup((prevSelectedDeductionGroup) => [
      ...prevSelectedDeductionGroup,
      ...deduc,
    ]);

    const updatedFormValues = {
      ...formValues,
      deductions:deductions,
      earnings: earnings,
      reimbursements: reimbursements,
    };

    setInitialValues(updatedFormValues);
  };
  useEffect(() => {
    getTemplateDropdown();
  }, []);
  useEffect(() => {
    if (data?.length) {
      if (formValues?.templateID === data[0]?.templateId && detailsForm) {
        populateEarningsFromTemplateValues(data);
      } else if (
        detailsForm?.length &&
        formValues?.templateID !== data[0]?.templateId
      ) {
        populateEarningsFromTemplateValues(detailsForm?.templateValues);
      }
      else{
        if (detailsForm?.templateValues?.length)
        populateEarningsFromTemplateValues(detailsForm?.templateValues);
    }
      
    } else {
      if (detailsForm?.templateValues?.length)
        populateEarningsFromTemplateValues(detailsForm?.templateValues);
    }
  }, [detailsForm]);
  useEffect(() => {
    document.title = `PeopleSol - Create Payroll Template `;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const removeItemReimbursement = (earningType) => {
    const updatedArray = selectedreimbursementGroup.filter(
      (element) => element !== earningType
    );
    const updatedInitialValue = { ...formValues };

    // Use destructuring to remove the earningType key from earnings
    const { [earningType]: removedValue, ...remainingEarnings } =
      updatedInitialValue.reimbursements;

    // Update the earnings property with the modified object
    updatedInitialValue.reimbursements = remainingEarnings;
    setInitialValues(updatedInitialValue);
    setSelectedreimbursementGroup(updatedArray);
  };
  const removeItem = (earningType) => {
    const updatedArray = selectedEarningGroup.filter(
      (element) => element !== earningType
    );
    const updatedInitialValue = { ...formValues };

    // Use destructuring to remove the earningType key from earnings
    const { [earningType]: removedValue, ...remainingEarnings } =
      updatedInitialValue.earnings;

    // Update the earnings property with the modified object
    updatedInitialValue.earnings = remainingEarnings;

    setInitialValues(updatedInitialValue);

    setSelectedEarningGroup(updatedArray);
  };

  const getMonthlyYearlyValue = (earning) => {
    let calcParam =
      earning?.earningType === "Custom Allowance"
        ? formValues?.earnings[earning?.nameInPayslip]?.CalculationValue
        : formValues?.earnings[earning?.earningType]?.CalculationValue;

    if (earning?.calculationType === "Percentage of Basic") {
      const monthlyValue =
        (parseFloat(basicMonthlyAmount) * (parseFloat(calcParam) / 100)) / 12;
      const yearlyValue =
        parseFloat(basicMonthlyAmount) * (parseFloat(calcParam) / 100);

      return {
        Monthly: isNaN(monthlyValue) ? 0 : monthlyValue,
        Yearly: isNaN(yearlyValue) ? 0 : yearlyValue,
      };
    } else if (earning?.calculationType === "Percentage of CTC") {
      const monthlyValue =
        (parseFloat(formValues.annualCTC) * (parseFloat(calcParam) / 100)) / 12;
      const yearlyValue =
        parseFloat(formValues.annualCTC) * (parseFloat(calcParam) / 100);

      return {
        Monthly: isNaN(monthlyValue) ? 0 : monthlyValue,
        Yearly: isNaN(yearlyValue) ? 0 : yearlyValue,
      };
    } else {
      const monthlyValue = parseFloat(calcParam);
      const yearlyValue = parseFloat(calcParam) * 12;

      return {
        Monthly: isNaN(monthlyValue) ? 0 : monthlyValue,
        Yearly: isNaN(yearlyValue) ? 0 : yearlyValue,
      };
    }
  };

  useEffect(() => {
    let earning = earningGroup?.["Basic"]?.[0];
    let calcParam = formValues?.earnings["Basic"]?.CalculationValue;
    if (earning?.calculationType !== "Flat Amount") {
      if (
        isNaN(parseFloat(formValues?.annualCTC) * (parseFloat(calcParam) / 100))
      )
        setBasicMonthlyAmount(0);
      else {
        setBasicMonthlyAmount(
          parseFloat(formValues?.annualCTC) * (parseFloat(calcParam) / 100)
        );
      }
    } else if (isNaN(parseFloat(calcParam))) {
      setBasicMonthlyAmount(0);
    } else {
      setBasicMonthlyAmount(calcParam);
    }
  }, [formValues?.annualCTC, formValues?.earnings]);

  const createPageData = async (values) => {
    try {
      setLoading(true);
      await postRequest(
        `EmployeeSalaryDetails/EmployeeMappingWithSalaryTemplate`,
        values
      );
      dispatch(
        showToast({ text: "Successfully Created", severity: "success" })
      );
    } catch (error) {
      dispatch(showToast({ text: error?.response?.data, severity: "error" }));
    } finally {
      setLoading(false);
      navigate(-1);
    }
  };
  const getMonthlyYearlyValueReimb = (reimbursement) => {
    let calcParam =
      formValues?.reimbursements[reimbursement?.reimbursementType]
        ?.maximumReimbursementAmount;
    const monthlyValue = parseFloat(calcParam);
    const yearlyValue = parseFloat(calcParam) * 12;

    return {
      Monthly: isNaN(monthlyValue) ? 0 : monthlyValue,
      Yearly: isNaN(yearlyValue) ? 0 : yearlyValue,
    };
  };
  useEffect(() => {
    let earning = earningGroup?.["Basic"]?.[0];
    let calcParam = formValues?.earnings["Basic"]?.CalculationValue;
    if (earning?.calculationType !== "Flat Amount") {
      if (
        isNaN(parseFloat(formValues?.annualCTC) * (parseFloat(calcParam) / 100))
      )
        setBasicMonthlyAmount(0);
      else {
        setBasicMonthlyAmount(
          parseFloat(formValues?.annualCTC) * (parseFloat(calcParam) / 100)
        );
      }
    } else if (isNaN(parseFloat(calcParam))) {
      setBasicMonthlyAmount(0);
    } else {
      setBasicMonthlyAmount(calcParam);
    }
  }, [formValues?.annualCTC]);
  // useEffect(() => {
  //   let yearlyTotal = 0;
  //   let monthlyTotal = 0;

  //   for (const key in formValues?.earnings) {
  //     const earning = formValues?.earnings[key];
  //     if (earning?.Yearly) {
  //       yearlyTotal += earning?.Yearly;
  //     }
  //     if (earning?.Monthly) {
  //       monthlyTotal += earning?.Monthly;
  //     }
  //   }
  //   for (const key in formValues?.deductions) {
  //     const deduction = formValues?.deductions[key];
  //     if (deduction?.Yearly) {
  //       yearlyTotal += deduction?.Yearly;
  //     }
  //     if (deduction?.Monthly) {
  //       monthlyTotal += deduction?.Monthly;
  //     }
  //   }

  //   setTotalYearlyEarnings(formValues?.annualCTC - yearlyTotal);
  //   setTotalMonthlyEarnings(formValues?.annualCTC / 12 - monthlyTotal);
  // }, [formValues?.earnings]);
  useEffect(() => {
    // Initialize total yearly and monthly earnings to 0
    let yearlyTotal = 0;
    let monthlyTotal = 0;
    for (const key in formValues?.deductions) {
      const deduction = formValues?.deductions[key];
      if (deduction?.Yearly) {
        yearlyTotal += deduction?.Yearly;
      }
      if (deduction?.Monthly) {
        monthlyTotal += deduction?.Monthly;
      }
    }
    // Iterate through the earnings object and calculate the totals
    for (const key in formValues?.earnings) {
      const earning = formValues?.earnings[key];
      if (earning?.Yearly) {
        yearlyTotal += earning?.Yearly;
      }
      if (earning?.Monthly) {
        monthlyTotal += earning?.Monthly;
      }
    }
    setTotalYearlyEarnings(formValues?.annualCTC - yearlyTotal);
    setTotalMonthlyEarnings(formValues?.annualCTC / 12 - monthlyTotal);
  }, [basicMonthlyAmount, formValues?.earnings]);
  return (
    <div
      className="moduleMainContainer"
      style={{
        height: !data?.length && "100%",
        margin: !data?.length && 0,
        padding: !data?.length && 0,
      }}
    >
      {!formValues?.templateID && (
        <p
          className="form-error"
          style={{ fontSize: "14px", fontWeight: "500" }}
        >
          {" "}
          Kindly map {employee} salary to proceed with the employee payroll
        </p>
      )}
      {data?.length && (
        <div
          className="tableHeaderContainer"
          style={{
            padding: "0 12px",
            boxShadow: "0px 1px 6px rgb(0 0 0 / 5%)",
            marginBottom: "6px",
          }}
        >
          <IconButton
            onClick={() => {
              navigate(-1);
            }}
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.368615 3.35738C0.173352 3.55265 0.173352 3.86923 0.368615 4.06449L3.5506 7.24647C3.74586 7.44173 4.06244 7.44173 4.2577 7.24647C4.45296 7.05121 4.45296 6.73463 4.2577 6.53936L1.42927 3.71094L4.2577 0.88251C4.45296 0.687248 4.45296 0.370666 4.2577 0.175404C4.06244 -0.0198586 3.74586 -0.0198586 3.5506 0.175404L0.368615 3.35738ZM18.749 4.21094C19.0252 4.21094 19.249 3.98708 19.249 3.71094C19.249 3.4348 19.0252 3.21094 18.749 3.21094V4.21094ZM0.722168 4.21094H18.749V3.21094H0.722168V4.21094Z"
                fill="#1296B0"
              />
            </svg>
          </IconButton>
          <h5>Salary Edit ({emp})</h5>
        </div>
      )}
      <div
        style={{
          height: data?.length ? "calc(100% - 60px)" : "100%",
          backgroundColor: "var(--form-background)",
          position: "relative",
        }}
      >
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={Yup.object({
            templateID: Yup.string().required("Template Is Required"),
            annualCTC: Yup.string().trim().required("AnnualCTC  Is Required"),
          })}
          onSubmit={(values) => {
            const modifiedValues = { ...values };

            for (const key in modifiedValues.earnings) {
              const earning = modifiedValues.earnings[key];
              if (earning.Monthly) {
                earning.MonthlyAmount = Number(earning.Monthly.toFixed(0));
                delete earning.Monthly; // Remove the old key
              }
              if (earning.Yearly) {
                earning.AnnualAmount = Number(earning.Yearly.toFixed(0));
                delete earning.Yearly; // Remove the old key
              }
            }

            // Create the payload in the desired format
            const payload = {
              // Id: detailsForm ? detailsForm.id : 0,

              TemplateValues: Object.keys(modifiedValues.earnings).map(
                (key) => ({
                  ...modifiedValues.earnings[key],
                  IsEnable: true,
                  templateId: detailsForm ? detailsForm.id : 0,
                  RevisionId: 0,
                  PayrollEmployeeId: eId ?? eid,
                  CostToCompanyAnnual: modifiedValues.annualCTC,
                  SalaryComponents: "Earnings",
                })
              ),
            };
            for (const key in values.deductions) {
              const deduction = values.deductions[key];
              if (deduction.Monthly) {
                deduction.MonthlyAmount = Number(deduction.Monthly.toFixed(0));
                delete deduction.Monthly;
              }
              if (deduction.Yearly) {
                deduction.AnnualAmount = Number(deduction.Yearly.toFixed(0));
                delete deduction.Yearly;
              }

              payload.TemplateValues.push({
                ...deduction,
                IsEnable: true,
                SalaryComponents: "Deductions",
                CalculationType: "Flat Amount",
                DisplayName:deduction.Type
              });
            }
            for (const key in values.reimbursements) {
              const reimbursement = values.reimbursements[key];
              if (reimbursement.Monthly) {
                reimbursement.MonthlyAmount = Number(
                  reimbursement.Monthly.toFixed(0)
                );
                delete reimbursement.Monthly;
              }
              if (reimbursement.Yearly) {
                reimbursement.AnnualAmount = Number(
                  reimbursement.Yearly.toFixed(0)
                );
                delete reimbursement.Yearly;
              }

              payload.TemplateValues.push({
                ...reimbursement,
                IsEnable: true,
                SalaryComponents: "Reimbursements",
                CalculationType: "Flat Amount",
                DisplayName: reimbursement?.name,
                calculationValue: reimbursement?.maximumReimbursementAmount,
              });
            }
    
            createPageData(payload.TemplateValues);
          }}
        >
          {({ values }) => {
            setFormValues(values);
            return (
              <Form
                style={{
                  height: "100%",
                  position: data?.length && "relative",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    right: "28%",
                    top: data ? "7%" : "9%",
                    width: "150px",
                    wordWrap: "break-word",
                  }}
                >
                  <LabelCustom
                    labelName={
                      formValues?.annualCTC > 0
                        ? `${convertToIndianNumberingSystem(
                            isNaN(formValues?.annualCTC)
                              ? 0
                              : formValues?.annualCTC
                          )} only`
                        : ""
                    }
                  />
                </div>
                <div className="formcustom threeColumn">
                  <div>
                    <LabelCustom
                      labelName="Salary Templates"
                      mandatory={true}
                    />
                    <SelectForm
                      // disabled={emp ? true : false}
                      name="templateID"
                      options={template}
                      placeholder="Enter template name"
                    />
                  </div>
                  <div>
                    <LabelCustom labelName="Annual CTC" mandatory={true} />
                    <InputCustomFormik
                      style={{ width: "140px" }}
                      name="annualCTC"
                      type="number"
                      placeholder="Enter Annual CTC (in ₹)"
                    />
                  </div>
                </div>

                {formValues?.templateID && (
                  <div
                    style={{ height: "1px" }}
                    className="horizontalLine"
                  ></div>
                )}
                {formValues?.templateID && (
                  <>
                    <div
                      className="templateTableContainer"
                      style={{ height: "calc(100% - 280px)" }}
                    >
                      <table
                        style={{ width: "calc(100% - 54px)", margin: "12px" }}
                      >
                        <thead style={{ textAlign: "left" }}>
                          <tr className="header-row template-table-header">
                            <th>Salary Component</th>
                            <th>
                              Calculation Type{" "}
                              <span style={{ paddingLeft: "32px" }}>
                                Description
                              </span>
                            </th>
                            <th>Monthly Amount</th>
                            <th>Annual Amount</th>
                            <th style={{ paddingRight: "18px" }}></th>
                          </tr>
                        </thead>
                        <tbody className="table-body">
                          {selectedEarningGroup?.map((earningType, index) => {
                            let earningTypeGroup = earningGroup[earningType];

                            let itemArray;
                            let item;
                            if (earningTypeGroup) {
                              itemArray = earningTypeGroup.filter(
                                (x) =>
                                  x.earningName ===
                                  formValues?.earnings?.[earningType]?.Type
                              );
                              item = itemArray[0];
                            }
                            if (item) {
                              formValues.earnings[earningType].Monthly =
                                getMonthlyYearlyValue(item).Monthly;
                              formValues.earnings[earningType].Yearly =
                                getMonthlyYearlyValue(item).Yearly;
                            }
                            let options = [];
                            earningGroup[earningType]?.map((earning) => {
                              return options.push({
                                label: earning?.earningName,
                                value: earning?.earningName,
                              });
                            });
                            if (typeof item === "undefined") return <></>;
                            else
                              return (
                                <tr key={index}>
                                  <td>
                                    <LabelCustom
                                      labelName={item?.earningName}
                                    />
                                  </td>
                                  <td>
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      {item?.calculationType ===
                                      "Flat Amount" ? (
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <div style={{ maxWidth: "164px" }}>
                                            <InputCustomFormik
                                              name={`earnings.${earningType}.CalculationValue`}
                                            />
                                          </div>
                                          <LabelCustom labelName="Flat Amount" />
                                        </div>
                                      ) : (
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <div style={{ maxWidth: "164px" }}>
                                            <InputCustomFormik
                                              name={`earnings.${earningType}.CalculationValue`}
                                            />
                                          </div>
                                          <LabelCustom
                                            labelName={
                                              earningType === "Basic"
                                                ? "% of CTC"
                                                : "% of Basic"
                                            }
                                          />
                                        </div>
                                      )}
                                    </div>
                                  </td>
                                  <td>
                                    <div>
                                      <LabelCustom
                                        labelName={getMonthlyYearlyValue(
                                          item
                                        ).Monthly?.toFixed(0)}
                                      />
                                      <InputCustomFormik
                                        value={
                                          getMonthlyYearlyValue(item).Monthly
                                        }
                                        name={`earnings.${earningType}.Monthly`}
                                        type="hidden"
                                        step="0.01"
                                        disabled
                                      />
                                    </div>
                                  </td>
                                  <td>
                                    <LabelCustom
                                      labelName={getMonthlyYearlyValue(
                                        item
                                      ).Yearly?.toFixed(0)}
                                    />
                                    <InputCustomFormik
                                      value={getMonthlyYearlyValue(item).Yearly}
                                      name={`earnings.${earningType}.Yearly`}
                                      type="hidden"
                                      step="0.01"
                                      disabled
                                    />
                                  </td>
                                  <td style={{ paddingRight: "18px" }}>
                                    <Tooltip title="remove">
                                      <IconButton>
                                        <DeleteForeverOutlinedIcon
                                          onClick={() =>
                                            removeItem(earningType)
                                          }
                                          color="error"
                                          fontSize="small"
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  </td>
                                </tr>
                              );
                          })}

                          <tr>
                            <td>
                              <LabelCustom labelName="Fixed" />
                            </td>
                            <td style={{ padding: "0 28px" }}>{"-"}</td>
                            <td>
                              <div style={{ maxWidth: "164px" }}>
                                <LabelCustom
                                  labelName={totalMonthlyEarnings.toFixed(0)}
                                />
                              </div>
                            </td>
                            <td>
                              <LabelCustom
                                labelName={totalYearlyEarnings.toFixed(0)}
                              />
                            </td>
                          </tr>
                        </tbody>
                        {selectedreimbursementGroup.length ? (
                          <tr className="dashed-bottom">
                            <th
                              colSpan="4"
                              className="header-row"
                              style={{ padding: "12px" }}
                            >
                              Reimbursement
                            </th>
                          </tr>
                        ) : (
                          <></>
                        )}
                        {selectedreimbursementGroup?.map(
                          (reimbursementType, index) => {
                            let reimbursementTypeGroup =
                              reimbursementGroup[reimbursementType];
                            let itemArray;
                            let item;
                            if (reimbursementTypeGroup) {
                              itemArray = reimbursementTypeGroup.filter(
                                (x) =>
                                  x.name ===
                                  formValues?.reimbursements?.[
                                    reimbursementType
                                  ]?.Type
                              );
                              item = itemArray[0];
                            }

                            if (item) {
                              formValues.reimbursements[
                                reimbursementType
                              ].Monthly =
                                getMonthlyYearlyValueReimb(item).Monthly;
                              formValues.reimbursements[
                                reimbursementType
                              ].Yearly =
                                getMonthlyYearlyValueReimb(item).Yearly;
                            }
                            let options = [];
                            reimbursementGroup[reimbursementType]?.map(
                              (reimbursement) => {
                                return options.push({
                                  label: reimbursement?.name,
                                  value: reimbursement?.name,
                                });
                              }
                            );

                            return (
                              <tr key={index}>
                                <td>
                                  {reimbursementTypeGroup?.length > 1 ? (
                                    <SelectForm
                                      options={options}
                                      name={`reimbursements.${reimbursementType}.name`}
                                    />
                                  ) : (
                                    <LabelCustom labelName={item?.name} />
                                  )}
                                </td>
                                <td>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <div style={{ maxWidth: "164px" }}>
                                        <InputCustomFormik
                                          name={`reimbursements.${reimbursementType}.maximumReimbursementAmount`}
                                        />
                                      </div>
                                      <LabelCustom labelName="Flat Amount" />
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div>
                                    <LabelCustom
                                      labelName={getMonthlyYearlyValueReimb(
                                        item
                                      ).Monthly?.toFixed(0)}
                                    />
                                    <InputCustomFormik
                                      value={
                                        getMonthlyYearlyValueReimb(item).Monthly
                                      }
                                      name={`reimbursements.${reimbursementType}.Monthly`}
                                      type="hidden"
                                      step="0.01"
                                      disabled
                                    />
                                  </div>
                                </td>
                                <td>
                                  <LabelCustom
                                    labelName={getMonthlyYearlyValueReimb(
                                      item
                                    ).Yearly?.toFixed(0)}
                                  />
                                  <InputCustomFormik
                                    value={
                                      getMonthlyYearlyValueReimb(item).Yearly
                                    }
                                    name={`reimbursements.${reimbursementType}.Yearly`}
                                    type="hidden"
                                    step="0.01"
                                    disabled
                                  />
                                </td>
                                <td style={{ paddingRight: "18px" }}>
                                  <Tooltip title="remove">
                                    <IconButton>
                                      <DeleteForeverOutlinedIcon
                                        onClick={() =>
                                          removeItemReimbursement(
                                            reimbursementType
                                          )
                                        }
                                        color="error"
                                        fontSize="small"
                                      />
                                    </IconButton>
                                  </Tooltip>
                                </td>
                              </tr>
                            );
                          }
                        )}
                        {selectedDeductionGroup?.length ?
                        <tr className="dashed-bottom">
                          <th
                            colSpan="4"
                            className="header-row"
                            style={{ padding: "12px" }}
                          >
                            Deductions
                          </th>
                        </tr>:<></>}
                        {selectedDeductionGroup?.map((deductionType, index) => {
                          let deductionTypeGroup =
                            deductionGroup[deductionType];
                          let itemArray;
                          let item;
                          if (deductionTypeGroup) {
                            itemArray = deductionTypeGroup.filter(
                              (x) =>
                                x?.type ===
                                formValues?.deductions?.[deductionType]?.Type
                            );
                            item = itemArray[0];
                          }

                          if (item) {
                            formValues.deductions[deductionType].Monthly =
                              getMonthlyYearlyValueDeduction(item).Monthly;
                            formValues.deductions[deductionType].Yearly =
                              getMonthlyYearlyValueDeduction(item).Yearly;
                          }
                          let options = [];
                          deductionGroup[deductionType]?.map((deduction) => {
                            return options.push({
                              label: deduction?.type,
                              value: deduction?.type,
                            });
                          });

                          return (
                            <tr key={index}>
                              <td>
                                <LabelCustom labelName={item?.type} />
                              </td>
                              <td>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <div style={{ maxWidth: "164px" }}>
                                      <InputCustomFormik
                                        disabled={true}
                                        name={`deductions.${deductionType}.CalculationValue`}
                                      />
                                    </div>
                                    <LabelCustom labelName="Flat Amount" />
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div>
                                  <LabelCustom
                                    labelName={getMonthlyYearlyValueDeduction(
                                      item
                                    ).Monthly?.toFixed(0)}
                                  />
                                  <InputCustomFormik
                                    value={
                                      getMonthlyYearlyValueDeduction(item)
                                        .Monthly
                                    }
                                    name={`deductions.${deductionType}.Monthly`}
                                    type="hidden"
                                    step="0.01"
                                    disabled
                                  />
                                </div>
                              </td>
                              <td>
                                <LabelCustom
                                  labelName={getMonthlyYearlyValueDeduction(
                                    item
                                  ).Yearly?.toFixed(0)}
                                />
                                <InputCustomFormik
                                  value={
                                    getMonthlyYearlyValueDeduction(item).Yearly
                                  }
                                  name={`deductions.${deductionType}.Yearly`}
                                  type="hidden"
                                  step="0.01"
                                  disabled
                                />
                              </td>
                              <td style={{ paddingRight: "18px" }}>
                                <Tooltip title="remove">
                                  <IconButton>
                                    <DeleteForeverOutlinedIcon
                                      onClick={() =>
                                        removeItemDeduction(deductionType)
                                      }
                                      color="error"
                                      fontSize="small"
                                    />
                                  </IconButton>
                                </Tooltip>
                              </td>
                            </tr>
                          );
                        })}
                      </table>
                    </div>
                    <table
                      style={{ width: "calc(100% - 24px)", margin: "12px" }}
                    >
                      <tbody>
                        <tr
                          className="header-row"
                          style={{
                            backgroundColor: "white",
                            textAlign: "left",
                            paddingLeft: "4px",
                            display: "flex",
                          }}
                        >
                          <th style={{ flex: 173 }}>Cost to Company</th>
                          <th style={{ flex: 370 }}></th>
                          <th style={{ flex: 140 }}>{`₹${
                            isNaN(parseFloat(formValues?.annualCTC) / 12)
                              ? 0
                              : (
                                  parseFloat(formValues?.annualCTC).toFixed(0) /
                                  12
                                ).toFixed(0)
                          }`}</th>

                          <th style={{ flex: 225 }}>{`₹${
                            isNaN(parseFloat(formValues?.annualCTC))
                              ? 0
                              : parseFloat(formValues?.annualCTC).toFixed(0)
                          }`}</th>
                        </tr>
                      </tbody>
                    </table>
                  </>
                )}
                {formValues?.templateID && (
                  <div
                    className="horizontalLine"
                    style={{ marginTop: "36px" }}
                  ></div>
                )}
                <div
                  style={{
                    position: "absolute",
                    bottom: 0,
                    background: "var(--form-background)",
                    display: "flex",
                    justifyContent: "flex-end",
                    width: "calc(100% - 24px)",
                    margin: "12px",
                  }}
                >
                  <button
                    className="button secondaryButton"
                    type="button"
                    onClick={returnPage}
                  >
                    Cancel
                  </button>
                  <button className="button primaryButton" type="submit">
                    {emp ? "Update CTC" : "Map CTC"}
                  </button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}

export default SalaryMapEmployee;
