import React, { useState, useEffect } from "react";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import { TextAreaFormik } from "../../../../Components/FormComponent/TextAreaFormik";
import { DatePickerFormiks } from "../../../../Components/FormComponent/DatePickerFormiks";
import { SelectForm } from "../../../../Components/FormComponent/SelectForm";
import { getRequest } from "../../../../Services/axios";
import { useDispatch } from "react-redux";
import { showToast } from "../../../../Features";

export const HotelCandidateComp = ({ formValues }) => {
  const genderOptions = [
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" },
    { label: "Others", value: "Others" },
  ];
  useEffect(() => {
    document.title = `PeopleSol - Candidate Hotel`;  
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  const [employeeList, setEmployeeList] = useState([]);
  const dispatch = useDispatch();

  const getEmployeeData = async () => {
    try {
      const result = await getRequest(
        "Candidate/GetCandidatesListWithoutRejection"
      );
      // //console.log("result", result);
      let listData = [];
      result?.data?.results?.map((item) => {
        return listData.push({ label: item.text, value: item.id });
      });
      setEmployeeList(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };

  useEffect(() => {
    getEmployeeData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="formcustom threeColumn">
        <div>
          <LabelCustom labelName={"Candidate"} mandatory={true} />
          <SelectForm name="candidate" options={employeeList} />
        </div>
        <div>
          <LabelCustom labelName={"Check-In Date and Time"} mandatory={true} />
          <DatePickerFormiks
            name={"departure"}
            showTimeSelect
            maxDate={formValues?.toDate}
          />
        </div>
        <div>
          <LabelCustom labelName={"Check-Out Date and Time"} mandatory={true} />
          <DatePickerFormiks
            name={"toDate"}
            showTimeSelect
            minDate={formValues?.departure}
          />
        </div>

        <div>
          <LabelCustom labelName={"Location"} mandatory={true} />
          <InputCustomFormik
            maxLength={150}
            name="fromLocation"
            type={"text"}
            placeholder={"Please Enter Location"}
          />
        </div>

        <div>
          <LabelCustom labelName={"Preferred Hotel "} mandatory={true} />
          <InputCustomFormik
            maxLength={150}
            name="preferredHotel"
            type={"text"}
            placeholder={"Please Enter Preferred Hotel"}
          />
        </div>
        <div>
          <LabelCustom labelName={"Age"} />
          <InputCustomFormik
            maxLength={3}
            name="age"
            type={"text"}
            placeholder={"Please Enter Age"}
          />
        </div>
        <div>
          <LabelCustom labelName={"Gender"} />
          <SelectForm name="gender" options={genderOptions} />
        </div>
        <div>
          <LabelCustom labelName={"Budget (In Thousands)"} mandatory={true} />
          <InputCustomFormik
            maxLength={10}
            name="budget"
            step=".001"
            placeholder={"Please enter budget"}
          />
        </div>
        <div>
          <LabelCustom labelName={"Booking Note"} />
          <TextAreaFormik
            maxLength={500}
            name="comments"
            placeholder={"Please Enter Booking Note"}
          />
        </div>
      </div>
    </>
  );
};
