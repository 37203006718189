import { Table, TableBody, TableHead, TableRow } from "@mui/material";
import { FieldArray, Form, Formik } from "formik";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { CheckboxFormiks } from "../../../../Components/FormComponent/CheckboxFormiks";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import { Loader } from "../../../../Components/FormComponent/Loader";
import {
  StyledTableCell,
  StyledTableCont,
} from "../../../../Components/FormComponent/Repeated";
import { isLoading, showToast } from "../../../../Features";
import { getRequest, postRequest } from "../../../../Services/axios";
import * as Yup from "yup";
export const SubmitBooking = ({ id, handleClose, getListData }) => {
  const [detailsform, setDetailsform] = useState(false);

  useEffect(() => {
    document.title = `PeopleSol - Submit Booking`;  
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  const data = id;

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const getFormDetails = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(
        `TravelHospitalityRequests/Details/${data}`
      );
      setDetailsform(result.data.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };

  useEffect(() => {
    if (data) getFormDetails();
  }, []);

  const create = async (values, url) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest(
        "TravelHospitalityRequests/UpdateTicketDetails",
        values
      );
      if (result)
        dispatch(
          showToast({ text: "Successfully Created", severity: "success" })
        );
      handleClose();
      getListData();
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  useEffect(() => {
    if (data) getFormDetails();
  }, [data]);
  const getInitalValues = () => {
    let returnArray = [
      {
        Id: 0,
        IsSelected: false,
        Remarks: "",
        Amount: "",
        Upload: null,
      },
    ];
    if (detailsform)
      if (detailsform.bookinglist)
        if (detailsform.bookinglist.length > 0) {
          returnArray = [];
          detailsform?.bookinglist?.map((item) => {
            return returnArray.push({
              Id: item.id,
              IsSelected: false,
              Remarks: item.remarks,
              Amount: item.amount,
              Upload: item.attachment ?? null,
            });
          });
        }
    return returnArray;
  };
  const initialvalue = {
    Id: data,
    Bookinglist: getInitalValues(),
  };
  if (loading) return <Loader />;
  else
    return (
      <Formik
        initialValues={initialvalue}
        validationSchema={Yup.object({
          Bookinglist: Yup.array()
            .min(1)
            .of(
              Yup.object().shape({
                IsSelected: Yup.bool().oneOf(
                  [true],
                  "Select Atleast 1 Ticket Option"
                ),
              })
            )
            .compact((v) => !v.IsSelected)
            .min(1, "Select Atleast 1 Ticket Option"),
        })}
        enableReinitialize
        onSubmit={(values) => {
          const payload = { Id: values.Id };
          let tempBooking = [];
          values.Bookinglist?.map((item) => {
            return tempBooking.push({
              Id: item.Id,
              IsSelected: item.IsSelected,
              Remarks: item.Remarks,
            });
          });
          payload["Bookinglist"] = tempBooking;
          create(payload);
        }}
      >
        {(formik) => {
          return (
            <Form>
              <div style={{ width: "100%" }}>
                <div style={{ display: "grid" }}>
                  <FieldArray name="Bookinglist">
                    {(fieldArrayProps) => {
                      const { push, remove, form } = fieldArrayProps;
                      const { values } = form;
                      const { Bookinglist } = values;
                      return (
                        <StyledTableCont>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <StyledTableCell
                                  padding="0"
                                  style={{
                                    fontSize: "12px",
                                  }}
                                  align="center"
                                >
                                  <LabelCustom labelName={"Select Option"} />
                                </StyledTableCell>
                                <StyledTableCell
                                  padding="0"
                                  style={{
                                    fontSize: "12px",
                                  }}
                                  align="center"
                                >
                                  <LabelCustom labelName={"Option Remark"} />
                                </StyledTableCell>

                                <StyledTableCell
                                  style={{
                                    fontSize: "12px",
                                  }}
                                  align="center"
                                >
                                  <LabelCustom labelName={"Attachment"} />
                                </StyledTableCell>
                                <StyledTableCell
                                  style={{
                                    fontSize: "12px",
                                  }}
                                  align="center"
                                >
                                  <LabelCustom labelName={"Amount"} />
                                </StyledTableCell>
                              </TableRow>
                            </TableHead>
                            {Bookinglist.map((Bookinglist, index) => {
                              return (
                                <>
                                  <TableBody key={index}>
                                    <StyledTableCell align="center">
                                      <CheckboxFormiks
                                        name={`Bookinglist[${index}].IsSelected`}
                                      />
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      <LabelCustom
                                        labelName={Bookinglist.Remarks}
                                      />
                                    </StyledTableCell>

                                    <StyledTableCell>
                                      <a
                                        style={{ margin: "6px" }}
                                        href={`${process.env.REACT_APP_.BASE_URL}/Core/Uploads/TravelBookings/${Bookinglist.Upload}`}
                                        target="blank"
                                      >
                                        {Bookinglist.Upload}
                                      </a>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      <LabelCustom
                                        labelName={Bookinglist.Amount}
                                      />
                                    </StyledTableCell>
                                  </TableBody>
                                </>
                              );
                            })}
                          </Table>
                        </StyledTableCont>
                      );
                    }}
                  </FieldArray>
                </div>
                <div className="createFootCust">
                  <div>
                    <button className="button primaryButton" type="submit">
                      Update Preferences
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    );
};
