import React, { useEffect, useState } from "react";
import { ChatMessage } from "./Components/ChatMessage";
import { format, isToday, isYesterday, subDays, isSameDay } from "date-fns";

export const scrollFunc = (replyId) => {
  // debugger;
  // console.log("object scrollFunc entered ");

  const element = document.getElementById("content" + replyId);
  if (element) {
    element.scrollIntoView({ behavior: "smooth" });
    // console.log("object scrollFunc processing ");

    const targetDiv = element.querySelector(".message-content");
    if (targetDiv) {
      targetDiv.style.position = "relative";
      targetDiv.classList.add("translucent-overlay");
      setTimeout(() => {
        targetDiv.classList.remove("translucent-overlay");
      }, 1100);
    }
  }
};
const ChatInterface = ({
  deleteMessage,
  messageList,
  setReplyData,
  LoggedInUser,
  setForwardData,
  forwardData,
  replyData,
  isOpen,
  togglePopup,
  handleContactClick,
  newMessages,
  messageResponse,
}) => {
  const [sortedMessageList, setSortedMessageList] = useState([]);
  const getMessageDateHeader = (date) => {
    const today = new Date();
    if (isToday(date)) return "Today";
    if (isYesterday(date)) return "Yesterday";
    for (let i = 2; i <= 7; i++) {
      if (isSameDay(subDays(today, i), date)) {
        return format(date, "EEEE"); // Day of the week
      }
    }
    return format(date, "dd/MM/yy"); // Date format for messages older than a week
  };
  let lastTime = new Date("1900-12-27T16:51:10.86");
  useEffect(() => {
    const sortedMessageListVar = messageList?.sort(
      (a, b) => new Date(a?.messages[0]?.time) - new Date(b?.messages[0]?.time)
    );
    setSortedMessageList(sortedMessageListVar);
  }, [messageList]);
  console.log("object sortedMessageList", sortedMessageList);
  useEffect(() => {
    // setSortedMessageList(...sortedMessageList, ...newMessages);
    if (messageResponse?.conversationId) {
      console.log("object messageResponse", {
        messages: [{ ...messageResponse }],
      });
      setSortedMessageList((prev) => [
        ...prev,
        { messages: [{ ...messageResponse }] },
      ]);
    }
    if (newMessages?.conversationId) {
      console.log("object newMessages", {
        messages: [{ ...newMessages }],
      });
      setSortedMessageList((prev) => [
        ...prev,
        { messages: [{ ...newMessages }] },
      ]);
    }
  }, [newMessages, messageResponse]);

  //   const scrollFuunc = () => {
  //     const element = document.getElementById("content" + replyData?.replyId);
  //     element.scrollIntoView();
  //   };

  return (
    <>
      {sortedMessageList?.map((item, index) => {
        // const messageDate = new Date(item?.messages[0].time);
        let header = null;
        // if (index === 0 || !isSameDay(messageDate, lastTime)) {
        //   header = getMessageDateHeader(messageDate);
        // }
        // lastTime = messageDate;
        return (
          <div key={item?.messages[0]?.id}>
            {header && (
              <div className="message-date-header">
                <div>{header}</div>
              </div>
            )}
            <div
              style={
                forwardData?.isActive &&
                forwardData?.msgId?.includes(item?.messages[0].id)
                  ? {
                      padding: "0px 12px",
                      backgroundColor: "rgb(0 0 0 / 5%)",
                    }
                  : {
                      padding: "0px 12px",
                    }
              }
            >
              <ChatMessage
                deleteMessage={deleteMessage}
                handleContactClick={handleContactClick}
                togglePopup={togglePopup}
                replyData={replyData}
                forwardData={forwardData}
                setForwardData={setForwardData}
                LoggedInUser={LoggedInUser}
                setReplyData={setReplyData}
                key={item?.messages[0]?.id}
                message={item?.messages[0]}
              />
            </div>
          </div>
        );
      })}
    </>
  );
};

export default ChatInterface;
