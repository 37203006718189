/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { FormCustomFormik } from "../../../Components/FormComponent/FormCustomFormik";
import { useDispatch } from "react-redux";
import { showToast, isLoading } from "../../../Features";
import { getRequest, postRequestForm } from "../../../Services/axios";
import { useLocation } from "react-router-dom";
import { CreateCoursesComp } from "./FormComp/CreateCoursesComp";
import { format } from "date-fns";

const CreateCourses = (props) => {
  const [detailsform, setDetailsform] = useState({});
  const { state } = useLocation();
  const data = state?.id;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState(null);
  const chooseFormValues = (values) => {
    setFormValues(values);
  };
  const getFormDetails = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(`TrainingCourses/Details/${data}`);
      setDetailsform(result.data?.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  useEffect(() => {
    if (data) {
      getFormDetails();
    }
  }, []);
  useEffect(() => {
    document.title = `PeopleSol - Create Courses`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const createPageData = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequestForm("TrainingCourses/Create", values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Created", severity: "success" })
        );
        returnPage();
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };

  const Edit = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequestForm("TrainingCourses/Edit", values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Created", severity: "success" })
        );
        returnPage();
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };

  const initialvalue = {
    title: "",
    categoryId: "",
    type: "",
    shortDescription: "",
    upload: null,
    locations: "",
    departments: "",
    levels: "",
    RecommendedFor: "",
    startDate: null,
    endDate: null,
    isActive: null,
  };

  const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];

  const validationSchema = Yup.object(
    data
      ? {
          title: Yup.string()
            .max(49, "Title must be less than 50 characters")
            .required("Please Enter Department "),
          categoryId: Yup.string().required("Please Select Category"),
          // type: Yup.string().required("Please Select Type"),
          shortDescription: Yup.string()

            .max(299, "Short Description must be less than 300 characters")
            .required("Please Enter Short Description"),
        }
      : {
          title: Yup.string()
            .max(49, "Title must be less than 50 characters")
            .required("Please Enter Title "),
          categoryId: Yup.string().required("Please Select Category "),
          // type: Yup.string().required("Please Select Type"),
          shortDescription: Yup.string()
            .max(299, "Short Description must be less than 300 characters")
            .required("Please Enter  Short Description"),
          upload: Yup.mixed()
            .nullable()
            .test(
              "Fichier taille",
              "File Size should be less than 2 mb",
              (value) => !value || (value && value.size <= 2 * 2048 * 2048)
            )
            .test(
              "format",
              "File Format is Incorrect",
              (value) =>
                !value || (value && SUPPORTED_FORMATS.includes(value.type))
            )
            .required("Please Upload Cover Image"),
        }
  );
  console.log("first formValues", formValues);
  const onSubmit = (values) => {
    const formData = new FormData();
    formData.append("title", values?.title);
    formData.append("Id", data ? data : 0);
    formData.append("categoryId", values?.categoryId);
    // formData.append("type", values?.type);
    formData.append("shortDescription", values?.shortDescription);
    if (typeof values?.upload === "object")
      formData.append("upload", values?.upload);
    else if (typeof values?.upload === "string") {
      formData.append("coverImage", values?.upload?.split("/").pop());
    }
    if (values?.startDate)
      formData.append(
        "startDate",
        `${format(values?.startDate, "MM/dd/yyyy")}`
      );
    if (values?.endDate)
      formData.append("endDate", `${format(values?.endDate, "MM/dd/yyyy")}`);
    if (values?.locations?.length > 0) {
      formData.append("locations", values?.locations);
    }
    if (values?.departments?.length > 0) {
      formData.append("departments", values?.departments);
    }
    if (values?.levels?.length > 0) {
      formData.append("levels", values?.levels);
    }
    if (values?.RecommendedFor?.length > 0) {
      formData.append("RecommendedFor", values?.RecommendedFor);
    }

    data ? Edit(formData) : createPageData(formData);
  };

  const navigate = useNavigate();

  const returnPage = () => {
    navigate("/ldmaster/chapters");
  };
  console.log("first ", detailsform?.trainingCourses?.locations);
  return (
    <FormCustomFormik
      chooseFormValues={chooseFormValues}
      loading={loading}
      FormHeading={data ? "Edit Chapter" : "Create Chapter"}
      ButtonLabel={data ? "Edit Chapter" : "Create Chapter"}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      initialvalue={
        data
          ? {
              title: detailsform?.trainingCourses?.title
                ? detailsform?.trainingCourses?.title
                : "",
              categoryId: detailsform?.trainingCourses?.categoryId
                ? detailsform?.trainingCourses?.categoryId
                : "",
              startDate: detailsform?.trainingCourses?.startDate
                ? new Date(detailsform?.trainingCourses?.startDate)
                : null,
              endDate: detailsform?.trainingCourses?.endDate
                ? new Date(detailsform?.trainingCourses?.endDate)
                : null,
              type: detailsform?.trainingCourses?.category?.type
                ? detailsform?.trainingCourses?.category?.type
                : "",
              shortDescription: detailsform?.trainingCourses?.shortDescription
                ? detailsform?.trainingCourses?.shortDescription
                : "",
              locations:
                detailsform?.trainingCourses?.locations?.length > 0
                  ? detailsform?.trainingCourses?.locations
                      ?.split(",")
                      ?.map(Number)
                  : "",
              departments:
                detailsform?.trainingCourses?.departments?.length > 0
                  ? detailsform?.trainingCourses?.departments
                      ?.split(",")
                      ?.map(Number)
                  : "",
              levels:
                detailsform?.trainingCourses?.levels?.length > 0
                  ? detailsform?.trainingCourses?.levels
                      ?.split(",")
                      ?.map(Number)
                  : "",
              RecommendedFor:
                detailsform?.trainingCourses?.recommendedFor?.length > 0
                  ? detailsform?.trainingCourses?.recommendedFor
                      ?.split(",")
                      ?.map(Number)
                  : "",
              isActive:
                detailsform?.trainingCourses?.isActive === true ? "Yes" : "No",
              upload: `TrainingCourses/${detailsform?.trainingCourses?.coverImage}`,
            }
          : initialvalue
      }
      returnPage={returnPage}
      propsDiv={
        <CreateCoursesComp data={detailsform} formValues={formValues} />
      }
    />
  );
};

export { CreateCourses };
