import { ErrorMessage, Field } from "formik";
import React from "react";
import {
  CheckBox,
  Label,
  Radio,
} from "../../../Components/FormComponent/RadioGroupInput/InputStyles";
import { TextError } from "../../../Components/FormComponent/TextError";
import WrongIcon from "../../../Assets/WrongIcon.svg";
import CorrectIcon from "../../../Assets/CorrectIcon.svg";
import { CheckBoxCustom } from "../../../Components/FormComponent/CheckBoxCustom";

export const QuestionRadioFormik = (props) => {
  const {
    isMultiSelect,
    name,
    options,
    displayCheck,
    keyobjstateProp,
    disabeProp,
    ...rest
  } = props;

  return (
    <>
      <div
        className="radioFormikLD formikComponentContainer"
        // style={{
        //   display: "flex",
        //   flexDirection: "column",
        //   alignItems: "flex-start",
        // }}
      >
        <Field name={name} {...rest}>
          {({ field }) => {
            return options.map((option, index) => {
              console.log("field", field);
              console.log("field?.value", field?.value);
              console.log("option?.id", option?.id);
              // console.log("option", option);
              console.log("displayCheck", displayCheck);

              const checkEquality = (id) => {
                if (isMultiSelect) {
                  return (
                    field?.value?.some((item) => Number(item) === id) ?? false
                  );
                } else {
                  return field?.value == id ?? false;
                }
              };
              return (
                <span
                  className="RadiotoCheckboxLD"
                  style={{ display: "flex", position: "relative" }}
                >
                  <span>
                    <CheckBoxCustom
                      isRadio={!isMultiSelect}
                      id={option.id}
                      // type="radio"
                      disabled={
                        // displayCheck && field?.value[index] != option?.id
                        displayCheck ? true : false
                      }
                      {...field}
                      value={option.id}
                      // checked={field.value === option.id}
                      {...props}
                    />

                    {displayCheck && checkEquality(option?.id) && (
                      <img
                        style={{
                          position: "absolute",
                          backdropFilter: " blur(15px)",
                          left: "9.7px",
                          top: "3.5px",
                          width: "13px",
                          height: "14px",
                          borderRadius: "3px",
                        }}
                        className="IconSizeLdForm"
                        src={keyobjstateProp === true ? CorrectIcon : WrongIcon}
                        alt="Correct or Wrong Icon"
                      />
                    )}
                  </span>
                  <Label htmlFor={option.id} style={{ cursor: "pointer" }}>
                    <span
                      style={{
                        marginLeft: "5px",
                        marginRight: "30px",
                        fontSize: "14px",
                        color: " var(--people-sol-dark-grey, #555)",
                        fontFamily: "Poppins",
                        fontWeight: "400",
                        lineHeight: "17px",
                      }}
                    >
                      {option.answerText}
                    </span>
                  </Label>
                  {/* {console.log("first displayCheck", displayCheck)}
                  {console.log("first field?.value ", field?.value)}
                  {console.log("first option?.id ", option?.id)} */}
                </span>
              );
            });
          }}
        </Field>
      </div>
      <div style={{ margin: "12px -3px" }}>
        <ErrorMessage
          name={name}
          component={TextError}
          styles={{ bottom: "-10px", left: "-10px" }}
        />
      </div>
    </>
  );
};
