import React, { useState } from "react";
import { useSelector } from "react-redux";
import SurveyPostActivityContainer from "./SurveyPostActivityContainer";
import ViewReportContainer from "./ViewReportContainer";
import { Popup } from "../../../../../../../Components/FormComponent/PopupComponent/Popup";
import { getRequest } from "../../../../../../../Services/axios";
import { useDispatch } from "react-redux";
import { setSurveyData } from "../../../../../../../Features/SocialSlice";
import { showToast } from "../../../../../../../Features";

function SurveyAndPollTitle({ titleData }) {
  const [deletePostActive, setDeletePostActive] = useState(false);
  const [deletePostId, setDeletePostId] = useState(0);
  const [loading, setLoading] = useState(false);
  const { LoggedInUser } = useSelector((state) => state.auth);
  const { surveyData } = useSelector((state) => state.social);
  const dispatch = useDispatch();
  const deleteSurveyPostById = async () => {
    setLoading(true);
    try {
      const result = await getRequest(`Survey/Delete?ID=${deletePostId}`);
      let x = surveyData?.filter((d) => {
        if (d?.id !== deletePostId) {
          return d;
        }
      });
      dispatch(setSurveyData(x));
      setDeletePostId(0);
      setDeletePostActive(false);
      dispatch(
        showToast({
          text: "Post Deleted Successfully",
          severity: "success",
        })
      );
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in deleting post",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      className={`surveyAndPollTitleContainer ${
        titleData?.employeeId === LoggedInUser?.id &&
        titleData?.categoryType !== "community" &&
        titleData?.categoryType !== "team"
          ? "surveyBoxShadow"
          : ""
      }`}
    >
      <h3>{titleData?.surveyTitle}</h3>
      <div className="titleViewReport">
        {titleData?.employeeId === LoggedInUser?.id &&
          titleData?.categoryType !== "community" &&
          titleData?.categoryType !== "team" && (
            <ViewReportContainer reportData={titleData} />
          )}
        {titleData?.employeeId === LoggedInUser?.id && (
          <SurveyPostActivityContainer
            surveyPostData={titleData}
            setDeletePostActive={setDeletePostActive}
            setDeletePostId={setDeletePostId}
          />
        )}
      </div>
      {deletePostActive && (
        <>
          <Popup
            firstClassName="deletePostPopupBox1"
            secondClassName="deletePostPopupBox2"
            popupHeading={`Delete Post`}
            content={
              <>
                <div className="socialPostDeleteBody">
                  <p>Are you sure want to Permanently remove this Post?</p>
                </div>
                {!loading && (
                  <div className="socialPostDeleteBtn">
                    <button onClick={deleteSurveyPostById}>Delete</button>
                    <button
                      onClick={() => {
                        setDeletePostActive(false);
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                )}
                {loading && (
                  <span
                    className="posting-socialpost"
                    style={{
                      position: "absolute",
                      bottom: "20px",
                      right: "10px",
                    }}
                  >
                    Deleting...
                  </span>
                )}
              </>
            }
            handleClose={() => {
              setDeletePostActive(false);
            }}
          />
        </>
      )}
    </div>
  );
}

export default SurveyAndPollTitle;
