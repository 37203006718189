import React from "react";
import { CreateHotelRequest } from "./CreateHotelRequest";
import { useEffect } from "react";

const CreateHotelBookingComp = ({ formValues, editTrue }) => {
  useEffect(() => {
    document.title = `PeopleSol - Hotel Booking Request`;  
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  return (
    <div
      className="moduleMainContainer"
      style={{ alignItems: "center", background: "var(--form-background)" }}
    >
      <CreateHotelRequest formValues={formValues} editTrue={editTrue} />
    </div>
  );
};

export { CreateHotelBookingComp };
