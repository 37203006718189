import React from "react";
import { FieldArray } from "formik";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import { Table } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { SelectForm } from "../../../../Components/FormComponent/SelectForm";
import { DatePickerFormiks } from "../../../../Components/FormComponent/DatePickerFormiks";
import {
  StyledTableCell,
  StyledTableCont,
} from "../../../../Components/FormComponent/Repeated";
import { TextAreaFormik } from "../../../../Components/FormComponent/TextAreaFormik";
import { showToast } from "../../../../Features";
import { useDispatch } from "react-redux";
import { useEffect } from "react";

const genderOptions = [
  { label: "Male", value: "Male" },
  { label: "Female", value: "Female" },
  { label: "Others", value: "Others" },
];
export const TrainVendorComp = ({ formValues }) => {
  const travellingOptions = [
    { label: "One Way Trip", value: "One Way Trip" },
    { label: "Round Trip", value: "Round Trip" },
    { label: "Multilocation Trip", value: "Multilocation Trip" },
  ];
  const genderOptions = [
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" },
    { label: "Others", value: "Others" },
  ];
  useEffect(() => {
    if (formValues?.travellingType === "Round Trip") {
      if (formValues?.fromLocation && formValues?.toLocation) {
        formValues.toReturnLocation = formValues?.fromLocation;
        formValues.fromReturnLocation = formValues?.toLocation;
      }
    }
  }, [formValues?.fromLocation, formValues?.toLocation]);
  useEffect(() => {
    if (formValues?.travellingType === "Round Trip") {
      if (formValues?.fromReturnLocation && formValues?.toReturnLocation) {
        formValues.fromLocation = formValues?.toReturnLocation;
        formValues.toLocation = formValues?.fromReturnLocation;
      }
    }
  }, [formValues?.toReturnLocation, formValues?.fromReturnLocation]);
  const dispatch = useDispatch();
  useEffect(() => {
    document.title = `PeopleSol - Vendor Train`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  return (
    <>
      <div
        style={{ borderBottom: "1px solid #E6E6E6" }}
        className="formcustom threeColumn"
      >
        <div>
          <LabelCustom labelName={"Travelling Type"} />
          <SelectForm
            // values={
            //   data
            //     ? genderOptions.filter(
            //         (options) => options.label === data?.gender
            //       )
            //     : opt
            // }
            name="travellingType"
            options={travellingOptions}
          />
        </div>
      </div>
      <div className="formcustom threeColumn">
        {formValues?.travellingType !== "Multilocation Trip" && (
          <>
            {" "}
            <div>
              <LabelCustom labelName={"Travelling Date"} mandatory={true} />
              <DatePickerFormiks name={"departure"} />
            </div>
            <div>
              <LabelCustom labelName={"From Location"} mandatory={true} />
              <InputCustomFormik
                maxLength={50}
                name="fromLocation"
                type={"text"}
                placeholder={" Enter From Location"}
              />
            </div>
            <div>
              <LabelCustom labelName={"To Location"} mandatory={true} />
              <InputCustomFormik
                maxLength={50}
                name="toLocation"
                type={"text"}
                placeholder={" Enter To Location"}
              />
            </div>
            {formValues?.travellingType === "Round Trip" && (
              <>
                <div>
                  <LabelCustom labelName={"Return Date"} mandatory={true} />
                  <DatePickerFormiks name={"returnDate"} />
                </div>
                <div>
                  <LabelCustom labelName={"From Location"} mandatory={true} />
                  <InputCustomFormik
                    maxLength={50}
                    name="fromReturnLocation"
                    type={"text"}
                    placeholder={" Enter From Location"}
                  />
                </div>

                <div>
                  <LabelCustom labelName={"To Location"} mandatory={true} />
                  <InputCustomFormik
                    maxLength={50}
                    name="toReturnLocation"
                    type={"text"}
                    placeholder={" Enter To Location"}
                  />
                </div>
              </>
            )}
          </>
        )}

        <div>
          <LabelCustom labelName={"Budget (In Thousands)"} mandatory={true} />
          <InputCustomFormik
            maxLength={10}
            name="budget"
            step=".001"
            placeholder={" enter Budget"}
          />
        </div>
        <div>
          <LabelCustom labelName={"Booking Note"} />
          <TextAreaFormik
            name="comments"
            placeholder={"Enter Booking Note"}
            maxLength={500}
          />
        </div>
      </div>
      <div>
        <label className="repeatedHeading">
          Please specify the vendor's details
        </label>
        <div style={{ width: "100%", overflowX: "auto" }}>
          <FieldArray name="groupItems">
            {(fieldArrayProps) => {
              const { push, remove, form } = fieldArrayProps;
              const { values } = form;
              const { groupItems } = values;
              return (
                <StyledTableCont sx={{ maxWidth: "100%" }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <StyledTableCell
                          padding="0"
                          style={{
                            fontSize: "12px",
                          }}
                          align="center"
                        >
                          <LabelCustom labelName={"S. No"} />
                        </StyledTableCell>
                        <StyledTableCell
                          padding="0"
                          style={{
                            fontSize: "12px",
                          }}
                          align="center"
                        >
                          <LabelCustom labelName={"Name"} mandatory={true} />
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            fontSize: "12px",
                          }}
                          align="center"
                        >
                          <LabelCustom
                            labelName={"Phone No."}
                            mandatory={true}
                          />
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            fontSize: "12px",
                          }}
                          align="center"
                        >
                          <LabelCustom labelName={"Email"} />
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            fontSize: "12px",
                          }}
                          align="center"
                        >
                          <LabelCustom labelName={"Age"} />
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            fontSize: "12px",
                          }}
                          align="center"
                        >
                          <LabelCustom labelName={"Gender"} />
                        </StyledTableCell>
                        {/* <StyledTableCell
                          style={{
                            fontSize: "12px",
                          }}
                          align="center"
                        >
                          <LabelCustom labelName={"Remarks"} />
                        </StyledTableCell> */}
                        <StyledTableCell
                          style={{
                            fontSize: "12px",
                          }}
                          align="center"
                        >
                          <LabelCustom labelName={"Add/Remove"} />
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    {groupItems?.map((groupItemss, index) => (
                      <TableBody key={index}>
                        {/* 1st column */}
                        <StyledTableCell align="center">
                          {index + 1}
                        </StyledTableCell>
                        {/* 2nd column */}
                        <StyledTableCell>
                          <InputCustomFormik
                            maxLength={150}
                            type="text"
                            name={`groupItems[${index}].name`}
                            placeholder={" enter the Name"}
                          />
                        </StyledTableCell>
                        {/* 3rd column */}
                        <StyledTableCell>
                          <InputCustomFormik
                            maxLength={10}
                            type="number"
                            step="0.01"
                            name={`groupItems[${index}].phone`}
                            placeholder={" enter Phone No."}
                          />
                        </StyledTableCell>
                        {/* 4th column */}
                        <StyledTableCell>
                          <InputCustomFormik
                            maxLength={320}
                            type="text"
                            name={`groupItems[${index}].email`}
                            placeholder={"enter the Email"}
                          />
                        </StyledTableCell>
                        {/* 5th column */}
                        <StyledTableCell>
                          <InputCustomFormik
                            maxLength={3}
                            type="number"
                            step="0.01"
                            name={`groupItems[${index}].age`}
                            placeholder={" enter the Age"}
                          />
                        </StyledTableCell>
                        {/* 6th column */}
                        <StyledTableCell>
                          <SelectForm
                            name={`groupItems[${index}].gender`}
                            options={genderOptions}
                          />
                        </StyledTableCell>
                        {/* 7th column */}
                        {/* <StyledTableCell>
                          <TextAreaFormik
                            name={`groupItems[${index}].remarks`}
                            placeholder={" Enter Remarks"}
                            maxLength={300}
                          />
                        </StyledTableCell> */}
                        {/* 8th column */}
                        <StyledTableCell
                          sx={{
                            textAlign: "center",
                          }}
                        >
                          <button
                            type="button"
                            className="remove-btn"
                            onClick={() =>
                              groupItems.length > 1
                                ? remove(index)
                                : dispatch(
                                    showToast({
                                      text: "Atleast One Item is Required ",
                                      severity: "error",
                                    })
                                  )
                            }
                          >
                            -
                          </button>
                          <button
                            type="button"
                            className="add-btn"
                            onClick={() =>
                              push({
                                name: "",
                                phone: "",
                                email: "",
                                age: "",
                                gender: "",
                                remarks: "",
                              })
                            }
                          >
                            +
                          </button>
                        </StyledTableCell>
                      </TableBody>
                    ))}
                  </Table>
                </StyledTableCont>
              );
            }}
          </FieldArray>
        </div>
      </div>
      {formValues?.travellingType === "Multilocation Trip" && (
        <div style={{ marginTop: "20px" }} className="repeatedSectionContainer">
          <label className="repeatedHeading">
            Please specify your journey details
          </label>
          <FieldArray name="Items">
            {(fieldArrayProps) => {
              const { push, remove, form } = fieldArrayProps;
              const { values, errors } = form;
              const { Items } = values;
              return (
                <StyledTableCont>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <StyledTableCell
                          padding="0"
                          style={{
                            fontSize: "12px",
                          }}
                          align="center"
                        >
                          <LabelCustom labelName={"S. No."} />
                        </StyledTableCell>
                        <StyledTableCell
                          padding="0"
                          style={{
                            fontSize: "12px",
                          }}
                          align="center"
                        >
                          <LabelCustom
                            labelName={"Travelling Date"}
                            mandatory={true}
                          />
                        </StyledTableCell>

                        <StyledTableCell
                          style={{
                            fontSize: "12px",
                          }}
                          align="center"
                        >
                          <LabelCustom
                            labelName={"From Location"}
                            mandatory={true}
                          />
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            fontSize: "12px",
                          }}
                          align="center"
                        >
                          <LabelCustom labelName={"To Location"} />
                        </StyledTableCell>

                        <StyledTableCell
                          style={{
                            fontSize: "12px",
                          }}
                          align="center"
                        >
                          <LabelCustom labelName={" Add/Remove"} />
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    {Items?.map((Requestlist, index) => (
                      <TableBody key={index}>
                        {/* 1st column */}
                        <StyledTableCell align="center">
                          {index + 1}
                        </StyledTableCell>
                        {/* 2nd column */}
                        <StyledTableCell>
                          <DatePickerFormiks
                            name={`Items[${index}].departure`}
                          />
                        </StyledTableCell>
                        <StyledTableCell>
                          <InputCustomFormik
                            maxLength={50}
                            name={`Items[${index}].fromLocation`}
                            type="text"
                            placeholder={" Enter From Location"}
                          />
                        </StyledTableCell>
                        <StyledTableCell>
                          <InputCustomFormik
                            maxLength={50}
                            name={`Items[${index}].toLocation`}
                            type={"text"}
                            placeholder={" Enter To Location"}
                          />
                        </StyledTableCell>

                        {/* 8th column */}
                        <StyledTableCell
                          sx={{
                            textAlign: "center",
                          }}
                        >
                          <button
                            type="button"
                            className="remove-btn"
                            onClick={() =>
                              Items.length > 1
                                ? remove(index)
                                : dispatch(
                                    showToast({
                                      text: "Atleast One Item is Required ",
                                      severity: "error",
                                    })
                                  )
                            }
                          >
                            -
                          </button>
                          <button
                            type="button"
                            className="add-btn"
                            onClick={() =>
                              push({
                                departure: "",
                                toLocation: "",
                                fromLocation: "",
                              })
                            }
                          >
                            +
                          </button>
                        </StyledTableCell>
                      </TableBody>
                    ))}
                  </Table>
                </StyledTableCont>
              );
            }}
          </FieldArray>
        </div>
      )}
    </>
  );
};
