import React, { useState } from "react";
import { VictoryPie, VictoryLabel } from "victory";

const DonutChartWithCenterText = ({ title, count, type, percent, data }) => {
  const [color, setColor] = useState([
    "#5BC4BF",
    "#F7941D",
    "#8A88ED",
    "#FB7158",
    "#77A2C7",
    "#DE64A1",
    "#B69FB3",
    "#E9B000",
    "#FF5733",
    "#48C9B0",
    "#FFC300",
    "#9B59B6",
    "#3498DB",
    "#2ECC71",
    "#E74C3C",
    "#F39C12",
    "#1ABC9C",
  ]);

  return (
    <div style={{ height: "200px", width: "200px" }}>
      {percent > 0 && (
        <svg viewBox="0 0 300 300">
          <VictoryPie
            standalone={false}
            width={350}
            height={350}
            data={[
              { y: data?.Age12_17?.[type] },
              { y: data?.Age18_24?.[type] },
              { y: data?.Age25_32?.[type] },
              { y: data?.Age33_40?.[type] },
              { y: data?.Age41_56?.[type] },
              { y: data?.Age57_64?.[type] },
              { y: data?.Age65_72?.[type] },
            ]}
            innerRadius={100}
            labelRadius={140}
            labels={() => ""}
            colorScale={color}
            style={{ labels: { fontSize: 0, fill: "none" } }}
          />
          <VictoryLabel
            textAnchor="middle"
            verticalAnchor="middle"
            x={170}
            y={160}
            text={count}
            style={{ fontSize: 50, fill: "#1296b0", fontFamily: "Poppins" }}
          />
          <VictoryLabel
            textAnchor="middle"
            verticalAnchor="middle"
            x={170}
            y={200}
            text={`${Math.round(percent)}%`}
            style={{ fontSize: 25, fill: "#555", fontFamily: "Poppins" }}
          />
        </svg>
      )}
      {percent <= 0 && (
        <svg viewBox="0 0 300 300">
          <VictoryPie
            standalone={false}
            width={350}
            height={350}
            data={[{ y: 1 }]}
            innerRadius={100}
            labelRadius={140}
            labels={() => ""}
            colorScale={["#ccc"]}
            style={{ labels: { fontSize: 0, fill: "none" } }}
          />
          <VictoryLabel
            textAnchor="middle"
            verticalAnchor="middle"
            x={170}
            y={160}
            text={count}
            style={{ fontSize: 50, fill: "#1296b0", fontFamily: "Poppins" }}
          />
          <VictoryLabel
            textAnchor="middle"
            verticalAnchor="middle"
            x={170}
            y={200}
            text={`${Math.round(percent)}%`}
            style={{ fontSize: 25, fill: "#555", fontFamily: "Poppins" }}
          />
        </svg>
      )}
      <p>{title ?? ""}</p>
    </div>
  );
};

export default DonutChartWithCenterText;
