import React from "react";
import { FieldArray } from "formik";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import { Table } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { SelectForm } from "../../../../Components/FormComponent/SelectForm";
import { DatePickerFormiks } from "../../../../Components/FormComponent/DatePickerFormiks";
import {
  StyledTableCell,
  StyledTableCont,
} from "../../../../Components/FormComponent/Repeated";
import { TextAreaFormik } from "../../../../Components/FormComponent/TextAreaFormik";
import { showToast } from "../../../../Features";
import { useDispatch } from "react-redux";
import { useEffect } from "react";

const genderOptions = [
  { label: "Male", value: "Male" },
  { label: "Female", value: "Female" },
  { label: "Others", value: "Others" },
];

export const HotelVendorComp = ({ formValues }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    document.title = `PeopleSol - Vendor Hotel`;  
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  return (
    <>
      <div className="formcustom threeColumn">
        <div>
          <LabelCustom labelName={"Check-In Date and Time"} mandatory={true} />
          <DatePickerFormiks
            name={"departure"}
            showTimeSelect
            maxDate={formValues?.toDate}
          />
        </div>
        <div>
          <LabelCustom labelName={"Check-Out Date and Time"} mandatory={true} />
          <DatePickerFormiks
            name={"toDate"}
            showTimeSelect
            minDate={formValues?.departure}
          />
        </div>

        <div>
          <LabelCustom labelName={"Location"} mandatory={true} />
          <InputCustomFormik
            maxLength={50}
            name="fromLocation"
            type={"text"}
            placeholder={"Please Enter Location"}
          />
        </div>

        <div>
          <LabelCustom labelName={"Preferred Hotel "} mandatory={true} />
          <InputCustomFormik
            maxLength={150}
            name="preferredHotel"
            type={"text"}
            placeholder={"Please Enter Preferred Hotel"}
          />
        </div>
        <div>
          <LabelCustom labelName={"Budget (In Thousands)"} mandatory={true} />
          <InputCustomFormik
            maxLength={10}
            name="budget"
            step=".001"
            placeholder={"Please enter budget"}
          />
        </div>
        <div>
          <LabelCustom labelName={"Booking Note"} />
          <TextAreaFormik
            maxLength={500}
            name="comments"
            placeholder={"Please Enter Booking Note"}
          />
        </div>
      </div>
      <div>
        <label className="repeatedHeading">
          Please specify the vendor's details
        </label>
        <div style={{ width: "100%", overflowX: "auto" }}>
          <FieldArray name="groupItems">
            {(fieldArrayProps) => {
              const { push, remove, form } = fieldArrayProps;
              const { values } = form;
              const { groupItems } = values;
              return (
                <StyledTableCont sx={{ maxWidth: "100%" }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <StyledTableCell
                          padding="0"
                          style={{
                            fontSize: "12px",
                          }}
                          align="center"
                        >
                          <LabelCustom labelName={"S. No"} />
                        </StyledTableCell>
                        <StyledTableCell
                          padding="0"
                          style={{
                            fontSize: "12px",
                          }}
                          align="center"
                        >
                          <LabelCustom labelName={"Name"} mandatory={true} />
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            fontSize: "12px",
                          }}
                          align="center"
                        >
                          <LabelCustom
                            labelName={"Phone No."}
                            mandatory={true}
                          />
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            fontSize: "12px",
                          }}
                          align="center"
                        >
                          <LabelCustom labelName={"Email"} />
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            fontSize: "12px",
                          }}
                          align="center"
                        >
                          <LabelCustom labelName={"Age"} />
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            fontSize: "12px",
                          }}
                          align="center"
                        >
                          <LabelCustom labelName={"Gender"} />
                        </StyledTableCell>
                        {/* <StyledTableCell
                          style={{
                            fontSize: "12px",
                          }}
                          align="center"
                        >
                          <LabelCustom labelName={"Remarks"} />
                        </StyledTableCell> */}
                        <StyledTableCell
                          style={{
                            fontSize: "12px",
                          }}
                          align="center"
                        >
                          <LabelCustom labelName={"Add/Remove"} />
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    {groupItems?.map((groupItemss, index) => (
                      <TableBody key={index}>
                        {/* 1st column */}
                        <StyledTableCell align="center">
                          {index + 1}
                        </StyledTableCell>
                        {/* 2nd column */}
                        <StyledTableCell>
                          <InputCustomFormik
                            maxLength={150}
                            type="text"
                            name={`groupItems[${index}].name`}
                            placeholder={"Please enter the name"}
                          />
                        </StyledTableCell>
                        {/* 3rd column */}
                        <StyledTableCell>
                          <InputCustomFormik
                            maxLength={10}
                            type="number"
                           step="0.01"
                            name={`groupItems[${index}].phone`}
                            placeholder={"Please enter Phone no."}
                          />
                        </StyledTableCell>
                        {/* 4th column */}
                        <StyledTableCell>
                          <InputCustomFormik
                            maxLength={320}
                            type="text"
                            name={`groupItems[${index}].email`}
                            placeholder={"Please enter the Email"}
                          />
                        </StyledTableCell>
                        {/* 5th column */}
                        <StyledTableCell>
                          <InputCustomFormik
                            maxLength={3}
                            type="number"
                           step="0.01"
                            name={`groupItems[${index}].age`}
                            placeholder={"Please enter the Age"}
                          />
                        </StyledTableCell>
                        {/* 6th column */}
                        <StyledTableCell>
                          <SelectForm
                            name={`groupItems[${index}].gender`}
                            options={genderOptions}
                          />
                        </StyledTableCell>
                        {/* 7th column */}
                        {/* <StyledTableCell>
                          <TextAreaFormik
                            maxLength={300}
                            name={`groupItems[${index}].remarks`}
                            placeholder={"Please Enter Remarks"}
                          />
                        </StyledTableCell> */}
                        {/* 8th column */}
                        <StyledTableCell
                          sx={{
                            textAlign: "center",
                          }}
                        >
                          <button
                            type="button"
                            className="remove-btn"
                            onClick={() =>
                              groupItems.length > 1
                                ? remove(index)
                                : dispatch(
                                    showToast({
                                      text: "Atleast One Item is Required ",
                                      severity: "error",
                                    })
                                  )
                            }
                          >
                            -
                          </button>
                          <button
                            type="button"
                            className="add-btn"
                            onClick={() =>
                              push({
                                name: "",
                                phone: "",
                                email: "",
                                age: "",
                                gender: "",
                                remarks: "",
                              })
                            }
                          >
                            +
                          </button>
                        </StyledTableCell>
                      </TableBody>
                    ))}
                  </Table>
                </StyledTableCont>
              );
            }}
          </FieldArray>
        </div>
      </div>
    </>
  );
};
