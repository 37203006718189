import React, { useState } from "react";
import RadioButtonComponent from "./RadioButtonComponent";
import PollRadioButtonComponent from "./PollRadioButtonComponent";

function PollQuestionAnswerContainer({
  questionData,
  setSelectedData,
  selectedData,
}) {
  const [selected, setSelected] = useState([]);
  const [selectedQuestionsId, setSelectedQuestionsId] = useState([]);
  const selectOptions = (ansData, queData) => {
    if (selectedQuestionsId?.includes(queData?.id)) {
      // debugger;
      if (!queData?.allowMultipleAnswer) {
        if (selected.includes(ansData?.id)) {
          let x = selected?.filter((dt) => dt !== ansData?.id);
          setSelected(x);
          let y = selectedData?.filter((dtt) => dtt?.AnswerId !== ansData?.id);
          setSelectedData(y);
          let z = selectedQuestionsId?.filter((dttt) => dttt !== queData?.id);
          setSelectedQuestionsId(z);
        } else {
          let x = selected?.filter((dt) => {
            return !queData?.answerOption?.some((ansd) => ansd?.id === dt);
          });
          setSelected(x);
          let y = selectedData?.filter(
            (dtt) => dtt?.QuestionId !== queData?.id
          );
          setSelectedData(y);
          setSelected([...x, ansData?.id]);
          setSelectedData([
            ...y,
            {
              Id: 0,
              SurveyId: questionData?.id,
              QuestionId: queData?.id,
              AnswerId: ansData?.id,
            },
          ]);
        }
      } else {
        setSelected((prev) => [...prev, ansData?.id]);
        setSelectedData((prev) => [
          ...prev,
          {
            Id: 0,
            SurveyId: questionData?.id,
            QuestionId: queData?.id,
            AnswerId: ansData?.id,
          },
        ]);
      }
    } else {
      setSelected((prev) => [...prev, ansData?.id]);
      setSelectedQuestionsId((prev) => [...prev, queData?.id]);
      setSelectedData((prev) => [
        ...prev,
        {
          Id: 0,
          SurveyId: questionData?.id,
          QuestionId: queData?.id,
          AnswerId: ansData?.id,
        },
      ]);
    }
  };

  const findSelectedOptions = (id, respondAnswer) => {
    // debugger;
    return (
      respondAnswer?.some((element) => {
        return element?.answerId === id;
      }) || false
    );
  };

  return (
    <div className="questionAnswerContainer">
      <div className="questionAnswerWrapper">
        {questionData?.questions?.map((queData, queDataIndex) => {
          return (
            <div className="questionAnswerList" key={queDataIndex}>
              <label>{queData?.questionText}</label>
              {queData?.allowMultipleAnswer ? (
                <PollRadioButtonComponent
                  findSelectedOptions={findSelectedOptions}
                  queData={queData}
                  selectOptions={selectOptions}
                  selected={selected}
                />
              ) : (
                <PollRadioButtonComponent
                  findSelectedOptions={findSelectedOptions}
                  queData={queData}
                  selectOptions={selectOptions}
                  selected={selected}
                  id={"xcvbh"}
                  questionData={questionData}
                />
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default PollQuestionAnswerContainer;
