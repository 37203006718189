/* eslint-disable react-hooks/exhaustive-deps */
import { IconButton, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import { isLoading, showToast } from "../../../../Features";
import { getRequest, postRequest } from "../../../../Services/axios";
import { Popup } from "../../../../Components/FormComponent/PopupComponent/Popup";
import { Remark } from "../../../../Components/FormComponent/PopupComponent/Remark";
import { Details } from "../../../HRMS/Components/DetailsComp/Details";
import { Notifications } from "../../../HRMS/Components/DetailsComp/Notifications";
import { TimeLineData } from "../../../HRMS/Components/TransferComponents/DetailsComp/Timeline/TimeLineData";
import UploadDetail from "../../../../Components/UploadDetail/UploadDetail";
import { Loader } from "../../../../Components/FormComponent/Loader";
import { format } from "date-fns";
import { getStatusColor } from "../../../../Utilities/getStatusColor";
import { getBadgeCount } from "../../../../Features/badgeUtils";
import { useSelector } from "react-redux";
import UploadDetailMulti from "../../../../Components/UploadDetail/UploadDetailMulti";
const TravelBookingDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [typeAction, setTypeAction] = useState("");
  const [summary, setSummary] = useState(false);
  const [colorAction, setColorAction] = useState("");
  const closeDetails = () => {
    if (state?.prevPath) {
      navigate(state.prevPath);
    }
    // else if (document.referrer) {
    //   // Use the referrer (previous URL) if available
    //   navigate(document.referrer);
    // }
    else {
      // Fallback: Go back in the browser's history
      navigate(-1);
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - Travel Booking`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const id = useParams();
  let xy = Object.keys(id);
  const n = id[xy].lastIndexOf("/");
  const selectedId = id[xy].substring(n + 1);
  const [selId, setSelId] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const currentUrl = window.location.href;
  function getIdFromDynamicUrl(url) {
    const urlObject = new URL(url);
    const segments = urlObject.pathname
      .split("/")
      .filter((segment) => segment !== "");
    const idSegmentIndex = segments.indexOf("details");
    if (idSegmentIndex !== -1 && idSegmentIndex < segments.length - 1) {
      return segments[idSegmentIndex + 1];
    }
    return null; // Return null if id not found in the URL
  }
  const { LoggedInUser } = useSelector((state) => state.auth);

  function extractValuesFromURL(url) {
    const regex = /\/details\/(\d+)\/\?action=(approve|reject|hold)&eid=(\d+)/;
    const matches = url.match(regex);

    if (matches) {
      const value1 = matches[1]; // "246"
      const approve = matches[2]; // "approve"
      const value2 = Number(matches[3]); // "16"

      return { value1, approve, value2 };
    } else {
      return { value1: null, approve: null, value2: null };
    }
  }
  const { value2, approve } = extractValuesFromURL(currentUrl);

  const togglePopup = (type, color, id) => {
    setTypeAction(type);
    setSelId(id);
    setColorAction(color);
    setIsOpen((prev) => !prev);
  };

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(false);
  const getDetails = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(
        `TravelHospitalityRequests/Details/${getIdFromDynamicUrl(currentUrl)}`
      );
      setData(result?.data?.data);
      setSummary(result?.data?.summary);
      if (approve) {
        if (
          LoggedInUser?.id === value2 &&
          result.data?.summary?.actionlist.length
        ) {
          if (approve === "reject" || approve === "hold") {
            togglePopup(
              approve,
              result.data?.summary?.actionlist[0]?.color,
              result.data?.summary?.actionlist[0]?.id
            );
          } else
            PostApRejHol(
              approve,
              result.data?.summary?.actionlist[0]?.id,
              "Remark"
            );
        }
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const clickHandlerFunction = (id, type, color) => {
    if (
      type !== "Approve" &&
      type !== "Release" &&
      type !== "Cancel" &&
      type !== "Ready for Release" &&
      type !== "HRReject"
    ) {
      togglePopup(type, color, id);
    } else {
      PostApRejHol(type, id);
    }
  };
  const PostApRejHol = async (type, id, Remark) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      if (
        type === "Approve" ||
        type === "Cancel" ||
        type === "Release" ||
        type === "HRReject"
      ) {
        await getRequest(`TravelHospitalityRequests/${type}/${id}`);
      } else if (type === "Ready for Release") {
        await getRequest(`TravelHospitalityRequests/HRApprove/${id}`);
      } else {
        await getRequest(`TravelHospitalityRequests/${type}/${id}/${Remark}`);
      }
      dispatch(showToast({ text: "Success", severity: "success" }));
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      await getBadgeCount(dispatch);
      dispatch(isLoading(false));
      setLoading(false);
      setIsOpen(false);
      getDetails();
    }
  };

  useEffect(() => {
    getDetails();
  }, []);
  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };

  return (
    <div className="moduleMasterDetailsPage">
      <div
        className="moduleMasterDetailsBackground"
        onClick={() => closeDetails()}
      ></div>
      <div className="moduleMasterDetailsPageContainer tabularMasterDetailsPageContainer">
        <div className="headingContainer">
          <h3>Travel Booking Details</h3>
          <IconButton onClick={() => closeDetails()} title="close">
            <svg
              width="13"
              height="13"
              viewBox="0 0 13 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.7579 0.56897L0.757935 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M0.757935 0.56897L12.7579 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </IconButton>
        </div>
        <div className="tabularMasterDetailsPageBody">
          <div className="tabContainer">
            <Tabs
              value={tabIndex}
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
              TabIndicatorProps={{ style: { background: "#1296B0" } }}
            >
              <Tab
                style={{ minWidth: 130 }}
                label={
                  <span
                    className={`tabHeading ${tabIndex === 0 && "tabActive"}`}
                  >
                    Details
                  </span>
                }
              />
              <Tab
                style={{ minWidth: 130 }}
                label={
                  <span
                    className={`tabHeading ${tabIndex === 1 && "tabActive"}`}
                  >
                    Timeline
                  </span>
                }
              />
              <Tab
                style={{ minWidth: 130 }}
                label={
                  <span
                    className={`tabHeading ${tabIndex === 2 && "tabActive"}`}
                  >
                    Notifications
                  </span>
                }
              />
            </Tabs>
            <div className="tabContainerLine"></div>
          </div>
          <>
            {tabIndex === 0 && data != null && (
              <>
                {loading ? (
                  <Loader />
                ) : (
                  <div
                    className="detailsEReq"
                    style={{ flexDirection: "column" }}
                  >
                    <div className="headingForm">Request Details</div>
                    <div className="detailInner">
                      <Details
                        detailTitle={"Reference Number"}
                        Details={data?.requestNumber}
                      />
                      <Details
                        detailTitle={"Status"}
                        Details={
                          <div
                            style={{
                              backgroundColor: getStatusColor(
                                data?.status
                              )?.split(",")?.[0],
                              color: getStatusColor(data?.status)?.split(
                                ","
                              )?.[1],
                              cursor: "default",
                              padding: "6px",
                              borderRadius: "6px",
                            }}
                          >
                            {summary?.status}
                          </div>
                        }
                      />
                      <Details
                        detailTitle={"Travel Mode"}
                        Details={data?.mode}
                      />
                      <Details
                        detailTitle={"Budget"}
                        Details={data?.budget !== null ? data?.budget : "-"}
                      />
                      <Details
                        detailTitle={"From Location"}
                        Details={data?.pickupLocation}
                      />

                      <Details
                        detailTitle={"To Location"}
                        Details={data?.dropLocation}
                      />
                      {data.mode === "Train" ||
                      data.mode === "Bus" ||
                      data.mode === "Cab" ? (
                        <>
                          <Details
                            detailTitle={"Travel Date"}
                            Details={format(
                              new Date(data?.fromDate),
                              "dd/MM/yyyy "
                            )}
                          />
                        </>
                      ) : data?.mode === "Flight" ? (
                        <>
                          <Details
                            detailTitle={"Departure Date"}
                            Details={format(
                              new Date(data?.fromDate),
                              "dd/MM/yyyy "
                            )}
                          />
                        </>
                      ) : data?.mode === "Hotel" ? (
                        <>
                          <Details
                            detailTitle={"Location"}
                            Details={data?.pickupLocation}
                          />
                          <Details
                            detailTitle={"Check-In Date and Time"}
                            Details={
                              data?.fromDate
                                ? format(
                                    new Date(data?.fromDate),
                                    "dd/MM/yyyy hh:mm a"
                                  )
                                : "-"
                            }
                          />
                          <Details
                            detailTitle={"Check-Out Date and Time"}
                            Details={
                              data?.toDate
                                ? format(
                                    new Date(data?.toDate),
                                    "dd/MM/yyyy hh:mm a"
                                  )
                                : "-"
                            }
                          />
                          <Details
                            detailTitle={"Preferred Hotel"}
                            Details={data?.preferredHotel}
                          />
                        </>
                      ) : (
                        ""
                      )}
                      <Details
                        detailTitle={"Booking Request For"}
                        Details={data?.type}
                      />
                      {data.type === "Candidate" &&
                        summary.candidateName != null && (
                          <Details
                            detailTitle={"Candidate"}
                            Details={`${summary?.candidateName}`}
                          />
                        )}
                      {(data.type === "Candidate" || data.type === "Self") &&
                        data.mode !== "Cab" && (
                          <>
                            <Details
                              detailTitle={"Gender"}
                              Details={`${data?.travelHospitalityEmployees?.map(
                                (item) => {
                                  return item?.gender;
                                }
                              )}`}
                            />
                            <Details
                              detailTitle={"Age"}
                              Details={`${data?.travelHospitalityEmployees?.map(
                                (item) => {
                                  return item?.age;
                                }
                              )}`}
                            />
                          </>
                        )}

                      <Details
                        detailTitle={"Booking Note"}
                        Details={data?.comments}
                      />

                      <Details
                        detailTitle={"Created On"}
                        Details={
                          data?.createdOn
                            ? format(
                                new Date(data?.createdOn),
                                "dd/MM/yyyy hh:mm a"
                              )
                            : "-"
                        }
                      />
                      <Details
                        detailTitle={"Last Updated On"}
                        Details={
                          data?.lastUpdatedOn
                            ? format(
                                new Date(data?.lastUpdatedOn),
                                "dd/MM/yyyy hh:mm a"
                              )
                            : "-"
                        }
                      />
                      {data?.attachment !== null && (
                        <Details
                          detailTitle={"Tickets"}
                          Details={
                            <UploadDetailMulti
                              filepath={`TravelBookings/${data?.attachment}`}
                            />
                          }
                        />
                      )}
                    </div>
                    <div className="headingForm"> Requester's Details</div>
                    <div className="detailInner">
                      <Details
                        detailTitle={"Requester Name"}
                        Details={`${data?.employee?.name} ${data?.employee?.lastName}`}
                      />
                      <Details
                        detailTitle={"Requester's Manager"}
                        Details={data?.employee?.rO_Name}
                      />
                      <Details
                        detailTitle={"Requester Designation"}
                        Details={data?.employee?.designation}
                      />
                      <Details
                        detailTitle={"Requester Department"}
                        Details={data?.employee?.department}
                      />
                      <Details
                        detailTitle={"Requester Email"}
                        Details={data?.employee?.email}
                      />
                      <Details
                        detailTitle={"Contact Number"}
                        Details={data?.employee?.personalPhoneNumber}
                      />
                      <Details
                        detailTitle={"Location"}
                        Details={data?.employee?.locationName}
                      />
                    </div>

                    {data?.bookinglist?.length > 0 && (
                      <table
                        className="tabless tabless-bordered"
                        style={{ width: "100%" }}
                      >
                        <thead
                          style={{ backgroundColor: "var(--primary-hover)" }}
                        >
                          <tr>
                            <th width="3%">S No.</th>
                            <th width="3%">Option</th>
                            <th width="3%">Attachment</th>
                            <th width="3%">amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data?.bookinglist?.map((item, index) => {
                            return (
                              <tr>
                                <td>{index + 1}</td>
                                <td>{item?.remarks}</td>
                                <td>
                                  {item?.attachment?.includes("|") ? (
                                    item?.attachment?.split("|").map((item) => {
                                      return (
                                        <div>
                                          {" "}
                                          <a
                                            href={`${process.env.REACT_APP_.BASE_URL}/Core/Uploads/TravelBookings/${item}`}
                                            target="blank"
                                          >
                                            {item}
                                          </a>
                                        </div>
                                      );
                                    })
                                  ) : (
                                    <a
                                      href={`${process.env.REACT_APP_.BASE_URL}/Core/Uploads/TravelBookings/${item?.attachment}`}
                                      target="blank"
                                    >
                                      {item?.attachment}
                                    </a>
                                  )}
                                </td>
                                <td>{item?.amount}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    )}

                    <div style={{ padding: "0 12px" }}>
                      {data.type !== "Self" &&
                      data.type !== "Group" &&
                      data.type !== "Candidate" ? (
                        <>
                          <table
                            className="tabless tabless-bordered"
                            style={{ width: "100%" }}
                          >
                            <thead
                              style={{
                                backgroundColor: "var(--primary-hover)",
                              }}
                            >
                              <tr>
                                <th width="3%">Sl. No.</th>
                                <th width="3%">Name</th>
                                <th width="3%">Phone Number</th>
                                <th width="3%">Email</th>
                                {data.mode !== "Cab" && (
                                  <>
                                    <th width="3%">Gender</th>
                                    <th width="3%">Age</th>
                                  </>
                                )}
                              </tr>
                            </thead>
                            <tbody>
                              {data != null &&
                                data?.items != null &&
                                data?.items.length > 0 &&
                                data?.items?.map((item, index) => {
                                  return (
                                    <tr>
                                      <td>{index + 1}</td>
                                      <td>{item?.name}</td>
                                      <td>{item?.phoneNumber}</td>
                                      <td>{item?.emailId}</td>
                                      <td>{item?.gender}</td>
                                      <td>{item?.age}</td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </>
                      ) : data?.type === "Group" ? (
                        <>
                          <div
                            className="headingForm"
                            style={{ margin: "24px 0px" }}
                          >
                            Booking Details
                          </div>
                          <table
                            className="tabless tabless-bordered"
                            style={{ width: "100%" }}
                          >
                            <thead
                              style={{
                                backgroundColor: "var(--primary-hover)",
                              }}
                            >
                              <tr>
                                <th width="3%">Sl. No.</th>
                                <th width="3%">Name</th>
                                <th width="3%">Phone Number</th>
                                <th width="3%">Email</th>
                                <th width="3%">Gender</th>
                                <th width="3%">Age</th>
                              </tr>
                            </thead>
                            <tbody>
                              {data != null &&
                                data?.employeeItems != null &&
                                data?.employeeItems.length > 0 &&
                                data?.employeeItems?.map((item, index) => {
                                  return (
                                    <tr>
                                      <td>{index + 1}</td>
                                      <td>{item?.selectedItem?.text}</td>
                                      <td>{item?.phoneNumber}</td>
                                      <td>{item?.emailId}</td>
                                      <td>{item?.gender}</td>
                                      <td>{item?.age}</td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </>
                      ) : (
                        ""
                      )}
                    </div>

                    {summary?.actionlist?.length > 0 ? (
                      <div
                        className="createFootDetails"
                        style={{ background: "white !important" }}
                      >
                        {summary?.actionlist.map((item) => (
                          <div>
                            <div
                              className="button secondaryButton"
                              onClick={() =>
                                clickHandlerFunction(
                                  item?.id,
                                  item?.type,
                                  item?.color
                                )
                              }
                              style={{
                                background: item?.color,
                                color: "white",
                              }}
                            >
                              {item?.type}
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : null}
                    {isOpen && (
                      <Popup
                        popupHeading={typeAction}
                        content={
                          <>
                            <Remark
                              data={PostApRejHol}
                              type={typeAction}
                              id={selId}
                              color={colorAction}
                              handleClose={togglePopup}
                            />
                          </>
                        }
                        handleClose={togglePopup}
                      />
                    )}
                  </div>
                )}
              </>
            )}
            {tabIndex === 1 && (
              <>
                <div className="tabularMasterDetailsPageTabBodyContainer">
                  <div style={{ display: "flex", width: "100%" }}>
                    <TimeLineData
                      id={getIdFromDynamicUrl(currentUrl)}
                      url="TravelHospitalityRequests"
                      requestNumber={data?.requestNumber}
                      module={"Travel and Hospitality Requests"}
                    />
                  </div>
                </div>
              </>
            )}

            {tabIndex === 2 && (
              <>
                <div
                  className="tabularMasterDetailsPageTabBodyContainer"
                  style={{ flexGrow: "initial" }}
                >
                  <Notifications
                    id={getIdFromDynamicUrl(currentUrl)}
                    module={"Travel and Hospitality Requests"}
                  />
                </div>
              </>
            )}
          </>
        </div>
      </div>
    </div>
  );
};

export { TravelBookingDetails };
