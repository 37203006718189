/* eslint-disable react-hooks/exhaustive-deps */
import { Form, Formik } from "formik";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { isLoading, showToast } from "../../../../Features";
import { getRequest, postRequest } from "../../../../Services/axios";
import { GroupBy } from "../../../../Utilities";
import { useLocation, useNavigate } from "react-router-dom";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import { SelectForm } from "../../../../Components/FormComponent/SelectForm";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { CheckboxFormiks } from "../../../../Components/FormComponent/CheckboxFormiks";
import { Loader } from "../../../../Components/FormComponent/Loader";
const deductionFrequencyOptions = [
  {
    label: "One-time deduction",
    value: "One-time deduction",
  },
  {
    label: "Recurring deduction for subsequent Payrolls",
    value: "Recurring deduction for subsequent Payrolls",
  },
];
const deductionTypeOptions = [
  { label: "Pre-Tax Deduction", value: "Pre-Tax Deduction" },
  { label: "Post-Tax Deduction", value: "Post-Tax Deduction" },
];

function CreateDeduction() {
  useEffect(() => {
    document.title = `PeopleSol - Create Deduction `;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState(null);

  const [detailsform, setDetailsform] = useState(null);
  const { state } = useLocation();
  const editId = state?.id;
  const getFormDetails = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(
        `SalaryComponents/DeductionDetails/${editId}`
      );
      setDetailsform(result?.data?.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const initialValues = {
    deductionType: editId ? detailsform?.taxType : "",
    deductionFrequency: editId ? detailsform?.deductionFrequency : "",
    isProRataBasis: editId ? detailsform?.isProRataBasis : false,
    isEmployerContributionInCTC: editId ? detailsform?.isEmployerContributionInCTC : null,
    associateThisDeduction: editId ? detailsform?.associateThisDeduction : "",
    payType: "Fixed Pay",
    calculationType: "Flat Amount",
    config: { epfContribution: "", taxPreference: "" },
    calculationValue: "",
    nameInPayslip:editId ? detailsform?.nameInPayslip : "",
    isActive: true,
  };
  useEffect(() => {
    if (editId) {
      getFormDetails();
    }
  }, [editId]);
  const create = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest(
        "SalaryComponents/CreateDeduction",
        values
      );
      if (result) {
        dispatch(
          showToast({ text: "Successfully Created", severity: "success" })
        );
        returnPage();
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const edit = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest(
        "SalaryComponents/EditDeduction",
        values
      );
      if (result) {
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
        returnPage();
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const navigate = useNavigate();
  const returnPage = () => {
    navigate(-1);
  };
  const getInvestmentDropdown = async () => {
    try {
      setLoading(true);
      const result = await getRequest(
        "/SalaryComponents/PreTaxDeductionInvestmentDropdown"
      );
      // if (result) {
      //   let tempObject = [];
      //   result?.data?.data?.map((item) => {
      //     return tempObject.push({
      //       label: item?.deductionType,
      //       value: item?.deductionType,
      //     });
      //   });
      // }
    } catch {
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (formValues?.deductionType === "Pre-Tax Deduction") {
      getInvestmentDropdown();
    }
  }, [formValues?.deductionType]);

  const submitEditCompany = (values) => {
    console.log('values?.deductionFrequency',values?.deductionFrequency)
    let payload = {};
    payload["Id"] = editId ?? 0;
    payload["deductionType"] = values?.deductionType;
    payload["taxType"] = values?.deductionType;
    payload["isEmployerContributionInCTC"] = values?.isEmployerContributionInCTC;
    payload["Name"] = values?.nameInPayslip;
    payload["deductionFrequency"] = values?.deductionFrequency==""? values?.associateThisDeduction:values?.deductionFrequency;
    payload["isProRataBasis"] = values?.isProRataBasis;
    payload["associateThisDeduction"] = values?.associateThisDeduction==""? values?.deductionFrequency:values?.associateThisDeduction;
    payload["NameInPayslip"] = values?.nameInPayslip;
    payload["IsActive"] = values.isActive;
    editId ? edit(payload) : create(payload);
  };
  return (
    <div className="moduleMainContainer">
      <div
        className={"companyCard"}
        style={{ overflow: "auto", backgroundColor: "var(--form-background)" }}
      >
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          onSubmit={(values) => submitEditCompany(values)}
        >
          {(formik) => {
            setFormValues(formik?.values);
            return (
              <Form
                style={{
                  overflow: "auto",
                  height: "calc(100% - 72px)",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <>
                  <div style={{ display: "flex" }}>
                    <div
                      style={{
                        width: "50%",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <LabelCustom
                        labelName={"Deduction Type"}
                        mandatory={true}
                      />
                      <SelectForm
                        name="deductionType"
                        options={deductionTypeOptions}
                        isDisabled={editId ? true : false}
                      />
                    </div>
                  </div>
                  {loading && (
                    <div
                      style={{
                        display: "flex",
                        flexGrow: 1,
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Loader />
                    </div>
                  )}
                  {formValues?.deductionType &&
                  !loading &&
                  formValues?.deductionType !== "" ? (
                    <div
                      className="companyCardContainer"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        borderTop: "1px solid var(--grey)",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <div style={{ width: "50%" }}>
                          {formValues?.deductionType === "Pre-Tax Deduction" && (
                            <div>
                              <LabelCustom
                                labelName={"Associate this deduction with"}
                                mandatory={true}
                              />
                              <SelectForm
                                name="associateThisDeduction"
                                options={deductionFrequencyOptions}
                              />
                            </div>
                          )}
                          <div>
                            <LabelCustom
                              labelName={"Name in Payslip"}
                              mandatory={true}
                            />
                            <InputCustomFormik name="nameInPayslip" />
                          </div>
                          {formValues?.deductionType === "Post-Tax Deduction" && (
                            <div>
                              <LabelCustom
                                labelName={"Select the deduction frequency"}
                                mandatory={true}
                              />
                              <SelectForm
                                name="deductionFrequency"
                                options={deductionFrequencyOptions}
                              />
                            </div>
                          )}
                          <div>
                            <CheckboxFormiks
                              name="isActive"
                              labelName={"Mark this as Active"}
                            />
                          </div>
                        </div>
                        {/* {formValues?.deductionType === "Pre-Tax Deduction" && (
                          <div
                            style={{
                              boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.02)",
                              backgroundColor: "white",
                              borderRadius: "4px",
                              width: "50%",
                              padding: "12px",
                              margin: "24px 12px 12px 12px",
                              lineHeight: "22px",
                              height: "fit-content",
                            }}
                          >
                            <h5 style={{ color: "var(--primary)" }}>
                              Other Configurations
                            </h5>
                            <div>
                              <CheckboxFormiks
                                name={"isEmployerContributionInCTC"}
                                labelName={
                                  "Include employer's contribution in the CTC"
                                }
                              />
                            </div>
                            <div>
                              <CheckboxFormiks
                                name={"isProRataBasis"}
                                labelName={"Calculate on pro-rata basis"}
                              />
                              <p
                                style={{
                                  fontSize: "11px",
                                  marginLeft: "45px",
                                  marginTop: "-24px",
                                }}
                              >
                                Pay will be adjusted based on employee working
                                days.
                              </p>
                            </div>
                          </div>
                        )} */}
                      </div>
                      <div
                        style={{
                          borderRadius: "8px",
                          backgroundColor: "#FFF4E5",
                          margin: "12px",
                          padding: "12px",
                        }}
                      >
                        NOTE: Once you associate this deduction with an
                        employee, you will only be able to edit the Name in
                        Payslip. The change will be reflected in both new and
                        existing employees.
                      </div>
                    </div>
                  ) : null}
                </>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    position: "absolute",
                    width: "calc(100% - 24px)",
                    bottom: "12px",
                    borderTop: "1px solid var(--secondary-text)",
                    margin: "0 12px",
                  }}
                >
                  <button
                    className="button secondaryButton"
                    type="button"
                    onClick={returnPage}
                  >
                    Cancel
                  </button>
                  <button className="button primaryButton" type="submit">
                    {editId ? "Update" : "Create"}
                  </button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}

export default CreateDeduction;
