import React, { useEffect, useState } from "react";
import { Popup } from "../../../../../Components/FormComponent/PopupComponent/Popup";
import { useSelector } from "react-redux";
import {
  getRequest,
  postRequest,
  postRequestForm,
} from "../../../../../Services/axios";
import { useDispatch } from "react-redux";
import { showToast } from "../../../../../Features";
import PDFViewer from "./PDFViewer";
import SocialTextLinkIcon from "../../../../../Assets/SocialTextLinkIcon.svg";
import SocialNewsEndLinkIcon from "../../../../../Assets/SocialNewsEndLinkIcon.svg";
import SocialCommunityGroupIcon from "../../../../../Assets/SocialCommunityGroupIcon.svg";
import SocialPostTeamsIcon from "../../../../../Assets/SocialPostTeamsIcon.svg";
import SocialPostComunityIcon from "../../../../../Assets/SocialPostComunityIcon.svg";
import ProfileAvatar from "../../../../../Assets/ProfileAvatar.svg";
import SocialNewsTag from "../../../../../Assets/SocialNewsTag.svg";
import SocialPostOrganizationIcon from "../../../../../Assets/SocialPostOrganizationIcon.svg";

import SocialAnnouncementTag from "../../../../../Assets/SocialAnnouncementTag.svg";
import dateFormat from "dateformat";
import {
  communityProfileImages,
  profileImages,
  socialPostImages,
} from "../../../../../Utilities/profileAndUploadImages";
import {
  docsArray,
  getAttachments,
  getContentLength,
  getExtension,
  getUserInitials,
  imgArray,
  videoArray,
} from "../../../../../Utilities/socialAllFunctions";
import { Mention, MentionsInput } from "react-mentions";
import { useNavigate, useParams } from "react-router-dom";
import ContentSocialDisplay from "./ContentSocialDisplay";
import ShowCarouselGreetingsImages from "./ShowCarouselGreetingsImages";
import ShowCarouselSocialImages from "./ShowCarouselSocialImages";
import { setRedDotSocialData } from "../../../../../Features/SocialSlice";
import getDisplayName from "../../../../../Utilities/displayNameFormat";

function SharePost({ getData }) {
  const [showContent, setShowContent] = useState(false);
  const { postid } = useParams();
  const [sharePostData, setSharePostData] = useState(null);
  const [summaryData, setSummaryData] = useState(null);
  const [newSharePostActive, setNewSharePostActive] = useState(true);
  const [shareTextContent, setShareTextContent] = useState("");
  const [loading, setLoading] = useState(false);
  const { LoggedInUser } = useSelector((state) => state.auth);
  const { employeeListReduxData } = useSelector((state) => state.social);
  const dispatch = useDispatch();
  const [selectPostTypeActive, setSelectPostTypeActive] = useState(false);
  const [PostSubType, setPostSubType] = useState("immediate");
  const [newPostType, setNewPostType] = useState("organisation");
  const [communityListActive, setCommunityListActive] = useState(false);
  const [selectPostSubTypeActive, setSelectPostSubTypeActive] = useState(false);
  const [allCommunityList, setAllCommunityList] = useState();
  const [newPostCommunityId, setNewPostCommunityId] = useState(0);
  const navigate = useNavigate();

  const getCommunityData = async () => {
    try {
      const result = await postRequest("Community/AllList", {
        createdOn: null,
        lastUpdatedOn: null,
        dataTableRequest: {
          iDisplayLength: 100,
          iDisplayStart: 0,
          sSearch: null,
          sorting: "Id",
          sSortDir_0: "descending",
          isExport: false,
        },
      });
      setAllCommunityList(result?.data?.data);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };

  const sharePost = async () => {
    setLoading(true);
    try {
      let result = null;
      var bodyFormData = new FormData();
      bodyFormData.append(
        "PostId",
        sharePostData?.sharedPostId ?? sharePostData?.id
      );
      bodyFormData.append("Npost", sharePostData?.id);
      bodyFormData.append("content", shareTextContent);
      bodyFormData.append("postType", newPostType);
      if (newPostType === "team") {
        bodyFormData.append("postSubType", PostSubType);
      }
      bodyFormData.append("communityId", newPostCommunityId);
      bodyFormData.append(
        "isNasted",
        sharePostData?.sharedPostId ? true : false
      );
      result = await postRequestForm(`socialPost/PostShare`, bodyFormData);
      setRedDotSocialData(1);
      if (result?.status === 200 && result?.statusText === "OK") {
        if (result?.data?.isSuccess) {
          await getData(true);
          navigate(-1);
          dispatch(
            showToast({
              text: "Post Shared Successfully",
              severity: "success",
            })
          );
        } else {
          dispatch(
            showToast({
              text: "Post doesn't shared successfully!",
              severity: "error",
            })
          );
        }
      }
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };

  const getSharedData = async () => {
    setLoading(true);
    try {
      const result = await getRequest(`SocialPost/Details?PostId=${postid}`);
      setSharePostData(result?.data?.data);
      setSummaryData(result?.data?.summary);
    } catch (error) {
      dispatch(
        showToast({
          text: "Something went wrong!",
          severity: "error",
        })
      );
      return;
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getSharedData();
    getCommunityData();
  }, []);

  return (
    <>
      {!loading && sharePostData !== null && (
        <div>
          {newSharePostActive && (
            <Popup
              firstClassName="sharePostPopup1"
              secondClassName="sharePostPopup2"
              contentClassName="sharePostContentHolder"
              popupHeading={
                <div className="newPostHeaderContainer">
                  <div
                    onClick={() => {
                      navigate(`/home/social/userprofile/${LoggedInUser?.id}`);
                    }}
                    className={`${
                      LoggedInUser?.image
                        ? "socialProfilePicContainer"
                        : "socialCustomProfilePic"
                    }`}
                  >
                    {!LoggedInUser?.image ? (
                      getUserInitials(
                        getDisplayName(LoggedInUser?.nameWithPersonNumber)
                      )
                    ) : (
                      <img
                        className="socialProfilePic"
                        src={
                          process.env.REACT_APP_.BASE_URL +
                          profileImages +
                          LoggedInUser?.image
                        }
                        alt="Profile"
                        onError={(e) => {
                          e.target.src = ProfileAvatar;
                        }}
                      />
                    )}
                  </div>
                  <div className="newPostHeaderTextContainer">
                    <h5>
                      {getDisplayName(LoggedInUser?.nameWithPersonNumber)}
                    </h5>
                    <p>{LoggedInUser?.designation}</p>
                    <span>
                      {employeeListReduxData?.map((d, i) => {
                        if (d?.id === LoggedInUser?.id) {
                          return d?.location;
                        } else {
                          return null;
                        }
                      })}
                    </span>
                  </div>
                </div>
              }
              content={
                <div className="newPostBodyContainer">
                  <MentionsInput
                    placeholder=" What do you want to talk about? "
                    autoFocus
                    value={shareTextContent}
                    maxLength={500}
                    onChange={(e) => {
                      setShareTextContent(e.target.value);
                    }}
                    a11ySuggestionsListLabel={
                      " What do you want to talk about? "
                    }
                    className={"createPostInputText"}
                  >
                    <Mention
                      trigger={"@"}
                      data={employeeListReduxData?.map((d) => {
                        return { id: d?.id, display: getDisplayName(d?.name) };
                      })}
                    />
                  </MentionsInput>
                  <div
                    className="sharePostContainer"
                    style={{
                      padding: "15px",
                      border: "1px solid #F1F2F2",
                      borderRadius: "10px",
                    }}
                  >
                    <div className="sharePostWrapper">
                      {sharePostData?.type !== "announcement" &&
                        sharePostData?.type !== "news" && (
                          <div className="newPostHeaderContainer">
                            <div
                              onClick={() => {
                                navigate(
                                  `/home/social/userprofile/${sharePostData?.employeeId}`
                                );
                              }}
                              className={`${
                                summaryData?.parantPostEmployee?.image
                                  ? "socialProfilePicContainer"
                                  : "socialCustomProfilePic"
                              }`}
                            >
                              {!summaryData?.parantPostEmployee?.image ? (
                                getUserInitials(
                                  getDisplayName(
                                    summaryData?.parantPostEmployee
                                      ?.nameWithPersonNumber
                                  )
                                )
                              ) : (
                                <img
                                  className="socialProfilePic"
                                  src={
                                    process.env.REACT_APP_.BASE_URL +
                                    profileImages +
                                    summaryData?.parantPostEmployee?.image
                                  }
                                  alt="Profile"
                                  onError={(e) => {
                                    e.target.src = ProfileAvatar;
                                  }}
                                />
                              )}
                            </div>
                            <div className="newPostHeaderTextContainer">
                              <h5>
                                {getDisplayName(
                                  summaryData?.parantPostEmployee
                                    ?.nameWithPersonNumber
                                )}
                              </h5>
                              <p>{summaryData?.parantPostEmployee?.roleName}</p>
                              <span>
                                {summaryData?.parantPostEmployee?.locationName}
                              </span>
                            </div>
                          </div>
                        )}

                      {sharePostData?.subType !== null &&
                        sharePostData?.type !== "news" &&
                        sharePostData?.type !== "announcement" && (
                          <div
                            className="postBodyContainer"
                            style={{ margin: "15px 10px 0px 15px" }}
                          >
                            <div className="postBodyTextContainer">
                              {sharePostData?.content?.split(/\r\n|\r|\n/)
                                .length > 4 && !showContent ? (
                                <ContentSocialDisplay
                                  content={getContentLength(
                                    sharePostData?.content
                                  )}
                                />
                              ) : (
                                <ContentSocialDisplay
                                  content={sharePostData?.content?.trim()}
                                />
                              )}
                              {sharePostData?.content?.split(/\r\n|\r|\n/)
                                .length > 4 &&
                                !showContent && (
                                  <span
                                    onClick={() => {
                                      setShowContent(true);
                                    }}
                                  >
                                    ...View More
                                  </span>
                                )}
                            </div>
                            <div
                              className={` ${
                                sharePostData?.sharedEmployee !== null
                                  ? "postBodyImgContainer"
                                  : ""
                              }`}
                            >
                              <div
                                className={
                                  getAttachments(sharePostData?.attachment)
                                    ?.length === 1
                                    ? ``
                                    : `newPostBodyImgMainWrapper`
                                }
                                style={{ margin: "0" }}
                              >
                                <div
                                  className="newPostBodyImgContainer1"
                                  style={{
                                    height: "300px",
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  {getExtension(
                                    getAttachments(sharePostData?.attachment)[0]
                                  ) !== "gif" ? (
                                    <ShowCarouselGreetingsImages
                                      photo={
                                        getAttachments(
                                          sharePostData?.attachment
                                        ) || []
                                      }
                                    />
                                  ) : (
                                    <ShowCarouselSocialImages
                                      photo={
                                        getAttachments(
                                          sharePostData?.attachment
                                        ) || []
                                      }
                                      showModal={() => {
                                        navigate(
                                          "details/" + sharePostData?.id
                                        );
                                      }}
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                      {sharePostData?.postSubType === null &&
                        sharePostData?.type === "news" && (
                          <div>
                            <div
                              className="postHeaderContainer"
                              style={{ boxShadow: "none" }}
                            >
                              <div className="leftPostHeaderContainer">
                                <div className="socialPostTitleAndTimeContainer">
                                  <label>
                                    {summaryData?.news?.title?.length > 40
                                      ? summaryData?.news?.title?.substring(
                                          0,
                                          40
                                        ) + "..."
                                      : summaryData?.news?.title}
                                  </label>
                                  <span className="postHrTime">
                                    •{" "}
                                    {dateFormat(
                                      summaryData?.createdOn,
                                      "hh:MM TT | dd mmm yyyy"
                                    )}
                                  </span>
                                </div>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <div className="newsTypeContainer">
                                  <span>{summaryData?.news?.category}</span>
                                  <article></article>
                                  <img src={SocialNewsTag} alt="" />
                                </div>
                              </div>
                            </div>
                            <div
                              className="postBodyContainer"
                              style={{ margin: "15px 10px 0px 15px" }}
                            >
                              <div>
                                {sharePostData?.attachment && (
                                  <div
                                    className={
                                      getAttachments(sharePostData?.attachment)
                                        ?.length === 1
                                        ? ``
                                        : `newPostBodyImgMainWrapper`
                                    }
                                    style={{ margin: "0" }}
                                  >
                                    <div
                                      className="newPostBodyImgContainer1"
                                      style={{
                                        height: "400px",
                                        display: "flex",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <img
                                        src={
                                          process.env.REACT_APP_.BASE_URL +
                                          socialPostImages +
                                          sharePostData?.attachment
                                        }
                                        alt=""
                                        style={{
                                          width: "100%",
                                          height: "100%",
                                          objectFit: "contain",
                                        }}
                                      />
                                    </div>
                                  </div>
                                )}
                              </div>
                              <div className="postBodyTextContainer">
                                {sharePostData?.content?.split(/\r\n|\r|\n/)
                                  .length > 4 && !showContent ? (
                                  <ContentSocialDisplay
                                    content={getContentLength(
                                      sharePostData?.content
                                    )}
                                  />
                                ) : (
                                  <ContentSocialDisplay
                                    content={sharePostData?.content?.trim()}
                                  />
                                )}
                                {sharePostData?.content?.split(/\r\n|\r|\n/)
                                  .length > 4 &&
                                  !showContent && (
                                    <span
                                      onClick={() => {
                                        setShowContent(true);
                                      }}
                                    >
                                      ...View More
                                    </span>
                                  )}
                              </div>
                              {sharePostData?.news?.link && (
                                <div
                                  className="newsLinkContainer"
                                  style={{ margin: "10px 0", width: "100%" }}
                                >
                                  <div className="newsLinkWrapper">
                                    <div className="newsLink">
                                      <img src={SocialTextLinkIcon} alt="" />{" "}
                                      <a
                                        href={sharePostData?.news?.link}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        <span>
                                          {sharePostData?.news?.link?.substring(
                                            0,
                                            50
                                          )}
                                        </span>
                                      </a>
                                    </div>
                                    <img
                                      style={{ width: "29px" }}
                                      src={SocialNewsEndLinkIcon}
                                      alt=""
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        )}

                      {sharePostData?.subType === null &&
                        sharePostData?.type !== "news" &&
                        sharePostData?.type !== "announcement" && (
                          <div
                            className="postBodyContainer"
                            style={{ margin: "15px 10px 0px 15px" }}
                          >
                            <div className="postBodyTextContainer">
                              {sharePostData?.content?.split(/\r\n|\r|\n/)
                                .length > 4 && !showContent ? (
                                <ContentSocialDisplay
                                  content={getContentLength(
                                    sharePostData?.content
                                  )}
                                />
                              ) : (
                                <ContentSocialDisplay
                                  content={sharePostData?.content?.trim()}
                                />
                              )}
                              {sharePostData?.content?.split(/\r\n|\r|\n/)
                                .length > 4 &&
                                !showContent && (
                                  <span
                                    onClick={() => {
                                      setShowContent(true);
                                    }}
                                  >
                                    ...View More
                                  </span>
                                )}
                            </div>
                            {sharePostData?.attachment &&
                              sharePostData?.attachment?.length > 0 &&
                              imgArray.includes(
                                getExtension(
                                  getAttachments(sharePostData?.attachment)
                                    ? getAttachments(
                                        sharePostData?.attachment
                                      )[0]
                                    : ""
                                )
                              ) && (
                                <div
                                  className="newPostBodyImgMainWrapper"
                                  style={{ margin: "0" }}
                                >
                                  <div
                                    className="newPostBodyImgContainer"
                                    style={{ width: "100%" }}
                                  >
                                    <ShowCarouselSocialImages
                                      photo={
                                        getAttachments(
                                          sharePostData?.attachment
                                        ) || []
                                      }
                                      showModal={() => {}}
                                    />
                                  </div>
                                </div>
                              )}

                            {sharePostData?.attachment?.length > 0 &&
                              videoArray.includes(
                                getExtension(
                                  getAttachments(sharePostData?.attachment)
                                    ? getAttachments(
                                        sharePostData?.attachment
                                      )[0]
                                    : ""
                                )
                              ) && (
                                <div
                                  className={` ${
                                    sharePostData?.sharedEmployee !== null
                                      ? "postBodyImgContainer"
                                      : ""
                                  }`}
                                >
                                  <div
                                    className="newPostBodyImgMainWrapper"
                                    style={{ margin: "0" }}
                                  >
                                    {getAttachments(sharePostData?.attachment)
                                      ?.length === 1 && (
                                      <div
                                        className="newPostBodyImgContainer"
                                        style={{ width: "100%" }}
                                      >
                                        <video
                                          src={
                                            process.env.REACT_APP_.BASE_URL +
                                            socialPostImages +
                                            getAttachments(
                                              sharePostData?.attachment
                                            )[0]
                                          }
                                          controls
                                          style={{
                                            borderRadius: "8px",
                                            width: "100%",
                                            height: "100%",
                                            overflow: "hidden",
                                          }}
                                        />
                                      </div>
                                    )}
                                  </div>
                                </div>
                              )}

                            {sharePostData?.attachment?.length > 0 &&
                              docsArray.includes(
                                getExtension(
                                  getAttachments(sharePostData?.attachment)
                                    ? getAttachments(
                                        sharePostData?.attachment
                                      )[0]
                                    : ""
                                )
                              ) && (
                                <div className="sharePostBodyImgContainer">
                                  {getAttachments(sharePostData?.attachment)
                                    ?.length === 1 && (
                                    <div className="sharePostBodyImgContainer">
                                      <PDFViewer
                                        pdfUrl={
                                          process.env.REACT_APP_.BASE_URL +
                                          socialPostImages +
                                          getAttachments(
                                            sharePostData?.attachment
                                          )[0]
                                        }
                                      />
                                    </div>
                                  )}
                                </div>
                              )}
                          </div>
                        )}

                      {sharePostData?.postSubType === null &&
                        sharePostData?.type === "announcement" && (
                          <div>
                            <div
                              className="postHeaderContainer"
                              style={{ boxShadow: "none" }}
                            >
                              <div className="leftPostHeaderContainer">
                                <div className="announcementHeaderContainer">
                                  <label>
                                    {summaryData?.announcement?.title ?? "--"}
                                  </label>
                                  <span className="postHrTime">
                                    •{" "}
                                    {dateFormat(
                                      sharePostData?.createdOn,
                                      "hh:MM TT | dd mmm yyyy"
                                    )}
                                  </span>
                                </div>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <div className="announcementTypeContainer">
                                  <span>Announcement</span>
                                  <article></article>
                                  <img src={SocialAnnouncementTag} alt="" />
                                </div>
                              </div>
                            </div>
                            <div
                              className="postBodyTextContainer"
                              style={{ marginLeft: "15px" }}
                            >
                              {sharePostData?.content?.split(/\r\n|\r|\n/)
                                .length > 4 && !showContent ? (
                                <ContentSocialDisplay
                                  content={getContentLength(
                                    sharePostData?.content
                                  )}
                                />
                              ) : (
                                <ContentSocialDisplay
                                  content={sharePostData?.content?.trim()}
                                />
                              )}
                              {sharePostData?.content?.split(/\r\n|\r|\n/)
                                .length > 4 &&
                                !showContent && (
                                  <span
                                    onClick={() => {
                                      setShowContent(true);
                                    }}
                                  >
                                    ...View More
                                  </span>
                                )}
                            </div>
                            <div
                              className="postBodyContainer"
                              style={{ margin: "15px 10px 0px 15px" }}
                            >
                              {sharePostData?.attachment && (
                                <div
                                  className={` ${
                                    sharePostData?.sharedEmployee !== null
                                      ? "postBodyImgContainer"
                                      : ""
                                  }`}
                                >
                                  <div
                                    className={
                                      getAttachments(sharePostData?.attachment)
                                        ?.length === 1
                                        ? ``
                                        : `newPostBodyImgMainWrapper`
                                    }
                                    style={{ margin: "0" }}
                                  >
                                    <div
                                      className="newPostBodyImgContainer1"
                                      style={{
                                        height: "400px",
                                        display: "flex",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <img
                                        src={
                                          sharePostData?.attachment
                                            ? process.env.REACT_APP_.BASE_URL +
                                              socialPostImages +
                                              sharePostData?.attachment
                                            : null
                                        }
                                        alt=""
                                        style={{
                                          width: "100%",
                                          height: "100%",
                                          objectFit: "contain",
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}

                              {sharePostData?.news?.link && (
                                <div
                                  className="newsLinkContainer"
                                  style={{ margin: "10px 0", width: "100%" }}
                                >
                                  <div className="newsLinkWrapper">
                                    <div className="newsLink">
                                      <img src={SocialTextLinkIcon} alt="" />{" "}
                                      <a
                                        href={sharePostData?.news?.link}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        <span>{sharePostData?.news?.link}</span>
                                      </a>
                                    </div>
                                    <img
                                      style={{ width: "29px" }}
                                      src={SocialNewsEndLinkIcon}
                                      alt=""
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                    </div>
                  </div>
                  <div className="shareBottomContainer">
                    <button
                      class={
                        shareTextContent === ""
                          ? "button sharePostGreyBtn"
                          : "button primaryButton"
                      }
                      onClick={() => {
                        if (shareTextContent) {
                          setNewSharePostActive(false);
                          setSelectPostTypeActive(true);
                        }
                      }}
                    >
                      Next
                    </button>
                  </div>
                </div>
              }
              handleClose={() => {
                setNewSharePostActive(false);
                navigate(-1);
              }}
            />
          )}
          {selectPostTypeActive && (
            <Popup
              firstClassName="selectPostTypePopupBox1"
              secondClassName="selectPostTypePopupBox2"
              // contentClassName="newPostPopupContent"
              popupHeading="Who can see your post?"
              content={
                <div className="selectPostTypeContainer">
                  <div className="selectPostTypeWrapper">
                    <div className="selectPostOrganizationContainer">
                      <div>
                        <article className="createPostTypeImgContainer">
                          <img src={SocialPostOrganizationIcon} alt="" />
                        </article>
                        <span>Organisation</span>
                      </div>
                      <div>
                        <label class="configContainer">
                          <input
                            type="radio"
                            checked={
                              newPostType === "organisation" ? true : false
                            }
                            name="attenAllowFromWeb"
                            onChange={() => {
                              setNewPostType("organisation");
                            }}
                          />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </div>
                    <div className="selectPostTeamContainer">
                      <div>
                        <article className="createPostTypeImgContainer">
                          <img
                            src={SocialPostTeamsIcon}
                            alt=""
                            style={{ height: "20px", paddingTop: "3px" }}
                          />
                        </article>
                        <span>Team</span>
                      </div>
                      <div>
                        <label class="configContainer">
                          <input
                            type="radio"
                            name="attenAllowFromWeb"
                            onChange={() => {
                              setNewPostType("team");
                            }}
                            checked={newPostType === "team" ? true : false}
                          />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </div>
                    <div className="selectPostCommunityContainer">
                      <div>
                        <article className="createPostTypeImgContainer">
                          <img
                            src={SocialPostComunityIcon}
                            alt=""
                            style={{ height: "22px", paddingTop: "5px" }}
                          />
                        </article>
                        <span>Community</span>
                      </div>
                      <div>
                        <label class="configContainer">
                          <input
                            type="radio"
                            name="attenAllowFromWeb"
                            onChange={() => {
                              setNewPostType("community");
                            }}
                            checked={newPostType === "community" ? true : false}
                          />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                  {!loading && (
                    <div
                      className="socialPostDeleteBtn"
                      style={{ marginTop: "20px" }}
                    >
                      <button
                        onClick={() => {
                          if (newPostType === "community") {
                            setCommunityListActive(true);
                            setSelectPostTypeActive(false);
                          } else if (newPostType === "team") {
                            setSelectPostSubTypeActive(true);
                            setSelectPostTypeActive(false);
                          } else if (newPostType === "organisation") {
                            sharePost();
                          }
                        }}
                      >
                        Share
                      </button>
                      <button
                        onClick={() => {
                          // setNewPostActive(true);
                          setNewSharePostActive(true);
                          setSelectPostTypeActive(false);
                        }}
                        style={{
                          border: "1px solid #1296B0",
                          color: "#1296B0",
                        }}
                      >
                        Back
                      </button>
                    </div>
                  )}
                  {loading && (
                    <span
                      style={{
                        position: "absolute",
                        color: "#bfbfbf",
                        bottom: "20px",
                        right: "20px",
                      }}
                    >
                      Sharing...
                    </span>
                  )}
                </div>
              }
              handleClose={() => {
                setSelectPostTypeActive(false);
                setShareTextContent("");
                setNewSharePostActive(false);
                navigate(-1);
              }}
            />
          )}
          {selectPostSubTypeActive && (
            <Popup
              firstClassName="selectPostTypePopupBox1 selectCommunityTypePopupBox1"
              secondClassName="selectPostTypePopupBox2 selectCommunityTypePopupBox2"
              popupHeading="Select SubType"
              content={
                <div className="selectPostTypeContainer">
                  <div className="selectPostTypeWrapper">
                    <div className="selectPostOrganizationContainer">
                      <div>
                        <article className="createPostTypeImgContainer">
                          <img src={SocialPostOrganizationIcon} alt="" />
                        </article>
                        <span>Immediate</span>
                      </div>
                      <div>
                        <label class="configContainer">
                          <input
                            type="radio"
                            checked={PostSubType === "immediate" ? true : false}
                            name="attenAllowFromWeb"
                            onChange={() => {
                              setPostSubType("immediate");
                            }}
                          />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </div>
                    <div className="selectPostTeamContainer">
                      <div>
                        <article className="createPostTypeImgContainer">
                          <img
                            src={SocialPostTeamsIcon}
                            alt=""
                            style={{ height: "20px", paddingTop: "4px" }}
                          />
                        </article>
                        <span>All Report</span>
                      </div>
                      <div>
                        <label class="configContainer">
                          <input
                            type="radio"
                            checked={
                              PostSubType === "allreporte" ? true : false
                            }
                            name="attenAllowFromWeb"
                            onChange={() => {
                              setPostSubType("allreporte");
                            }}
                          />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                  {!loading && (
                    <div
                      className="socialPostDeleteBtn"
                      style={{ marginTop: "20px" }}
                    >
                      <button
                        onClick={() => {
                          sharePost();
                        }}
                      >
                        Share
                      </button>
                      <button
                        onClick={() => {
                          setSelectPostSubTypeActive(false);
                          setSelectPostTypeActive(true);
                        }}
                        style={{
                          border: "1px solid #1296B0",
                          color: "#1296B0",
                        }}
                      >
                        Back
                      </button>
                    </div>
                  )}
                  {loading && <div className="saving-post">Sharing...</div>}
                </div>
              }
              handleClose={() => {
                setSelectPostSubTypeActive(false);
                setSelectPostTypeActive(true);
                setCommunityListActive(false);
                setShareTextContent("");
                setNewSharePostActive(false);
                navigate(-1);
              }}
            />
          )}
          {communityListActive && (
            <Popup
              firstClassName="newPostForEmployeePopup1 CreatePostSelectCommunity"
              secondClassName="newPostForEmployeePopup2"
              popupHeading="Select Community"
              content={
                <div className="selectPostTypeContainer">
                  <div className="communityBodyContainer">
                    {allCommunityList?.length > 0 &&
                      allCommunityList.map((d, i) => {
                        return (
                          <div className="communityBodyWrapper">
                            <div className="communityListContainer">
                              <div className="communityListWrapper">
                                <div className="communityListImgContainer">
                                  <div
                                    onClick={(e) => {
                                      // handleUserProfileClick(LoggedInUser?.id);
                                    }}
                                    className={`${
                                      d?.item?.communityImage
                                        ? "socialConnectProfilePicContainer"
                                        : "socialCommunityCustomProfilePic"
                                    }`}
                                  >
                                    {!d?.item?.communityImage &&
                                    d?.item?.communityName ? (
                                      getUserInitials(d?.item?.communityName)
                                    ) : (
                                      <img
                                        className="socialProfilePic"
                                        style={{
                                          width: "45px",
                                          height: "45px",
                                          marginRight: "15px",
                                        }}
                                        src={
                                          process.env.REACT_APP_.BASE_URL +
                                          communityProfileImages +
                                          d?.item?.communityImage
                                        }
                                        alt="Profile"
                                        onError={(e) => {
                                          e.target.src =
                                            SocialCommunityGroupIcon;
                                        }}
                                      />
                                    )}
                                  </div>
                                </div>
                                <div className="communityListTextContainer">
                                  <h5>{d?.item?.communityName}</h5>
                                  <p>{d?.item?.communityType}</p>
                                </div>
                              </div>
                              <div className="CommunityListDateTimeWrapper">
                                <div
                                  className="forwardMsgChkbox"
                                  onClick={() => {
                                    setNewPostCommunityId(d?.item?.id);
                                  }}
                                >
                                  <input
                                    type="checkbox"
                                    class="myinput large"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                  {!loading && (
                    <div
                      className="socialPostDeleteBtn commClass"
                      style={{ marginTop: "20px" }}
                    >
                      <button
                        onClick={() => {
                          if (newPostCommunityId === 0) {
                            dispatch(
                              showToast({
                                text: "Please select community to proceed!",
                                severity: "error",
                              })
                            );
                            return;
                          }
                          sharePost();
                        }}
                      >
                        Share
                      </button>
                      <button
                        onClick={() => {
                          setCommunityListActive(false);
                          setSelectPostTypeActive(true);
                        }}
                        style={{
                          border: "1px solid #1296B0",
                          color: "#1296B0",
                        }}
                      >
                        Back
                      </button>
                    </div>
                  )}
                  {loading && <div className="saving-post">Sharing...</div>}
                </div>
              }
              handleClose={() => {
                setCommunityListActive(false);
                setShareTextContent("");
                setNewSharePostActive(false);
                navigate(-1);
              }}
            />
          )}
        </div>
      )}
    </>
  );
}

export default SharePost;
