import React from "react";
import ProgressBar from "@ramonak/react-progress-bar";

function PollRadioButtonComponent({
  queData,
  selectOptions,
  selected,
  findSelectedOptions,
  questionData,
}) {
  return (
    <div className="surveyRadioButtonComponent">
      {queData?.answerOption?.map((ansData, ansIndex) => {
        return (
          <div className="pollAnswerOptionsList">
            {queData?.respondAnswer?.length <= 0 && (
              <article
                onClick={() => {
                  selectOptions(ansData, queData);
                }}
              >
                {selected.includes(ansData?.id) ? (
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="8"
                      cy="8"
                      r="7.5"
                      fill="white"
                      stroke="#1296B0"
                      strokeWidth="1.5"
                    />
                    <circle cx="8" cy="8" r="5" fill="#1296B0" />
                  </svg>
                ) : (
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="8"
                      cy="8"
                      r="7.5"
                      fill="white"
                      stroke="#BFBFBF"
                      strokeWidth="1.5"
                    />
                    <circle
                      cx="8.00036"
                      cy="8.00036"
                      r="5.30993"
                      fill="white"
                    />
                  </svg>
                )}
              </article>
            )}
            {queData?.respondAnswer?.length > 0 && (
              <article>
                {findSelectedOptions(ansData?.id, queData?.respondAnswer) ? (
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="8"
                      cy="8"
                      r="7.5"
                      fill="white"
                      stroke="#1296B0"
                      strokeWidth="1.5"
                    />
                    <circle cx="8" cy="8" r="5" fill="#1296B0" />
                  </svg>
                ) : (
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="8"
                      cy="8"
                      r="7.5"
                      fill="white"
                      stroke="#BFBFBF"
                      strokeWidth="1.5"
                    />
                    <circle
                      cx="8.00036"
                      cy="8.00036"
                      r="5.30993"
                      fill="white"
                    />
                  </svg>
                )}
              </article>
            )}
            <div className="pollDetailsAnswersListContainer">
              <div className="pollDetailsAnswers" key={ansIndex}>
                <label>
                  {ansIndex + 1} . {ansData?.answerText}
                </label>
                <div className="pollDetailsGraphContainer">
                  <div className="pollDetailsGraphWrapper">
                    <ProgressBar
                      completed={ansData?.respondPercentage}
                      bgColor="#1296b0"
                      height="5px"
                      width="100%"
                      labelColor="#1296b0"
                    />
                  </div>
                </div>
              </div>
              <div
                style={{
                    width:"5%",
                  marginLeft: "5px",
                  position: "relative",
                  bottom: "-6px",
                }}
              >
                {ansData?.respondPercentage}%
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default PollRadioButtonComponent;
