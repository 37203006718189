import * as signalR from "@microsoft/signalr";

const signalRService = (() => {
  // This is an Immediately Invoked Function Expression (IIFE)
  let connection = null;

  const startConnection = async (userId) => {
    console.log("c0nnection function called");

    connection = new signalR.HubConnectionBuilder()
      .withUrl("https://chat.aesssolutions.com/conversationsHub", {
        headers: {
          X_AppUser: `${userId}`,
        },
      })
      .configureLogging(signalR.LogLevel.Information)
      .build();
    // let retryCount = 0;
    // const maxRetries = 5;

    // connection.onclose = async (error) => {
    //   if (error) {
    //     console.error("Disconnected due to an error:", error);
    //     if (retryCount < maxRetries) {
    //       retryCount++; // Increment the retry count
    //       console.log(
    //         `Attempting to reconnect... Attempt ${retryCount}/${maxRetries}`
    //       );
    //       await new Promise((resolve) =>
    //         setTimeout(resolve, 1000 * retryCount)
    //       ); // Exponential back-off
    //       await startConnection(userId);
    //     } else {
    //       console.log("Maximum reconnect attempts reached. Not retrying.");
    //       // Handle max retries reached scenario
    //       // Notify the user, update UI/state, etc.
    //     }
    //   } else {
    //     console.log(
    //       "Disconnected by the server or connection intentionally stopped."
    //     );
    //     // This was a clean disconnect, handle accordingly
    //     // Notify the user, update UI/state, etc.
    //   }
    // };

    try {
      await connection.start();
      console.log("SignalR Connected.");
    } catch (err) {
      console.log(err);
      setTimeout(() => startConnection(userId), 5000);
    }
  };

  const isConnected = () => {
    //check if connected
    return (
      connection && connection.state === signalR.HubConnectionState.Connected
    );
  };
  const stopConnection = async () => {
    // Remove the events

    if (connection) {
      connection.off("UpdateConversations");
      connection.off("UpdateMessages");
    }

    // Stop the connection
    if (connection) {
      await connection.stop();
      console.log("SignalR Disconnected.");
    }
  };

  // invoke functions

  const invokeGetConversations = async (userId) => {
    if (isConnected()) {
      return await connection.invoke("GetConversations", userId, "");
    } else {
      console.error(
        "Cannot invoke '" +
          "GetConversations" +
          "': the connection is not active."
      );
      // Handle the error appropriately - retry, alert the user, etc.
    }
  };

  const invokeGetMessages = async (contactId, userId) => {
    if (isConnected()) {
      return await connection.invoke("GetMessages", contactId, userId, "");
    } else {
      console.error(
        "Cannot invoke '" + "GetMessages" + "': the connection is not active."
      );
      // Handle the error appropriately - retry, alert the user, etc.
    }
  };
  const invokeSendMessages = async (userId, contactId, data) => {
    if (isConnected()) {
      return await connection.invoke("SendMessage", userId, contactId, data);
    } else {
      console.error(
        "Cannot invoke '" + "SendMessage" + "': the connection is not active."
      );
      // Handle the error appropriately - retry, alert the user, etc.
    }
  };
  const invokeGetContacts = async (userId) => {
    if (isConnected()) {
      return await connection.invoke("GetContacts", userId, "");
    } else {
      console.error(
        "Cannot invoke '" + "GetContacts" + "': the connection is not active."
      );
      // Handle the error appropriately - retry, alert the user, etc.
    }
  };
  const invokeToggleStarMsg = async (userId, conversationId, msgId) => {
    console.log("object invokeToggleStarMsg", userId, conversationId, msgId);
    if (isConnected()) {
      return await connection.invoke(
        "ToggleMessageBookmarkStatus",
        userId,
        conversationId,
        msgId
      );
    } else {
      console.error(
        "Cannot invoke " +
          "Message Bookmark Status" +
          ": the connection is not active."
      );
      // Handle the error appropriately - retry, alert the user, etc.
    }
  };
  const invokeForwardMessages = async (
    conversationId,
    msgIdList,
    userIdList
  ) => {
    // console.log("object conversationId", conversationId);
    // console.log("object msgIdList", msgIdList);
    // console.log("object userIdList", userIdList);
    // console.log("object conversationId", typeof conversationId);
    // console.log("object msgIdList", typeof msgIdList);
    // console.log("object userIdList", typeof userIdList);
    if (isConnected()) {
      return await connection.invoke(
        "ForwardMessages",
        conversationId,
        msgIdList,
        userIdList
      );
    } else {
      console.error(
        "Cannot invoke " + "ForwardMessages" + ": the connection is not active."
      );
      // Handle the error appropriately - retry, alert the user, etc.
    }
  };

  // Delete selected msg and clear chat
  const usedeleteSelectedMsg = (
    deleteReqType,
    conversationId,
    userId,
    msgId,
    starMsgStatus = false
  ) => {
    if (deleteReqType === "deleteForEveryone") {
      if (msgId?.length === 1) {
        connection.invoke("DeleteMessage", conversationId, userId, msgId[0]);
      }
      //delete the multi message only for me
      if (msgId?.length > 1) {
        connection.invoke("DeleteMessages", conversationId, userId, msgId);
      }
    }
    if (deleteReqType === "deleteForMe") {
      if (msgId?.length === 1) {
        connection.invoke(
          "DeleteMessageForMe",
          conversationId,
          userId,
          msgId[0]
        );
      }
      //delete the multi message only for me
      if (msgId?.length > 1) {
        connection.invoke("DeleteForMeMessages", conversationId, userId, msgId);
      }
    }
    if (deleteReqType === "clearChat") {
      connection.invoke("ClearMessages", conversationId, userId, starMsgStatus);
    }
  };

  //on functions

  const onUpdateConversations = (callback) => {
    connection.on("UpdateConversations", callback);
  };

  const onUpdateMessages = (callback) => {
    connection.on("UpdateMessages", callback);
  };
  const onNewMessages = (callback) => {
    connection.on("NewMessage", callback);
  };
  const onMessageResponse = (callback) => {
    connection.on("MessageResponse", callback);
  };

  const onUpdateContacts = (callback) => {
    connection.on("UpdateContacts", callback);
  };

  //return functions
  return {
    startConnection,
    stopConnection,
    invokeGetConversations,
    invokeGetMessages,
    isConnected,
    invokeSendMessages,
    usedeleteSelectedMsg,
    invokeForwardMessages,
    invokeGetContacts,
    onUpdateConversations,
    onUpdateMessages,
    onNewMessages,
    onMessageResponse,
    onUpdateContacts,
    invokeToggleStarMsg,
  };
})();

export default signalRService;
