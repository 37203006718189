import React from 'react';
import { PayrollTemplateTab } from './PayrollTemplateTab';
import { useEffect } from 'react';

const PayrollTemplate = () => {
    useEffect(() => {
        document.title = `PeopleSol - Payroll Template `;
        return () => {
          document.title = 'PeopleSol';
        };
      }, []);
    return (
        <div className="moduleMainContainer">
            <PayrollTemplateTab title="Payroll Template"/>
        </div>
    )
}

export { PayrollTemplate }