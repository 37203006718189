/* eslint-disable react-hooks/exhaustive-deps */
import { Form, Formik } from "formik";
import React from "react";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { useLocation, useNavigate } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import AddEarningPopup from "../PayrollTemplateComponents/AddEarningPopup";
import { useState } from "react";
import { getRequest, postRequest } from "../../../../Services/axios";
import { GroupBy } from "../../../../Utilities";
import { useEffect } from "react";
import { SelectForm } from "../../../../Components/FormComponent/SelectForm";
import { IconButton } from "@mui/material";
import { useDispatch } from "react-redux";
import { showToast } from "../../../../Features";
import * as Yup from "yup";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import { Tooltip } from "antd";
import AddReimbursementPopup from "../PayrollTemplateComponents/AddReimbursementPopup";
import { GroupObjectBy } from "../../../../Utilities/GroupObjectBy";
import AddDeductionPopup from "../PayrollTemplateComponents/AddDeductionPopup";

function CreatePayrollTemplate() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const selectedId = location?.state?.id;
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState(null);
  const [detailsForm, setDetailsForm] = useState(null);
  const [earningGroup, setEarningGroup] = useState([]);
  const [reimbursementGroup, setreimbursementGroup] = useState([]);
  const [deductionGroup, setDeductionGroup] = useState([]);
  const [selectedEarningGroup, setSelectedEarningGroup] = useState([]);
  const [selectedreimbursementGroup, setSelectedreimbursementGroup] = useState(
    []
  );
  const [selectedDeductionGroup, setSelectedDeductionGroup] = useState([]);
  const [initialValues, setInitialValues] = useState({
    templateName: detailsForm ? detailsForm.templateName : "",
    description: detailsForm ? detailsForm.description : "",
    annualCTC: detailsForm ? detailsForm.annualCTC : "",
    earnings: {
      Basic: {
        Type: "Basic",
        CalculationType: "Percentage of CTC",
        DisplayName: "Basic",
        CalculationValue:50
      },
    },
    reimbursements: {},
    deductions: {},
  });

  const getFormDetails = async () => {
    try {
      setLoading(true);
      const result = await getRequest(`SalaryTemplate/Details/${selectedId}`);
      setDetailsForm(result?.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };
  const [basicMonthlyAmount, setBasicMonthlyAmount] = useState(20000);
  const getDropdownReimbData = async () => {
    try {
      setLoading(true);
      let result = await getRequest(
        "SalaryComponents/reimbursmentDropdown?state=true"
      );
      if (result) {
        let tempEarningGroup = GroupBy(result?.data?.data, "name");
        setreimbursementGroup(tempEarningGroup);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  const getDropdownData = async () => {
    try {
      setLoading(true);
      let result = await getRequest(
        "SalaryComponents/EarningDropdown?state=true"
      );
      if (result) {
        let tempEarningGroup = GroupBy(result?.data?.data, "earningName");
        setEarningGroup(tempEarningGroup);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  const getDropdownDeductionData = async () => {
    try {
      setLoading(true);
      let result = await getRequest(
        "SalaryComponents/DeductionDropdown?state=true"
      );
      if (result) {
        let tempDeductionGroup = GroupObjectBy(result?.data?.summary);
        setDeductionGroup(tempDeductionGroup);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (selectedId) getFormDetails();
    getDropdownData();
    getDropdownDeductionData();
    getDropdownReimbData();
  }, []);
  const returnPage = () => {
    navigate(-1);
  };
  const getAllAddableEarningTypes = () => {
    let array = Object.keys(earningGroup);
    let indexBasic = array.indexOf("Basic");
    if (indexBasic > -1) {
      array.splice(indexBasic, 1);
    }

    let indexFixedAllowance = array.indexOf("Fixed Allowance");
    if (indexFixedAllowance > -1) {
      array.splice(indexFixedAllowance, 1);
    }

    return array;
  };
  const getAllAddableReimTypes = () => {
    let array = Object.keys(reimbursementGroup);
    return array;
  };
  const getAllAddableDeductionTypes = () => {
    let array = Object.keys(deductionGroup);
    return array;
  };
  const populateEarningsFromTemplateValues = (detailsForm, templateValues) => {
    const earnings = {};
    const reimbursements = {};
    const deductions = {};
    const dd = [];
    const rr = [];
    const deduc = [];
    setSelectedEarningGroup([]);
    setSelectedreimbursementGroup([]);
    setSelectedDeductionGroup([]);
    templateValues.forEach((earning) => {
      if (earning.salaryComponents === "Earnings") {
        dd.push(earning.type);
        if (earning.type === "Fixed Allowance") {
          const key = earning.type;
          earnings[key] = {
            templateId: earning.templateId,
            id: earning.id,
          };
        } else {
          const key = earning.type;
          earnings[key] = {
            Type: earning.type,
            CalculationType: earning.calculationType,
            DisplayName: earning.displayName,
            Monthly: earning.monthlyAmount,
            Yearly: earning.annualAmount,
            templateId: earning.templateId,
            id: earning.id,
            CalculationValue: earning.calculationValue?.toString(),
          };
        }
      } else if (earning.salaryComponents === "Reimbursements") {
        rr.push(earning.type);
        const key = earning.type;
        reimbursements[key] = {
          Type: earning.type,
          name: earning.displayName,
          Monthly: earning.monthlyAmount,
          Yearly: earning.annualAmount,
          templateId: earning.templateId,
          id: earning.id,
          maximumReimbursementAmount: earning?.calculationValue?.toString(),
        };
      } else {
  
        if (earning.type === "ESI Employer Contribution") deduc.push("esi");
        if (earning.type === "EPF Employer Contribution") deduc.push("epf");

        let key;
        if (earning.type === "ESI Employer Contribution") key = "esi";
        if (earning.type === "EPF Employer Contribution") key = "epf";
        deductions[key] = {
          Type: earning.type,
          CalculationType: earning.calculationType,
          DisplayName: earning.type,
          Monthly: earning.monthlyAmount,
          Yearly: earning.annualAmount,
          templateId: earning.templateId,
          id: earning.id,
          CalculationValue: earning.calculationValue?.toString(),
        };
      }
    });

    const filteredDD = dd.filter(
      (type) => type !== "Basic" && type !== "Fixed Allowance"
    );

    setSelectedEarningGroup((prevSelectedEarningGroup) => [
      ...prevSelectedEarningGroup,
      ...filteredDD,
    ]);
    setSelectedreimbursementGroup((prevSelectedReimbursementGroup) => [
      ...prevSelectedReimbursementGroup,
      ...rr,
    ]);
    setSelectedDeductionGroup((prevSelectedDeductionGroup) => [
      ...prevSelectedDeductionGroup,
      ...deduc,
    ]);

    const updatedFormValues = {
      ...formValues,
      templateName: detailsForm?.templateName, // Provide the actual value
      description: detailsForm?.description, // Provide the actual value
      annualCTC: detailsForm?.annualCTC, // Provide the actual value
      earnings: earnings,
      reimbursements: reimbursements,
      deductions: deductions,
    };

    setInitialValues(updatedFormValues);
  };

  useEffect(() => {
    if (detailsForm && detailsForm.templateValues.length) {
      populateEarningsFromTemplateValues(
        detailsForm,
        detailsForm.templateValues
      );
    }
  }, [detailsForm]);
  const updateSelectedEarningType = (selectedEarningTypes) => {
    if (
      Array.isArray(selectedEarningTypes) &&
      selectedEarningTypes.length > 0
    ) {
      selectedEarningTypes.forEach((selectedEarningType) => {
        if (earningGroup.hasOwnProperty(selectedEarningType)) {
          const earningTypeGroup = earningGroup[selectedEarningType];
          if (earningTypeGroup) {
            const selectedEarning = earningTypeGroup.find(
              (x) => x.earningName === earningTypeGroup[0].earningName
            );

            const newEarning = {
              Type: selectedEarning.earningName,
              CalculationValue: selectedEarning.calculationValue,
              CalculationType: selectedEarning.calculationType,
              DisplayName: selectedEarning.nameInPayslip,
            };

            setInitialValues((prevValues) => ({
              ...prevValues,
              earnings: {
                ...prevValues.earnings,
                [selectedEarningType]: newEarning,
              },
            }));

            setSelectedEarningGroup((prevSelected) => [
              ...prevSelected,
              selectedEarningType,
            ]);
          }
        }
      });
    }
  };
  function formatTotalMonthlyEarnings(totalMonthlyEarnings) {
    if (totalMonthlyEarnings >= 0) {
      return totalMonthlyEarnings.toFixed(0);
    } else if (totalMonthlyEarnings < 0 && totalMonthlyEarnings > -1) {
      return "-1";
    } else {
      return totalMonthlyEarnings.toFixed(0); // Handle other cases accordingly
    }
  }
  
  const updateSelectedReimbType = (selectedreimbursementTypes) => {
    if (
      Array.isArray(selectedreimbursementTypes) &&
      selectedreimbursementTypes.length > 0
    ) {
      selectedreimbursementTypes.forEach((selectedreimbursementType) => {
        if (reimbursementGroup.hasOwnProperty(selectedreimbursementType)) {
          const reimbursementTypeGroup =
            reimbursementGroup[selectedreimbursementType];
          if (reimbursementTypeGroup) {
            const selectedReimbursement = reimbursementTypeGroup?.find(
              (x) => x?.name === reimbursementTypeGroup[0]?.name
            );
            const newReimbursement = {
              Type: selectedReimbursement.reimbursementType,
              maximumReimbursementAmount:
                selectedReimbursement.maximumReimbursementAmount,
              name: selectedReimbursement.name,
            };

            setInitialValues((prevValues) => ({
              ...prevValues,
              reimbursements: {
                ...prevValues.reimbursements,
                [selectedreimbursementType]: newReimbursement,
              },
            }));

            setSelectedreimbursementGroup((prevSelected) => [
              ...prevSelected,
              selectedreimbursementType,
            ]);
          }
        }
      });
    }
  };
  const updateSelectedDeductionType = (selecteddeductionTypes) => {
    if (
      Array.isArray(selecteddeductionTypes) &&
      selecteddeductionTypes.length > 0
    ) {
      selecteddeductionTypes.forEach((selecteddeductionType) => {
        if (deductionGroup.hasOwnProperty(selecteddeductionType)) {
          const deductionTypeGroup = deductionGroup[selecteddeductionType];
          if (deductionTypeGroup) {
            const selectedDeduction = deductionTypeGroup?.find(
              (x) => x?.name === deductionTypeGroup[0]?.name
            );
            const newDeduction = {
              Type: selectedDeduction.type,
              CalculationValue: selectedDeduction.calculationValue,
              CalculationType: selectedDeduction.calculationType,
              name: selectedDeduction.type,
            };

            setInitialValues((prevValues) => ({
              ...prevValues,
              deductions: {
                ...prevValues.deductions,
                [selecteddeductionType]: newDeduction,
              },
            }));

            setSelectedDeductionGroup((prevSelected) => [
              ...prevSelected,
              selecteddeductionType,
            ]);
          }
        }
      });
    }
  };
  const addNewEarningClickHandler = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <AddEarningPopup
            onClose={onClose}
            allEarningTypes={getAllAddableEarningTypes()}
            selectedEarningGroup={selectedEarningGroup}
            onClick={updateSelectedEarningType}
          />
        );
      },
    });
  };
  const addNewreimbursementClickHandler = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <AddReimbursementPopup
            onClose={onClose}
            allReimbursementTypes={getAllAddableReimTypes()}
            selectedReimbursementTypes={selectedreimbursementGroup}
            onClick={updateSelectedReimbType}
          />
        );
      },
    });
  };
  const addDeductionClickHandler = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <AddDeductionPopup
          formValues={formValues}
            onClose={onClose}
            allDeductionTypes={getAllAddableDeductionTypes()}
            selectedDeductionTypes={selectedDeductionGroup}
            onClick={updateSelectedDeductionType}
          />
        );
      },
    });
  };
  useEffect(() => {
    document.title = `PeopleSol - Create Payroll Template `;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const removeItem = (earningType) => {
    const updatedArray = selectedEarningGroup.filter(
      (element) => element !== earningType
    );
    const updatedInitialValue = { ...formValues };

    const { [earningType]: removedValue, ...remainingEarnings } =
      updatedInitialValue.earnings;

    updatedInitialValue.earnings = remainingEarnings;

    setInitialValues(updatedInitialValue);

    setSelectedEarningGroup(updatedArray);
  };
  const removeItemReimbursement = (earningType) => {
    const updatedArray = selectedreimbursementGroup.filter(
      (element) => element !== earningType
    );
    const updatedInitialValue = { ...formValues };

    const { [earningType]: removedValue, ...remainingEarnings } =
      updatedInitialValue.reimbursements;

    updatedInitialValue.reimbursements = remainingEarnings;
    setInitialValues(updatedInitialValue);
    setSelectedreimbursementGroup(updatedArray);
  };
  const removeItemDeduction = (deductionType) => {
    const updatedArray = selectedDeductionGroup.filter(
      (element) => element !== deductionType
    );
    const updatedInitialValue = { ...formValues };

    const { [deductionType]: removedValue, ...remainingDeduction } =
      updatedInitialValue.deductions;

    updatedInitialValue.deductions = remainingDeduction;
    setInitialValues(updatedInitialValue);
    setSelectedDeductionGroup(updatedArray);
  };
  const checkAddEarningVisibility = () => {
    if (selectedEarningGroup?.length > 0)
      return (
        selectedEarningGroup?.length !== Object.keys(earningGroup)?.length - 1
      );
    else return true;
  };
  const getBasicComponent = () => {
    let earningTypeGroup = earningGroup["Basic"];
    let item = earningTypeGroup?.[0];
    let options = [];
    earningTypeGroup?.map((earning) => {
      return options.push({
        label: earning?.earningName,
        value: earning?.earningName,
      });
    });
    return earningTypeGroup?.length > 1 ? (
      <SelectForm options={options} name="earnings.Basic.Type" />
    ) : (
      <LabelCustom labelName={item?.nameInPayslip} />
    );
  };
  const getFixedComponent = () => {
    let earningTypeGroup = earningGroup["Fixed Allowance"];
    let item = earningTypeGroup?.[0];
    let options = [];
    earningTypeGroup?.map((earning) => {
      return options.push({
        label: earning?.earningName,
        value: earning?.earningName,
      });
    });
    return earningTypeGroup?.length > 1 ? (
      <SelectForm options={options} name="earnings.FixedAllowance.Type" />
    ) : (
      <LabelCustom labelName={item?.nameInPayslip} />
    );
  };
  const getMonthlyYearlyValue = (earning) => {
    let calcParam =
      earning?.earningType === "Custom Allowance"
        ? formValues?.earnings[earning?.nameInPayslip]?.CalculationValue
        : formValues?.earnings[earning?.earningType]?.CalculationValue;

    if (earning?.calculationType === "Percentage of Basic") {
      const monthlyValue =
        (parseFloat(basicMonthlyAmount) * (parseFloat(calcParam) / 100)) / 12;
      const yearlyValue =
        parseFloat(basicMonthlyAmount) * (parseFloat(calcParam) / 100);

      return {
        Monthly: isNaN(monthlyValue) ? 0 : monthlyValue,
        Yearly: isNaN(yearlyValue) ? 0 : yearlyValue,
      };
    } else {
      const monthlyValue = parseFloat(calcParam);
      const yearlyValue = parseFloat(calcParam) * 12;

      return {
        Monthly: isNaN(monthlyValue) ? 0 : monthlyValue,
        Yearly: isNaN(yearlyValue) ? 0 : yearlyValue,
      };
    }
  };
  const getMonthlyYearlyValueReimb = (reimbursement) => {
    let calcParam =
      formValues?.reimbursements[reimbursement?.reimbursementType]
        ?.maximumReimbursementAmount;
    const monthlyValue = parseFloat(calcParam);
    const yearlyValue = parseFloat(calcParam) * 12;

    return {
      Monthly: isNaN(monthlyValue) ? 0 : monthlyValue,
      Yearly: isNaN(yearlyValue) ? 0 : yearlyValue,
    };
  };
  const getMonthlyYearlyValueDeduction = (deduction) => {
    let name;
    if (deduction?.type === "EPF Employer Contribution") {
      name = "epf";
    } else {
      name = "esi";
    }
if(formValues?.earnings['Dearness Allowance']?.Yearly!==undefined){
  
  let calcParam = Number(formValues?.deductions[name]?.CalculationValue);
  const monthlyValue = parseFloat(
    ((formValues?.earnings?.Basic?.Yearly+formValues.earnings['Dearness Allowance'].Yearly) * calcParam) / 100 / 12
  );
  const yearlyValue = parseFloat(((formValues?.earnings?.Basic?.Yearly+formValues.earnings['Dearness Allowance'].Yearly) * calcParam) / 100);

  return {
    Monthly: isNaN(monthlyValue) ? 0 : monthlyValue,
    Yearly: isNaN(yearlyValue) ? 0 : yearlyValue,
  }
}else{
    let calcParam = Number(formValues?.deductions[name]?.CalculationValue);
    const monthlyValue = parseFloat(
      (formValues?.earnings?.Basic?.Yearly * calcParam) / 100 / 12
    );
    const yearlyValue = parseFloat((formValues?.earnings?.Basic?.Yearly * calcParam) / 100);
  
    return {
      Monthly: isNaN(monthlyValue) ? 0 : monthlyValue,
      Yearly: isNaN(yearlyValue) ? 0 : yearlyValue,
  }
}
  };

  useEffect(() => {
    let earning = earningGroup?.["Basic"]?.[0];
    let calcParam = formValues?.earnings["Basic"]?.CalculationValue;
    if (earning?.calculationType !== "Flat Amount") {
      if (
        isNaN(parseFloat(formValues?.annualCTC) * (parseFloat(calcParam) / 100))
      )
        setBasicMonthlyAmount(0);
      else {
        setBasicMonthlyAmount(
          parseFloat(formValues?.annualCTC) * (parseFloat(calcParam) / 100)
        );
      }
    } else if (isNaN(parseFloat(calcParam))) {
      setBasicMonthlyAmount(0);
    } else {
      setBasicMonthlyAmount(calcParam);
    }
  }, [formValues?.earnings?.Basic?.CalculationValue, formValues?.annualCTC]);
  useEffect(() => {
    if (!formValues?.earnings) return;
    Object.keys(formValues?.earnings)?.map((earningType) => {
      if (earningType === "Basic" || earningType === "Fixed Allowance") {
        return setInitialValues((prevValues) => {
          const updatedEarnings = {
            ...formValues.earnings,
            Basic: {
              ...formValues.earnings?.["Basic"],
              Monthly: basicMonthlyAmount / 12,
              Yearly: basicMonthlyAmount,
            },
            "Fixed Allowance": {
              ...formValues.earnings?.["Fixed Allowance"],
              Type: "Fixed Allowance",
              CalculationType: "Fixed",
              DisplayName: "Fixed Allowance",
              MonthlyAmount: Number(totalMonthlyEarnings?.toFixed(0)),
              AnnualAmount: Number(totalYearlyEarnings?.toFixed(0)),
            },
          };
          return {
            ...formValues,
            earnings: updatedEarnings,
          };
        });
      } else {
        let earningTypeGroup = earningGroup?.[earningType];
        if (earningTypeGroup?.length > 1) {
          let selectedEarning = earningTypeGroup?.filter(
            (x) => x?.earningName === formValues?.earnings?.[earningType]?.Type
          );
          //NOT WORKING WHEN MULTIPLE MULTI SELECT COMPONENTS ARE THERE
          return setInitialValues((prevValues) => {
            const updatedEarnings = {
              ...formValues.earnings,
              [earningType]: {
                ...formValues.earnings?.[earningType],
                CalculationValue: selectedEarning?.[0]?.calculation,
              },
            };
            return {
              ...formValues,
              earnings: updatedEarnings,
            };
          });
        }
      }
    });
  }, [formValues?.earnings, formValues?.annualCTC]);
  const [totalYearlyEarnings, setTotalYearlyEarnings] = useState(0);
  const [totalMonthlyEarnings, setTotalMonthlyEarnings] = useState(0);
  console.log('totalMonthlyEarnings',totalMonthlyEarnings)
  useEffect(() => {
    let yearlyTotal = 0;
    let monthlyTotal = 0;

    for (const key in formValues?.earnings) {
      const earning = formValues?.earnings[key];
      if (earning?.Yearly) {
        yearlyTotal += earning?.Yearly;
      }
      if (earning?.Monthly) {
        monthlyTotal += earning?.Monthly;
      }
    }
    for (const key in formValues?.deductions) {
      const deduction = formValues?.deductions[key];
      if (deduction?.Yearly) {
        yearlyTotal += deduction?.Yearly;
      }
      if (deduction?.Monthly) {
        monthlyTotal += deduction?.Monthly;
      }
    }

    setTotalYearlyEarnings(formValues?.annualCTC - yearlyTotal);
    setTotalMonthlyEarnings(formValues?.annualCTC / 12 - monthlyTotal);
  }, [formValues?.earnings]);
  const editPageData = async (values) => {
    try {
      setLoading(true);
      await postRequest(`SalaryTemplate/Edit`, values);
      dispatch(
        showToast({ text: "Successfully Created", severity: "success" })
      );
    } catch (error) {
      dispatch(showToast({ text: error?.response?.data, severity: "error" }));
    } finally {
      setLoading(false);
      navigate(-1);
    }
  };
  const createPageData = async (values) => {
    try {
      setLoading(true);
      await postRequest(`SalaryTemplate/Create`, values);
      dispatch(
        showToast({ text: "Successfully Created", severity: "success" })
      );
    } catch (error) {
      dispatch(showToast({ text: error?.response?.data, severity: "error" }));
    } finally {
      setLoading(false);
      navigate(-1);
    }
  };
  console.log("selected", selectedreimbursementGroup);
  return (
    <div className="moduleMainContainer">
      <div
        className="headingContainer"
        style={{ marginLeft: "12px", width: "98%", padding: "0" }}
      >
        <div
          onClick={() => navigate(-1)}
          style={{ display: "flex" }}
          className="returnToDashboard cursor"
        >
          <div>
            <svg
              width="20"
              height="8"
              viewBox="0 0 20 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.03829 3.35738C0.84303 3.55265 0.84303 3.86923 1.03829 4.06449L4.22027 7.24647C4.41553 7.44173 4.73212 7.44173 4.92738 7.24647C5.12264 7.05121 5.12264 6.73463 4.92738 6.53936L2.09895 3.71094L4.92738 0.88251C5.12264 0.687248 5.12264 0.370666 4.92738 0.175404C4.73212 -0.0198586 4.41553 -0.0198586 4.22027 0.175404L1.03829 3.35738ZM19.4187 4.21094C19.6949 4.21094 19.9187 3.98708 19.9187 3.71094C19.9187 3.4348 19.6949 3.21094 19.4187 3.21094V4.21094ZM1.39185 4.21094H19.4187V3.21094H1.39185V4.21094Z"
                fill="#1296B0"
              />
            </svg>
          </div>
          <label className="returnLabelHolder">
            {" "}
            <h5>{selectedId ? "Update" : "Create"} Payroll Template</h5>
          </label>
        </div>
      </div>
      <div
        style={{
          height: "calc(100% - 50px)",
          backgroundColor: "var(--form-background)",
        }}
      >
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={Yup.object({
            templateName: Yup.string()
              .trim()
              .required("Template Name Is Required"),
            annualCTC: Yup.string().trim().required("AnnualCTC  Is Required"),
          })}
          onSubmit={(values) => {
            const payload = {
              Id: detailsForm ? detailsForm.id : 0,
              TemplateName: values.templateName,
              Description: values.description,
              AnnualCTC: values.annualCTC,
              TemplateValues: [],
            };

            for (const key in values.earnings) {
              const earning = values.earnings[key];
              if (earning.Monthly) {
                earning.MonthlyAmount = Number(earning.Monthly.toFixed(0));
                delete earning.Monthly;
              }
              if (earning.Yearly) {
                earning.AnnualAmount = Number(earning.Yearly.toFixed(0));
                delete earning.Yearly;
              }

              payload.TemplateValues.push({
                ...earning,
                IsEnable: true,
                SalaryComponents: "Earnings",
              });
            }
            for (const key in values.deductions) {
              const deduction = values.deductions[key];
              if (deduction.Monthly) {
                deduction.MonthlyAmount = Number(deduction.Monthly.toFixed(0));
                delete deduction.Monthly;
              }
              if (deduction.Yearly) {
                deduction.AnnualAmount = Number(deduction.Yearly.toFixed(0));
                delete deduction.Yearly;
              }

              payload.TemplateValues.push({
                ...deduction,
                IsEnable: true,
                SalaryComponents: "Deductions",
                CalculationType: "Flat Amount",
                DisplayName:deduction.Type
              });
            }

            for (const key in values.reimbursements) {
              const reimbursement = values.reimbursements[key];
              if (reimbursement.Monthly) {
                reimbursement.MonthlyAmount = Number(
                  reimbursement.Monthly.toFixed(0)
                );
                delete reimbursement.Monthly;
              }
              if (reimbursement.Yearly) {
                reimbursement.AnnualAmount = Number(
                  reimbursement.Yearly.toFixed(0)
                );
                delete reimbursement.Yearly;
              }

              payload.TemplateValues.push({
                ...reimbursement,
                IsEnable: true,
                SalaryComponents: "Reimbursements",
                CalculationType: "Flat Amount",
                DisplayName: reimbursement?.name,
                calculationValue: reimbursement?.maximumReimbursementAmount,
              });
            }

            if (totalMonthlyEarnings <= 0) {
              dispatch(
                showToast({
                  text: "Fixed Allowance Cannot be negative",
                  severity: "error",
                })
              );
            } else {
              if (detailsForm) {
                editPageData(payload);
              } else {
                createPageData(payload);
              }
            }
          }}
        >
          {({ values }) => {
            setFormValues(values);
            return (
              <Form style={{ height: "100%" }}>
                <div className="formcustom threeColumn">
                  <div>
                    <LabelCustom labelName="Template Name" mandatory={true} />
                    <InputCustomFormik
                      name="templateName"
                      placeholder="Enter template name"
                    />
                  </div>
                  <div>
                    <LabelCustom labelName="Description" />
                    <InputCustomFormik
                      name="description"
                      placeholder="Enter description"
                    />
                  </div>
                  <div>
                    <LabelCustom
                      labelName="Annual CTC (This for Reference only)"
                      mandatory={true}
                    />
                    <InputCustomFormik
                      name="annualCTC"
                      type="number"
                      placeholder="Enter Annual CTC (in ₹)"
                    />
                  </div>
                </div>
                <div style={{ height: "1px" }} className="horizontalLine"></div>
                <div className="templateTableContainer">
                  <table style={{ width: "calc(100% - 24px)", margin: "12px" }}>
                    <thead style={{ textAlign: "left" }}>
                      <tr className="header-row template-table-header">
                        <th>Salary Component</th>
                        <th>
                          Calculation Type{" "}
                          <span style={{ paddingLeft: "32px" }}>
                            Description
                          </span>
                        </th>
                        <th>Monthly Amount</th>
                        <th>Annual Amount</th>
                        <th style={{ paddingRight: "18px" }}></th>
                      </tr>
                    </thead>
                    <tbody className="table-body">
                      <tr>
                        <td>{getBasicComponent()}</td>
                        <td>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <div style={{ maxWidth: "164px" }}>
                              <InputCustomFormik name="earnings.Basic.CalculationValue" />
                            </div>
                            <LabelCustom labelName="% of CTC" />
                          </div>
                        </td>
                        <td>
                          <div style={{ maxWidth: "164px" }}>
                            <LabelCustom
                              labelName={(basicMonthlyAmount / 12)?.toFixed(0)}
                            />
                          </div>
                        </td>
                        <td>
                          <LabelCustom
                            labelName={basicMonthlyAmount?.toFixed(0)}
                          />
                        </td>
                        <td style={{ paddingRight: "18px" }}></td>
                      </tr>
                      {selectedEarningGroup?.map((earningType, index) => {
                        let earningTypeGroup = earningGroup[earningType];
                        let itemArray;
                        let item;
                        if (earningTypeGroup) {
                          itemArray = earningTypeGroup.filter(
                            (x) =>
                              x.earningName ===
                              formValues?.earnings?.[earningType]?.Type
                          );
                          item = itemArray[0];
                        }
                        if (item) {
                          formValues.earnings[earningType].Monthly =
                            getMonthlyYearlyValue(item).Monthly;
                          formValues.earnings[earningType].Yearly =
                            getMonthlyYearlyValue(item).Yearly;
                        }
                        let options = [];
                        earningGroup[earningType]?.map((earning) => {
                          return options.push({
                            label: earning?.earningName,
                            value: earning?.earningName,
                          });
                        });

                        return (
                          <tr key={index}>
                            <td>
                              {earningTypeGroup?.length > 1 ? (
                                <SelectForm
                                  options={options}
                                  name={`earnings.${earningType}.Type`}
                                />
                              ) : (
                                <LabelCustom labelName={item?.earningName} />
                              )}
                            </td>
                            <td>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {item?.calculationType === "Flat Amount" ? (
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <div style={{ maxWidth: "164px" }}>
                                      <InputCustomFormik
                                        name={`earnings.${earningType}.CalculationValue`}
                                      />
                                    </div>
                                    <LabelCustom labelName="Flat Amount" />
                                  </div>
                                ) : (
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <div style={{ maxWidth: "164px" }}>
                                      <InputCustomFormik
                                        name={`earnings.${earningType}.CalculationValue`}
                                      />
                                    </div>
                                    <LabelCustom labelName="% of Basic" />
                                  </div>
                                )}
                              </div>
                            </td>
                            <td>
                              <div>
                                <LabelCustom
                                  labelName={getMonthlyYearlyValue(
                                    item
                                  ).Monthly?.toFixed(0)}
                                />
                                <InputCustomFormik
                                  value={getMonthlyYearlyValue(item).Monthly}
                                  name={`earnings.${earningType}.Monthly`}
                                  type="hidden"
                                  step="0.01"
                                  disabled
                                />
                              </div>
                            </td>
                            <td>
                              <LabelCustom
                                labelName={getMonthlyYearlyValue(
                                  item
                                ).Yearly?.toFixed(0)}
                              />
                              <InputCustomFormik
                                value={getMonthlyYearlyValue(item).Yearly}
                                name={`earnings.${earningType}.Yearly`}
                                type="hidden"
                                step="0.01"
                                disabled
                              />
                            </td>
                            <td style={{ paddingRight: "18px" }}>
                              <Tooltip title="remove">
                                <IconButton>
                                  <DeleteForeverOutlinedIcon
                                    onClick={() => removeItem(earningType)}
                                    color="error"
                                    fontSize="small"
                                  />
                                </IconButton>
                              </Tooltip>
                            </td>
                          </tr>
                        );
                      })}

                      <tr>
                        <td>{getFixedComponent()}</td>
                        <td>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <div
                              style={{ minWidth: "164px", textAlign: "center" }}
                            >
                              {"-"}
                            </div>
                            <LabelCustom labelName="Fixed" />
                          </div>
                        </td>
                        <td>
                          <div style={{ maxWidth: "164px" }}>
                            <LabelCustom
                              labelName={formatTotalMonthlyEarnings(totalMonthlyEarnings)}
                            />
                          </div>
                        </td>
                        <td>
                          <LabelCustom
                            labelName={formatTotalMonthlyEarnings(totalYearlyEarnings)}
                          />
                        </td>
                        <td style={{ paddingRight: "18px" }}></td>
                      </tr>
                      {checkAddEarningVisibility() && (
                        <tr>
                          <th colSpan="4">
                            <div
                              style={{
                                display: "flex",
                                gap: "6px",
                                padding: "6px",
                                alignItems: "center",
                                color: "var(--primary)",
                                width: "fit-content",
                                margin: "6px",
                                borderRadius: "6px",
                              }}
                              className="cursorBackground"
                              onClick={addNewEarningClickHandler}
                            >
                              <div
                                style={{
                                  backgroundColor: "var(--primary-hover)",
                                  padding: "6px",
                                  borderRadius: "6px",
                                  display: "flex",
                                }}
                              >
                                <svg
                                  width="13"
                                  height="13"
                                  viewBox="0 0 13 13"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <line
                                    x1="6.53463"
                                    y1="0.898279"
                                    x2="6.53463"
                                    y2="11.947"
                                    stroke="#1296B0"
                                    stroke-width="1.46843"
                                    stroke-linecap="round"
                                  />
                                  <line
                                    x1="11.9508"
                                    y1="6.37875"
                                    x2="0.902138"
                                    y2="6.37875"
                                    stroke="#1296B0"
                                    stroke-width="1.46843"
                                    stroke-linecap="round"
                                  />
                                </svg>
                              </div>
                              <p>Add Earning</p>
                            </div>
                          </th>
                        </tr>
                      )}
                      <tr className="dashed-bottom">
                        <th
                          colSpan="4"
                          className="header-row"
                          style={{ padding: "12px" }}
                        >
                          Reimbursements
                        </th>
                      </tr>
                      {selectedreimbursementGroup?.map(
                        (reimbursementType, index) => {
                          let reimbursementTypeGroup =
                            reimbursementGroup[reimbursementType];
                          let itemArray;
                          let item;
                          if (reimbursementTypeGroup) {
                            itemArray = reimbursementTypeGroup.filter(
                              (x) =>
                                x.name ===
                                formValues?.reimbursements?.[reimbursementType]
                                  ?.Type
                            );
                            item = itemArray[0];
                          }

                          if (item) {
                            formValues.reimbursements[
                              reimbursementType
                            ].Monthly =
                              getMonthlyYearlyValueReimb(item).Monthly;
                            formValues.reimbursements[
                              reimbursementType
                            ].Yearly = getMonthlyYearlyValueReimb(item).Yearly;
                          }
                          let options = [];
                          reimbursementGroup[reimbursementType]?.map(
                            (reimbursement) => {
                              return options.push({
                                label: reimbursement?.name,
                                value: reimbursement?.name,
                              });
                            }
                          );

                          return (
                            <tr key={index}>
                              <td>
                                {reimbursementTypeGroup?.length > 1 ? (
                                  <SelectForm
                                    options={options}
                                    name={`reimbursements.${reimbursementType}.name`}
                                  />
                                ) : (
                                  <LabelCustom labelName={item?.name} />
                                )}
                              </td>
                              <td>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <div style={{ maxWidth: "164px" }}>
                                      <InputCustomFormik
                                        name={`reimbursements.${reimbursementType}.maximumReimbursementAmount`}
                                      />
                                    </div>
                                    <LabelCustom labelName="Flat Amount" />
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div>
                                  <LabelCustom
                                    labelName={getMonthlyYearlyValueReimb(
                                      item
                                    ).Monthly?.toFixed(0)}
                                  />
                                  <InputCustomFormik
                                    value={
                                      getMonthlyYearlyValueReimb(item).Monthly
                                    }
                                    name={`reimbursements.${reimbursementType}.Monthly`}
                                    type="hidden"
                                    step="0.01"
                                    disabled
                                  />
                                </div>
                              </td>
                              <td>
                                <LabelCustom
                                  labelName={getMonthlyYearlyValueReimb(
                                    item
                                  ).Yearly?.toFixed(0)}
                                />
                                <InputCustomFormik
                                  value={
                                    getMonthlyYearlyValueReimb(item).Yearly
                                  }
                                  name={`reimbursements.${reimbursementType}.Yearly`}
                                  type="hidden"
                                  step="0.01"
                                  disabled
                                />
                              </td>
                              <td style={{ paddingRight: "18px" }}>
                                <Tooltip title="remove">
                                  <IconButton>
                                    <DeleteForeverOutlinedIcon
                                      onClick={() =>
                                        removeItemReimbursement(
                                          reimbursementType
                                        )
                                      }
                                      color="error"
                                      fontSize="small"
                                    />
                                  </IconButton>
                                </Tooltip>
                              </td>
                            </tr>
                          );
                        }
                      )}
                      <tr>
                        <th colSpan="4">
                          <div
                            style={{
                              display: "flex",
                              gap: "6px",
                              padding: "6px",
                              alignItems: "center",
                              color: "var(--primary)",
                              width: "fit-content",
                              margin: "6px",
                              borderRadius: "6px",
                            }}
                            onClick={addNewreimbursementClickHandler}
                            className="cursorBackground"
                          >
                            <div
                              style={{
                                backgroundColor: "var(--primary-hover)",
                                padding: "6px",
                                borderRadius: "6px",
                                display: "flex",
                              }}
                            >
                              <svg
                                width="13"
                                height="13"
                                viewBox="0 0 13 13"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <line
                                  x1="6.53463"
                                  y1="0.898279"
                                  x2="6.53463"
                                  y2="11.947"
                                  stroke="#1296B0"
                                  stroke-width="1.46843"
                                  stroke-linecap="round"
                                />
                                <line
                                  x1="11.9508"
                                  y1="6.37875"
                                  x2="0.902138"
                                  y2="6.37875"
                                  stroke="#1296B0"
                                  stroke-width="1.46843"
                                  stroke-linecap="round"
                                />
                              </svg>
                            </div>
                            <p>Add Reimbursement</p>
                          </div>
                        </th>
                      </tr>
                      <tr className="dashed-bottom">
                        <th
                          colSpan="4"
                          className="header-row"
                          style={{ padding: "12px" }}
                        >
                          Deduction
                        </th>
                      </tr>
                      {selectedDeductionGroup?.map((deductionType, index) => {
                        let deductionTypeGroup = deductionGroup[deductionType];
                        let itemArray;
                        let item;
                        if (deductionTypeGroup) {
                          itemArray = deductionTypeGroup.filter(
                            (x) =>
                              x.type ===
                              formValues?.deductions?.[deductionType]?.Type
                          );
                          item = itemArray[0];
                        }

                        if (item) {
                          formValues.deductions[deductionType].Monthly =
                            getMonthlyYearlyValueDeduction(item).Monthly;
                          formValues.deductions[deductionType].Yearly =
                            getMonthlyYearlyValueDeduction(item).Yearly;
                        }
                        let options = [];
                        deductionGroup[deductionType]?.map((deduction) => {
                          return options.push({
                            label: deduction?.type,
                            value: deduction?.type,
                          });
                        });

                        return (
                          <tr key={index}>
                            <td>
                              {deductionTypeGroup?.length > 1 ? (
                                <SelectForm
                                  options={options}
                                  name={`deductions.${deductionType}.type`}
                                />
                              ) : (
                                <LabelCustom labelName={item?.type} />
                              )}
                            </td>
                            <td>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <div style={{ maxWidth: "164px" }}>
                                    <InputCustomFormik
                                      disabled={true}
                                      name={`deductions.${deductionType}.CalculationValue`}
                                    />
                                  </div>
                                  <LabelCustom labelName="Flat Amount" />
                                </div>
                              </div>
                            </td>
                            <td>
                              <div>
                                <LabelCustom
                                  labelName={getMonthlyYearlyValueDeduction(
                                    item
                                  ).Monthly?.toFixed(0)}
                                />
                                <InputCustomFormik
                                  value={
                                    getMonthlyYearlyValueDeduction(item).Monthly
                                  }
                                  name={`deductions.${deductionType}.Monthly`}
                                  type="hidden"
                                  step="0.01"
                                  disabled
                                />
                              </div>
                            </td>
                            <td>
                              <LabelCustom
                                labelName={getMonthlyYearlyValueDeduction(
                                  item
                                ).Yearly?.toFixed(0)}
                              />
                              <InputCustomFormik
                                value={
                                  getMonthlyYearlyValueDeduction(item).Yearly
                                }
                                name={`deductions.${deductionType}.Yearly`}
                                type="hidden"
                                step="0.01"
                                disabled
                              />
                            </td>
                            <td style={{ paddingRight: "18px" }}>
                              <Tooltip title="remove">
                                <IconButton>
                                  <DeleteForeverOutlinedIcon
                                    onClick={() =>
                                      removeItemDeduction(deductionType)
                                    }
                                    color="error"
                                    fontSize="small"
                                  />
                                </IconButton>
                              </Tooltip>
                            </td>
                          </tr>
                        );
                      })}
                      <tr>
                        <th colSpan="4">
                          <div
                            style={{
                              display: "flex",
                              gap: "6px",
                              padding: "6px",
                              alignItems: "center",
                              color: "var(--primary)",
                              width: "fit-content",
                              margin: "6px",
                              borderRadius: "6px",
                            }}
                            onClick={addDeductionClickHandler}
                            className="cursorBackground"
                          >
                            <div
                              style={{
                                backgroundColor: "var(--primary-hover)",
                                padding: "6px",
                                borderRadius: "6px",
                                display: "flex",
                              }}
                            >
                              <svg
                                width="13"
                                height="13"
                                viewBox="0 0 13 13"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <line
                                  x1="6.53463"
                                  y1="0.898279"
                                  x2="6.53463"
                                  y2="11.947"
                                  stroke="#1296B0"
                                  stroke-width="1.46843"
                                  stroke-linecap="round"
                                />
                                <line
                                  x1="11.9508"
                                  y1="6.37875"
                                  x2="0.902138"
                                  y2="6.37875"
                                  stroke="#1296B0"
                                  stroke-width="1.46843"
                                  stroke-linecap="round"
                                />
                              </svg>
                            </div>
                            <p>Add Deduction</p>
                          </div>
                        </th>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <table
                  style={{
                    width: "calc(100% - 24px)",
                    margin: "12px",
                    border: "1px dotted black",
                  }}
                >
                  <tbody>
                    <tr
                      className="header-row"
                      style={{
                        backgroundColor: "white",
                        textAlign: "left",
                        paddingLeft: "4px",
                        display: "flex",
                      }}
                    >
                      <th style={{ flex: 274 }}>Cost to Company</th>
                      <th style={{ flex: 316 }}></th>
                      <th style={{ flex: 177 }}>{`₹${
                        isNaN(parseFloat(formValues?.annualCTC) / 12)
                          ? 0
                          : (
                              parseFloat(formValues?.annualCTC).toFixed(0) / 12
                            ).toFixed(0)
                      }`}</th>

                      <th style={{ flex: 219 }}>{`₹${
                        isNaN(parseFloat(formValues?.annualCTC))
                          ? 0
                          : parseFloat(formValues?.annualCTC).toFixed(0)
                      }`}</th>
                    </tr>
                  </tbody>
                </table>

                <div
                  style={{
                    position: "absolute",
                    bottom: 0,
                    display: "flex",
                    justifyContent: "flex-end",
                    width: "100%",
                  }}
                >
                  <button
                    className="button secondaryButton"
                    type="button"
                    onClick={returnPage}
                  >
                    Cancel
                  </button>
                  <button className="button primaryButton" type="submit">
                    {detailsForm
                      ? "Update Payroll Template"
                      : "Create Payroll Template"}
                  </button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}

export default CreatePayrollTemplate;
