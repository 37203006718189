import { Table, TableBody, TableHead, TableRow } from "@mui/material";
import { FieldArray, Form, Formik } from "formik";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { CheckboxFormiks } from "../../../../Components/FormComponent/CheckboxFormiks";
import { FileUploadFormik } from "../../../../Components/FormComponent/FileUploadFormik";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import { Loader } from "../../../../Components/FormComponent/Loader";
import * as Yup from "yup";
import {
  StyledTableCell,
  StyledTableCont,
} from "../../../../Components/FormComponent/Repeated";
import { isLoading, showToast } from "../../../../Features";
import { getRequest, postRequestForm } from "../../../../Services/axios";
import { FileUploadFormikMulti } from "../../../../Components/FormComponent/FileUploadFormikMulti";
export const UploadTicket = ({ id, handleClose, getListData }) => {
  const [detailsform, setDetailsform] = useState(false);

  useEffect(() => {
    document.title = `PeopleSol - Upload Ticket`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const data = id;

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const getFormDetails = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(
        `TravelHospitalityRequests/Details/${data}`
      );
      setDetailsform(result.data.data);
      // //console.log("result.data", result.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };

  useEffect(() => {
    if (data) getFormDetails();
  }, []);

  const create = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequestForm(
        "TravelHospitalityRequests/UpdateTicket",
        values
      );
      dispatch(
        showToast({
          text: "Tickets successfully uploaded.",
          severity: "success",
        })
      );
      handleClose();
      getListData();
    } catch (error) {
      dispatch(
        showToast({ text: "Error in uploading tickets.", severity: "error" })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  useEffect(() => {
    if (data) getFormDetails();
  }, [data]);
  const getInitalValues = () => {
    let returnArray = [
      {
        Id: 0,
        IsSelected: false,
        Remarks: "",
        Amount: "",
        Upload: null,
      },
    ];
    if (detailsform)
      if (detailsform.bookinglist)
        if (detailsform.bookinglist.length > 0) {
          returnArray = [];
          detailsform?.bookinglist?.map((item) => {
            return returnArray.push({
              Id: item.id,
              IsSelected: item.isSelected,
              Remarks: item.remarks,
              Amount: item.amount,
              Upload: item.attachment,
            });
          });
        }
    return returnArray;
  };
  const initialvalue = {
    Id: data,
    fileUpload: "",
    Bookinglist: getInitalValues(),
  };
  const updateAmount = async (Id, Amount) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(
        `TravelHospitalityRequests/SaveAmount/${Id}/${Amount}`
      );
      if (result)
        dispatch(
          showToast({
            text: "Amount successfully updated.",
            severity: "success",
          })
        );
      getFormDetails();
    } catch (error) {
      dispatch(
        showToast({ text: "Error in updating amount.", severity: "error" })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  if (loading) return <Loader />;
  else
    return (
      <Formik
        initialValues={initialvalue}
        validationSchema={Yup.object({
          fileUpload: Yup.array()
            .min(1, "Please Select Team Members")
            .required(),
        })}
        enableReinitialize
        onSubmit={(values) => {
          const formData = new FormData();
          formData.append("Id", values.Id);
          values.fileUpload?.map((item) => {
            formData.append("fileUpload", item);
          });
          create(formData);
        }}
      >
        {(formik) => {
          return (
            <Form>
              <div style={{ width: "100%" }}>
                <div style={{ display: "grid" }}>
                  <FieldArray name="Bookinglist">
                    {(fieldArrayProps) => {
                      const { form } = fieldArrayProps;
                      const { values } = form;
                      const { Bookinglist } = values;

                      return (
                        <StyledTableCont>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <StyledTableCell
                                  padding="0"
                                  style={{
                                    fontSize: "12px",
                                  }}
                                  align="center"
                                ></StyledTableCell>
                                <StyledTableCell
                                  padding="0"
                                  style={{
                                    fontSize: "12px",
                                  }}
                                  align="center"
                                >
                                  <LabelCustom labelName={"Option Remark"} />
                                </StyledTableCell>

                                <StyledTableCell
                                  style={{
                                    fontSize: "12px",
                                  }}
                                  align="center"
                                >
                                  <LabelCustom labelName={"Attachment"} />
                                </StyledTableCell>
                                <StyledTableCell
                                  style={{
                                    fontSize: "12px",
                                  }}
                                  align="center"
                                >
                                  <LabelCustom labelName={"Amount"} />
                                </StyledTableCell>
                              </TableRow>
                            </TableHead>
                            {Bookinglist.map((Bookinglist, index) => {
                              let bgColor = "transparent";
                              if (Bookinglist.IsSelected)
                                bgColor = "var(--primary-hover)";
                              return (
                                <>
                                  <TableBody key={index}>
                                    <TableRow sx={{ backgroundColor: bgColor }}>
                                      <StyledTableCell align="center">
                                        <CheckboxFormiks
                                          disabled
                                          name={`Bookinglist[${index}].IsSelected`}
                                        />
                                      </StyledTableCell>
                                      <StyledTableCell>
                                        <LabelCustom
                                          labelName={Bookinglist.Remarks}
                                        />
                                      </StyledTableCell>

                                      <StyledTableCell>
                                        <a
                                          style={{ margin: "6px" }}
                                          href={`${process.env.REACT_APP_.BASE_URL}/Core/Uploads/TravelBookings/${Bookinglist.Upload}`}
                                          target="blank"
                                        >
                                          {Bookinglist.Upload}
                                        </a>
                                      </StyledTableCell>
                                      <StyledTableCell>
                                        {Bookinglist.IsSelected ? (
                                          <div
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "center",
                                            }}
                                          >
                                            <InputCustomFormik
                                              name={`Bookinglist[${index}].Amount`}
                                            />
                                            <button
                                              type="button"
                                              className="button primaryButton"
                                              onClick={() =>
                                                updateAmount(
                                                  formik.values.Bookinglist[
                                                    index
                                                  ].Id,
                                                  formik.values.Bookinglist[
                                                    index
                                                  ].Amount
                                                )
                                              }
                                            >
                                              Update Amount
                                            </button>
                                          </div>
                                        ) : (
                                          <LabelCustom
                                            labelName={Bookinglist.Amount}
                                          />
                                        )}
                                      </StyledTableCell>
                                    </TableRow>
                                  </TableBody>
                                </>
                              );
                            })}
                          </Table>
                        </StyledTableCont>
                      );
                    }}
                  </FieldArray>
                </div>
                <div>
                  <LabelCustom labelName="Upload Tickets" mandatory={true} />
                  <FileUploadFormikMulti name="fileUpload" />
                </div>
                <div className="createFootCust">
                  <div>
                    <button className="button primaryButton" type="submit">
                      Update Preferences
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    );
};
