import React from "react";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import { TextAreaFormik } from "../../../../Components/FormComponent/TextAreaFormik";
import { DatePickerFormiks } from "../../../../Components/FormComponent/DatePickerFormiks";
import { SelectForm } from "../../../../Components/FormComponent/SelectForm";
import { useEffect } from "react";

export const HotelSelfComp = ({ formValues }) => {
  const genderOptions = [
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" },
    { label: "Others", value: "Others" },
  ];
  useEffect(() => {
    document.title = `PeopleSol - Self Hotel`;  
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  return (
    <div className="formcustom threeColumn">
      <div>
        <LabelCustom labelName={"Check-In Date and Time"} mandatory={true} />
        <DatePickerFormiks
          name={"departure"}
          showTimeSelect
          maxDate={formValues?.toDate}
        />
      </div>
      <div>
        <LabelCustom labelName={"Check-Out Date and Time"} mandatory={true} />
        <DatePickerFormiks
          name={"toDate"}
          minDate={formValues?.departure}
          showTimeSelect
        />
      </div>

      <div>
        <LabelCustom labelName={"Location"} mandatory={true} />
        <InputCustomFormik
          maxLength={50}
          name="fromLocation"
          type={"text"}
          placeholder={"Please Enter Location"}
        />
      </div>

      <div>
        <LabelCustom labelName={"Preferred Hotel"} mandatory={true} />
        <InputCustomFormik
          maxLength={150}
          name="preferredHotel"
          type={"text"}
          placeholder={"Please Enter Preferred Hotel"}
        />
      </div>

      <div>
        <LabelCustom labelName={"Age"} />
        <InputCustomFormik
          maxLength={3}
          name="age"
          type={"text"}
          placeholder={"Please Enter Age"}
        />
      </div>
      <div>
        <LabelCustom labelName={"Gender"} />
        <SelectForm name="gender" options={genderOptions} />
      </div>
      <div>
        <LabelCustom labelName={"Budget (INR)"} mandatory={true} />
        <InputCustomFormik
          maxLength={10}
          name="budget"
          step=".001"
          placeholder={"Please enter budget"}
        />
      </div>
      <div>
        <LabelCustom labelName={"Booking Note"} />
        <TextAreaFormik
          name="comments"
          placeholder={"Please Enter Booking Note"}
          maxLength={500}
        />
      </div>
    </div>
  );
};
