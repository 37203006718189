import { Box } from "@mui/material";
import { Button, Slider } from "@mui/material";
import React from "react";
import AvatarEditor from "react-avatar-editor";

export const ImageViewer = ({ src, setShowOptions }) => {
  return (
    <div>
      <div className="imagePreviewContainer">
        <div>
          <AvatarEditor
            // ref={cropRef}
            image={src}
            style={{
              width: "100%",
              height: "100%",
            }}
            border={50}
            borderRadius={150}
            color={[0, 0, 0, 0.72]}
            // scale={slideValue / 10}
            rotate={0}
          />

          {/* MUI Slider */}
          <Slider
            min={10}
            max={50}
            sx={{
              margin: "0 auto",
              width: "80%",
              color: "cyan",
            }}
            size="medium"
            // defaultValue={slideValue}
            // value={slideValue}
            // onChange={(e) =>
            //   setSlideValue(e.target.value)
            // }
          />
          <Box
            sx={{
              display: "flex",
              padding: "10px",
              border: "3px solid white",
              background: "black",
            }}
          >
            <Button
              size="small"
              sx={{
                marginRight: "10px",
                color: "white",
                borderColor: "white",
              }}
              variant="outlined"
              onClick={(e) => setShowOptions(false)}
            >
              cancel
            </Button>
            <Button
              sx={{ background: "#5596e6" }}
              size="small"
              variant="contained"
              // onClick={handleSave}
            >
              Save
            </Button>
          </Box>
        </div>
      </div>
    </div>
  );
};
