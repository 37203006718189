import { getRequest } from "../../../../../Services/axios";

export const removePostLike = async (postId) => {
  try {
    const result = await getRequest(`SocialPost/RemoveLike?PostId=${postId}`);
  } catch (error) {
  } finally {
  }
};
export const removePostDisLike = async (postId) => {
  try {
    const result = await getRequest(
      `SocialPost/RemoveDisLike?PostId=${postId}`
    );
  } catch (error) {
  } finally {
  }
};
export const likePost = async (postId, likedType) => {
  try {
    const result = await getRequest(
      `SocialPost/Like?PostId=${postId}&LikedType=${likedType}`
    );
  } catch (error) {
  } finally {
  }
};

export const disLikePost = async (postId, disLikedType) => {
  try {
    const result = await getRequest(
      `SocialPost/DisLike?PostId=${postId}&DisLikedType=${disLikedType}`
    );
  } catch (error) {
  } finally {
  }
};

export const formatTimeFromDate = (mydate) => {
  let date = new Date(mydate);
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();
  const formattedHours = String(hours).padStart(2, "0");
  const formattedMinutes = String(minutes).padStart(2, "0");
  // const formattedSeconds = String(seconds).padStart(2, "0");
  return `${formattedHours} hrs, ${formattedMinutes} min`;
};
