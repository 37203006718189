import React from "react";
import { DatePickerFormiks } from "../../../../Components/FormComponent/DatePickerFormiks";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { TextAreaFormik } from "../../../../Components/FormComponent/TextAreaFormik";
import { FieldArray } from "formik";
import {
  StyledTableCell,
  StyledTableCont,
} from "../../../../Components/FormComponent/Repeated";
import { TableBody, TableHead, TableRow, Table } from "@mui/material";
import { SelectForm } from "../../../../Components/FormComponent/SelectForm";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { getRequest } from "../../../../Services/axios";
import { showToast } from "../../../../Features";
import { useEffect } from "react";
import { FileUploadFormik } from "../../../../Components/FormComponent/FileUploadFormik";
import { Loader } from "../../../../Components/FormComponent/Loader";
const yesNoOptions = [
  { label: "Yes", value: "Yes" },
  { label: "No", value: "No" },
];
const CreateReimbursementComp = ({
  formValues,
  loading,
  setInitialValues,
  getInitialValuesRepeated,
  editIDData,
  seteditIDDatafunc,
}) => {
  const dispatch = useDispatch();
  const [employeeList, setEmployeeList] = useState([]);
  const [travelRequestsIdList, setTravelRequestsIdList] = useState([]);
  const [advanceRequestsList, setAdvanceRequestsList] = useState([]);
  const [resultData, setResultData] = useState([]);
  useEffect(() => {
    document.title = `PeopleSol - Reimbursement`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const getEmployeeData = async () => {
    try {
      const result = await getRequest("Expenses/GetExpenseTypes");
      let listData = [];
      result?.data?.results?.map((item) => {
        return listData.push({
          label: item.text,
          value: item.id ? Number(item.id) : null,
        });
      });
      setEmployeeList(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  const getadvanceRequests = async () => {
    try {
      const result = await getRequest(
        "TravelAdvanceRequests/GetMyAdvanceRequests"
      );
      let listData = [];
      result?.data?.map((item) => {
        return listData.push({ label: item.text, value: item.id });
      });
      setAdvanceRequestsList(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  const gettravelRequests = async () => {
    try {
      const result = await getRequest("Expenses/GetTravelRequest");
      let listData = [];
      result?.data?.map((item) => {
        return listData.push({ label: item.requestNumber, value: item.id });
      });
      setTravelRequestsIdList(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  const getTravelDetails = async (id) => {
    try {
      const result = await getRequest(
        `TravelHospitalityRequests/Details/${id}`
      );
      setInitialValues((prev) => ({
        ...prev,
        ...formValues,
        Expenselist: getInitialValuesRepeated(
          formValues.Expenselist,
          result?.data?.data?.fromDate,
          result?.data?.data?.budget,
          result?.data?.data?.bookinglist
        ),
      }));

      setResultData(result?.data?.data);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  useEffect(() => {
    if (formValues?.TravalRequestId) {
      if (formValues?.TravalRequestId && !editIDData) {
        getTravelDetails(formValues?.TravalRequestId);
      }
      seteditIDDatafunc();
    }
  }, [formValues?.TravalRequestId]);

  useEffect(() => {
    getEmployeeData();
    getadvanceRequests();
    gettravelRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (loading) return <Loader />;
  else
    return (
      <>
        <div className="formcustom twoColumn">
          <div>
            <LabelCustom labelName={"Travel Location"} mandatory={true} />
            <InputCustomFormik
              name={"Location"}
              placeholder={"Enter the Location"}
            />
          </div>
          <div>
            <LabelCustom labelName={"Travel Advance Taken"} />
            <SelectForm
              name={"AdvanceRequestID"}
              options={advanceRequestsList}
              placeholder={"Select Travel Advance"}
            />
          </div>
          <div>
            <LabelCustom labelName={"Travel Request Id"} />
            <SelectForm
              name={"TravalRequestId"}
              options={travelRequestsIdList}
              placeholder={"Select Travel Advance"}
            />
          </div>

          {/* <div>
            <LabelCustom labelName={"Comments/Notes"} />
            <InputCustomFormik
              name="Comments"
              type={"text"}
              placeholder={"Enter Comments/Notes"}
            />
          </div> */}
        </div>
        <div>
          <label className="repeatedHeading">Expenses</label>
          <div
            style={{
              width: "100%",
              overflowX: "auto",
              overflowY: "hidden",
              paddingTop: "12px",
            }}
          >
            <FieldArray name="Expenselist">
              {(fieldArrayProps) => {
                const { push, remove, insert, form } = fieldArrayProps;
                const { values } = form;
                const { Expenselist } = values;
                return (
                  <StyledTableCont sx={{ maxWidth: "100%" }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <StyledTableCell
                            padding="0"
                            style={{
                              fontSize: "12px",
                            }}
                            align="center"
                          >
                            <LabelCustom labelName={"S. No"} />
                          </StyledTableCell>
                          <StyledTableCell
                            padding="0"
                            style={{
                              fontSize: "12px",
                            }}
                            align="center"
                          >
                            <LabelCustom
                              labelName={"Expense Date"}
                              mandatory={true}
                            />
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              fontSize: "12px",
                            }}
                            align="center"
                          >
                            <LabelCustom
                              labelName={"Expense Type"}
                              mandatory={true}
                            />
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              fontSize: "12px",
                            }}
                            align="center"
                          >
                            <LabelCustom
                              labelName={"Claimed Amount (Rs)"}
                              mandatory={true}
                            />
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              fontSize: "12px",
                            }}
                            align="center"
                          >
                            <LabelCustom
                              labelName={"Is Receipt Available?"}
                              mandatory={true}
                            />
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              fontSize: "12px",
                            }}
                            align="center"
                          >
                            <LabelCustom
                              labelName={"Receipt"}
                              mandatory={true}
                            />
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              fontSize: "12px",
                            }}
                            align="center"
                          >
                            <LabelCustom
                              labelName={"Remarks"}
                              mandatory={true}
                            />
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              fontSize: "12px",
                            }}
                            align="center"
                          >
                            <LabelCustom labelName={"Add/Remove"} />
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      {Expenselist?.map((Expenselists, index) => (
                        <TableBody key={index}>
                          {/* 1st column */}
                          <StyledTableCell align="center">
                            {index + 1}
                          </StyledTableCell>
                          {/* 2nd column */}
                          <StyledTableCell>
                            <DatePickerFormiks
                              name={`Expenselist[${index}].Date`}
                              placeholder={"Select the Expense Date"}
                            />
                          </StyledTableCell>
                          {/* 3rd column */}
                          <StyledTableCell>
                            <SelectForm
                              options={employeeList}
                              name={`Expenselist[${index}].ExpenseTypeId`}
                              placeholder={"Select the Expense Type"}
                            />
                          </StyledTableCell>
                          {/* 4th column */}
                          <StyledTableCell>
                            <InputCustomFormik
                              type="number"
                              step="0.01"
                              name={`Expenselist[${index}].Amount`}
                              placeholder={"Enter the Expense Amount"}
                            />
                          </StyledTableCell>
                          {/* 5th column */}
                          <StyledTableCell>
                            <SelectForm
                              options={yesNoOptions}
                              name={`Expenselist[${index}].IsReceiptAvailable`}
                            />
                          </StyledTableCell>
                          {/* 6th column */}
                          <StyledTableCell>
                            {(
                              formValues?.Expenselist
                                ? formValues?.Expenselist.length > 0
                                  ? formValues?.Expenselist[index]
                                      ?.IsReceiptAvailable === "Yes"
                                  : null
                                : null
                            ) ? (
                              <FileUploadFormik
                                name={`Expenselist[${index}].Upload`}
                              />
                            ) : (
                              <InputCustomFormik
                                name={`Expenselist[${index}].Upload`}
                                placeholder={"Enter the Justification"}
                              />
                            )}
                          </StyledTableCell>
                          {/* 7th column */}
                          <StyledTableCell>
                            <TextAreaFormik
                              name={`Expenselist[${index}].Remarks`}
                              placeholder={"Enter Remarks"}
                            />
                          </StyledTableCell>
                          {/* 8th column */}
                          <StyledTableCell sx={{ textAlign: "center" }}>
                            <button
                              type="button"
                              className="remove-btn"
                              onClick={() =>
                                Expenselist.length > 1
                                  ? remove(index)
                                  : dispatch(
                                      showToast({
                                        text: "Atleast One Item is Required ",
                                        severity: "error",
                                      })
                                    )
                              }
                            >
                              -
                            </button>
                            <button
                              type="button"
                              className="add-btn"
                              onClick={() =>
                                insert(index + 1, {
                                  Date: Expenselist[index].Date,
                                  ExpenseTypeId: "",
                                  Amount: Expenselist[index].Amount,
                                  IsReceiptAvailable: "Yes",
                                  Upload: Expenselist[index].Upload,
                                  Remarks: "",
                                })
                              }
                            >
                              +
                            </button>
                          </StyledTableCell>
                        </TableBody>
                      ))}
                    </Table>
                  </StyledTableCont>
                );
              }}
            </FieldArray>
          </div>
        </div>
      </>
    );
};

export { CreateReimbursementComp };
