import React, { useState } from "react";
import DonutChartWithCenterText from "./DonutChartWithCenterText";
import GenderDetails from "./GenderDetails";
import AgeDetails from "./AgeDetails";
import LocationDetails from "./LocationDetails";
import DepartmentDetails from "./DepartmentDetails";
import { useEffect } from "react";

function Report({ surveyReportData }) {
  const locationData = Object.keys(surveyReportData?.locationNumber ?? {});
  const departmentData = Object.keys(surveyReportData?.departmentNumber ?? {});
  const [locCount, setLocCount] = useState(0);
  const [deptCount, setdeptCount] = useState(0);

  useEffect(() => {
    let sum1 = 0;
    let sum2 = 0;
    if (locationData && surveyReportData) {
      locationData.forEach((location) => {
        const locationNumber = surveyReportData?.locationNumber[location];
        if (locationNumber) {
          sum1 += locationNumber;
        }
      });
      setLocCount(sum1);
    }
    if (departmentData && surveyReportData) {
      departmentData.forEach((department) => {
        const departmentNumber = surveyReportData?.departmentNumber[department];
        if (departmentNumber) {
          sum2 += departmentNumber;
        }
      });
      setdeptCount(sum2);
    }
  }, [locationData, surveyReportData]);

  return (
    <div className="report-container">
      <div className="SurveyReportPageWrapper">
        <div>
          <DonutChartWithCenterText
            title={"Male"}
            type={"male"}
            count={surveyReportData?.gendersNumber?.male}
            percent={surveyReportData?.genders?.male}
            data={surveyReportData?.ageAndGender?.ageGroups}
          />
        </div>

        <div>
          <DonutChartWithCenterText
            title={"Female"}
            type={"female"}
            count={surveyReportData?.gendersNumber?.female}
            percent={surveyReportData?.genders?.female}
            data={surveyReportData?.ageAndGender?.ageGroups}
          />
        </div>
        <div>
          <DonutChartWithCenterText
            title={"Transgender"}
            type={"transgender"}
            count={surveyReportData?.gendersNumber?.transgender}
            percent={surveyReportData?.genders?.transgender}
            data={surveyReportData?.ageAndGender?.ageGroups}
          />
        </div>
        <div>
          <DonutChartWithCenterText
            title={"Gender Nuetral"}
            type={"genderNuetral"}
            count={surveyReportData?.gendersNumber?.genderNeutral}
            percent={surveyReportData?.genders?.genderNeutral}
            data={surveyReportData?.ageAndGender?.ageGroups}
          />
        </div>
        <div>
          <DonutChartWithCenterText
            title={"Do not Disclose"}
            type={"doNotDisclose"}
            count={surveyReportData?.gendersNumber?.doNotDisclose}
            percent={surveyReportData?.genders?.doNotDisclose}
            data={surveyReportData?.ageAndGender?.ageGroups}
          />
        </div>

        <div className="displayAgeAndGenderListContainer">
          <article className="displayAgeAndGenderListWrapper">
            <span className="circleChart bgColor1"></span>
            <p>Age (12-17)</p>
          </article>
          <article className="displayAgeAndGenderListWrapper">
            <span className="circleChart bgColor2"></span>
            <p>Age (18-24)</p>
          </article>
          <article className="displayAgeAndGenderListWrapper">
            <span className="circleChart bgColor3"></span>
            <p>Age (25-32)</p>
          </article>
          <article className="displayAgeAndGenderListWrapper">
            <span className="circleChart bgColor4"></span>
            <p>Age (33-40)</p>
          </article>
          <article className="displayAgeAndGenderListWrapper">
            <span className="circleChart bgColor5"></span>
            <p>Age (41-56)</p>
          </article>
          <article className="displayAgeAndGenderListWrapper">
            <span className="circleChart bgColor6"></span>
            <p>Age (57-64)</p>
          </article>
          <article className="displayAgeAndGenderListWrapper">
            <span className="circleChart bgColor7"></span>
            <p>Age (65-72)</p>
          </article>
        </div>
      </div>
      <div className="ageAndGenderDetailContainer">
        <div className="ageAndGenderDetailWrapper">
          <table>
            <thead>
              <tr>
                <th></th>
                <th>Male</th>
                <th>Female</th>
                <th>Transgender</th>
                <th>
                  Gender <br />
                  Nuetral
                </th>
                <th>
                  Do Not <br />
                  Close
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className="textColor1">
                <td>
                  <article className="displayAgeAndGenderListWrapper">
                    <span className="circleChart bgColor1"></span>
                    <p>Age (12-17)</p>
                  </article>
                </td>
                <td>
                  <p>
                    {
                      surveyReportData?.ageAndGenderNumber?.ageGroups?.Age12_17
                        ?.male
                    }
                  </p>
                  <span>
                    ({surveyReportData?.ageAndGender?.ageGroups?.Age12_17?.male}
                    %)
                  </span>
                </td>
                <td>
                  {" "}
                  <p>
                    {
                      surveyReportData?.ageAndGenderNumber?.ageGroups?.Age12_17
                        ?.female
                    }
                  </p>
                  <span>
                    (
                    {
                      surveyReportData?.ageAndGender?.ageGroups?.Age12_17
                        ?.female
                    }
                    %)
                  </span>
                </td>
                <td>
                  <p>
                    {
                      surveyReportData?.ageAndGenderNumber?.ageGroups?.Age12_17
                        ?.transgender
                    }
                  </p>
                  <span>
                    (
                    {
                      surveyReportData?.ageAndGender?.ageGroups?.Age12_17
                        ?.transgender
                    }
                    %)
                  </span>
                </td>
                <td>
                  {" "}
                  <p>
                    {
                      surveyReportData?.ageAndGenderNumber?.ageGroups?.Age12_17
                        ?.genderNeutral
                    }
                  </p>
                  <span>
                    (
                    {
                      surveyReportData?.ageAndGender?.ageGroups?.Age12_17
                        ?.genderNeutral
                    }
                    %)
                  </span>
                </td>
                <td>
                  {" "}
                  <p>
                    {
                      surveyReportData?.ageAndGenderNumber?.ageGroups?.Age12_17
                        ?.doNotDisclose
                    }
                  </p>
                  <span>
                    (
                    {
                      surveyReportData?.ageAndGender?.ageGroups?.Age12_17
                        ?.doNotDisclose
                    }
                    %)
                  </span>
                </td>
              </tr>
              <tr className="textColor2">
                <td>
                  <article className="displayAgeAndGenderListWrapper">
                    <span className="circleChart bgColor2"></span>
                    <p>Age (18-24)</p>
                  </article>
                </td>
                <td>
                  <p>
                    {
                      surveyReportData?.ageAndGenderNumber?.ageGroups?.Age18_24
                        ?.male
                    }
                  </p>
                  <span>
                    ({surveyReportData?.ageAndGender?.ageGroups?.Age18_24?.male}
                    %)
                  </span>
                </td>
                <td>
                  {" "}
                  <p>
                    {
                      surveyReportData?.ageAndGenderNumber?.ageGroups?.Age18_24
                        ?.female
                    }
                  </p>
                  <span>
                    (
                    {
                      surveyReportData?.ageAndGender?.ageGroups?.Age18_24
                        ?.female
                    }
                    %)
                  </span>
                </td>
                <td>
                  {" "}
                  <p>
                    {
                      surveyReportData?.ageAndGenderNumber?.ageGroups?.Age18_24
                        ?.transgender
                    }
                  </p>
                  <span>
                    (
                    {
                      surveyReportData?.ageAndGender?.ageGroups?.Age18_24
                        ?.transgender
                    }
                    %)
                  </span>
                </td>
                <td>
                  {" "}
                  <p>
                    {
                      surveyReportData?.ageAndGenderNumber?.ageGroups?.Age18_24
                        ?.genderNeutral
                    }
                  </p>
                  <span>
                    (
                    {
                      surveyReportData?.ageAndGender?.ageGroups?.Age18_24
                        ?.genderNeutral
                    }
                    %)
                  </span>
                </td>
                <td>
                  {" "}
                  <p>
                    {
                      surveyReportData?.ageAndGenderNumber?.ageGroups?.Age18_24
                        ?.doNotDisclose
                    }
                  </p>
                  <span>
                    ({" "}
                    {
                      surveyReportData?.ageAndGender?.ageGroups?.Age18_24
                        ?.doNotDisclose
                    }
                    %)
                  </span>
                </td>
              </tr>
              <tr className="textColor3">
                <td>
                  <article className="displayAgeAndGenderListWrapper">
                    <span className="circleChart bgColor3"></span>
                    <p>Age (25-32)</p>
                  </article>
                </td>
                <td>
                  <p>
                    {
                      surveyReportData?.ageAndGenderNumber?.ageGroups?.Age25_32
                        ?.male
                    }
                  </p>
                  <span>
                    ({surveyReportData?.ageAndGender?.ageGroups?.Age25_32?.male}
                    %)
                  </span>
                </td>
                <td>
                  {" "}
                  <p>
                    {
                      surveyReportData?.ageAndGenderNumber?.ageGroups?.Age25_32
                        ?.female
                    }
                  </p>
                  <span>
                    (
                    {
                      surveyReportData?.ageAndGender?.ageGroups?.Age25_32
                        ?.female
                    }
                    %)
                  </span>
                </td>
                <td>
                  {" "}
                  <p>
                    {
                      surveyReportData?.ageAndGenderNumber?.ageGroups?.Age25_32
                        ?.transgender
                    }
                  </p>
                  <span>
                    ({" "}
                    {
                      surveyReportData?.ageAndGender?.ageGroups?.Age25_32
                        ?.transgender
                    }
                    %)
                  </span>
                </td>
                <td>
                  {" "}
                  <p>
                    {
                      surveyReportData?.ageAndGenderNumber?.ageGroups?.Age25_32
                        ?.genderNeutral
                    }
                  </p>
                  <span>
                    ({" "}
                    {
                      surveyReportData?.ageAndGender?.ageGroups?.Age25_32
                        ?.genderNeutral
                    }
                    %)
                  </span>
                </td>
                <td>
                  {" "}
                  <p>
                    {
                      surveyReportData?.ageAndGenderNumber?.ageGroups?.Age25_32
                        ?.doNotDisclose
                    }
                  </p>
                  <span>
                    (
                    {
                      surveyReportData?.ageAndGender?.ageGroups?.Age25_32
                        ?.doNotDisclose
                    }
                    %)
                  </span>
                </td>
              </tr>
              <tr className="textColor4">
                <td>
                  <article className="displayAgeAndGenderListWrapper">
                    <span className="circleChart bgColor4"></span>
                    <p>Age (33-40)</p>
                  </article>
                </td>
                <td>
                  <p>
                    {
                      surveyReportData?.ageAndGenderNumber?.ageGroups?.Age33_40
                        ?.male
                    }
                  </p>
                  <span>
                    ({surveyReportData?.ageAndGender?.ageGroups?.Age33_40?.male}
                    %)
                  </span>
                </td>
                <td>
                  {" "}
                  <p>
                    {
                      surveyReportData?.ageAndGenderNumber?.ageGroups?.Age33_40
                        ?.female
                    }
                  </p>
                  <span>
                    (
                    {
                      surveyReportData?.ageAndGender?.ageGroups?.Age33_40
                        ?.female
                    }
                    %)
                  </span>
                </td>
                <td>
                  {" "}
                  <p>
                    {
                      surveyReportData?.ageAndGenderNumber?.ageGroups?.Age33_40
                        ?.transgender
                    }
                  </p>
                  <span>
                    (
                    {
                      surveyReportData?.ageAndGender?.ageGroups?.Age33_40
                        ?.transgender
                    }
                    %)
                  </span>
                </td>
                <td>
                  {" "}
                  <p>
                    {
                      surveyReportData?.ageAndGenderNumber?.ageGroups?.Age33_40
                        ?.genderNeutral
                    }
                  </p>
                  <span>
                    (
                    {
                      surveyReportData?.ageAndGender?.ageGroups?.Age33_40
                        ?.genderNeutral
                    }
                    %)
                  </span>
                </td>
                <td>
                  {" "}
                  <p>
                    {
                      surveyReportData?.ageAndGenderNumber?.ageGroups?.Age33_40
                        ?.doNotDisclose
                    }
                  </p>
                  <span>
                    (
                    {
                      surveyReportData?.ageAndGender?.ageGroups?.Age33_40
                        ?.doNotDisclose
                    }
                    %)
                  </span>
                </td>
              </tr>
              <tr className="textColor5">
                <td>
                  <article className="displayAgeAndGenderListWrapper">
                    <span className="circleChart bgColor5"></span>
                    <p>Age (41-56)</p>
                  </article>
                </td>
                <td>
                  <p>
                    {
                      surveyReportData?.ageAndGenderNumber?.ageGroups?.Age41_56
                        ?.male
                    }
                  </p>
                  <span>
                    ({surveyReportData?.ageAndGender?.ageGroups?.Age41_56?.male}
                    %)
                  </span>
                </td>
                <td>
                  {" "}
                  <p>
                    {
                      surveyReportData?.ageAndGenderNumber?.ageGroups?.Age41_56
                        ?.female
                    }
                  </p>
                  <span>
                    (
                    {
                      surveyReportData?.ageAndGender?.ageGroups?.Age41_56
                        ?.female
                    }
                    %)
                  </span>
                </td>
                <td>
                  {" "}
                  <p>
                    {
                      surveyReportData?.ageAndGenderNumber?.ageGroups?.Age41_56
                        ?.transgender
                    }
                  </p>
                  <span>
                    (
                    {
                      surveyReportData?.ageAndGender?.ageGroups?.Age41_56
                        ?.transgender
                    }
                    %)
                  </span>
                </td>
                <td>
                  {" "}
                  <p>
                    {
                      surveyReportData?.ageAndGenderNumber?.ageGroups?.Age41_56
                        ?.genderNeutral
                    }
                  </p>
                  <span>
                    (
                    {
                      surveyReportData?.ageAndGender?.ageGroups?.Age41_56
                        ?.genderNeutral
                    }
                    %)
                  </span>
                </td>
                <td>
                  {" "}
                  <p>
                    {
                      surveyReportData?.ageAndGenderNumber?.ageGroups?.Age41_56
                        ?.doNotDisclose
                    }
                  </p>
                  <span>
                    (
                    {
                      surveyReportData?.ageAndGender?.ageGroups?.Age41_56
                        ?.doNotDisclose
                    }
                    %)
                  </span>
                </td>
              </tr>
              <tr className="textColor6">
                <td>
                  <article className="displayAgeAndGenderListWrapper">
                    <span className="circleChart bgColor6"></span>
                    <p>Age (57-64)</p>
                  </article>
                </td>
                <td>
                  <p>
                    {
                      surveyReportData?.ageAndGenderNumber?.ageGroups?.Age57_64
                        ?.male
                    }
                  </p>
                  <span>
                    ({surveyReportData?.ageAndGender?.ageGroups?.Age57_64?.male}
                    %)
                  </span>
                </td>
                <td>
                  {" "}
                  <p>
                    {
                      surveyReportData?.ageAndGenderNumber?.ageGroups?.Age57_64
                        ?.female
                    }
                  </p>
                  <span>
                    (
                    {
                      surveyReportData?.ageAndGender?.ageGroups?.Age57_64
                        ?.female
                    }
                    %)
                  </span>
                </td>
                <td>
                  {" "}
                  <p>
                    {
                      surveyReportData?.ageAndGenderNumber?.ageGroups?.Age57_64
                        ?.transgender
                    }
                  </p>
                  <span>
                    (
                    {
                      surveyReportData?.ageAndGender?.ageGroups?.Age57_64
                        ?.transgender
                    }
                    %)
                  </span>
                </td>
                <td>
                  {" "}
                  <p>
                    {
                      surveyReportData?.ageAndGenderNumber?.ageGroups?.Age57_64
                        ?.genderNeutral
                    }
                  </p>
                  <span>
                    (
                    {
                      surveyReportData?.ageAndGender?.ageGroups?.Age57_64
                        ?.genderNeutral
                    }
                    %)
                  </span>
                </td>
                <td>
                  {" "}
                  <p>
                    {
                      surveyReportData?.ageAndGenderNumber?.ageGroups?.Age57_64
                        ?.doNotDisclose
                    }
                  </p>
                  <span>
                    (
                    {
                      surveyReportData?.ageAndGender?.ageGroups?.Age57_64
                        ?.doNotDisclose
                    }
                    %)
                  </span>
                </td>
              </tr>
              <tr className="textColor7">
                <td>
                  <article className="displayAgeAndGenderListWrapper">
                    <span className="circleChart bgColor7"></span>
                    <p>Age (65-72)</p>
                  </article>
                </td>
                <td>
                  <p>
                    {
                      surveyReportData?.ageAndGenderNumber?.ageGroups?.Age65_72
                        ?.male
                    }
                  </p>
                  <span>
                    ({surveyReportData?.ageAndGender?.ageGroups?.Age65_72?.male}
                    %)
                  </span>
                </td>
                <td>
                  {" "}
                  <p>
                    {
                      surveyReportData?.ageAndGenderNumber?.ageGroups?.Age65_72
                        ?.female
                    }
                  </p>
                  <span>
                    (
                    {
                      surveyReportData?.ageAndGender?.ageGroups?.Age65_72
                        ?.female
                    }
                    %)
                  </span>
                </td>
                <td>
                  {" "}
                  <p>
                    {
                      surveyReportData?.ageAndGenderNumber?.ageGroups?.Age65_72
                        ?.transgender
                    }
                  </p>
                  <span>
                    (
                    {
                      surveyReportData?.ageAndGender?.ageGroups?.Age65_72
                        ?.transgender
                    }
                    %)
                  </span>
                </td>
                <td>
                  {" "}
                  <p>
                    {
                      surveyReportData?.ageAndGenderNumber?.ageGroups?.Age65_72
                        ?.genderNeutral
                    }
                  </p>
                  <span>
                    (
                    {
                      surveyReportData?.ageAndGender?.ageGroups?.Age65_72
                        ?.genderNeutral
                    }
                    %)
                  </span>
                </td>
                <td>
                  {" "}
                  <p>
                    {
                      surveyReportData?.ageAndGenderNumber?.ageGroups?.Age65_72
                        ?.doNotDisclose
                    }
                  </p>
                  <span>
                    (
                    {
                      surveyReportData?.ageAndGender?.ageGroups?.Age65_72
                        ?.doNotDisclose
                    }
                    %)
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="genderReportContainer">
        <div className="genderReportWrapper">
          <div className="genderDetail-container">
            <GenderDetails
              count={
                surveyReportData?.gendersNumber?.doNotDisclose +
                surveyReportData?.gendersNumber?.female +
                surveyReportData?.gendersNumber?.genderNeutral +
                surveyReportData?.gendersNumber?.male +
                surveyReportData?.gendersNumber?.transgender
              }
              percent={100}
              data={surveyReportData}
            />
          </div>
          <div className="genderReportList">
            <div className="genderReportList-container">
              <article className="displayAgeAndGenderListWrapper">
                <span className="circleChart bgColor1"></span>
                <p>Male</p>
              </article>
              <article className="genderReportNumbeAndPercentContainer textColor1">
                <p>{surveyReportData?.gendersNumber?.male}</p>
                <p>{surveyReportData?.genders?.male}%</p>
              </article>
            </div>
            <div className="genderReportList-container">
              <article className="displayAgeAndGenderListWrapper">
                <span className="circleChart bgColor2"></span>
                <p>Female</p>
              </article>
              <article className="genderReportNumbeAndPercentContainer textColor2">
                <p>{surveyReportData?.gendersNumber?.female}</p>
                <p>{surveyReportData?.genders?.female}%</p>
              </article>
            </div>
            <div className="genderReportList-container">
              <article className="displayAgeAndGenderListWrapper">
                <span className="circleChart bgColor3"></span>
                <p>Transgender</p>
              </article>
              <article className="genderReportNumbeAndPercentContainer textColor3">
                <p>{surveyReportData?.gendersNumber?.transgender}</p>
                <p>{surveyReportData?.genders?.transgender}%</p>
              </article>
            </div>
            <div className="genderReportList-container">
              <article className="displayAgeAndGenderListWrapper">
                <span className="circleChart bgColor4"></span>
                <p>Gender Nuetral</p>
              </article>
              <article className="genderReportNumbeAndPercentContainer textColor4">
                <p>{surveyReportData?.gendersNumber?.genderNeutral}</p>
                <p>{surveyReportData?.genders?.genderNeutral}%</p>
              </article>
            </div>
            <div className="genderReportList-container">
              <article className="displayAgeAndGenderListWrapper">
                <span className="circleChart bgColor5"></span>
                <p>Do Not Disclose</p>
              </article>
              <article className="genderReportNumbeAndPercentContainer textColor5">
                <p>{surveyReportData?.gendersNumber?.doNotDisclose}</p>
                <p>{surveyReportData?.genders?.doNotDisclose}%</p>
              </article>
            </div>
          </div>
        </div>
      </div>
      <div className="ageReportContainer">
        <div className="ageReportWrapper">
          <div className="ageDetail-container">
            <AgeDetails
              count={
                surveyReportData?.ageNumber?.Age12_17 +
                surveyReportData?.ageNumber?.Age18_24 +
                surveyReportData?.ageNumber?.Age25_32 +
                surveyReportData?.ageNumber?.Age33_40 +
                surveyReportData?.ageNumber?.Age41_56 +
                surveyReportData?.ageNumber?.Age57_64 +
                surveyReportData?.ageNumber?.Age65_72
              }
              percent={100}
              data={surveyReportData}
            />
          </div>
          <div className="ageReportList">
            <div className="genderReportList-container">
              <article className="displayAgeAndGenderListWrapper">
                <span className="circleChart bgColor1"></span>
                <p>Age (12-17)</p>
              </article>
              <article className="ageReportNumberAndPercentContainer textColor1">
                <p>{surveyReportData?.ageNumber?.Age12_17}</p>
                <p>{surveyReportData?.age?.Age12_17}%</p>
              </article>
            </div>
            <div className="genderReportList-container">
              <article className="displayAgeAndGenderListWrapper">
                <span className="circleChart bgColor2"></span>
                <p>Age (18-24)</p>
              </article>
              <article className="ageReportNumberAndPercentContainer textColor2">
                <p>{surveyReportData?.ageNumber?.Age18_24}</p>
                <p>{surveyReportData?.age?.Age18_24}%</p>
              </article>
            </div>
            <div className="genderReportList-container">
              <article className="displayAgeAndGenderListWrapper">
                <span className="circleChart bgColor3"></span>
                <p>Age (25-32)</p>
              </article>
              <article className="ageReportNumberAndPercentContainer textColor3">
                <p>{surveyReportData?.ageNumber?.Age25_32}</p>
                <p>{surveyReportData?.age?.Age25_32}%</p>
              </article>
            </div>
            <div className="genderReportList-container">
              <article className="displayAgeAndGenderListWrapper">
                <span className="circleChart bgColor4"></span>
                <p>Age (33-40)</p>
              </article>
              <article className="ageReportNumberAndPercentContainer textColor4">
                <p>{surveyReportData?.ageNumber?.Age33_40}</p>
                <p>{surveyReportData?.age?.Age33_40}%</p>
              </article>
            </div>
            <div className="genderReportList-container">
              <article className="displayAgeAndGenderListWrapper">
                <span className="circleChart bgColor5"></span>
                <p>Age (41-56)</p>
              </article>
              <article className="ageReportNumberAndPercentContainer textColor5">
                <p>{surveyReportData?.ageNumber?.Age41_56}</p>
                <p>{surveyReportData?.age?.Age41_56}%</p>
              </article>
            </div>
            <div className="genderReportList-container">
              <article className="displayAgeAndGenderListWrapper">
                <span className="circleChart bgColor6"></span>
                <p>Age (57-64)</p>
              </article>
              <article className="ageReportNumberAndPercentContainer textColor6">
                <p>{surveyReportData?.ageNumber?.Age57_64}</p>
                <p>{surveyReportData?.age?.Age57_64}%</p>
              </article>
            </div>
            <div className="genderReportList-container">
              <article className="displayAgeAndGenderListWrapper">
                <span className="circleChart bgColor7"></span>
                <p>Age (65-72)</p>
              </article>
              <article className="ageReportNumberAndPercentContainer textColor7">
                <p>{surveyReportData?.ageNumber?.Age65_72}</p>
                <p>{surveyReportData?.age?.Age65_72}%</p>
              </article>
            </div>
          </div>
        </div>
      </div>
      <div className="locationReportContainer">
        <div className="locationReportWrapper">
          <div className="locationDetail-container">
            <LocationDetails
              count={locCount}
              percent={100}
              data={surveyReportData}
            />
          </div>
          <div className="locationReportList">
            {locationData?.map((locData, locIndex) => {
              return (
                <div className="genderReportList-container">
                  <article className="displayAgeAndGenderListWrapper">
                    <span
                      className={`circleChart bgColor${locIndex + 1}`}
                    ></span>
                    <p>{locData}</p>
                  </article>
                  <article
                    className={`locationReportNumberAndPercentContainer textColor${
                      locIndex + 1
                    }`}
                  >
                    <p>{surveyReportData?.locationNumber?.[locData]}</p>
                    <p>{surveyReportData?.location?.[locData]}%</p>
                  </article>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="departmantReportContainer">
        <div className="departmentReportWrapper">
          <div className="departmentDetail-container">
            <DepartmentDetails
              count={deptCount}
              percent={100}
              data={surveyReportData}
            />
          </div>
          <div className="departmentReportList">
            {departmentData?.map((deptData, deptIndex) => {
              return (
                <div className="genderReportList-container">
                  <article className="displayAgeAndGenderListWrapper">
                    <span
                      className={`circleChart bgColor${deptIndex + 1}`}
                    ></span>
                    <p>{deptData}</p>
                  </article>
                  <article
                    className={`locationReportNumberAndPercentContainer textColor${
                      deptIndex + 1
                    }`}
                  >
                    <p>{surveyReportData?.departmentNumber?.[deptData]}</p>
                    <p>{surveyReportData?.department?.[deptData]}%</p>
                  </article>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Report;
