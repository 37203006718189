import React from "react";
import { Table, TableBody, TableHead, TableRow } from '@mui/material'
import { FieldArray, Form, Formik } from 'formik'

import { StyledTableCell, StyledTableCont } from '../../../../Components/FormComponent/Repeated'
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import { useEffect } from "react";


export const NotiFLowForm = ({detailsform}) => {
  useEffect(()=>{
    document.title = `PeopleSol - Notification Flow`;
    return () => {
      document.title = 'PeopleSol';
    };
  })

  return (
    <>
      <div>
      <Formik
      enableReinitialize={true}
      initialValues={{ title:  detailsform?.notificationFlowsIntervals[0]?.title,
          areminder1: detailsform?.notificationFlowsIntervals[0]?.reminder1,  
          areminder2: detailsform?.notificationFlowsIntervals[0]?.reminder2, 
          areminder3: detailsform?.notificationFlowsIntervals[0]?.reminder3, 
          aescalation1: detailsform?.notificationFlowsIntervals[0]?.escalation1, 
          aescalation2: detailsform?.notificationFlowsIntervals[0]?.escalation2, 
          aescalation3: detailsform?.notificationFlowsIntervals[0]?.escalation3, 
          aautoApproveAfter: detailsform?.notificationFlowsIntervals[0]?.autoApproveAfter,

          breminder1: detailsform?.notificationFlowsIntervals[1]?.reminder1,  
          breminder2: detailsform?.notificationFlowsIntervals[1]?.reminder2, 
          breminder3: detailsform?.notificationFlowsIntervals[1]?.reminder3, 
          bescalation1: detailsform?.notificationFlowsIntervals[1]?.escalation1, 
          bescalation2: detailsform?.notificationFlowsIntervals[1]?.escalation2, 
          bescalation3: detailsform?.notificationFlowsIntervals[1]?.escalation3, 
          bautoApproveAfter: detailsform?.notificationFlowsIntervals[1]?.autoApproveAfter,

          creminder1: detailsform?.notificationFlowsIntervals[2]?.reminder1,  
          creminder2: detailsform?.notificationFlowsIntervals[2]?.reminder2, 
          creminder3: detailsform?.notificationFlowsIntervals[2]?.reminder3, 
          cescalation1: detailsform?.notificationFlowsIntervals[2]?.escalation1, 
          cescalation2: detailsform?.notificationFlowsIntervals[2]?.escalation2, 
          cescalation3: detailsform?.notificationFlowsIntervals[2]?.escalation3, 
          cautoApproveAfter: detailsform?.notificationFlowsIntervals[2]?.autoApproveAfter,
          }} onSubmit={()=>{}} >
          <Form>

         

    
              <StyledTableCont>
                <Table>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell
                        padding="0"
                        style={{
                          fontSize: "12px",
                          fontWeight:"bolder"
                        }}
                        align="center"
                      >
                        Priority
                      </StyledTableCell>
                      <StyledTableCell
                        padding="0"
                        style={{
                          fontSize: "12px",
                          fontWeight:"bolder"
                        }}
                        align="center"
                      >
                          Reminder 1 (In Hrs)
                      </StyledTableCell>
                   
                      <StyledTableCell
                        style={{
                          fontSize: "12px",
                          fontWeight:"bolder"
                        }}
                        align="center"
                      >
                        Reminder 2 (In Hrs)
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          fontSize: "12px",
                          fontWeight:"bolder"
                        }}
                        align="center"
                      >
                        Reminder 3 (In Hrs)
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          fontSize: "12px",
                          fontWeight:"bolder"
                        }}
                        align="center"
                      >
                        Escalation 1 (In Hrs)
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          fontSize: "12px",
                          fontWeight:"bolder"
                        }}
                        align="center"
                      >
                        Escalation 2 (In Hrs)
                      </StyledTableCell>
                      
                      <StyledTableCell
                        style={{
                          fontSize: "12px",
                          fontWeight:"bolder"
                        }}
                        align="center"
                      >
                        Escalation 3 (In Hrs)
                      </StyledTableCell>

                      <StyledTableCell
                        style={{
                          fontSize: "12px",
                          fontWeight:"bolder"
                        }}
                        align="center"
                      >
                        Auto Approve After
                      </StyledTableCell>
                      
                    </TableRow>
                  </TableHead>
                  {/* {items?.map((items, index) => ( */}
                    <TableBody 
                    // key={index}
                    >
                      <TableRow>

                        {/* 1st column */}
                      <StyledTableCell align="center" style={{
                          fontSize: "12px",
                          fontWeight:"bolder"
                        }}>
                        {/* {index == 0} */}
                        High
                      </StyledTableCell>

                      {/* 2nd column */}
                      <StyledTableCell>
                      <InputCustomFormik 
                      disabled
                          type='number'
                          name={`areminder1`}   
                          
                          placeholder={" Enter Value  "}
                        />

                      </StyledTableCell>

                      {/* 3rd column */}
                      <StyledTableCell>
                      <InputCustomFormik 
                      disabled
                          type='number'
                          name={`areminder2`}  
                          
                          placeholder={" Enter Value  "}
                        />
                       
                      </StyledTableCell>

                      {/* 4th column */}
                      <StyledTableCell>
                      <InputCustomFormik 
                      disabled
                          type='number'
                          name={`areminder3`}  
                          
                          placeholder={" Enter Value  "}
                        />
                        
                      </StyledTableCell>

                      {/* 5th column */}
                      <StyledTableCell>
                      <InputCustomFormik 
                      disabled
                          type='number'
                          name={`aescalation1`}   
                          
                          placeholder={" Enter Value  "}
                        />
                      </StyledTableCell>

                      {/* 6th column */}
                      <StyledTableCell>
                      <InputCustomFormik 
                      disabled
                          type='number'
                          name={`aescalation2`}  
                          
                          placeholder={" Enter Value  "}
                        />
                      </StyledTableCell>

                      {/* 7th column */}
                      <StyledTableCell>
                        <InputCustomFormik 
                        disabled
                        type='number'
                        name={`aescalation3`}  
                        
                        placeholder={" Enter Value  "}
                        />
                      </StyledTableCell>
                      {/* 8th Column */}
                      <StyledTableCell>
                        <InputCustomFormik 
                        disabled
                        type='number'
                        name={`aautoApproveAfter`}  
                        
                        placeholder={" Enter Value  "}
                        />
                      </StyledTableCell>
                      </TableRow>

                      {/* Medium Row */}

                      <TableRow>

                        {/* 1st column */}
                      <StyledTableCell align="center" style={{
                          fontSize: "12px",
                          fontWeight:"bolder"
                        }}>
                        {/* {index == 1} */}
                        Medium
                      </StyledTableCell>

                      {/* 2nd column */}
                      <StyledTableCell>
                      <InputCustomFormik 
                      disabled
                          type='number'
                          name={`breminder1`}   
                          
                          placeholder={" Enter Value  "}
                        />

                      </StyledTableCell>

                      {/* 3rd column */}
                      <StyledTableCell>
                      <InputCustomFormik 
                      disabled
                          type='number'
                          name={`breminder2`}  
                          
                          placeholder={" Enter Value  "}
                        />
                       
                      </StyledTableCell>

                      {/* 4th column */}
                      <StyledTableCell>
                      <InputCustomFormik 
                      disabled
                          type='number'
                          name={`breminder3`}  
                          
                          placeholder={" Enter Value  "}
                        />
                        
                      </StyledTableCell>

                      {/* 5th column */}
                      <StyledTableCell>
                      <InputCustomFormik 
                      disabled
                          type='number'
                          name={`bescalation1`}   
                          
                          placeholder={" Enter Value  "}
                        />
                      </StyledTableCell>

                      {/* 6th column */}
                      <StyledTableCell>
                      <InputCustomFormik 
                      disabled
                          type='number'
                          name={`bescalation2`}  
                          
                          placeholder={" Enter Value  "}
                        />
                      </StyledTableCell>

                      {/* 7th column */}
                      <StyledTableCell>
                        <InputCustomFormik 
                        disabled
                        type='number'
                        name={`bescalation3`}  
                        
                        placeholder={" Enter Value  "}
                        />
                      </StyledTableCell>
                      {/* 8th Column */}
                      <StyledTableCell>
                        <InputCustomFormik 
                        disabled
                        type='number'
                        name={`bautoApproveAfter`}  
                        
                        placeholder={" Enter Value  "}
                        />
                      </StyledTableCell>
                      </TableRow>

                      {/* Low Row */}

                      <TableRow>

                      {/* 1st column */}
                      <StyledTableCell align="center" style={{
                          fontSize: "12px",
                          fontWeight:"bolder"
                        }}>
                      {/* {index == 2} */}
                      Low
                      </StyledTableCell>

                      {/* 2nd column */}
                      <StyledTableCell>
                      <InputCustomFormik 
                      disabled
                        type='number'
                        name={`creminder1`}   
                        
                        placeholder={" Enter Value  "}
                      />

                      </StyledTableCell>

                      {/* 3rd column */}
                      <StyledTableCell>
                      <InputCustomFormik 
                      disabled
                        type='number'
                        name={`creminder2`}  
                        
                        placeholder={" Enter Value  "}
                      />

                      </StyledTableCell>

                      {/* 4th column */}
                      <StyledTableCell>
                      <InputCustomFormik 
                      disabled
                        type='number'
                        name={`creminder3`}  
                        
                        placeholder={" Enter Value  "}
                      />

                      </StyledTableCell>

                      {/* 5th column */}
                      <StyledTableCell>
                      <InputCustomFormik 
                      disabled
                        type='number'
                        name={`cescalation1`}   
                        
                        placeholder={" Enter Value  "}
                      />
                      </StyledTableCell>

                      {/* 6th column */}
                      <StyledTableCell>
                      <InputCustomFormik 
                      disabled
                        type='number'
                        name={`cescalation2`}  
                        
                        placeholder={" Enter Value  "}
                      />
                      </StyledTableCell>

                      {/* 7th column */}
                      <StyledTableCell>
                      <InputCustomFormik 
                      disabled
                      type='number'
                      name={`cescalation3`}  

                      placeholder={" Enter Value  "}
                      />
                      </StyledTableCell>
                      {/* 8th Column */}
                      <StyledTableCell>
                      <InputCustomFormik 
                      disabled
                      type='number'
                      name={`cautoApproveAfter`}  

                      placeholder={" Enter Value  "}
                      />
                      </StyledTableCell>
                      </TableRow>

                    </TableBody>
           
                </Table>
              </StyledTableCont>
          
              </Form>
              </Formik>
      </div>
    </>
  );
};
