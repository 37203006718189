import React, { useState } from "react";
import "./Community.css";
import { Popup } from "../../../../../Components/FormComponent/PopupComponent/Popup";
import SearchMagnifierIcon from "../../../../../Assets/SearchMagnifierIcon.svg";
import SocialCommunityNewProfileCameraIcon from "../../../../../Assets/SocialCommunityNewProfileCameraIcon.svg";
import SocialCommunityNewProfileImage from "../../../../../Assets/SocialCommunityNewProfileImage.svg";
import SocialEditIcon from "../../../../../Assets/SocialEditIcon.svg";
import ArrowBackButton from "../../../../../Assets/ArrowBackButton.svg";
import ProfileAvatar from "../../../../../Assets/ProfileAvatar.svg";
import SocialPostOrganizationIcon from "../../../../../Assets/SocialPostOrganizationIcon.svg";
import SocialPostTeamsIcon from "../../../../../Assets/SocialPostTeamsIcon.svg";
import { Form, Formik } from "formik";
import { LabelCustom } from "../../../../../Components/FormComponent/LabelCustom";
import { TextAreaFormik } from "../../../../../Components/FormComponent/TextAreaFormik";
import { InputCustomFormik } from "../../../../../Components/FormComponent/InputCustomFormik";
import { IconButton } from "@mui/material";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getRequest, postRequestForm } from "../../../../../Services/axios";
import { showToast } from "../../../../../Features";
import { Loader } from "../../../../../Components/FormComponent/Loader";
import { useEffect } from "react";
import { profileImages } from "../../../../../Utilities/profileAndUploadImages";
import { getUserInitials } from "../../../../../Utilities/socialAllFunctions";

function CreateCommunity({ getCommunityData, setIsCreateCommunityActive }) {
  const [loading, setLoading] = useState(false);
  const [selectedParticipant, setSelectedParticipant] = useState([]);
  const [selectedParticipantId, setSelectedParticipantId] = useState([]);
  const [communityCreationActive, setCommunityCreationActive] = useState(false);
  const [isEmployeeSelectActive, setIsEmployeeSelectActive] = useState(false);
  const [communityTypeActive, setCommunityTypeActive] = useState(true);
  const [communityType, setCommunityType] = useState("public");
  const [newCommunityProfileImg, setNewCommunityProfileImg] = useState(null);
  const [allEmployeeList, setAllEmployeeList] = useState([]);
  const [selectEmployee, setSelectEmployee] = useState([]);
  const [storedEmployeeId, setStoredEmployeeId] = useState([]);
  const dispatch = useDispatch();
  const { LoggedInUser } = useSelector((state) => state.auth);
  function searchChatters(e) {
    if (e.target.value !== "") {
      let tempChatters = JSON.parse(JSON.stringify(selectEmployee));
      tempChatters = tempChatters.filter(
        (d, i) =>
          d?.name
            ?.toLowerCase()
            ?.includes(e.currentTarget.value?.toLowerCase()) &&
          !storedEmployeeId?.includes(d?.id)
      );

      if (tempChatters?.length <= 0) {
        setAllEmployeeList(undefined);
      } else {
        setAllEmployeeList(tempChatters);
      }
    } else {
      setAllEmployeeList(selectEmployee);
    }
  }

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setNewCommunityProfileImg(file);
  };
  function getDisplayName(nameWithPersonNumber) {
    let index = nameWithPersonNumber?.indexOf("(");
    let name = nameWithPersonNumber?.substring(0, index);
    return name;
  }
  const getEmployeeData = async () => {
    try {
      const result = await getRequest("Social/GetEmployeesImageDropDown");
      let x = result?.data?.filter((d) => {
        if (d?.id !== LoggedInUser?.id) {
          return d;
        }
      });
      setAllEmployeeList(x);
      setSelectEmployee(x);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  const createCommunity = async (values) => {
    setLoading(true);
    try {
      const result = await postRequestForm("Community/Create", values);
      if (!result?.data?.isSuccess) {
        dispatch(
          showToast({
            text: result?.data?.message,
            severity: "error",
          })
        );
        return;
      }
      getCommunityData();
      dispatch(
        showToast({
          text: "Community Created successfully!",
          severity: "success",
        })
      );
      setIsEmployeeSelectActive(false);
      setIsCreateCommunityActive(false);
      setCommunityTypeActive(false);
      setCommunityType("public");
      setSelectedParticipant([]);
      setSelectedParticipantId([]);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in creating community",
          severity: "error",
        })
      );
      return;
    } finally {
      setLoading(false);
    }
  };
  const validationSchema = Yup.object({
    communityName: Yup.string().required("Please Enter Community Name"),
    communityDescription: Yup.string().required(
      "Please enter description about your community"
    ),
  });
  const onSubmit = (values) => {
    let templist = [];
    selectedParticipant.forEach((item, index) => {
      templist.push(item.id);
    });
    const formData = new FormData();
    formData.append("CommunityName", values.communityName?.trim());
    formData.append("Upload", newCommunityProfileImg);
    selectedParticipant.forEach((item, index) => {
      templist.push(item.id);
      formData.append("MemberIds[" + index + "]", item.id);
    });
    formData.append("About", values.communityDescription?.trim());
    formData.append("AdminIds", LoggedInUser.id);
    formData.append("CommunityType", communityType);
    createCommunity(formData);
  };

  useEffect(() => {
    getEmployeeData();
  }, []);
  return (
    <div>
      {communityTypeActive && (
        <Popup
          firstClassName="selectPostTypePopupBox1 selectCommunityTypePopupBox1"
          secondClassName="selectPostTypePopupBox2 selectCommunityTypePopupBox2"
          popupHeading="Select Community Type"
          content={
            <div className="selectPostTypeContainer">
              <div className="selectPostTypeWrapper">
                <div className="selectPostOrganizationContainer">
                  <div>
                    <article className="createPostTypeImgContainer">
                      <img src={SocialPostOrganizationIcon} alt="" />
                    </article>
                    <span>Public</span>
                  </div>
                  <div>
                    <label class="configContainer">
                      <input
                        type="radio"
                        checked={communityType === "public" ? true : false}
                        name="attenAllowFromWeb"
                        onChange={() => {
                          setCommunityType("public");
                        }}
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>
                <div className="selectPostTeamContainer">
                  <div>
                    <article className="createPostTypeImgContainer">
                      <img
                        src={SocialPostTeamsIcon}
                        alt=""
                        style={{ height: "20px", paddingTop: "4px" }}
                      />
                    </article>
                    <span>Private</span>
                  </div>
                  <div>
                    <label class="configContainer">
                      <input
                        type="radio"
                        checked={communityType === "private" ? true : false}
                        name="attenAllowFromWeb"
                        onChange={() => {
                          setCommunityType("private");
                        }}
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>
              </div>
              {!loading && (
                <div
                  className="socialPostDeleteBtn"
                  style={{ marginTop: "20px" }}
                >
                  <button
                    onClick={() => {
                      if (communityType === "public") {
                        setCommunityCreationActive(true);
                      } else {
                        setIsEmployeeSelectActive(true);
                      }
                      setCommunityTypeActive(false);
                    }}
                  >
                    Next
                  </button>
                  <button
                    onClick={() => {
                      setCommunityTypeActive(false);
                      setCommunityType("public");
                    }}
                    style={{ border: "1px solid #1296B0", color: "#1296B0" }}
                  >
                    Back
                  </button>
                </div>
              )}
            </div>
          }
          handleClose={() => {
            setCommunityTypeActive(false);
            setCommunityType("public");
            setIsCreateCommunityActive(false);
          }}
        />
      )}
      {isEmployeeSelectActive && (
        <Popup
          firstClassName="SelectCommunityMembers1"
          secondClassName="SelectCommunityMembers2"
          contentClassName="SelectCommunityMemberContent"
          bodyClassName="SelectCommunityMembersBody"
          popupHeading={
            <div style={{ display: "flex", alignItems: "center" }}>
              <IconButton
                onClick={() => {
                  setIsEmployeeSelectActive(false);
                  setCommunityTypeActive(true);
                }}
              >
                <img
                  src={ArrowBackButton}
                  alt=""
                  style={{ margin: "5px 3px", cursor: "pointer" }}
                />
              </IconButton>
              <h5>Select Community Members</h5>
            </div>
          }
          content={
            <div className="newCommunityContainer">
              <div className="communityEmployeeSearchContainer">
                {selectedParticipant?.length > 0 && (
                  <div className="communitySelectedEmployeeContainer">
                    {selectedParticipant.map((item, index) => {
                      return (
                        <div className="communitySelectChipContainer">
                          <div
                            className={`${
                              item?.image
                                ? "socialProfilePicContainer"
                                : "socialCustomProfilePic"
                            }`}
                            style={{
                              width: "30px",
                              height: "30px",
                              margin: "0",
                              fontSize: "14px",
                            }}
                          >
                            {!item?.image ? (
                              getUserInitials(getDisplayName(item?.name))
                            ) : (
                              <img
                                className="commentProfilePic"
                                style={{ width: "28px", height: "28px" }}
                                onClick={() => {
                                  // handleUserProfileClick(d?.id);
                                }}
                                src={
                                  process.env.REACT_APP_.BASE_URL +
                                  profileImages +
                                  item?.image
                                }
                                alt="Profile"
                                onError={(e) => {
                                  e.target.src = ProfileAvatar;
                                }}
                              />
                            )}
                          </div>
                          <label className="chipLabel">
                            {getDisplayName(item.name)}
                          </label>
                          <IconButton
                            onClick={() => {
                              let tempList = JSON.parse(
                                JSON.stringify(selectedParticipant)
                              );
                              tempList = tempList.filter((selectedValue) => {
                                return (
                                  Number(selectedValue.id) !== Number(item.id)
                                );
                              });
                              let z = JSON.parse(
                                JSON.stringify(storedEmployeeId)
                              );
                              z = z.filter((storedId) => {
                                return Number(storedId) !== Number(item?.id);
                              });
                              setSelectedParticipant(tempList);

                              setStoredEmployeeId(z);
                              setAllEmployeeList([...allEmployeeList, item]);
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="10"
                              height="9"
                              viewBox="0 0 10 9"
                              fill="none"
                            >
                              <line
                                x1="8.11133"
                                y1="1.68654"
                                x2="1.44905"
                                y2="8.34882"
                                stroke="#555555"
                                stroke-linecap="round"
                              />
                              <line
                                x1="0.5"
                                y1="-0.5"
                                x2="9.92189"
                                y2="-0.5"
                                transform="matrix(0.707107 0.707107 0.707107 -0.707107 1.67139 0.979431)"
                                stroke="#555555"
                                stroke-linecap="round"
                              />
                            </svg>
                          </IconButton>
                        </div>
                      );
                    })}
                  </div>
                )}
                <div className="chatListSearchContainer">
                  <input
                    type="text"
                    className={`chatListSearch ${
                      selectedParticipant?.length > 0
                        ? "communityListSearch"
                        : null
                    }`}
                    placeholder="Search"
                    onChange={searchChatters}
                  />
                  <img
                    src={SearchMagnifierIcon}
                    alt=""
                    style={{ marginRight: "15px", cursor: "pointer" }}
                  />
                </div>
              </div>
              <div
                style={{ width: "100%", borderBottom: "1px solid #EEE" }}
              ></div>
              {allEmployeeList?.length <= 0 &&
                selectedParticipant?.length <= 0 && <Loader />}
              {allEmployeeList?.length > 0 && (
                <div className="communityMemberListBox newScrollbar">
                  {allEmployeeList &&
                    allEmployeeList?.map((item, index) => (
                      <div
                        onClick={() => {
                          let searchSelectedParticipant =
                            selectedParticipant?.find(
                              (d) => d?.id === item?.id
                            );
                          if (!searchSelectedParticipant)
                            setSelectedParticipant([
                              ...selectedParticipant,
                              item,
                            ]);
                          let z = JSON.parse(JSON.stringify(storedEmployeeId));
                          z.push(item?.id);
                          setStoredEmployeeId(z);
                          let tempList = JSON.parse(
                            JSON.stringify(allEmployeeList)
                          );
                          tempList = tempList.filter((selectedValue) => {
                            return Number(selectedValue.id) !== Number(item.id);
                          });
                          setAllEmployeeList(tempList);
                        }}
                        className="communityAllEmployeeListContainer"
                      >
                        <div className="newChatActiveImage">
                          <div
                            className={`${
                              item?.image
                                ? "socialProfilePicContainer"
                                : "socialCustomProfilePic"
                            }`}
                            style={{
                              width: "50px",
                              height: "50px",
                              margin: "0",
                            }}
                          >
                            {!item?.image ? (
                              getUserInitials(getDisplayName(item?.name))
                            ) : (
                              <img
                                className="commentProfilePic"
                                onClick={() => {
                                  // handleUserProfileClick(d?.id);
                                }}
                                src={
                                  process.env.REACT_APP_.BASE_URL +
                                  profileImages +
                                  item?.image
                                }
                                alt="Profile"
                                onError={(e) => {
                                  e.target.src = ProfileAvatar;
                                }}
                              />
                            )}
                          </div>
                        </div>
                        <div className="newChatPostMainTextContainer">
                          <label className="chatListName">
                            {getDisplayName(item?.name)}
                          </label>
                          <label className="chatListRole">
                            {item?.jobRoal}
                          </label>
                          <label className="chatListRole">
                            {item?.location}
                          </label>
                        </div>
                      </div>
                    ))}
                </div>
              )}
              {((selectedParticipant?.length > 0 &&
                allEmployeeList?.length <= 0) ||
                allEmployeeList === undefined) && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    height: "100%",
                    color: "#bfbfbf",
                    marginTop: "20%",
                  }}
                >
                  No Records Found!
                </div>
              )}
              {selectedParticipant.length > 0 && (
                <div
                  className="groupCreationNxtBtn"
                  onClick={() => {
                    setCommunityCreationActive(true);
                    setIsEmployeeSelectActive(false);
                    // setNewGroupList(false);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="37"
                    height="37"
                    viewBox="0 0 37 37"
                    fill="none"
                  >
                    <circle cx="18.6455" cy="18.8914" r="18" fill="#1296B0" />
                    <path
                      d="M27.8232 19.1169L21.3721 12.5392M27.8232 19.1169L21.3721 24.9637M27.8232 19.1169H9.18649"
                      stroke="white"
                      stroke-width="1.10154"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              )}
            </div>
          }
          handleClose={() => {
            setIsEmployeeSelectActive(false);
            setIsCreateCommunityActive(false);
            setSelectedParticipant([]);
            setSelectedParticipantId([]);
          }}
        />
      )}
      {communityCreationActive && (
        <Popup
          firstClassName="CreateCommunityPopupBox1"
          secondClassName="CreateCommunityPopupBox2"
          bodyClassName="SelectCommunityMembersBody newScrollbar"
          popupHeading={
            <div>
              <img
                src={ArrowBackButton}
                alt=""
                style={{ marginRight: "15px", cursor: "pointer" }}
                onClick={() => {
                  setCommunityTypeActive(true);
                  setCommunityCreationActive(false);
                }}
              />
              <span>Create Community</span>
            </div>
          }
          content={
            <div className="createCommunityMainContainer">
              <div className="createCommunityDefaultProfileImgContainer">
                <img src={SocialCommunityNewProfileImage} alt="" />
                <div
                  className="createCommunityImageInputContainer"
                  onClick={() => {
                    document.querySelector("#newGroupProfileImgFile").click();
                  }}
                >
                  {newCommunityProfileImg === null && (
                    <img src={SocialCommunityNewProfileCameraIcon} alt="" />
                  )}
                  {newCommunityProfileImg !== null && (
                    <img src={SocialEditIcon} alt="" />
                  )}
                  <input
                    type="file"
                    id="newGroupProfileImgFile"
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                    multiple
                    accept=".jpg,.png,.gif,.jpeg"
                  />
                </div>
              </div>
              <div
                className="createCommunityProfileImgContainer"
                id="newGroupProfileImg"
              >
                {newCommunityProfileImg !== null && (
                  <img
                    src={URL.createObjectURL(newCommunityProfileImg)}
                    alt=""
                    style={{ top: "5px", left: "3px" }}
                  />
                )}
              </div>
              <div className="createCommunityTextContainer">
                <Formik
                  initialValues={{
                    communityName: "",
                    communityDescription: "",
                  }}
                  validationSchema={validationSchema}
                  onSubmit={onSubmit}
                >
                  <Form>
                    <LabelCustom
                      labelName={"Community Name"}
                      mandatory={true}
                    />
                    <InputCustomFormik
                      placeholder={"Enter Your Community Name"}
                      type="text"
                      name="communityName"
                    />
                    <LabelCustom labelName={"Description"} mandatory={true} />
                    <TextAreaFormik
                      maxLength={500}
                      placeholder="What is the purpose of your community?"
                      style={{ height: "120px" }}
                      // onChange={(e) => {}}
                      name="communityDescription"
                    />
                    <div className="createCommunityBottomconatiner">
                      {!loading && (
                        <button
                          className="button primaryButton"
                          type="submit"
                          // style={{ background: color, color: "white" }}
                        >
                          Create
                        </button>
                      )}
                      {loading && (
                        <div
                          style={{
                            fontWeight: "500",
                            color: "#bfbfbf",
                            paddingBottom: "20px",
                            paddingRight: "10px",
                          }}
                        >
                          Creating...
                        </div>
                      )}
                    </div>
                  </Form>
                </Formik>
              </div>
            </div>
          }
          handleClose={() => {
            setIsEmployeeSelectActive(false);
            setIsCreateCommunityActive(false);
            setSelectedParticipant([]);
            setSelectedParticipantId([]);
          }}
        />
      )}
    </div>
  );
}

export default CreateCommunity;
