import React from "react";
import { PayAreaTab } from "./PayAreaTab";
import { useEffect } from "react";

const PayArea = () => {
  useEffect(() => {
    document.title = `PeopleSol - Pay Area`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  return (
    <div className="moduleMainContainer">
      <PayAreaTab title="Pay Area" />
    </div>
  );
};
export { PayArea };
