import React from "react";
import SurvayMainDisplayList from "./SurvayMainDisplayList";

function SurveyPage() {
  return (
    <div className="surveyPageContainer">
      <div className="surveyPageWrapper">
        <SurvayMainDisplayList type="survey" />
      </div>
    </div>
  );
}

export default SurveyPage;
