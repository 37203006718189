/* eslint-disable react-hooks/exhaustive-deps */
import { IconButton, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import { isLoading, showToast } from "../../../../Features";
import { getRequest, postRequest } from "../../../../Services/axios";
import { Popup } from "../../../../Components/FormComponent/PopupComponent/Popup";
import { Remark } from "../../../../Components/FormComponent/PopupComponent/Remark";
import { Details } from "../../../HRMS/Components/DetailsComp/Details";
import { Notifications } from "../../../HRMS/Components/DetailsComp/Notifications";
import { TimeLineData } from "../../../HRMS/Components/TransferComponents/DetailsComp/Timeline/TimeLineData";
import { format } from "date-fns";
import UploadDetail from "../../../../Components/UploadDetail/UploadDetail";
import { Loader } from "../../../../Components/FormComponent/Loader";
import UploadImageDetail from "../../../../Components/UploadDetail/UploadImageDetail";
import { getStatusColor } from "../../../../Utilities/getStatusColor";
import { Verify } from "./Verify";
import { useSelector } from "react-redux";
import { getBadgeCount } from "../../../../Features/badgeUtils";
const ReimbursmentDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const returnPage = () => {
    if (state?.returnUrl) navigate(state?.returnUrl);
    else navigate(-1);
  };
  const [typeAction, setTypeAction] = useState("");
  const [colorAction, setColorAction] = useState("");
  const closeDetails = () => {
    returnPage();
  };
  const currentUrl = window.location.href;
  function getIdFromDynamicUrl(url) {
    const urlObject = new URL(url);
    const segments = urlObject.pathname
      .split("/")
      .filter((segment) => segment !== "");
    const idSegmentIndex = segments.indexOf("details");
    if (idSegmentIndex !== -1 && idSegmentIndex < segments.length - 1) {
      return segments[idSegmentIndex + 1];
    }
    return null; // Return null if id not found in the URL
  }
  const { LoggedInUser } = useSelector((state) => state.auth);

  function extractValuesFromURL(url) {
    const regex = /\/details\/(\d+)\/\?action=(approve|reject|hold)&eid=(\d+)/;
    const matches = url.match(regex);

    if (matches) {
      const value1 = matches[1]; // "246"
      const approve = matches[2]; // "approve"
      const value2 = Number(matches[3]); // "16"

      return { value1, approve, value2 };
    } else {
      return { value1: null, approve: null, value2: null };
    }
  }
  useEffect(() => {
    document.title = `PeopleSol - Reimbursement Details`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const { value2, approve } = extractValuesFromURL(currentUrl);

  const [selId, setSelId] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const togglePopup = (type, color, id) => {
    setTypeAction(type);
    setColorAction(color);
    setSelId(id);
    setIsOpen((prev) => !prev);
  };
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(false);
  const getDetails = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(
        `Expenses/Details/${getIdFromDynamicUrl(currentUrl)}`
      );
      setData(result.data);
      if (approve) {
        if (
          LoggedInUser?.id === value2 &&
          result.data?.summary?.actionList.length
        ) {
          if (approve === "reject" || approve === "hold") {
            togglePopup(
              result.data?.summary?.actionList[0]?.id,
              approve,
              result.data?.summary?.actionList[0]?.color
            );
          } else
            PostApRejHol(
              approve,
              result.data?.summary?.actionList[0]?.id,
              "Remark"
            );
        }
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const clickHandlerFunction = (id, type, color) => {
    if (
      type !== "Approve" &&
      type !== "Release" &&
      type !== "Cancel" &&
      type !== "Ready for Release" &&
      type !== "Export" &&
      type !== "Confirm"
    ) {
      togglePopup(type, color, id);
    } else {
      PostApRejHol(type, id);
    }
  };
  const PostApRejHol = async (type, id, Remark) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      if (
        type === "Approve" ||
        type === "Cancel" ||
        type === "Release" ||
        type === "Export"
      ) {
        await getRequest(`Expenses/${type}/${id}`);
      } else if (type === "Ready for Release") {
        await getRequest(`Expenses/HRApprove/${id}`);
      } else if (type === "Confirm") {
        await postRequest(`Expenses/Confirm?Id=${id}`);
      } else if (type === "Send for Correction") {
        await getRequest(`Expenses/SentForCorrection/${id}/${Remark}`);
      } else {
        await getRequest(`Expenses/${type}/${id}/${Remark}`);
      }
      dispatch(showToast({ text: "Success", severity: "success" }));
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      await getBadgeCount(dispatch);
      dispatch(isLoading(false));
      setLoading(false);
      setIsOpen(false);
      getDetails();
    }
  };
  const settledAmount =
    data?.data?.advanceRequest?.advanceSalary !== undefined
      ? data?.data?.expenseDetails?.reduce(
          (sum, card) => sum + card.approvedAmount,
          0
        ) - data?.data?.advanceRequest?.advanceSalary
      : data?.data?.expenseDetails?.reduce(
          (sum, card) => sum + card.approvedAmount,
          0
        );
  useEffect(() => {
    getDetails();
  }, []);
  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };

  return (
    <div className="moduleMasterDetailsPage">
      <div
        className="moduleMasterDetailsBackground"
        onClick={() => closeDetails()}
      ></div>
      <div className="moduleMasterDetailsPageContainer tabularMasterDetailsPageContainer">
        <div className="headingContainer">
          <h3>Reimbursement Details</h3>
          <IconButton onClick={() => closeDetails()} title="close">
            <svg
              width="13"
              height="13"
              viewBox="0 0 13 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.7579 0.56897L0.757935 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M0.757935 0.56897L12.7579 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </IconButton>
        </div>
        <div className="tabularMasterDetailsPageBody">
          <div className="tabContainer">
            <Tabs
              value={tabIndex}
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
              TabIndicatorProps={{ style: { background: "#1296B0" } }}
            >
              <Tab
                style={{ minWidth: 130 }}
                label={
                  <span
                    className={`tabHeading ${tabIndex === 0 && "tabActive"}`}
                  >
                    Details
                  </span>
                }
              />
              <Tab
                style={{ minWidth: 130 }}
                label={
                  <span
                    className={`tabHeading ${tabIndex === 1 && "tabActive"}`}
                  >
                    Timeline
                  </span>
                }
              />
              <Tab
                style={{ minWidth: 130 }}
                label={
                  <span
                    className={`tabHeading ${tabIndex === 2 && "tabActive"}`}
                  >
                    Notifications
                  </span>
                }
              />
            </Tabs>
            <div className="tabContainerLine"></div>
          </div>
          <>
            {tabIndex === 0 &&
              (loading ? (
                <div className="noDataFound">
                  <Loader />
                </div>
              ) : (
                <div
                  className="detailsEReq"
                  style={{ flexDirection: "column" }}
                >
                  <div className="headingForm"> Request Details</div>
                  <div className="detailInner">
                    <Details
                      detailTitle={"Reference Number"}
                      Details={data?.data?.referenceNo}
                    />
                    <Details
                      detailTitle={"Status"}
                      Details={
                        <div
                          style={{
                            backgroundColor: getStatusColor(
                              data?.data?.status
                            )?.split(",")?.[0],
                            color: getStatusColor(data?.data?.status)?.split(
                              ","
                            )?.[1],
                            cursor: "default",
                            padding: "6px",
                            borderRadius: "6px",
                          }}
                        >
                          {data?.summary?.statusLabel}
                        </div>
                      }
                    />
                    <Details
                      detailTitle={"Travel Advance Amount Taken"}
                      Details={
                        data?.data?.advanceRequest?.advanceSalary != null
                          ? data?.data?.advanceRequest?.advanceSalary.toFixed(2)
                          : "-"
                      }
                    />
                    <Details
                      detailTitle={"Claimed Amount"}
                      Details={data?.data?.expenseDetails
                        ?.reduce((sum, card) => sum + card.amount, 0)
                        .toFixed(2)}
                    />
                    {(data?.data?.status === "Ready for Release" ||
                      data?.data?.status === "Released") && (
                      <>
                        <Details
                          detailTitle={"Approved Claimed Amount"}
                          Details={data?.data?.expenseDetails
                            ?.reduce(
                              (sum, card) => sum + card.approvedAmount,
                              0
                            )
                            .toFixed(2)}
                        />
                        <Details
                          detailTitle={"Final Amount To Be Received"}
                          Details={settledAmount.toFixed(2)}
                        />
                      </>
                    )}
                    <Details
                      detailTitle={"Reimbursment Submission Date"}
                      Details={
                        data?.data?.submissionDate
                          ? format(
                              new Date(data?.data?.submissionDate),
                              "dd/MM/yyyy hh:mm a"
                            )
                          : "-"
                      }
                    />
                    <Details
                      detailTitle={"Last Updated On"}
                      Details={
                        data?.data?.lastUpdatedOn
                          ? format(
                              new Date(data?.data?.lastUpdatedOn),
                              "dd/MM/yyyy hh:mm a"
                            )
                          : "-"
                      }
                    />
                  </div>
                  <div className="headingForm"> Requester's Details</div>
                  <div className="detailInner">
                    <Details
                      detailTitle={"Requester Name"}
                      Details={`${data?.data?.employee?.name} ${data?.data?.employee?.lastName}`}
                    />
                    <Details
                      detailTitle={"Requester's Manager"}
                      Details={data?.data?.employee?.rO_Name}
                    />
                    <Details
                      detailTitle={"Requester Designation"}
                      Details={data?.data?.employee?.designation}
                    />
                    <Details
                      detailTitle={"Requester Department"}
                      Details={data?.data?.employee?.department}
                    />
                    <Details
                      detailTitle={"Requester Email"}
                      Details={data?.data?.employee?.email}
                    />
                    <Details
                      detailTitle={"Contact Number"}
                      Details={data?.data?.employee?.personalPhoneNumber}
                    />
                    <Details
                      detailTitle={"Location"}
                      Details={data?.data?.employee?.locationName}
                    />
                  </div>
                  <div className="headingForm"> Claimed Details</div>
                  <div className="detailInner">
                    {data?.data?.status !== "Ready for Release" &&
                    data?.data?.status !== "Released" ? (
                      <table
                        className="tabless tabless-bordered"
                        style={{ width: "100%" }}
                      >
                        <thead
                          style={{ backgroundColor: "var(--primary-hover)" }}
                        >
                          <tr>
                            <th width="3%">Expense Date</th>
                            <th width="3%">Expense Type</th>
                            <th width="3%">Claimed Amount (Rs)</th>
                            <th width="3%">Is Receipt Available?</th>
                            <th width="3%"> Receipt</th>
                            <th width="3%"> Remarks</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data?.data?.expenseDetails?.map((item, index) => {
                            return (
                              <tr>
                                <td>
                                  {item.date
                                    ? format(new Date(item.date), "dd/MM/yyyy")
                                    : "-"}
                                </td>
                                <td>{item?.expenseType?.title}</td>
                                <td>{item?.amount}</td>
                                <td>{item?.isReceiptAvailable}</td>
                                <td>
                                  {item?.isReceiptAvailable === "Yes" ? (
                                    item.attachment != null ? (
                                      <UploadDetail
                                        filepath={`Expenses/${item?.attachment}`}
                                      />
                                    ) : (
                                      "-"
                                    )
                                  ) : (
                                    item?.justification
                                  )}
                                </td>
                                <td>{item?.remarks}</td>
                              </tr>
                            );
                          })}
                          <tr class="receipt_grand_total">
                            <td>
                              <b>Total Claimed Amount </b>
                            </td>
                            <td>
                              {data?.data?.expenseDetails?.reduce(
                                (sum, card) => sum + card.amount,
                                0
                              )}
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                          </tr>
                        </tbody>
                      </table>
                    ) : (
                      <table
                        className="tabless tabless-bordered"
                        style={{ width: "100%" }}
                      >
                        <thead
                          style={{ backgroundColor: "var(--primary-hover)" }}
                        >
                          <tr>
                            <th width="3%">Expense Date</th>
                            <th width="3%">Expense Type</th>
                            <th width="3%">Claimed Amount (Rs)</th>
                            <th width="3%">Approved Amount (Rs)</th>
                            <th width="3%">Reason</th>
                            <th width="3%">Is Receipt Available?</th>
                            <th width="3%"> Receipt</th>
                            <th width="3%"> Remarks</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data?.data?.expenseDetails?.map((item, index) => {
                            return (
                              <tr>
                                <td>
                                  {item.date
                                    ? format(new Date(item.date), "dd/MM/yyyy")
                                    : "-"}
                                </td>
                                <td>{item?.expenseType?.title}</td>
                                <td>{item?.amount}</td>
                                <td>{item?.approvedAmount}</td>
                                <td>{item?.reason}</td>
                                <td>{item?.isReceiptAvailable}</td>
                                <td>
                                  {item?.isReceiptAvailable === "Yes" ? (
                                    item.attachment != null ? (
                                      <UploadDetail
                                        filepath={`Expenses/${item?.attachment}`}
                                      />
                                    ) : (
                                      "-"
                                    )
                                  ) : (
                                    item?.justification
                                  )}
                                </td>
                                <td>{item?.remarks}</td>
                              </tr>
                            );
                          })}
                          <tr class="receipt_grand_total">
                            <td>
                              {" "}
                              <b>Travel Advance Amount Taken </b>
                            </td>
                            <td>
                              {" "}
                              {data?.data?.advanceRequest?.advanceSalary != null
                                ? data?.data?.advanceRequest?.advanceSalary.toFixed(
                                    2
                                  )
                                : "-"}{" "}
                            </td>
                            <td>
                              <b>Total Claimed Amount </b>
                            </td>
                            <td>
                              {data?.data?.expenseDetails
                                ?.reduce((sum, card) => sum + card.amount, 0)
                                .toFixed(2)}
                            </td>
                            <td>
                              <b>Total Approved Amount </b>
                            </td>
                            <td>
                              {data?.data?.expenseDetails
                                ?.reduce(
                                  (sum, card) => sum + card.approvedAmount,
                                  0
                                )
                                .toFixed(2)}
                            </td>
                            <td>
                              <b>Final Amount To Be Received</b>
                            </td>
                            <td>{settledAmount.toFixed(2)}</td>
                          </tr>
                        </tbody>
                      </table>
                    )}
                  </div>
                  {data?.summary?.actionList?.length > 0 ? (
                    <div
                      className="createFootDetails"
                      style={{ background: "white !important" }}
                    >
                      {data?.summary?.actionList.map((item) => (
                        <div>
                          <div
                            className="button secondaryButton"
                            onClick={() =>
                              clickHandlerFunction(
                                item?.id,
                                item?.type,
                                item?.color
                              )
                            }
                            style={{
                              background:
                                item?.type === "Export"
                                  ? "var(--primary)"
                                  : item?.color,
                              color: "white",
                            }}
                          >
                            {item?.type}
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : null}
                  {isOpen && (
                    <Popup
                      popupHeading={typeAction}
                      content={
                        <>
                          {typeAction === "Verify" ? (
                            <Verify
                              id={selId}
                              handleClose={togglePopup}
                              getListData={getDetails}
                            />
                          ) : (
                            <Remark
                              data={PostApRejHol}
                              type={typeAction}
                              id={selId}
                              color={colorAction}
                              handleClose={togglePopup}
                            />
                          )}
                        </>
                      }
                      handleClose={togglePopup}
                    />
                  )}
                </div>
              ))}
            {tabIndex === 1 && (
              <>
                <div className="tabularMasterDetailsPageTabBodyContainer">
                  <div style={{ display: "flex", width: "100%" }}>
                    <TimeLineData
                      id={getIdFromDynamicUrl(currentUrl)}
                      url={"Expenses"}
                      requestNumber={data?.data?.referenceNo}
                      module={"Expense Request"}
                    />
                  </div>
                </div>
              </>
            )}

            {tabIndex === 2 && (
              <>
                <div
                  className="tabularMasterDetailsPageTabBodyContainer"
                  style={{ flexGrow: "initial" }}
                >
                  <Notifications
                    id={getIdFromDynamicUrl(currentUrl)}
                    module={"Expense Request"}
                    //module={data?.summary?.module}
                  />
                </div>
              </>
            )}
          </>
        </div>
      </div>
    </div>
  );
};

export { ReimbursmentDetails };
