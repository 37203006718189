/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Tabs, Tab } from "@mui/material";

import "./ProjectTasksCard.css";
import { getRequest, postRequest } from "../../../../../Services/axios";
import { format } from "date-fns";
import NoRequestsFound from "./NoRequestsFound";
import { SelectCustom } from "../../../../../Components/FormComponent/SelectCustom";
import { useSelector } from "react-redux";
import { getStatusColor } from "../../../../../Utilities/getStatusColor";
import SelectCustomEmployeeCreateAttendance from "../../../../HRMS/Components/Time & Attendance/SelectCustomEmployeeCreateAttendance";

const ProjectTasksCard = ({ navigate, isManager, teamList }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const { LoggedInUser } = useSelector((state) => state.auth);
  const [reqData, setReqData] = useState({
    createdOn: null,
    lastUpdatedOn: null,
    StartsOnDate: `${format(new Date(), "dd/MM/yyyy")} ${
      new Date()
        ? format(new Date(), "dd/MM/yyyy")
        : format(new Date(), "dd/MM/yyyy")
    }`,
    status: "All",
    EmployeeId: LoggedInUser?.id,
    dataTableRequest: {
      iDisplayLength: 20,
      iDisplayStart: 0,
      isExport: false,
    },
  });

  const [loading, setLoading] = useState(false);
  const [tbodyData, setTbodyData] = useState(null);
  const getTasksData = async () => {
    let result;
    try {
      setLoading(true);
      result = await postRequest("Tasks/List", reqData);
      if (result.data.count > 0) {
        setTbodyData(JSON.parse(result.data.data));
      } else {
        setTbodyData(false);
      }
    } catch (error) {
      setTbodyData(false);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getTasksData();
  }, [reqData]);

  const [selectedEmployee, setSelectedEmployee] = useState([
    { label: "Myself", value: LoggedInUser?.id },
  ]);
  const [idActive, setIdActive] = useState(LoggedInUser?.id);
  useEffect(() => {
    if (LoggedInUser) {
      setIdActive(LoggedInUser?.id);
    }
  }, [LoggedInUser]);
  return (
    <>
      <div
        className="headingContainer cursor headingWithDropdown"
        style={{ marginBottom: "-12px", zIndex: "999" }}
      >
        <h3
          className="cursor"
          onClick={() => navigate("/hrms/projecttask/task")}
        >
          Today's Tasks
        </h3>
        {isManager && (
          <div style={{ width: "48%" }}>
            {/* <SelectCustom
              dropdownHeight="236px"
              styles={{ minWidth: "100%", width: "100%", maxWidth: "100%" }}
              values={selectedEmployee}
              options={teamList}
              onChange={(value) => {
                setSelectedEmployee(value);
                setReqData({
                  ...reqData,
                  EmployeeId:
                    value[0]?.label == "Myself"
                      ? LoggedInUser?.id
                      : value[0]?.value,
                });
              }}
              fullWidth={true}
              styl={{
                backgroundColor: "var(--primary-hover)",
                margin: 0,
                minHeight: "40px",
                maxHeight: "40px",
                padding: "5px 10px",
              }}
            /> */}
            <SelectCustomEmployeeCreateAttendance
              styles={{
                minWidth: "100%",
                width: "100%",
                maxWidth: "100%",
              }}
              selectedEmployeeList={selectedEmployee}
              setSelectedEmployeeList={setSelectedEmployee}
              values={selectedEmployee}
              setIdActive={setIdActive}
              urlProp={"Employees/GetEmployeesForAttendance"}
              lgid={LoggedInUser?.id}
              styl={{
                backgroundColor: "var(--primary-hover)",
                margin: 0,
                minHeight: "40px",
                maxHeight: "40px",
                padding: "5px 10px",
              }}
              deepStyle={{ backgroundColor: "var(--primary-hover)" }}
            />
          </div>
        )}
      </div>
      <div className="dashboardCardContentHolder">
        {tabIndex === 0 &&
          (tbodyData ? (
            <div className="approvalFrameTabData">
              <table className="teamAttendanceCard">
                <tr className="teamAttendanceTableHeader">
                  <th></th>
                  <th>Name</th>
                  <th>Deadline</th>
                  <th>Progress</th>
                  <th>Status</th>
                </tr>
                {tbodyData &&
                  tbodyData?.map((val, key) => {
                    return (
                      <tr key={key} className="teamAttendanceItemContainer">
                        <td></td>
                        <td className="projectNameItem">{val.Title ?? "--"}</td>
                        <td className="taskItem">
                          {val.EndsOn
                            ? format(new Date(val.EndsOn), "dd/MM/yyyy")
                            : "-"}
                        </td>

                        <td className="teamAttendanceItem">
                          <div className="progressHolder">
                            <p className="progressText">
                              {val.Percentage ? `${val.Percentage}%` : "--"}
                            </p>
                          </div>
                        </td>
                        <td className="teamAttendanceItem">
                          <div className="statusHolder">
                            <div
                              style={{
                                backgroundColor: getStatusColor(
                                  val?.Status
                                )?.split(",")?.[0],
                                color: getStatusColor(val?.Status)?.split(
                                  ","
                                )?.[1],
                                cursor: "default",
                                padding: "0px 6px",
                                borderRadius: "4px",
                              }}
                            >
                              {val?.Status}
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
              </table>
            </div>
          ) : (
            <NoRequestsFound text={"No Task Today"} />
          ))}
        {tabIndex === 1 && (
          <div className="approvalFrameTabData">
            <table className="teamAttendanceCard">
              <tr className="teamAttendanceTableHeader">
                <th></th>
                <th>Name</th>
                <th>Deadline</th>
                <th>Progress</th>
                <th>Status</th>
              </tr>
              {tbodyData &&
                tbodyData?.map((val, key) => {
                  return (
                    <tr key={key} className="teamAttendanceItemContainer">
                      <td></td>
                      <td className="projectNameItem">{val.Title}</td>
                      <td className="taskItem">
                        {val.EndsOn
                          ? format(new Date(val.EndsOn), "dd/MM/yyyy")
                          : "--"}
                      </td>

                      <td className="teamAttendanceItem">
                        <div className="progressHolder">
                          <p className="progressText">
                            {val.Percentage ? `${val.Percentage}%` : "--"}
                          </p>
                        </div>
                      </td>
                      <td className="teamAttendanceItem">
                        <div className="statusHolder">
                          <p
                            className={`statusText ${val.Status?.toString()
                              .toLowerCase()
                              .replaceAll(" ", "")}`}
                          >
                            {val.Status}
                          </p>
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </table>
          </div>
        )}
      </div>
    </>
  );
};

export { ProjectTasksCard };
