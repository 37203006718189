import React, { useState, useEffect } from "react";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import { TextAreaFormik } from "../../../../Components/FormComponent/TextAreaFormik";
import { DatePickerFormiks } from "../../../../Components/FormComponent/DatePickerFormiks";
import { SelectForm } from "../../../../Components/FormComponent/SelectForm";
import { getRequest } from "../../../../Services/axios";
import { useDispatch } from "react-redux";
import { showToast } from "../../../../Features";
import {
  StyledTableCell,
  StyledTableCont,
} from "../../../../Components/FormComponent/Repeated";
import { Table, TableBody, TableHead, TableRow } from "@mui/material";
import { FieldArray } from "formik";

export const FlightCandidateComp = ({ formValues }) => {
  const travellingOptions = [
    { label: "One Way Trip", value: "One Way Trip" },
    { label: "Round Trip", value: "Round Trip" },
    { label: "Multilocation Trip", value: "Multilocation Trip" },
  ];
  const genderOptions = [
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" },
    { label: "Others", value: "Others" },
  ];
  useEffect(() => {
    if (formValues?.travellingType === "Round Trip") {
      if (formValues?.fromReturnLocation && formValues?.toReturnLocation) {
        formValues.fromLocation = formValues?.toReturnLocation;
        formValues.toLocation = formValues?.fromReturnLocation;
      }
    }
  }, [formValues?.toReturnLocation, formValues?.fromReturnLocation]);
  useEffect(() => {
    if (formValues?.travellingType === "Round Trip") {
      if (formValues?.fromLocation && formValues?.toLocation) {
        formValues.toReturnLocation = formValues?.fromLocation;
        formValues.fromReturnLocation = formValues?.toLocation;
      }
    }
  }, [formValues?.fromLocation, formValues?.toLocation]);
  const [employeeList, setEmployeeList] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    document.title = `PeopleSol - Candidate Flight`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const getEmployeeData = async () => {
    try {
      const result = await getRequest(
        "Candidate/GetCandidatesListWithoutRejection"
      );
      // //console.log("result", result);
      let listData = [];
      result?.data?.results?.map((item) => {
        return listData.push({ label: item.text, value: item.id });
      });
      setEmployeeList(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };

  useEffect(() => {
    getEmployeeData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div
        style={{ borderBottom: "1px solid #E6E6E6" }}
        className="formcustom threeColumn"
      >
        <div>
          <LabelCustom labelName={"Travelling Type"} />
          <SelectForm name="travellingType" options={travellingOptions} />
        </div>
      </div>
      <div className="formcustom threeColumn">
        <div>
          <LabelCustom labelName={"Candidate"} mandatory={true} />
          <SelectForm name="candidate" options={employeeList} />
        </div>
        <div>
          <LabelCustom labelName={"Gender"} />
          <SelectForm name="gender" options={genderOptions} />
        </div>
        <div>
          <LabelCustom labelName={"Age"} />
          <InputCustomFormik
            maxLength={3}
            name="age"
            type={"text"}
            placeholder={" Enter Age"}
          />
        </div>

        {formValues?.travellingType !== "Multilocation Trip" && (
          <>
            {" "}
            <div>
              <LabelCustom labelName={"Travelling Date"} mandatory={true} />
              <DatePickerFormiks name={"departure"} />
            </div>
            <div>
              <LabelCustom labelName={"From Location"} mandatory={true} />
              <InputCustomFormik
                maxLength={50}
                name="fromLocation"
                type={"text"}
                placeholder={" Enter From Location"}
              />
            </div>
            <div>
              <LabelCustom labelName={"To Location"} mandatory={true} />
              <InputCustomFormik
                maxLength={50}
                name="toLocation"
                type={"text"}
                placeholder={" Enter To Location"}
              />
            </div>
            {formValues?.travellingType === "Round Trip" && (
              <>
                <div>
                  <LabelCustom labelName={"Return Date"} mandatory={true} />
                  <DatePickerFormiks name={"returnDate"} />
                </div>
                <div>
                  <LabelCustom labelName={"From Location"} mandatory={true} />
                  <InputCustomFormik
                    maxLength={50}
                    name="fromReturnLocation"
                    type={"text"}
                    placeholder={" Enter From Location"}
                  />
                </div>

                <div>
                  <LabelCustom labelName={"To Location"} mandatory={true} />
                  <InputCustomFormik
                    maxLength={50}
                    name="toReturnLocation"
                    type={"text"}
                    placeholder={" Enter To Location"}
                  />
                </div>
              </>
            )}
          </>
        )}
        <div>
          <LabelCustom labelName={"Budget (In Thousands)"} mandatory={true} />
          <InputCustomFormik
            maxLength={10}
            name="budget"
            step=".001"
            placeholder={" enter Budget"}
          />
        </div>

        <div>
          <LabelCustom labelName={"Booking Note"} />
          <TextAreaFormik
            name="comments"
            placeholder={" Enter Booking Note"}
            maxLength={500}
          />
        </div>
      </div>
      {formValues?.travellingType === "Multilocation Trip" && (
        <div style={{ marginTop: "20px" }} className="repeatedSectionContainer">
          <label className="repeatedHeading">
            Please specify your journey details
          </label>
          <FieldArray name="Items">
            {(fieldArrayProps) => {
              const { push, remove, form } = fieldArrayProps;
              const { values, errors } = form;
              const { Items } = values;
              return (
                <StyledTableCont>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <StyledTableCell
                          padding="0"
                          style={{
                            fontSize: "12px",
                          }}
                          align="center"
                        >
                          <LabelCustom labelName={"S. No."} />
                        </StyledTableCell>
                        <StyledTableCell
                          padding="0"
                          style={{
                            fontSize: "12px",
                          }}
                          align="center"
                        >
                          <LabelCustom
                            labelName={"Travelling Date"}
                            mandatory={true}
                          />
                        </StyledTableCell>

                        <StyledTableCell
                          style={{
                            fontSize: "12px",
                          }}
                          align="center"
                        >
                          <LabelCustom
                            labelName={"From Location"}
                            mandatory={true}
                          />
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            fontSize: "12px",
                          }}
                          align="center"
                        >
                          <LabelCustom labelName={"To Location"} />
                        </StyledTableCell>

                        <StyledTableCell
                          style={{
                            fontSize: "12px",
                          }}
                          align="center"
                        >
                          <LabelCustom labelName={" Add/Remove"} />
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    {Items?.map((Requestlist, index) => (
                      <TableBody key={index}>
                        {/* 1st column */}
                        <StyledTableCell align="center">
                          {index + 1}
                        </StyledTableCell>
                        {/* 2nd column */}
                        <StyledTableCell>
                          <DatePickerFormiks
                            name={`Items[${index}].departure`}
                          />
                        </StyledTableCell>
                        <StyledTableCell>
                          <InputCustomFormik
                            maxLength={50}
                            name={`Items[${index}].fromLocation`}
                            type="text"
                            placeholder={" Enter From Location"}
                          />
                        </StyledTableCell>
                        <StyledTableCell>
                          <InputCustomFormik
                            maxLength={50}
                            name={`Items[${index}].toLocation`}
                            type={"text"}
                            placeholder={" Enter To Location"}
                          />
                        </StyledTableCell>

                        {/* 8th column */}
                        <StyledTableCell
                          sx={{
                            textAlign: "center",
                          }}
                        >
                          <button
                            type="button"
                            className="remove-btn"
                            onClick={() =>
                              Items.length > 1
                                ? remove(index)
                                : dispatch(
                                    showToast({
                                      text: "Atleast One Item is Required ",
                                      severity: "error",
                                    })
                                  )
                            }
                          >
                            -
                          </button>
                          <button
                            type="button"
                            className="add-btn"
                            onClick={() =>
                              push({
                                departure: "",
                                toLocation: "",
                                fromLocation: "",
                              })
                            }
                          >
                            +
                          </button>
                        </StyledTableCell>
                      </TableBody>
                    ))}
                  </Table>
                </StyledTableCont>
              );
            }}
          </FieldArray>
        </div>
      )}
    </>
  );
};
