import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { FormCustomFormik } from "../../../../Components/FormComponent/FormCustomFormik";
import { useDispatch } from "react-redux";
import { showToast, isLoading } from "../../../../Features";
import { getRequest, postRequest } from "../../../../Services/axios";
import { useLocation } from "react-router-dom";
import { CreatePayAreaComp } from "./CreatePayAreaComp";

const CreatePayArea = () => {
  useEffect(() => {
    document.title = `PeopleSol - Create Pay Area `;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  const { state } = useLocation();

  const data = state?.id;

  const [detailsform, setDetailsform] = useState(false);

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const getFormDetails = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(`PayArea/Details/${data}`);
      setDetailsform(result?.data?.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  useEffect(() => {
    if (data) {
      getFormDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createPageData = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest("PayArea/Create", values);
      if (result) {
        dispatch(
          showToast({
            text: "Induction plan has been created successfully",
            severity: "success",
          })
        );
        returnPage();
      }
    } catch (error) {
      // // //console.log(error)
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const Edit = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest("PayArea/Edit", values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
        returnPage();
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };

  const initialValue = {
    Id: data ? data : 0,
    Name: data ? detailsform[0]?.payArea?.name    : "",
    EmployeeIds: detailsform[0]?.payAreaMappingDetails?.map((item)=>item?.payrollEmployeeId),
  };
  const validationSchema = Yup.object({
    EmployeeIds: Yup.array().required("Please select Employees"),
    Name: Yup.string().required("Please select Induction Date"),
  });

  const onSubmit = (values) => {
    data ? Edit(values) : createPageData(values);
  };
  const navigate = useNavigate();
  const returnPage = () => {
    if (state?.returnUrl) navigate(state?.returnUrl);
    else navigate(-1);
  };
  return (
    <FormCustomFormik
      loading={loading}
      FormHeading={data ? "Update Pay Area" : "Create Pay Area"}
      ButtonLabel={data ? "Update Pay Area" : "Create Pay Area"}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      initialvalue={initialValue}
      returnPage={returnPage}
      propsDiv={<CreatePayAreaComp detailsform={detailsform} data={data}/>}
    />
  );
};

export { CreatePayArea };
