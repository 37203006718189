import React  from "react";
import { InputCustomFormik } from "../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../Components/FormComponent/LabelCustom";
import { useEffect } from "react";


export const CreateClaimTypeComp = () => {
  useEffect(() => {
    document.title = `PeopleSol - Create Claim Type`;  
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  return (
    <div>
      <div>
        <LabelCustom labelName={"Name"} mandatory={true} />
        <InputCustomFormik
        
        maxLength={150}
          name="title"
          type={"text"}
          placeholder={"Enter Name"}
        />
      </div>
    
      
    </div>
  );
};
