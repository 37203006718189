/* eslint-disable react-hooks/exhaustive-deps */
import { IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import "./SalaryStructureDetail.css";
import { getRequest, postRequest } from "../../../../Services/axios";
import { useDispatch } from "react-redux";
import { showToast } from "../../../../Features";
import { GroupBy } from "../../../../Utilities";
import { Loader } from "../../../../Components/FormComponent/Loader";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { Form, Formik } from "formik";

const SalaryStructureDetail = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const editId = state?.id;
  const month = state?.month;
  const empId = state?.empId;
  const closeDetails = () => {
    navigate(-1)
  };

  useEffect(() => {
    document.title = `PeopleSol - Salary Structure Details`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const [loading, setLoading] = useState(false);
  const [isDisplay, setIsDisplay] = useState(false);
  const [isEarning, setIsEarning] = useState(false);
  const [detailsform, setDetailsform] = useState(null);
  const [salaryComponents, setSalaryComponents] = useState({});
  const [payableDays, setPaybleDays] = useState(30);
  const getDetails = async () => {
    try {
      setLoading(true);
      const result = await getRequest(
        `PreparePayroll/SalaryDetails/${empId}/${month}`
      );
      //console.log("result?.data?.data", result?.data);
      setDetailsform(result?.data);
      let tempSalaryComponents = GroupBy(
        result?.data?.summary?.salaryDetails?.salaryComponents,
        "component"
      );
      setPaybleDays(result?.data?.summary?.payableDays);
      //console.log("tempSalaryComponents", tempSalaryComponents);
      setSalaryComponents(tempSalaryComponents);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (empId && month) {
      getDetails();
    } 
  }, [empId]);
  console.log("ise", isEarning);
  const createPageData = async (values) => {
    try {
      setLoading(true);
      await getRequest(`PreparePayroll/AddBonus/${empId}/${month}/${values.bonus}`);
      dispatch(
        showToast({ text: "Successfully Created", severity: "success" })
      );
    } catch (error) {
      dispatch(showToast({ text: error?.response?.data, severity: "error" }));
    } finally {
      setLoading(false);
      navigate(-1);
    }
  };
  const createPageLop = async (values) => {
    try {
      setLoading(true);
      await getRequest(`PreparePayroll/AddLOP/${empId}/${month}/${values.lop}`);
      dispatch(
        showToast({ text: "Successfully Created", severity: "success" })
      );
    } catch (error) {
      dispatch(showToast({ text: error?.response?.data, severity: "error" }));
    } finally {
      setLoading(false);
      navigate(-1);
    }
  };
  return (
    <div className="moduleMasterDetailsPage">
      <div
        className="moduleMasterDetailsBackground"
        onClick={() => closeDetails()}
      ></div>
      <div className="salaryStructureContainer">
        <IconButton
          onClick={() => closeDetails()}
          style={{ position: "absolute", top: "3px", right: "3px" }}
        >
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.0512 0.826172L1.45312 11.1331"
              stroke="#F71D1D"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M1.45312 0.826172L11.0512 11.1331"
              stroke="#F71D1D"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </IconButton>
        {loading ? (
          <Loader />
        ) : (
          <Formik
            initialValues={{ lop: 0, bonus: "" }}
            onSubmit={(values) => {

             if(isEarning) createPageData(values)
             if(isDisplay) createPageLop(values)
            }}
          >
            {({ values }) => {
              return (
                <Form style={{ height: "100%",position:'relative' }}>
                  <div className="salaryStructureHeader">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <h5 style={{ color: "var(--primary)" }}>
                          {detailsform?.data?.name}
                        </h5>
                        <p>{`(${detailsform?.data?.personNumber})`}</p>
                      </div>
                      <div>
                        <p>Total Net Payable</p>
                        <h5 style={{ color: "var(--primary)" }}>₹{detailsform?.summary?.salaryDetails?.totalNetPayable}</h5>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "12px",
                      }}
                    >
                      <div>
                        <p style={{ fontSize: "11px" }}>Account Name</p>
                        <h6>{detailsform?.data?.accountHolderName ?? '-'}</h6>
                      </div>
                      <div>
                        <p style={{ fontSize: "11px" }}>Bank Name</p>
                        <h6>{detailsform?.data?.bankName ?? "-"}</h6>
                      </div>
                      <div>
                        <p style={{ fontSize: "11px" }}>IFSC Code</p>
                        <h6>{detailsform?.data?.ifscCode ?? "-"}</h6>
                      </div>
                      <div>
                        <p style={{ fontSize: "11px" }}>Account Number</p>
                        <h6>{detailsform?.data?.accountNumber ?? "-"}</h6>
                      </div>
                    </div>
                  </div>
                  <div className="salaryStructureBody">
                    <div className="salaryStructureHeadingContainer">
                      <h6 style={{ color: "#5BC4BF" }}>Total Days</h6>
                      <div
                        className="addSalaryCompButton cursor"
                        onClick={() => {
                          setIsDisplay(true);
                          setIsEarning(false);
                        }}
                      >
                        + Add LOP
                      </div>
                    </div>
                    <div className="salaryStructureLineContainer">
                      <p>Payable Days</p>
                      <h6>{payableDays}</h6>
                    </div>
                    {isDisplay && (
                      <>
                        <div className="salaryStructureLineContainer">
                          <p>LOP Days</p>
                          <p style={{ display: "flex", alignItems: "center" }}>
                            {" "}
                            <InputCustomFormik
                              name="lop"
                              type="number"
                              style={{
                                border: "1px solid lightgray",
                                width: "50px",
                              }}
                            />
                            <IconButton
                              title="remove"
                              onClick={() => setIsDisplay(false)}
                            >
                              {" "}
                              <svg
                                width="12"
                                height="12"
                                viewBox="0 0 12 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <circle
                                  cx="6.32687"
                                  cy="5.87888"
                                  r="5.65573"
                                  fill="#FDD2D2"
                                />
                                <line
                                  x1="9.27246"
                                  y1="5.69775"
                                  x2="3.38098"
                                  y2="5.69775"
                                  stroke="#F71D1D"
                                  stroke-linecap="round"
                                />
                              </svg>
                            </IconButton>
                          </p>
                        </div>
                        <div className="salaryStructureLineContainer">
                          <p>Actual Payable Days</p>
                          <h6>{payableDays - values?.lop}</h6>
                        </div>
                      </>
                    )}
                    <div
                      className="salaryStructureHeadingContainer"
                      style={{ marginTop: "24px" }}
                    >
                      <h6 style={{ color: "#82C1AD" }}>Total Earning</h6>
                      <h6 style={{ color: "#82C1AD" }}>
                        {detailsform?.summary?.salaryDetails?.totalEarning+detailsform?.summary?.salaryDetails?.bonus}
                      </h6>
                      <div
                        className="addSalaryCompButton cursor"
                        onClick={() => {
                          setIsDisplay(false);
                          setIsEarning(true);
                        }}
                      >
                        + Add Earning
                      </div>
                    </div>
                    {Object.keys(salaryComponents)
                      .filter(
                        (key) =>
                          key.trim().replace(/[^\w\s]/gi, "") === "Earnings"
                      )
                      .map((key) => {
                        return (
                          <div key={key}>
                            {salaryComponents[key].map((earning) => (
                              <div
                                className="salaryStructureLineContainer"
                                key={earning.id}
                              >
                                <p>{earning.type}</p>
                                <h6>{earning.amount}</h6>
                              </div>
                            ))}
                          </div>
                        );
                      })}
                    {isEarning && (
                      <>
                        <div className="salaryStructureLineContainer">
                          <p>Bonus</p>
                          <p style={{ display: "flex", alignItems: "center" }}>
                            {" "}
                            <InputCustomFormik
                              name="bonus"
                              type="number"
                              style={{
                                border: "1px solid lightgray",
                                width: "50px",
                              }}
                            />
                            <IconButton
                              title="remove"
                              onClick={() => setIsEarning(false)}
                            >
                              {" "}
                              <svg
                                width="12"
                                height="12"
                                viewBox="0 0 12 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <circle
                                  cx="6.32687"
                                  cy="5.87888"
                                  r="5.65573"
                                  fill="#FDD2D2"
                                />
                                <line
                                  x1="9.27246"
                                  y1="5.69775"
                                  x2="3.38098"
                                  y2="5.69775"
                                  stroke="#F71D1D"
                                  stroke-linecap="round"
                                />
                              </svg>
                            </IconButton>
                          </p>
                        </div>
                      </>
                    )}
                    <div
                      className="salaryStructureHeadingContainer"
                      style={{ marginTop: "24px" }}
                    >
                      <h6 style={{ color: "#B69FB3" }}>Total Deduction</h6>
                      <h6 style={{ color: "#B69FB3" }}>
                        {detailsform?.summary?.salaryDetails?.totalDeduction}
                      </h6>
                    </div>
                    {Object.keys(salaryComponents)
                      .filter(
                        (key) =>
                          key.trim().replace(/[^\w\s]/gi, "") === "Deductions"
                      )
                      .map((key) => {
                        return (
                          <div key={key}>
                            {salaryComponents[key].map((earning) => (
                              <div
                                className="salaryStructureLineContainer"
                                key={earning.id}
                              >
                                <p>{earning.type}</p>
                                <h6>{earning.amount}</h6>
                              </div>
                            ))}
                          </div>
                        );
                      })}
                    <div
                      className="salaryStructureHeadingContainer"
                      style={{ marginTop: "24px" }}
                    >
                      <h6 style={{ color: "#A0A0CB" }}>Total Reimbursement</h6>
                      <h6 style={{ color: "#A0A0CB" }}>
                        {
                          detailsform?.summary?.salaryDetails
                            ?.totalReimbursement
                        }
                      </h6>
                    </div>
                    {Object.keys(salaryComponents)
                      .filter(
                        (key) =>
                          key.trim().replace(/[^\w\s]/gi, "") ===
                          "Reimbursements"
                      )
                      .map((key) => {
                        return (
                          <div key={key}>
                            {salaryComponents[key].map((earning) => (
                              <div
                                className="salaryStructureLineContainer"
                                key={earning.id}
                              >
                                <p>{earning.type}</p>
                                <h6>{earning.amount}</h6>
                              </div>
                            ))}
                          </div>
                        );
                      })}
                    <div
                      className="salaryStructureHeadingContainer"
                      style={{
                        marginTop: "12px",
                    
                        borderBottom: 0,
                        paddingTop: "6px",
                      }}
                    >
                      <h6 style={{ color: "var(--primary)" }}>
                        Total Net Payable
                      </h6>
                      <h6 style={{ color: "var(--primary)" }}>
                        {detailsform?.summary?.salaryDetails?.totalNetPayable}
                      </h6>
                    </div>
                  </div>
                  <div
                    style={{
                      right: 0,
                      position:'absolute',
                      bottom:0,
                      display: "flex",
                      background: "var(--primary-hover)",
                      justifyContent: "flex-end",
                      width: "100%",
                    }}
                  >
                    <button className="button secondaryButton" type="button" onClick={() => closeDetails()}>
                      Cancel
                    </button>
                    <button className="button primaryButton" type="submit">
                      Save
                    </button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        )}
      </div>
    </div>
  );
};

export { SalaryStructureDetail };
