import React from 'react'
import { useEffect } from 'react';

const OutdoorsCardComp = ({time, title, date, source}) => {
 
  return (
    <div className="attendanceCardContentContainer" style={{background: '#FFEEF2'}}>
      <div className="cardDateDisplay" style={{justifyContent:'space-between'}}>{date ? date : ""}
      <label style={{fontSize:'8px', color:'black'}}>{title ? title : ""}</label> </div>

        <div className='attendanceCardPresentCell' style={{background:'#FFEEF2', borderLeft: '2px solid #DE64A1'}}>
        <label className='attendanceIn' style={{fontSize: '9px', paddingLeft: '2px'}}>{time ?time[0]: ""}</label>
        </div>
            
        <div className='attendanceCardPresentCell' style={{background:'#FFEEF2', borderLeft: '2px solid #DE64A1'}}>
        <label className='attendanceOut' style={{fontSize: '9px', paddingLeft: '2px'}}>{time ? time[1]: ""}</label>
        </div>
        
        </div>
  )
}

export {OutdoorsCardComp}
