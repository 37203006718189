import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  FormikMultiStepForm,
  FormikMultiStepFormStep,
} from "../../../../Components/FormikMultiStepForm/FormikMultiStepForm";
import { isLoading, showToast } from "../../../../Features";
import { getRequest, postRequestForm } from "../../../../Services/axios";
import {
  Facilities,
  KPI,
  PositionDetails,
  VacancyDetails,
} from "./RequisitionFormComp";
import { format } from "date-fns";
import * as Yup from "yup";
export const CreateRequisition = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { state } = useLocation();
  const editid = state?.id;
  const returnUrl = state?.returnUrl;
  const [editFormData, seEditFormData] = React.useState([]);
  const getDetails = async () => {
    try {
      const result = await getRequest(`ManpowerIndents/Details/${editid}`);
      seEditFormData(result.data?.data);
    } catch (error) {
      dispatch(showToast({ text: error.response.message, severity: "error" }));
    } finally {
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - Create Requisition`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const createFunction = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequestForm("ManpowerIndents/Create", values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Created", severity: "success" })
        );
        returnPage();
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.data?.response ?? "Some error occured",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const EditFunction = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequestForm("ManpowerIndents/Edit", values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
        returnPage();
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.data?.response ?? "Some error occured",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const onSubmit = async (values) => {
    const formData = new FormData();
    formData.append("Id", editid ? editid : 0);
    if (values?.ResignatingEmployeeId)
      formData.append("ResignatingEmployeeId", values?.ResignatingEmployeeId);
    if (values?.ReasonForVacancy)
      formData.append("ReasonForVacancy", values?.ReasonForVacancy);
    if (values?.IntenalArrangementNeeded)
      formData.append(
        "IntenalArrangementNeeded",
        values?.IntenalArrangementNeeded
      );
    if (values?.VacanctDesigination)
      formData.append("VacanctDesigination", values?.VacanctDesigination);
    if (values?.upload) formData.append("upload", values?.upload);
    if (values?.VacanctLocation)
      formData.append("VacanctLocation", values?.VacanctLocation);
    if (values?.ReleventExpirience)
      formData.append("ReleventExpirience", values?.ReleventExpirience);
    if (values?.EducationLevel)
      formData.append("EducationLevel", values?.EducationLevel);
    if (values?.NumberOfPositions)
      formData.append("NumberOfPositions", values?.NumberOfPositions);
    if (values?.Specialization)
      formData.append("Specialization", values?.Specialization);
    formData.append("OldCtc", values?.OldCtc ?? 0);
    if (values?.ProposedCtc)
      formData.append("ProposedCtc", values?.ProposedCtc);
    if (values?.ManagementLevel)
      formData.append("ManagementLevel", values?.ManagementLevel);
    if (values?.ExpectedDoj)
      formData.append(
        "ExpectedDoj",
        `${format(values?.ExpectedDoj, "MM/dd/yyyy")}`
      );
    if (values?.SkilsRequired)
      formData.append("SkilsRequired", values?.SkilsRequired);
    if (values?.ReportingManager)
      formData.append("ReportingManager", values?.ReportingManager);
    if (values?.JobDescription)
      formData.append("JobDescription", values?.JobDescription);
    if (values?.OthersReason)
      formData.append("OthersReason", values?.OthersReason);
    if (values?.IsAssessmentRequired)
      formData.append("IsAssessmentRequired", values?.IsAssessmentRequired);
    if (values?.Uploads) formData.append("Uploads", values?.Uploads);
    if (values?.DescriptionUpload)
      formData.append("DescriptionUpload", values?.DescriptionUpload);
    if (values?.JoiningBuddy)
      formData.append("JoiningBuddy", values?.JoiningBuddy);
    if (values?.TechnicalEducation)
      formData.append("TechnicalEducation", values?.TechnicalEducation);
    if (values?.PrefferdIndustry)
      formData.append("PrefferdIndustry", values?.PrefferdIndustry);

    if (values?.KpiTemplatelist[0].Threshold) {
      values?.KpiTemplatelist.forEach(function (object, index) {
        formData.append(
          `KpiTemplatelist[${index}][KPIname]`,
          object.KPIname ? object.KPIname : ""
        );
        formData.append(
          `KpiTemplatelist[${index}][StartDate]`,
          object.StartDate ? `${format(object.StartDate, "MM/dd/yyyy")}` : ""
        );
        formData.append(
          `KpiTemplatelist[${index}][ExpectedDateofAchievement]`,
          object.ExpectedDateofAchievement
            ? `${format(object.ExpectedDateofAchievement, "MM/dd/yyyy")}`
            : ""
        );
        formData.append(
          `KpiTemplatelist[${index}][Threshold]`,
          object.Threshold ? object.Threshold : ""
        );
        formData.append(
          `KpiTemplatelist[${index}][Weightage]`,
          object.Weightage ? object.Weightage : ""
        );
        formData.append(
          `KpiTemplatelist[${index}][Objective]`,
          object.Objective ? object.Objective : ""
        );
      });
    }
    if (values?.item) {
      Object.keys(values?.item).forEach(function (object, index) {
        formData.append(`item[${index}][FacilityId]`, object);
        formData.append(
          `item[${index}][Value]`,
          Object.values(values?.item)[index]
        );
      });
    }
    editid ? EditFunction(formData) : createFunction(formData);
  };
  const navigate = useNavigate();
  const returnPage = () => {
    if (returnUrl) {
      navigate(returnUrl);
    } else {
      navigate(-1);
    }
  };
  const getKpiTemplatelist = () => {
    let returnArray = [
      {
        Objective: "",
        KPIname: "",
        StartDate: "",
        ExpectedDateofAchievement: "",
        Threshold: "",
        Weightage: "",
      },
    ];
    if (editid) {
      returnArray = [];
      if (editFormData?.manpowerIndent?.kpiMpiTemplate?.length > 0) {
        editFormData?.manpowerIndent?.kpiMpiTemplate?.map((item) => {
          returnArray.push({
            Objective: item.objective,
            KPIname: item.kpiname,
            StartDate: item.startDate ? new Date(item.startDate) : null,
            ExpectedDateofAchievement: item.expectedDateofAchievement
              ? new Date(item.expectedDateofAchievement)
              : null,
            Threshold: item.threshold,
            Weightage: item.weightage,
          });
          return null;
        });
      } else
        return [
          {
            Objective: "",
            KPIname: "",
            StartDate: "",
            ExpectedDateofAchievement: "",
            Threshold: "",
            Weightage: "",
          },
        ];
    }
    return returnArray;
  };
  const getItemList = () => {
    let returnArray = {};
    editid
      ? editFormData?.manpowerIndent?.manpowerIndentFacilities?.map((item) => {
          returnArray[item.facilityId] = item.value;
          return null;
        })
      : (returnArray = {});
    return returnArray;
  };
  const initialValues = {
    totalWeightage: 0,
    VacanctDesigination: "",
    VacanctLocation: "",
    ReleventExpirience: "",
    NumberOfPositions: "",
    EducationLevel: "",
    ProposedCtc: "",
    Specialization: "",
    ExpectedDoj: null,
    ManagementLevel: "",
    ReportingManager: "",
    TechnicalEducation: null,
    PrefferdIndustry: null,
    SkilsRequired: "",
    JobDescription: "",
    upload: "",
    ReasonForVacancy: "",
    ResignatingEmployeeId: null,
    IntenalArrangementNeeded: "",
    IsAssessmentRequired: "",
    JoiningBuddy: null,
    OldCtc: null,
    KpiTemplatelist: getKpiTemplatelist(),
    item: getItemList(),
  };
  const [formValues, setFormValues] = useState({});
  const chooseFormValues = (values) => {
    setFormValues(values);
  };

  const SUPPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/png",
    "application/pdf",
  ];
  const [locationList, setLocationList] = useState([]);
  const [managmentList, setManagmentmentList] = useState([]);
  const [designationList, setDesignationList] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);
  const getlocations = async () => {
    try {
      const result = await getRequest("LeaveTypes/GetLocationName");
      let listData = [];
      result.data.map((item) => {
        return listData.push({ label: item.name, value: item.name });
      });
      setLocationList(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  const getDesignations = async () => {
    try {
      const result = await getRequest("Role/GetRole");
      let listData = [];
      result.data.map((item) => {
        return listData.push({ label: item.name, value: item.name });
      });
      setDesignationList(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  const getManagment = async () => {
    try {
      const result = await getRequest("Designation/GetDesignations");
      let listData = [];
      result.data.map((item) => {
        return listData.push({ label: item.name, value: item.id });
      });
      setManagmentmentList(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  const getEmployees = async () => {
    try {
      const result = await getRequest("LeaveTypes/GetEmployees");
      let listData = [];
      result.data.map((item) => {
        return listData.push({ label: item.name, value: item.id });
      });
      setEmployeeList(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  const [facilitiesList, setFacilitiesList] = useState(false);
  const getFacilitiesList = async () => {
    try {
      if (facilitiesList?.length > 0) return;
      const result = await getRequest("ManpowerIndents/GetFacilities");
      if (result) {
        setFacilitiesList(result.data.data);
      }
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    }
  };
  useEffect(() => {
    getEmployees();
    getDesignations();
    getManagment();
    getlocations();
    if (editid) {
      getDetails();
    }
    getFacilitiesList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editid]);

  return (
    <FormikMultiStepForm
      loading={loading}
      initialValues={
        editid
          ? {
              ...initialValues,
              VacanctDesigination:
                editFormData?.manpowerIndent?.vacanctDesigination,
              ProposedCtc: editFormData?.manpowerIndent?.proposedCtc,
              VacanctLocation: editFormData?.manpowerIndent?.vacanctLocation,
              ReleventExpirience:
                editFormData?.manpowerIndent?.releventExpirience,
              NumberOfPositions:
                editFormData?.manpowerIndent?.numberOfPositions,
              EducationLevel: editFormData?.manpowerIndent?.educationLevel,
              Specialization: editFormData?.manpowerIndent?.specialization,
              ExpectedDoj: editFormData?.manpowerIndent?.expectedDoj
                ? new Date(editFormData?.manpowerIndent?.expectedDoj)
                : null,
              ManagementLevel: editFormData?.manpowerIndent?.managementLevel,
              ReportingManager: editFormData?.manpowerIndent?.reportingManager,
              TechnicalEducation:
                editFormData?.manpowerIndent?.technicalEducation,
              PrefferdIndustry: editFormData?.manpowerIndent?.prefferdIndustry,
              SkilsRequired: editFormData?.manpowerIndent?.skilsRequired,
              JobDescription: editFormData?.manpowerIndent?.jobDescription,
              upload: editFormData?.manpowerIndent?.uploads
                ? `ManpowerIndents/${editFormData?.manpowerIndent?.uploads}`
                : "",
              ReasonForVacancy: editFormData?.manpowerIndent?.reasonForVacancy,
              ResignatingEmployeeId:
                editFormData?.manpowerIndent?.resignatingEmployeeId,
              IntenalArrangementNeeded:
                editFormData?.manpowerIndent?.intenalArrangementNeeded,
              IsAssessmentRequired:
                editFormData?.manpowerIndent?.isAssessmentRequired,
              JoiningBuddy: editFormData?.manpowerIndent?.joiningBuddy
                ? editFormData?.manpowerIndent?.joiningBuddy[0]?.id
                : null,
              OldCtc: editFormData?.manpowerIndent?.oldCtc,
            }
          : initialValues
      }
      onSubmit={onSubmit}
      returnPage={returnPage}
      heading={editid ? "Update Requisition" : "Create Requisition"}
      submitText={editid ? "Update Requisition" : "Create Requisition"}
      chooseFormValues={chooseFormValues}
    >
      <FormikMultiStepFormStep
        label="Position"
        validationSchema={Yup.object({
          VacanctDesigination: Yup.string().required("Please Select Role"),
          VacanctLocation: Yup.string().required("Please Select Location"),
          ReleventExpirience: Yup.string().required("Please Select Experience"),
          NumberOfPositions: Yup.string()
            .max(3, "Positions must be less than 4 characters")
            .required("Please enter number of position"),
          EducationLevel: Yup.string().required(
            "Please Select Education Level"
          ),
          ProposedCtc: Yup.string()
            .max(15, "Name must be less than 11 characters")
            .required("Please enter Proposed CTC"),
          Specialization: Yup.string().required("Please Select Specialization"),
          ManagementLevel: Yup.string().required(
            "Please Select Management Level"
          ),
          ReportingManager: Yup.string().required(
            "Please Select Reporting Manager"
          ),
          SkilsRequired: Yup.string()

            .max(299, "Skills must be less than 300 characters")
            .required("Please enter required skills"),
          JobDescription: Yup.string()

            .max(499, "Description must be less than 500 characters")
            .nullable()
            .notRequired(),
          ExpectedDoj: Yup.string()
            .required("Please Select Expected Date of Joining")
            .nullable(),
          TechnicalEducation: Yup.string()

            .max(149, "Education must be less than 150 characters")
            .nullable()
            .notRequired(),
          PrefferdIndustry: Yup.string()

            .max(149, "Industry must be less than 150 characters")
            .nullable()
            .notRequired(),
          upload: Yup.mixed()
            .required("Please upload your Job Description")
            .test(
              "Fichier taille",
              "File Size must be less than 1 mb",
              (value) =>
                !value ||
                (value && value.size <= 2 * 2048 * 2048) ||
                typeof value !== "object"
            )
            .test(
              "format",
              "File Format is Incorrect",
              (value) =>
                !value ||
                (value && SUPPORTED_FORMATS.includes(value.type)) ||
                typeof value !== "object"
            ),
        })}
      >
        <PositionDetails
          employeeList={employeeList}
          designationList={designationList}
          managmentList={managmentList}
          locationList={locationList}
        />
      </FormikMultiStepFormStep>
      <FormikMultiStepFormStep
        label="Vacancy"
        validationSchema={Yup.object({
          ReasonForVacancy: Yup.string().required("Please Select the reason"),
          IntenalArrangementNeeded: Yup.string().required(
            "Please Select one option"
          ),
          IsAssessmentRequired: Yup.string().required(
            "Please Select one option"
          ),
        })}
      >
        <VacancyDetails formValues={formValues} employeeList={employeeList} />
      </FormikMultiStepFormStep>
      <FormikMultiStepFormStep
        label="KPI"
        // validationSchema={Yup.object({
        //   KpiTemplatelist: Yup.array().of(
        //     Yup.object().shape({
        //       Objective: Yup.string()

        //         .max(499, "Objective must be less than 500 characters")
        //         .required("Please enter the Objective"),
        //       KPIname: Yup.string()

        //         .max(149, "Name must be less than 150 characters")
        //         .required("Please enter the KPI name"),
        //       StartDate: Yup.string().required("Please Select the Start Date"),
        //       ExpectedDateofAchievement: Yup.string().required(
        //         "Please Select the Expected Date"
        //       ),
        //       Threshold: Yup.string()

        //         .max(149, "Threshold must be less than 150 characters")
        //         .required("Please enter the Threshold"),
        //       Weightage: Yup.string()

        //         .max(149, "Weightage must be less than 150 characters")
        //         .required("Please enter the Weightage"),
        //     })
        //   ),
        //   totalWeightage: Yup.string()
        //     .required("Please enter the Total Weightage")
        //     .test(
        //       "Is 100 percent",
        //       "Total % should be 100",
        //       (value) => Number(value) === 100
        //     ),
        // })}
      >
        <KPI formValues={formValues} />
      </FormikMultiStepFormStep>
      <FormikMultiStepFormStep label="Facilities">
        <Facilities facilitiesList={facilitiesList} />
      </FormikMultiStepFormStep>
    </FormikMultiStepForm>
  );
};
