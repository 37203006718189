import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { FormCustomFormik } from "../../../Components/FormComponent/FormCustomFormik";
import { CreateTravelEligibilityComp } from "../FormComp/CreateTravelEligibilityComp";
import { useDispatch } from 'react-redux';
import { showToast, isLoading } from '../../../Features';
import { getRequest, postRequest } from '../../../Services/axios';
import { useLocation } from "react-router-dom";
import { useEffect } from "react";



const CreateTravelEligibility = (props) => {

  const { state } = useLocation();

  const data = state?.id;

  const dispatch = useDispatch();
  
  useEffect(() => {
    document.title = `PeopleSol - Create Travel Eligibility`;  
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);

  const [loading, setLoading] = useState(true);
  const [detail, setDetail] = React.useState([]);
    const createPageData = async (values) => {
      try {
          
          dispatch(isLoading(true));
          setLoading(true);
          const result = await postRequest('TravelAdvanceEligibilities/Create', values);
          dispatch(showToast({ text: "Successfully Created", severity: "success" }));
          
      } catch (error) {

          dispatch(showToast({ text: error?.response?.data , severity: "error" }));
      } finally {
          dispatch(isLoading(false));
          setLoading(false);
      }
  }
  const getDetails = async () => {
    try {
      const result = await getRequest (`TravelAdvanceEligibilities/Details/${data}`);
      setDetail(result.data);
    } catch (error) {        dispatch(
      showToast({ text: error.response.message, severity: "error" })
    ) 
    } finally {
    }
  };
  React.useEffect(() => {
    if(data){
      getDetails();
    }
  }, [data]);

  const Edit = async (values) => {
    try {
      const result = await postRequest(
        "TravelAdvanceEligibilities/Edit",
        values
      );
      if (result) {
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
      }
    } catch (error) { dispatch(
      showToast({ text: error?.response?.data ?? "some error occured", severity: "error" })
    ) }
  };

  const initialvalue = {
   
    bandId: data?detail?.bandId:"",
    perdayEligibilty: data?detail?.perdayEligibilty:"",
   
   
  };
  const validationSchema = Yup.object({
    bandId: Yup.string().required("Please Select Band"),
    perdayEligibilty: Yup.string().required("Please Enter Per Day Eligibilty "),
   
  });

  const onSubmit = (values) => {
   
    const edit = {
      ...values,
      Id: data,
    
     

    };

    const create={
     ...values,
     id:0,
    }
    data ? Edit(edit) : createPageData(create);
    returnPage()
     }

  const navigate = useNavigate();

  const returnPage = () => {
    navigate("/travelmaster/traveleligibility");
  };

  return (
   <FormCustomFormik FormHeading={data?'Edit Travel Advance Eligibility':'Create Travel Advance Eligibility'} ButtonLabel={data?'Update Travel Advance Eligibility':'Create Travel Advance Eligibility'} onSubmit={onSubmit} validationSchema={validationSchema} initialvalue={
   initialvalue
  } returnPage={returnPage} propsDiv={<CreateTravelEligibilityComp data={data} />} />
  );
};

export { CreateTravelEligibility };