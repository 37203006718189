import React from 'react';
import { WorkLocationTab } from './WorkLocationTab';
import { useEffect } from 'react';

const WorkLocation = () => {
    useEffect(() => {
        document.title = `PeopleSol - Work Location `;
        return () => {
          document.title = 'PeopleSol';
        };
      }, []);
    return (
        <div className="moduleMainContainer">
                    <WorkLocationTab title="Work Location"/>
        </div>
    )
}
export { WorkLocation }