import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { FormCustomFormik } from "../../../Components/FormComponent/FormCustomFormik";
import { useDispatch } from 'react-redux';
import { showToast, isLoading } from '../../../Features';
import { postRequest } from '../../../Services/axios';
import { useLocation } from "react-router-dom";
import { CreateClaimTypeComp } from "../FormComp/CreateClaimTypeComp";
import { useEffect } from "react";



const CreateClaimType = (props) => {

  const { state } = useLocation();

  const data = state?.dataEdit;

  const dispatch = useDispatch();
  
  const [loading, setLoading] = useState(true);

    const createPageData = async (values) => {
      try {
          
          dispatch(isLoading(true));
          setLoading(true);
          const result = await postRequest('ExpenseTypes/Create', values);
          dispatch(showToast({ text: "Successfully Created", severity: "success" }));
          returnPage()

      } catch (error) {

          dispatch(showToast({ text: error?.response?.data ?? "Error in fetching Data", severity: "error" }));
      } finally {
          dispatch(isLoading(false));
          setLoading(false);
      }
  }
  useEffect(() => {
    document.title = `PeopleSol - Create Claim Type`;  
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  const Edit = async (values) => {
    try {
      const result = await postRequest(
        "ExpenseTypes/Edit",
        values
      );
      if (result) {
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
      }
      returnPage()

    } catch (error) { dispatch(
      showToast({ text: error?.response?.data ?? "some error occured", severity: "error" })
    ) }
  };

  const initialvalue = {
   
    title: "",
    
   
  };
  const validationSchema = Yup.object({
    title: Yup.string().max(149,"title must be less than 150 characters").required("Please Enter Name"),
  
  });

  const onSubmit = (values) => {
   
    const edit = {
      ...values,
      Id: data?.Id,
      
    };

    const create={
     ...values,
     id:0,
    
     isDataActive: true
    }
    data ? Edit(edit) : createPageData(create);
     }

  const navigate = useNavigate();

  const returnPage = () => {
    if (state?.returnUrl) navigate(state?.returnUrl);
    else navigate(-1);
  };

  return (
   <FormCustomFormik FormHeading={data?'Edit Claim Type':'Create Claim Type'} ButtonLabel={data?'Update Claim Type':'Create Claim Type'} onSubmit={onSubmit} validationSchema={validationSchema} initialvalue={
    data
      ? {
          ...initialvalue,
          title: data.Title,
         
          
        }
      : initialvalue
  } returnPage={returnPage} propsDiv={<CreateClaimTypeComp  />} />
  );
};

export { CreateClaimType };