import React from "react";
import SurvayMainDisplayList from "./SurvayMainDisplayList";

function PollPage() {
  return (
    <div className="surveyPageContainer">
      <div className="surveyPageWrapper">
      <SurvayMainDisplayList type="poll" />
      </div>
    </div>
  );
}

export default PollPage;
