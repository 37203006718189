/* eslint-disable react-hooks/exhaustive-deps */
import { Form, Formik } from "formik";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { isLoading, showToast } from "../../../../Features";
import { getRequest, postRequest } from "../../../../Services/axios";
import { GroupBy } from "../../../../Utilities";
import { useLocation, useNavigate } from "react-router-dom";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import { SelectForm } from "../../../../Components/FormComponent/SelectForm";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { CheckboxFormiks } from "../../../../Components/FormComponent/CheckboxFormiks";
import { Loader } from "../../../../Components/FormComponent/Loader";
const handleUnclaimedLTAOptions = [
  {
    label: "Carry forward unclaimed LTA to the next financial year",
    value: "Carry forward unclaimed LTA to the next financial year",
  },
  {
    label: "Encash unclaimed LTA at the end of each financial year",
    value: "Encash unclaimed LTA at the end of each financial year",
  },
];
function CreateReimbursement() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState(null);

  const [detailsform, setDetailsform] = useState(null);
  const { state } = useLocation();
  const editId = state?.id;
  const getFormDetails = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(
        `SalaryComponents/ReimbursementDetails/${editId}`
      );
      setDetailsform(result?.data?.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - Create Reimbursement `;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const initialValues = {
    reimbursementType: editId ? detailsform?.reimbursementType : "",
    restrictFBP: editId ? detailsform?.restrictFBP : null,
    payType: "Fixed Pay",
    calculationType: "Flat Amount",
    config: { epfContribution: "", taxPreference: "" },
    calculationValue: "",
    nameInPayslip: editId ? detailsform?.nameInPayslip : "",
    MaximumReimbursementAmount: editId
      ? detailsform?.maximumReimbursementAmount
      : "",
    handleUnclaimedLTA: editId ? detailsform?.handleUnclaimedLTA : "",
    PerDayOrPerMonth: editId ? detailsform?.perDayOrPerMonth===0?"PerDay":"PerMonth" : "",
    isActive: true,
  };
  useEffect(() => {
    if (editId) {
      getFormDetails();
    }
  }, [editId]);
  const create = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest(
        "SalaryComponents/CreateReimbursement",
        values
      );
      if (result) {
        dispatch(
          showToast({ text: "Successfully Created", severity: "success" })
        );
        returnPage();
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data?.title ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const edit = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest(
        "SalaryComponents/EditReimbursement",
        values
      );
      if (result) {
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
        returnPage();
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data?.title ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const navigate = useNavigate();
  const returnPage = () => {
    navigate(-1);
  };
  // const getEarningType = async () => {
  //   try {
  //     setLoading(true);
  //     const result = await getRequest("SalaryComponents/DefaultEarning");
  //     if (result) {
  //       setEarningTypeDetails(result?.data?.data);
  //       let tempObject = [];
  //       result?.data?.data?.map((item) => {
  //         return tempObject.push({
  //           label: item?.reimbursementType,
  //           value: item?.reimbursementType,
  //         });
  //       });
  //     }
  //   } catch {
  //   } finally {
  //     setLoading(false);
  //   }
  // };
  const submitEditCompany = (values) => {
    let payload = {};
    payload["Id"] = editId ?? 0;
    payload["ReimbursementType"] = values?.reimbursementType;
    payload["NameInPayslip"] = values?.nameInPayslip;
    payload["Name"] = values?.nameInPayslip;
    payload["MaximumReimbursementAmount"] = values?.MaximumReimbursementAmount;
    payload["handleUnclaimedLTA"] = values?.handleUnclaimedLTA;
    payload["IsActive"] = values.isActive;
    payload["restrictFBP"] = values.restrictFBP;
    payload["perDayOrPerMonth"] = values.PerDayOrPerMonth=="PerDay"?0:1;
    editId ? edit(payload) : create(payload);
  };
  return (
    <div className="moduleMainContainer">
      <div
        className={"companyCard"}
        style={{ overflow: "auto", backgroundColor: "var(--form-background)" }}
      >
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          onSubmit={(values) => submitEditCompany(values)}
        >
          {(formik) => {
            setFormValues(formik?.values);
            return (
              <Form
                style={{
                  overflow: "auto",
                  height: "calc(100% - 72px)",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <>
                  {loading && (
                    <div
                      style={{
                        display: "flex",
                        flexGrow: 1,
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Loader />
                    </div>
                  )}
                  {!loading ? (
                    <div
                      className="companyCardContainer"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        borderTop: "1px solid var(--grey)",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <div style={{ width: "50%" }}>
                          <div>
                            <LabelCustom
                              labelName={"Reimbursement Type"}
                              mandatory={true}
                            />
                            <InputCustomFormik
                              name="reimbursementType"
                              isDisabled={editId ? true : false}
                            />
                          </div>
                          <div>
                            <LabelCustom
                              labelName={"Name in Payslip"}
                              mandatory={true}
                            />
                            <InputCustomFormik name="nameInPayslip" />
                          </div>
                          {formValues?.reimbursementType ===
                            "Leave Travel Allowance" && (
                            <div>
                              <LabelCustom
                                labelName={
                                  "How do you want to handle unclaimed LTA?"
                                }
                                mandatory={true}
                              />
                              <SelectForm
                                name="handleUnclaimedLTA"
                                options={handleUnclaimedLTAOptions}
                              />
                            </div>
                          )}
                          <div>
                            <LabelCustom
                              labelName={"Enter Amount*"}
                              mandatory={true}
                            />
                          </div>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <InputCustomFormik
                              placeholder="Enter Maximum Amount"
                              name="MaximumReimbursementAmount"
                            />

                            <SelectForm
                              options={[
                                { label: "PerMonth", value: "PerMonth" },
                                { label: "PerDay", value: "PerDay" },
                              ]}
                              placeholder="Enter PerDay/PerMonth"
                              name="PerDayOrPerMonth"
                            />
                          </div>

                          <div>
                            <CheckboxFormiks
                              name="isActive"
                              labelName={"Mark this as Active"}
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          borderRadius: "8px",
                          backgroundColor: "#FFF4E5",
                          margin: "12px",
                          padding: "12px",
                        }}
                      >
                        NOTE: Once you associate this component with an
                        employee, you will only be able to edit the Name in
                        Payslip and Amount. The changes you make to Amount will
                        apply only to new employees.
                      </div>
                    </div>
                  ) : null}
                </>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    position: "absolute",
                    width: "calc(100% - 24px)",
                    bottom: "12px",
                    borderTop: "1px solid var(--secondary-text)",
                    margin: "0 12px",
                  }}
                >
                  <button
                    className="button secondaryButton"
                    type="button"
                    onClick={returnPage}
                  >
                    Cancel
                  </button>
                  <button className="button primaryButton" type="submit">
                    {editId ? "Update" : "Create"}
                  </button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}

export default CreateReimbursement;
