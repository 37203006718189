import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  contactList:[],
  blockedContacts: [],
  bookMarkedMessages:[],
  currentChatDetails:{}
};

const ChatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    setContactList: (state, action) => {
      state.contactList = action.payload;
    },
    setBlockedContacts: (state, action) => {
      state.blockedContacts = action.payload;
    },
    setBookMarkedMessages: (state, action) => {
      state.bookMarkedMessages = action.payload;
    },
    setCurrentChatDetails: (state, action) => {
      state.currentChatDetails = action.payload;
    },
  },
});

export const { setContactList, setBlockedContacts, setBookMarkedMessages, setCurrentChatDetails } = ChatSlice.actions;
export default ChatSlice.reducer;


