import React, { useEffect, useState } from "react";
import "./AttendanceCard.css";
import { Calendar } from "antd";
import dateFormat from "dateformat";
import { useDispatch } from "react-redux";
import { showToast } from "../../../../../Features";
import { getRequest } from "../../../../../Services/axios";
import { BlankCardComp } from "./AttendanceCardComp/BlankCardComp";
import { AbsentCardComp } from "./AttendanceCardComp/AbsenCardComp";
import { PresentCardComp } from "./AttendanceCardComp/PresentCardComp";
import { WeeklyCardComp } from "./AttendanceCardComp/WeeklyCardComp";
import { HolidayCardComp } from "./AttendanceCardComp/HolidayCardComp";
import { HalfDayCardComp } from "./AttendanceCardComp/HalfDayCardComp";
import { LeaveCardComp } from "./AttendanceCardComp/LeaveCardComp";
import { OptionalLeaveCardComp } from "./AttendanceCardComp/OptionalLeaveCardComp";
import { OutdoorsCardComp } from "./AttendanceCardComp/OutdoorsCardComp";
import { useSelector } from "react-redux";
import { SelectCustom } from "../../../../../Components/FormComponent/SelectCustom";
import Skeleton from "react-loading-skeleton";

const AttendanceCard = ({ isManager, navigate, teamList }) => {
  const { LoggedInUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [attendanceList, setAttendanceList] = useState(null);
  const [idActive, setIdActive] = useState(LoggedInUser?.id);
  const [dateObject, setDateObject] = useState({
    startDate: dateFormat(
      new Date(new Date()?.getFullYear(), new Date()?.getMonth(), 1),
      "yyyy-mm-dd"
    ),
    endDate: dateFormat(
      new Date(new Date()?.getFullYear(), new Date()?.getMonth() + 1, 0),
      "yyyy-mm-dd"
    ),
  });
  const [currentMonth, setCurrentMonth] = useState(
    dateFormat(new Date(), "mmmm yyyy")
  ); // State variable to hold the current month name and year

  useEffect(() => {
    getAttendanceData(idActive, dateObject?.startDate, dateObject?.endDate);
  }, [idActive, dateObject]);

  useEffect(() => {
    if (LoggedInUser) {
      setIdActive(LoggedInUser?.id);
    }
  }, [LoggedInUser]);

  const [selectedEmployee, setSelectedEmployee] = useState([
    { label: "Myself", value: LoggedInUser?.id },
  ]);

  const onPanelChange = (value, mode) => {
    const starttDate = dateFormat(
      new Date(value.$d?.getFullYear(), value.$d?.getMonth(), 1),
      "yyyy-mm-dd"
    );
    const enddDate = dateFormat(
      new Date(value.$d?.getFullYear(), value.$d?.getMonth() + 1, 0),
      "yyyy-mm-dd"
    );
    setDateObject({ startDate: starttDate, endDate: enddDate });
    setCurrentMonth(dateFormat(value.$d, "mmmm yyyy")); // Update current month name and year
  };

  const getAttendanceData = async (id, startDate, endDate) => {
    if (!id) return;
    if (!startDate) return;
    if (!endDate) return;
    try {
      setLoading(true);
      const result = await getRequest(
        `Attendance/GetAttendance/${id}/${startDate}/${endDate}`
      );
      setAttendanceList(result.data);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };

  const dateFullCellRender = (date) => {
    const data =
      attendanceList &&
      attendanceList?.find(
        (x) =>
          new Date(x.start).toDateString() === new Date(date).toDateString()
      );

    if (!data) {
      return (
        <>
          <BlankCardComp
            date={dateFormat(date, "dd")}
            title={data?.title}
            time={data?.timing ? data?.timing?.split("\n") : null}
            source={data?.source}
          />
        </>
      );
    } else if (data?.title?.includes("Absent")) {
      return (
        <>
          <AbsentCardComp
            date={dateFormat(data?.start, "dd")}
            title={data?.title}
          />
        </>
      );
    } else if (data?.title?.includes("Present")) {
      return (
        <>
          <PresentCardComp
            date={dateFormat(data?.start, "dd")}
            title={data?.title}
            time={data?.timing ? data?.timing?.split("\n") : null}
            source={data?.source}
          />
        </>
      );
    } else if (data?.title.includes("Weekly")) {
      return (
        <>
          <WeeklyCardComp
            date={dateFormat(data?.start, "dd")}
            title={data?.title}
          />
        </>
      );
    } else if (data?.title.includes("Holiday")) {
      return (
        <>
          <HolidayCardComp
            date={dateFormat(data?.start, "dd")}
            title={data?.title}
          />
        </>
      );
    } else if (data?.title.includes("Half")) {
      return (
        <>
          <HalfDayCardComp
            date={dateFormat(data?.start, "dd")}
            title={data?.title}
            time={data?.timing ? data?.timing?.split("\n") : null}
          />
        </>
      );
    } else if (data?.title.includes("Leave")) {
      return (
        <>
          <LeaveCardComp
            date={dateFormat(data?.start, "dd")}
            title={data?.title}
          />
        </>
      );
    } else if (data?.title.includes("Optional")) {
      return (
        <>
          <OptionalLeaveCardComp
            date={dateFormat(data?.start, "dd")}
            title={data?.title}
          />
        </>
      );
    } else if (data?.title.includes("Outdoor")) {
      return (
        <>
          <OutdoorsCardComp
            date={dateFormat(data?.start, "dd")}
            title={data?.title}
            time={data?.timing ? data?.timing?.split("\n") : null}
          />
        </>
      );
    } else {
      return (
        <>
          <BlankCardComp date={dateFormat(date, "dd")} title={data?.title} />
        </>
      );
    }
  };

  const headerRender = () => null;

  return (
    <>
      <div
        className="headingContainer cursor headingWithDropdown"
        style={{ marginBottom: "-12px", zIndex: "999" }}
      >
        <h3
          className="cursor"
          onClick={() => navigate("/hrms/timeattendance/myattendance")}
        >
          Attendance
        </h3>
        <h3 style={{fontSize:'14px',color:'black'}}>{currentMonth}</h3> {/* Display the current month */}
        {isManager && (
          <div style={{ width: "48%" }}>
            <SelectCustom
              dropdownHeight="236px"
              styles={{ minWidth: "100%", width: "100%", maxWidth: "100%" }}
              values={selectedEmployee}
              options={teamList}
              onChange={(value) => {
                setIdActive(value?.[0]?.value);
                setSelectedEmployee(value);
              }}
              fullWidth={true}
              styl={{
                backgroundColor: "var(--primary-hover)",
                margin: 0,
                minHeight: "40px",
                maxHeight: "40px",
                padding: "5px 10px",
              }}
            />
          </div>
        )}
      </div>
      <div className="dashboardCardContentHolder">
        {loading && (
          <div
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              zIndex: 10,
            }}
          >
            <Skeleton
              width="100%"
              height="100%"
              style={{ position: "absolute" }}
            />
          </div>
        )}
        <div className="calendarContainer">
          <Calendar
            fullscreen={false}
            fullCellRender={dateFullCellRender}
            style={{ textAlign: "center" }}
            onPanelChange={onPanelChange}
            headerRender={headerRender}
          />
        </div>
      </div>
    </>
  );
};

export { AttendanceCard };
