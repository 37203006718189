import React, { useCallback, useEffect, useRef, useState } from "react";
import "./Chat.css";

import { useSelector } from "react-redux";
import { Form, Formik } from "formik";
import { Box, IconButton, Popover, Tooltip } from "@mui/material";
import { ChatMessage } from "./Components/ChatMessage";
import EmojiPicker from "emoji-picker-react";
import { InputCustom } from "../../Components/FormComponent/InputCustom";
import ContactList from "./Components/ContactList";
import signalRService from "../../Services/signalRService";
import OutsideClickHandler from "../../Components/OutsideClickHandler/OutsideClickHandler";
import MyProfileDetails from "./Components/MyProfileDetails/MyProfileDetails";
import SendImageChat from "../../Assets/SendImageChat.svg";
import ScheduleMessageChat from "../../Assets/ScheduleMessageChat.svg";
import BeforeActiveThirdImg from "../../Assets/BeforeActiveThirdImg.svg";
import BeforeActiveSecondImg from "../../Assets/BeforeActiveSecondImg.svg";
import BeforeActiveFirstImg from "../../Assets/BeforeActiveFirstImg.svg";
import { format } from "date-fns";
import { ImageViewer } from "./Components/UserAttchments/ImageViewer";
import MySettingDetails from "./Components/Settings/MySettingDetails";
import ChatInterfaceIntro from "./Components/ChatInterfaceIntro";
import StarredMessages from "./Components/StarredMessages/StarredMessages";
import BlockLists from "./Components/BlockLists/BlockLists";
import EmpProfiledetails from "./Components/EmpProfileDetails/EmpProfileDetails";
import ChatInterface from "./ChatInterface";
import InputSectionChat from "./InputSectionChat";
import NewGroup from "./Components/NewGroup/NewGroup";
import { Popup } from "../../Components/FormComponent/PopupComponent/Popup";
import ForwardInputChat from "./ForwardInputChat";
import ForwardPopup from "./ForwardPopup";
import GroupInfo from "./Components/GroupInfo/GroupInfo";
import ForwardViewAll from "./ForwardViewAll";
import DocumentPreviewContainer from "./Components/UserAttchments/DocumentPreviewContainer";
import { confirmAlert } from "react-confirm-alert";
import CompanyBroadcast from "./CompanyBroadcast";

export const ChatApp = () => {
  const { LoggedInUser } = useSelector((state) => state.auth);
  const [emoji, setEmoji] = useState(false);
  const [src, setSrc] = useState(null);
  const [activeChat, SetActiveChat] = useState("");
  const [messageInput, setMessageInput] = useState("");
  const [chatList, setChatList] = useState([]);
  const [selectedEmoji, setSelectedEmoji] = useState([]);
  const [fileType, setFileType] = useState("");
  const [settingsActive, setSettingsActive] = useState(false);
  const [starredMessage, setStarredMessage] = useState(false);
  const [blocklist, setBlocklist] = useState(false);
  const [groupInfo, setGroupInfo] = useState(false);
  const [mySelfChat, setMySelfChat] = useState(false);
  const [empProfileDetails, setEmpProfileDetails] = useState(false);
  const [myProfileDetails, setMyProfileDetails] = useState(false);
  const [newChat, setNewChat] = useState(false);
  const [newGroup, setNewGroup] = useState(false);
  const [allChatList, setAllChatList] = useState([]);
  const [messageList, setMessageList] = useState([]);
  const [messageResponse, setMessageResponse] = useState([]);
  const [newMessages, setNewMessages] = useState([]);
  const [contactsList, setContactsList] = useState([]);
  const [cursorPosition, setCursorPosition] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [sendPayload, setSendPayload] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchor, setAnchor] = useState(null);
  const [viewAll, setViewAll] = useState(false);
  const [selectedDocs, setSelectedDocs] = useState([]);
  const [companyBroadcast, setCompanyBroadcast] = useState(false);
  const [attachmentSelected, setAttachmentSelected] = useState(false);

  const handleDocsSelect = (files) => {
    if (!files) {
      setSelectedDocs([]);
      setAttachmentSelected(false);
    } else {
      setSelectedDocs(files);
      setAttachmentSelected(true);
    }
  };
  const [replyData, setReplyData] = useState({
    replyId: null,
    replyContent: "",
    data: null,
  });
  const [forwardData, setForwardData] = useState({
    isActive: false,
    msgId: [],
    userId: [],
    conversationId: [],
  });
  const [isOpen, setIsOpen] = useState(false);

  const togglePopup = () => {
    setIsOpen((prev) => !prev);
  };
  const togglePop = () => {
    setCompanyBroadcast((prev) => !prev);
  };
  const toggleNewGroup = () => {
    setNewGroup((prev) => !prev);
    setAnchorEl(false);
  };
  const inputFile = useRef(null);

  const open = Boolean(anchorEl);
  const rightOpen = Boolean(anchor);
  // console.log("anchorEl", open);
  const id = open ? "simple-popover" : undefined;
  const _id = rightOpen ? "simple-popover" : undefined;
  const handleMoreClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleRightMoreClick = (event) => {
    setAnchor(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setAnchor(null);
  };

  useEffect(() => {
    console.log("c0nnection useEffect called");
    const initChat = async () => {
      await signalRService.startConnection(LoggedInUser.personNumber);
      signalRService.onUpdateConversations((data) => {
        // setChatList((prevChatList) => {
        //   return [...prevChatList, ...data];
        // });
        setChatList([...data]);
      });
      signalRService.onUpdateMessages(setMessageList);
      signalRService.onNewMessages(setNewMessages);
      signalRService.onMessageResponse(setMessageResponse);
      signalRService.onUpdateContacts(setContactsList);

      // Load initial data
      const initialConversations = await signalRService.invokeGetConversations(
        LoggedInUser.personNumber
      );
      const initialContacts = await signalRService.invokeGetContacts(
        LoggedInUser.personNumber
      );
      // setChatList(initialConversations);
    };

    initChat();

    return () => {
      setMessageList([]);
      setChatList([]);
      setContactsList([]);
      signalRService.stopConnection();
    };
  }, [LoggedInUser.personNumber]);

  const handleDocumentClick = () => {
    // Trigger file picker for documents
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept =
      "application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"; // Define accepted document types
    fileInput.addEventListener("change", (event) => {
      const selected = event.target.files[0];
      handleFileSelection(selected, "document");
    });
    fileInput.click();
  };
  const handleFileSelection = (file, type) => {
    setSelectedFile(file);
    setFileType(type);
    setShowOptions(false);
  };
  const handlePhotoClick = () => {
    // Trigger file picker for photos
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = "image/*"; // Accept all image types
    fileInput.addEventListener("change", (event) => {
      const selected = event.target.files[0];
      setSrc(URL.createObjectURL(event.target.files[0]));
      handleFileSelection(selected, "photo");
      // console.log("selected", selected);
    });
    fileInput.click();
  };
  useEffect(() => {
    setSendPayload({
      From: {
        Id: LoggedInUser?.id,
        MyIBID: activeChat?.participatingContacts?.filter(
          (item) => item.personNumber === LoggedInUser?.personNumber
        )[0]?.myIBID,
        Name: LoggedInUser?.name,
        PersonNumber: LoggedInUser?.personNumber,
      },
      Time: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss"),
      MessageType: 0,
      Caption: "",
      Content: "",
      MessageStatus: 0,
      ReplyingToMessageId: null,
      ReplyingToMessage: null,
      ConversationId: activeChat?.id,
      HasBookmarked: false,
      IsMediaCompressed: false,
    });
  }, [activeChat]);

  const handleInputChange = (e) => {
    const { value } = e.target;
    setMessageInput(value);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Backspace") {
      // Handle backspace key
      if (messageInput === "") {
        // Remove the last selected emoji when the input field is empty
        setSelectedEmoji((prev) => prev.slice(0, -1));
      }
    } else if (e.key === " ") {
      // Handle space key
      if (messageInput.endsWith(" ")) {
        // Append the last selected emoji when the space key is pressed after a word
        setSelectedEmoji((prev) => [...prev, " "]);
      }
    }
  };
  function createNewGroupChat(items) {
    let tempList = [...chatList];
    let newData = tempList.splice(0, 0, items);
    setChatList([...tempList]);
    SetActiveChat((tempList[0] = items));
    SetActiveChat(tempList[0].msgs ?? []);
  }
  function searchChatters(e) {
    if (e.target.value !== "") {
      let tempChatters = JSON.parse(JSON.stringify(allChatList));
      tempChatters = tempChatters.filter((d, i) =>
        d.name?.toLowerCase()?.includes(e.currentTarget.value?.toLowerCase())
      );
      setChatList(tempChatters);
    } else {
      setChatList(allChatList);
    }
  }
  // console.log("chatList", chatList);
  function showStatus(onlineStatus) {
    if (onlineStatus === 1) {
      return "greenDot";
    } else if (onlineStatus === 2) {
      return "redDot";
    } else if (onlineStatus === 3) {
      return "yellowDot";
    } else if (onlineStatus === 4) {
      return "offlineDot";
    } else {
      return "offlineDot";
    }
  }
  const handleAttachmentClick = () => {
    setShowOptions((prev) => !prev);
  };
  const deleteMessage = (conversationId, userId, msgId) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui-chat-Delete">
            <h1 className="deleteMessageFont">Delete Message?</h1>
            <div>
              <button
                className="button cancelButton chatDeleteButton"
                onClick={onClose}
              >
                Cancel
              </button>
              <button
                className="button primaryButton chatDeleteButton"
                onClick={() => {
                  signalRService?.usedeleteSelectedMsg(
                    "deleteForMe",
                    conversationId,
                    userId,
                    msgId
                  );
                  onClose();
                  handleContactClick(conversationId);
                  setForwardData({
                    isActive: false,
                    msgId: [],
                    userId: [],
                    conversationId: [],
                  });
                }}
              >
                Delete for me
              </button>
              <button
                className="button primaryButton chatDeleteButton"
                onClick={() => {
                  signalRService?.usedeleteSelectedMsg(
                    "deleteForEveryone",
                    conversationId,
                    userId,
                    msgId
                  );
                  onClose();
                  handleContactClick(conversationId);
                  setForwardData({
                    isActive: false,
                    msgId: [],
                    userId: [],
                    conversationId: [],
                  });
                }}
              >
                Delete for everyone
              </button>
            </div>
          </div>
        );
      },
    });
  };
  const sendMessageText = async (messageInput, replyID) => {
    let currentUser = activeChat?.participatingContacts?.filter(
      (item) => item.personNumber === LoggedInUser?.personNumber
    );
    let chatMsgObj = {
      From: {
        Id: 0,
        MyIBID: 0,
        Name: "",
        PersonNumber: "",
      },
      Time: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss"),
      MessageType: 0,
      Caption: "",
      Content: "",
      MessageStatus: 0,
      ReplyingToMessageId: null,
      ReplyingToMessage: null,
      ConversationId: activeChat?.id,
      HasBookmarked: false,
      IsMediaCompressed: false,
    };
    chatMsgObj.From.Id = currentUser[0].id;
    chatMsgObj.From.MyIBID = currentUser[0].myIBID;
    chatMsgObj.From.Name = currentUser[0].name;
    chatMsgObj.From.PersonNumber = currentUser[0].personNumber;
    chatMsgObj.Content = messageInput;
    if (replyID) {
      chatMsgObj.ReplyingToMessageId = replyID;
    }
    console.log("object chatMsgObj", chatMsgObj);
    await signalRService.invokeSendMessages(
      LoggedInUser.personNumber,
      activeChat?.id,
      chatMsgObj
    );
  };
  const getMessagesList = async (contactId) => {
    await signalRService.invokeGetMessages(
      contactId,
      LoggedInUser.personNumber
    );
  };

  console.log("messageList", messageList);

  const handleContactClick = (contactId) => {
    getMessagesList(contactId);
  };
  const handleEmojiClick = (emojiData) => {
    const { emoji } = emojiData;
    setMessageInput((prevMessage) => {
      // Insert the emoji at the cursor position
      const start = prevMessage.substring(0, cursorPosition);
      const end = prevMessage.substring(cursorPosition);
      return start + emoji + end;
    });
    setCursorPosition((prevPos) => prevPos + 2); // Move the cursor after the inserted emoji
  };
  const handleOptionSelect = async (option) => {
    if (option === "Settings") {
      setSettingsActive(true);
    } else if (option === "starredMessage") {
      setStarredMessage(true);
    } else if (option === "blocklist") {
      setBlocklist(true);
    } else if (option === "New Group") {
      setNewGroup(true);
    } else if (option === "Company Broadcast") {
      setCompanyBroadcast(true);
    }
    handleClose();
  };
  return (
    <>
      <div className="chatContainerBg">
        <div className="chatContainerBg1"></div>
        <div className="chatContainerBg2"></div>
        <div className="chatContainerBg3"></div>
        <div className="chatContainerBg4"></div>
      </div>
      <div className="chatContainer">
        <div className="chatMainContainer">
          {myProfileDetails && (
            <MyProfileDetails setMyProfileDetails={setMyProfileDetails} />
          )}

          {settingsActive && (
            <MySettingDetails
              setAnchorEl={setAnchorEl}
              setSettingsActive={setSettingsActive}
            />
          )}
          {starredMessage && (
            <div className="chatContactListContainer">
              <div className="myProfileHeaderContainer">
                <div className="myProfileHeaderWrapper">
                  <svg
                    onClick={() => {
                      setStarredMessage(false);
                      setAnchorEl(false);
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="19"
                    height="12"
                    viewBox="0 0 19 12"
                    fill="none"
                  >
                    <path
                      d="M18.0986 6.60446C18.5128 6.60446 18.8486 6.26868 18.8486 5.85446C18.8486 5.44025 18.5128 5.10446 18.0986 5.10446V6.60446ZM0.495584 5.32413C0.202692 5.61702 0.202692 6.0919 0.495584 6.38479L5.26856 11.1578C5.56145 11.4507 6.03632 11.4507 6.32922 11.1578C6.62211 10.8649 6.62211 10.39 6.32922 10.0971L2.08657 5.85446L6.32922 1.61182C6.62211 1.31893 6.62211 0.844054 6.32922 0.551161C6.03632 0.258267 5.56145 0.258267 5.26856 0.551161L0.495584 5.32413ZM18.0986 5.10446L1.02592 5.10446V6.60446L18.0986 6.60446V5.10446Z"
                      fill="#F7941D"
                    />
                  </svg>
                  <span>Starred message</span>
                </div>
                <StarredMessages setStarredMessages={setStarredMessage} />
              </div>
            </div>
          )}
          {blocklist && (
            <div className="chatContactListContainer">
              <div className="myProfileHeaderContainer">
                <div className="myProfileHeaderWrapper">
                  <svg
                    onClick={() => {
                      setBlocklist(false);
                      setAnchorEl(false);
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="19"
                    height="12"
                    viewBox="0 0 19 12"
                    fill="none"
                  >
                    <path
                      d="M18.0986 6.60446C18.5128 6.60446 18.8486 6.26868 18.8486 5.85446C18.8486 5.44025 18.5128 5.10446 18.0986 5.10446V6.60446ZM0.495584 5.32413C0.202692 5.61702 0.202692 6.0919 0.495584 6.38479L5.26856 11.1578C5.56145 11.4507 6.03632 11.4507 6.32922 11.1578C6.62211 10.8649 6.62211 10.39 6.32922 10.0971L2.08657 5.85446L6.32922 1.61182C6.62211 1.31893 6.62211 0.844054 6.32922 0.551161C6.03632 0.258267 5.56145 0.258267 5.26856 0.551161L0.495584 5.32413ZM18.0986 5.10446L1.02592 5.10446V6.60446L18.0986 6.60446V5.10446Z"
                      fill="#F7941D"
                    />
                  </svg>
                  <span>Block List</span>
                </div>
              </div>
              <BlockLists setBlockContactList={setBlocklist} />
            </div>
          )}

          {!myProfileDetails &&
            !settingsActive &&
            !starredMessage &&
            !blocklist && (
              <div className="chatContactListContainer">
                <div className="chatHeaderContainer">
                  <div className="selfChatHeader">
                    <div
                      className="chatListHeader"
                      onClick={() => {
                        setMyProfileDetails(true);
                      }}
                    >
                      <div className="activeImage">
                        <img
                          src={`${process.env.REACT_APP_.BASE_URL}/Core/Uploads/ProfileImageUploads/${LoggedInUser?.image}`}
                          alt="Profile"
                        />
                        <div className="greenDot"></div>
                      </div>
                      <div className="allChatListDisplayContainer">
                        <label className="postMainName">
                          {LoggedInUser?.name &&
                            (LoggedInUser?.name?.replace(/\s\(\d+\)$/, "")
                              .length > 20
                              ? LoggedInUser?.name
                                  .replace(/\s\(\d+\)$/, "")
                                  .substring(0, 20) + "..."
                              : LoggedInUser?.name.replace(/\s\(\d+\)$/, ""))}
                        </label>
                        <label>Active</label>
                      </div>
                    </div>
                    <div className="selfChatHeaderActionsContainer">
                      <div className="newChatAndGroupContainer">
                        <IconButton
                          id={id}
                          aria-describedby={id}
                          onClick={(event) => handleMoreClick(event)}
                          title="more"
                        >
                          <svg
                            width="4"
                            height="18"
                            viewBox="0 0 4 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle
                              cx="1.72626"
                              cy="2.4375"
                              r="1.5"
                              fill="#1296B0"
                            />
                            <circle
                              cx="1.72626"
                              cy="9.4375"
                              r="1.5"
                              fill="#1296B0"
                            />
                            <circle
                              cx="1.72626"
                              cy="16.4375"
                              r="1.5"
                              fill="#1296B0"
                            />
                          </svg>
                        </IconButton>
                      </div>
                    </div>
                    <Popover
                      id={id}
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                    >
                      <div
                        className="dropdowncol"
                        style={{
                          fontFamily: "Poppins",
                          fontSize: "12px",
                        }}
                      >
                        <div
                          className="taskCont"
                          onClick={() =>
                            handleOptionSelect("Company Broadcast")
                          }
                        >
                          Company Broadcast{" "}
                        </div>
                        <div
                          className="taskCont"
                          onClick={() => handleOptionSelect("New Group")}
                        >
                          New Group
                        </div>
                        <div
                          className="taskCont"
                          onClick={() => handleOptionSelect("Hold Salary")}
                        >
                          Community
                        </div>
                        <div
                          className="taskCont"
                          onClick={() => handleOptionSelect("starredMessage")}
                        >
                          Starred Message
                        </div>
                        <div
                          className="taskCont"
                          onClick={() => handleOptionSelect("blocklist")}
                        >
                          Block List
                        </div>
                        <div
                          className="taskCont"
                          onClick={() => handleOptionSelect("Settings")}
                        >
                          Settings
                        </div>
                      </div>
                    </Popover>
                    <Popover
                      id={_id}
                      open={rightOpen}
                      anchorEl={anchor}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                    >
                      <div
                        className="dropdowncol"
                        style={{
                          fontFamily: "Poppins",
                          fontSize: "12px",
                        }}
                      >
                        <div
                          className="taskCont"
                          onClick={() =>
                            handleOptionSelect("Company Broadcast")
                          }
                        >
                         View contact
                        </div>
                        <div
                          className="taskCont"
                          onClick={() => handleOptionSelect("New Group")}
                        >
                          Media, links and docs
                        </div>
                        <div
                          className="taskCont"
                          onClick={() => handleOptionSelect("Hold Salary")}
                        >
                         Clear Chat
                        </div>
                        <div
                          className="taskCont"
                          onClick={() => handleOptionSelect("starredMessage")}
                        >
                          Block
                        </div>
                      
                      </div>
                    </Popover>
                  </div>
                </div>
                <div style={{ height: "calc(100% - 90px)", width: "100%" }}>
                  <div className="chatListSearchContainer">
                    <input
                      type="text"
                      className="chatListSearch"
                      placeholder="Search"
                      onChange={searchChatters}
                    />
                    <svg
                      width="13"
                      height="13"
                      viewBox="0 0 13 13"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="5.5" cy="5.5" r="4.5" stroke="#1296B0" />
                      <line
                        x1="8.70711"
                        y1="9"
                        x2="12"
                        y2="12.2929"
                        stroke="#1296B0"
                        strokeLinecap="round"
                      />
                    </svg>
                  </div>

                  <div
                    className="mySelfChatContainer"
                    onClick={() => {
                      setMySelfChat(true);
                      // setActiveAttachment(mySelfActiveChat);
                    }}
                  >
                    <div
                      className="chatListContentContainerActive mySelfChatBox active"
                      style={{
                        backgroundColor: "#F0F0FF",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <div className="activeImage">
                          <img
                            src={`${process.env.REACT_APP_.BASE_URL}/Core/Uploads/ProfileImageUploads/${LoggedInUser?.image}`}
                            alt="Profile"
                          />
                          <div className={"greenDot"}></div>
                        </div>
                        <div className="allChatListDisplayContainer">
                          <label
                            className="chatListName"
                            style={{ color: "#8A88ED" }}
                          >
                            My Self
                          </label>
                          <label className="chatListlastMsg">
                            Hello,{" "}
                            {LoggedInUser?.name &&
                              LoggedInUser?.name
                                ?.replace(/\s\(\d+\)$/, "")
                                ?.split(" ")[0]}{" "}
                            how are you
                          </label>
                        </div>
                      </div>
                      <div style={{ color: "#8A88ED", paddingRight: " 14px" }}>
                        {">>"}
                      </div>
                    </div>
                  </div>
                  <div className="chatListContainer">
                    {/* Show Chat Contact List Here */}
                    <ContactList
                      LoggedInUser={LoggedInUser}
                      chatList={chatList}
                      showStatus={showStatus}
                      SetActiveChat={SetActiveChat}
                      handleContactClick={handleContactClick}
                    />
                  </div>
                </div>
              </div>
            )}
          {Object.keys(activeChat)?.length > 0 ? (
            <div className="chatConversationContainer">
              <>
                <div className="chatHeaderContainer">
                  <div className="openedChatHeader">
                    <div
                      className="selfChatHeader"
                      onClick={() => {
                        activeChat?.type === undefined ||
                        activeChat?.type === "Contact"
                          ? setEmpProfileDetails(true)
                          : setGroupInfo(true);
                      }}
                    >
                      {/* {console.log("activeChat", activeChat)} */}
                      <div className="activeImage">
                        <div
                          className={`activeImageContainer ${
                            !activeChat.icon ? "customProfilePic" : ""
                          }`}
                          style={
                            activeChat.icon
                              ? {
                                  backgroundImage: `url(${activeChat.icon})`,
                                }
                              : {}
                          }
                        >
                          {!activeChat?.icon
                            ? activeChat?.title
                                ?.split(" ")
                                ?.slice(0, 2)
                                ?.map((item) => {
                                  return item?.charAt(0)?.toUpperCase();
                                })
                                .join("")
                            : null}
                        </div>
                        <div
                        // className={showStatus(activeChat.onlineStatus)}
                        ></div>
                      </div>
                      <div
                        className="allChatListDisplayContainer"
                        style={{ justifyContent: "start" }}
                      >
                        <label className="postMainName">
                          {activeChat?.title}
                        </label>
                        {activeChat?.type === "Contact" && (
                          <label>
                            {activeChat?.participatingContacts?.filter(
                              (item) =>
                                item.personNumber !== LoggedInUser?.personNumber
                            )[0]?.onlineStatus === 1 && "Active"}{" "}
                            {activeChat?.participatingContacts?.filter(
                              (item) =>
                                item.personNumber !== LoggedInUser?.personNumber
                            )[0]?.onlineStatus === 2 && "Busy"}{" "}
                            {activeChat?.participatingContacts?.filter(
                              (item) =>
                                item.personNumber !== LoggedInUser?.personNumber
                            )[0]?.onlineStatus === 3 && "Away"}{" "}
                            {activeChat?.participatingContacts?.filter(
                              (item) =>
                                item.personNumber !== LoggedInUser?.personNumber
                            )[0]?.onlineStatus === 4 && "Offline"}
                          </label>
                        )}
                      </div>
                    </div>
                   
                  </div>
                  <div className="newChatAndGroupContainer" style={{position: 'absolute',top: '14px',right: '5px'}}>
                        <IconButton
                          id={id}
                          aria-describedby={id}
                          onClick={(event) => handleRightMoreClick(event)}
                          title="more"
                        >
                          <svg
                            width="4"
                            height="18"
                            viewBox="0 0 4 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle
                              cx="1.72626"
                              cy="2.4375"
                              r="1.5"
                              fill="#1296B0"
                            />
                            <circle
                              cx="1.72626"
                              cy="9.4375"
                              r="1.5"
                              fill="#1296B0"
                            />
                            <circle
                              cx="1.72626"
                              cy="16.4375"
                              r="1.5"
                              fill="#1296B0"
                            />
                          </svg>
                        </IconButton>
                      </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    height: "calc(100% - 65px)",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {attachmentSelected ? (
                    <DocumentPreviewContainer
                      // handleDocumentClick={handleDocumentClick}
                      handleDocsSelect={handleDocsSelect}
                      selectedFiles={selectedDocs}
                      attachmentSelected={attachmentSelected}
                    />
                  ) : (
                    <>
                      <div
                        className="imagePreviewContainer"
                        style={{
                          flex: " 1",
                          display: "flex",
                          flexDirection: "column",
                          padding: "0px",
                        }}
                      >
                        <ChatInterface
                          newMessages={newMessages}
                          messageResponse={messageResponse}
                          deleteMessage={deleteMessage}
                          isOpen={isOpen}
                          togglePopup={togglePopup}
                          replyData={replyData}
                          forwardData={forwardData}
                          setForwardData={setForwardData}
                          LoggedInUser={LoggedInUser}
                          messageList={messageList}
                          setReplyData={setReplyData}
                          handleContactClick={handleContactClick}
                        />

                        <OutsideClickHandler
                          onOutsideClick={() => {
                            setEmoji(false);
                          }}
                        >
                          {emoji && (
                            <EmojiPicker
                              style={{ bottom: "6px", left: "6px" }}
                              onEmojiClick={handleEmojiClick}
                            />
                          )}
                        </OutsideClickHandler>
                      </div>
                      {forwardData?.isActive ? (
                        <ForwardInputChat
                          LoggedInUser={LoggedInUser}
                          deleteMessage={deleteMessage}
                          isOpen={isOpen}
                          togglePopup={togglePopup}
                          forwardData={forwardData}
                          setForwardData={setForwardData}
                        />
                      ) : (
                        <InputSectionChat
                          setMessageInput={setMessageInput}
                          setReplyData={setReplyData}
                          LoggedInUser={LoggedInUser}
                          handleDocsSelect={handleDocsSelect}
                          replyData={replyData}
                          setEmoji={setEmoji}
                          handleAttachmentClick={handleAttachmentClick}
                          showOptions={showOptions}
                          handleDocumentClick={handleDocumentClick}
                          handlePhotoClick={handlePhotoClick}
                          inputFile={inputFile}
                          setShowOptions={setShowOptions}
                          src={src}
                          fileType={fileType}
                          selectedFile={selectedFile}
                          messageInput={messageInput}
                          sendMessageText={sendMessageText}
                          selectedEmoji={selectedEmoji}
                          ScheduleMessageChat={ScheduleMessageChat}
                          handleInputChange={handleInputChange}
                          handleKeyDown={handleKeyDown}
                          SendImageChat={SendImageChat}
                        />
                      )}
                    </>
                  )}
                </div>
              </>
            </div>
          ) : (
            <ChatInterfaceIntro
              LoggedInUser={LoggedInUser}
              BeforeActiveFirstImg={BeforeActiveFirstImg}
              BeforeActiveSecondImg={BeforeActiveSecondImg}
              BeforeActiveThirdImg={BeforeActiveThirdImg}
            />
          )}
          {empProfileDetails && (
            <EmpProfiledetails
              setEmpProfileDetails={setEmpProfileDetails}
              // setActiveAttachment={setActiveAttachment}
              activeChat={activeChat}
            />
          )}
          {groupInfo && (
            <GroupInfo
              setGroupInfo={setGroupInfo}
              // setActiveAttachment={setActiveAttachment}
            />
          )}
        </div>
      </div>

      {isOpen && (
        <Popup
          contentClassName="forwardPopup-chat"
          popupHeading={
            viewAll
              ? "(" + `${forwardData?.userId?.length}` + ")"
              : "Forward message to"
          }
          needPrevious={viewAll}
          handlePrevious={() => setViewAll((prev) => !prev)}
          bodyClassName="overflowStop"
          createMyHead="createHeadForward"
          content={
            !viewAll ? (
              <ForwardPopup
                handleClose={togglePopup}
                LoggedInUser={LoggedInUser}
                contactsList={contactsList}
                chatList={chatList}
                setForwardData={setForwardData}
                forwardData={forwardData}
                setViewAll={setViewAll}
                viewAll={viewAll}
              />
            ) : (
              <>
                <ForwardViewAll
                  LoggedInUser={LoggedInUser}
                  contactsList={contactsList}
                  chatList={chatList}
                  setForwardData={setForwardData}
                  forwardData={forwardData}
                  setViewAll={setViewAll}
                  viewAll={viewAll}
                  showStatus={showStatus}
                />
              </>
            )
          }
          handleClose={togglePopup}
        />
      )}
      {newGroup && (
        <Popup
          firstClassName="newChatPopup1"
          secondClassName="newChatPopup2"
          bodyClassName="newChatPopupBodyContainer"
          popupHeading={"Add Group Member"}
          content={
            <>
              <NewGroup
                setNewGroup={setNewGroup}
                createNewGroupChat={createNewGroupChat}
                chatList={chatList}
                handleClose={handleClose}
              />
            </>
          }
          handleClose={toggleNewGroup}
        />
      )}
      {companyBroadcast && (
        <Popup
          color="var(--primary)"
          popupHeading={"Select Contact"}
          contentClassName='newPad'
          content={
            <>
              <CompanyBroadcast setCompanyBroadcast={setCompanyBroadcast} />
            </>
          }
          handleClose={togglePop}
        />
      )}
    </>
  );
};
