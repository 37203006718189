import React from 'react';
import { TaxDeclarationTab } from './TaxDeclarationTab';
import { useEffect } from 'react';

const TaxDeclaration = () => {
    useEffect(() => {
        document.title = `PeopleSol -  Tax Declaration`;
        return () => {
          document.title = 'PeopleSol';
        };
      }, []);
    return (
        <div className="moduleMainContainer">
           
            
            <TaxDeclarationTab title="Tax Declaration Or Submission"/>
        </div>
    )
}

export { TaxDeclaration }