import { IconButton } from "@mui/material";
import React, { useEffect } from "react";
import ArrowBackButton from "../../../../../../../Assets/ArrowBackButton.svg";
import SurveyDownArrowIcon from "../../../../../../../Assets/SurveyDownArrowIcon.svg";
import SurveyUpArrowIcon from "../../../../../../../Assets/SurveyUpArrowIcon.svg";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getRequest } from "../../../../../../../Services/axios";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { showToast } from "../../../../../../../Features";
import ProgressBar from "@ramonak/react-progress-bar";

function SurveyDetailsPage() {
  const [surveyDetailsData, setSurveyDetailsData] = useState({});
  const { state } = useLocation();
  // console.log("state", state);
  const [loading, setLoading] = useState(false);
  const [showQuestionAnswer, setShowQuestionAnswer] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const getSurveyDetails = async () => {
    setLoading(true);
    try {
      const result = await getRequest("Survey/Details/" + id);
      setSurveyDetailsData(result?.data?.summary);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };

  const goToReportPage = (
    displayType,
    answerId = 0,
    surveyTitle = "Overall Survey Report"
  ) => {
    navigate("/home/social/surveyreport/" + id, {
      state: {
        data: surveyDetailsData,
        type: displayType,
        ansId: answerId,
        title: surveyTitle,
      },
    });
  };
  useEffect(() => {
    getSurveyDetails();
  }, []);
  return (
    <div className="surveyDetailsContainer">
      <div className="surveyDetailsWrapper">
        <div className="surveyDetailsHeader">
          <div className="surveyDetailsHeaderImg">
            <IconButton
              onClick={() => {
                navigate(-1);
              }}
            >
              <img
                src={ArrowBackButton}
                alt=""
                style={{ margin: "4px 1px", cursor: "pointer" }}
              />
            </IconButton>
          </div>
          <div className="surveyDetailsHeaderText">
            {surveyDetailsData?.survey?.surveyTitle}
          </div>
        </div>
        <div className="surveyDetailsData">
          <div className="surveyDetailsParaContainer">
            <p>{surveyDetailsData?.survey?.description}</p>
          </div>
          <div className="surveyDetailsSurveyOptionsContainer">
            <h5>
              {state?.type === "survey"
                ? "Overall Survey Report"
                : "Overall Poll Report"}
            </h5>
            <div className="surveyDetailsSurveyOptionsWrapper">
              <div
                className="optionAll"
                onClick={() => {
                  goToReportPage("All");
                }}
              >
                <span>All</span> <label>{surveyDetailsData?.allCount}</label>
              </div>
              <div
                className="optionView"
                onClick={() => {
                  goToReportPage("Viewed");
                }}
              >
                <span>Viewed by</span>{" "}
                <label>{surveyDetailsData?.viewCount}</label>
              </div>
              <div
                className="optionRespond"
                onClick={() => {
                  goToReportPage("Respond");
                }}
              >
                <span>Respond by</span>
                <label>{surveyDetailsData?.respondCount}</label>
              </div>
              <div
                className="optionNotRespond"
                onClick={() => {
                  goToReportPage("NotRespond");
                }}
              >
                <span>Not Respond</span>
                <label>{surveyDetailsData?.notRespondCount}</label>
              </div>
            </div>
          </div>
          <div className="surveyDetailsQuestionContainer">
            <div className="surveyDetailsQuestionWrapper">
              {surveyDetailsData?.survey?.surveyQuestions?.map((d, index) => {
                return (
                  <div className="surveyDetailsQuestionListContent" key={index}>
                    {showQuestionAnswer !== d.id && (
                      <div
                        className="surveyDetailsQuestion"
                        style={{ borderRadius: "6px", margin: "15px 0px" }}
                        onClick={() => {
                          setShowQuestionAnswer(d?.id);
                        }}
                      >
                        <p>{d?.questionText}</p>
                        <img src={SurveyDownArrowIcon} alt="" />
                      </div>
                    )}
                    {showQuestionAnswer === d?.id && (
                      <div className="surveyDetailsFullQuestionAnswer">
                        <div
                          className="surveyDetailsQuestion"
                          onClick={() => {
                            setShowQuestionAnswer(0);
                          }}
                        >
                          <p>{d?.questionText}</p>
                          <img src={SurveyUpArrowIcon} alt="" />
                        </div>
                        <div className="surveyDetailsAnswersListContainer">
                          {d?.surveyQuestionAnswers?.map((qAns, qIndex) => {
                            return (
                              <div
                                className="surveyDetailsAnswers"
                                key={qIndex}
                              >
                                <label>
                                  {qIndex + 1} . {qAns?.answerText}
                                </label>
                                <div className="surveyDetailsGraphContainer">
                                  <div className="surveyDetailsGraphWrapper">
                                    <div>
                                      <ProgressBar
                                        completed={qAns?.respondPercentage}
                                        bgColor="#1296b0"
                                        height="5px"
                                        width="100%"
                                        labelColor="#1296b0"
                                      />
                                    </div>
                                    <label>
                                      <span>{qAns?.respondPercentage}%</span>{" "}
                                      <button
                                        className="button surveyDetailsBtn"
                                        onClick={() => {
                                          goToReportPage(
                                            "All",
                                            qAns?.id,
                                            qAns?.answerText
                                          );
                                        }}
                                      >
                                        Details
                                      </button>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SurveyDetailsPage;
