import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { postRequest } from "../../../Services/axios";
import "./NotificationsItem.css";
import ProfileAvatar from "../../../Assets/ProfileAvatar.svg";
import { getBadgeCount } from "../../../Features/badgeUtils";
import { useEffect } from "react";
import { processExpression } from "../../../Utilities/socialAllFunctions";
import { id } from "date-fns/locale";

const NotificationsItem = (props) => {
  //console.log("props.redirectionUrl",props.redirectionUrl)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const redirect = () => {
    setAsRead();
    if (
      props.redirectionUrl === "home/dashboard" &&
      props.module === "Work & Tasks"
    ) {
      navigate(`/hrms/projecttask/task/details/${props?.rId}`);
      navigate(props.redirectionUrl?.toLowerCase());
      props.closeNotifications();
    }
    if (props?.heading === "Social") {
      navigate("home/social/"+ props.type+ "/details/" + props?.rId);
      props.closeNotifications();
    }
    // else if (props.redirectionUrl?.includes("comment")) {
    //   console.log(
    //     " props.redirectionUrl?.includes(comment)",
    //     props.redirectionUrl?.includes("comment")
    //   );
    //   let arr = props.redirectionUrl?.split("/");
    //   if ((arr[2] = "social")) {
    //     console.log("social arr[5]", arr[5]);
    //     localStorage.setItem("commentid", JSON.stringify(arr[6]));
    //     props.closeNotifications();
    //     navigate("home/social/social/details/" + arr[5]);
    //   } else if ((arr[2] = "news")) {
    //     localStorage.setItem("commentid", JSON.stringify(arr[6]));
    //     props.closeNotifications();
    //     navigate("home/social/news/details/" + arr[5]);
    //   } else if ((arr[2] = "announcements")) {
    //     localStorage.setItem("commentid", JSON.stringify(arr[6]));
    //     props.closeNotifications();
    //     navigate("home/social/announcements/details/" + arr[5]);
    //   }
    // }
    navigate(props.redirectionUrl?.toLowerCase());
    props.closeNotifications();
  };
  const setAsRead = async () => {
    try {
      const result = await postRequest(
        `Settings/NotificationMarkasRead?ID=${props.Id}`
      );
    } catch (error) {
    } finally {
      await getBadgeCount(dispatch);
    }
  };
  const patterns = [
    {
      regex: /\@\[([^\]]+)\]\((\d+)\)/g,
      replaceFunction: (_, text, userId) => {
        return text;
      },
    },
  ];
  function processTextWithPatterns(text, patterns) {
    return patterns.reduce((processedText, pattern) => {
      const { regex, replaceFunction } = pattern;
      return processedText.replace(regex, replaceFunction);
    }, text);
  }
  function processExpression(text) {
    return processTextWithPatterns(text, patterns);
  }
  useEffect(() => {
    document.title = `PeopleSol - Notification Item`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  return (
    <>
      <div className="notificationsitem" onClick={redirect}>
        <div className="notificationsItemImage">
          {props.imagePath ? (
            <img
              src={`${process.env.REACT_APP_.BASE_URL}/Core/Uploads/ProfileImageUploads/${props.imagePath}`}
              alt="Profile"
            />
          ) : (
            <img src={ProfileAvatar} alt="Profile" />
          )}
        </div>
        <div className="notificationDataContainer">
          <div className="notificationCount">{props.heading}</div>
          <div className="horizontalLinePrimary"></div>
          <div
            className="notificationsItemcontent"
            dangerouslySetInnerHTML={{
              __html: processExpression(props?.content),
            }}
          ></div>
          <div className="dataContainer">
            <div className="notificationsItemtime">{props.time}</div>
            <div className="notificationsItemdatetime">{props.datetime}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotificationsItem;
