import React from "react";
import { DatePickerFormiks } from "../../../../Components/FormComponent/DatePickerFormiks";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { useEffect } from "react";

const CreateTravelAdvanceComp = ({ formValues }) => {
  useEffect(() => {
    document.title = `PeopleSol - Advance Travel`;  
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  return (
    <div>
      <div className="formcustom twoColumn">
        <div>
          <LabelCustom labelName="Travel Planned Start Date" mandatory={true} />
          <DatePickerFormiks
            name="startDate"
            minDate={new Date()}
            maxDate={formValues?.endDate}
          />
        </div>
        <div>
          <LabelCustom labelName="Travel Planned End Date" mandatory={true} />
          <DatePickerFormiks name="endDate" minDate={formValues?.startDate} />
        </div>
        <div>
          <LabelCustom labelName="Per Day Eligibility" />
          <InputCustomFormik name="perDayEligibilty" disabled />
        </div>
        <div>
          <LabelCustom
            labelName="Travel Advance Amount (In Thousands)"
            mandatory={true}
          />
          <InputCustomFormik
            name="advanceSalary"
            type="number"
            step="0.01"
            min={1}
            max={9999999999}
            placeholder="Enter Travel Advance Amount "
          />
        </div>
        <div>
          <LabelCustom labelName="Purpose of Advance" />
          <InputCustomFormik
            name="purposeOfAdvance"
            maxLength="500"
            placeholder="Enter Purpose of Advance"
          />
        </div>
      </div>
    </div>
  );
};

export { CreateTravelAdvanceComp };
