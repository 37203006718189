import { Carousel } from "react-responsive-carousel";
import {
  albumPostImages,
} from "../../../../../Utilities/profileAndUploadImages";
import "./ShowCarouselImages.css";

const getConfigurableProps = () => ({
  showArrows: true,
  showStatus: true,
  showIndicators: true,
  infiniteLoop: false,
  showThumbs: false,
  useKeyboardArrows: false,
  autoPlay: false,
  stopOnHover: true,
  swipeable: true,
  dynamicHeight: true,
  emulateTouch: true,
  autoFocus: true,
  selectedItem: 0,
  interval: 90000,
  transitionTime: 500,
  swipeScrollTolerance: 5,
});

const ShowCarouselGreetingsImages = ({ photo, showModal }) => (
  <Carousel
    {...getConfigurableProps()}
    onClickItem={() => {
      showModal();
    }}
    showIndicators={photo?.length > 1 ? true : false}
    showStatus={photo?.length > 1 ? true : false}
  >
    {photo &&
      photo?.map((item) => {
        return (
          <div className="galleryImageWrapper1">
            <img
              style={{}}
              src={process.env.REACT_APP_.BASE_URL + albumPostImages + item}
              alt=""
            />
          </div>
        );
      })}
  </Carousel>
);
export default ShowCarouselGreetingsImages;
