import { IconButton, Tooltip } from "@mui/material";
import React, { useRef } from "react";
import { InputCustom } from "../../Components/FormComponent/InputCustom";
import { Form, Formik } from "formik";
import { ImageViewer } from "./Components/UserAttchments/ImageViewer";
import { scrollFunc } from "./ChatInterface";

const InputSectionChat = ({
  setEmoji,
  handleAttachmentClick,
  showOptions,
  handleDocsSelect,
  handlePhotoClick,
  setShowOptions,
  src,
  fileType,
  selectedFile,
  messageInput,
  sendMessageText,
  selectedEmoji,
  ScheduleMessageChat,
  handleInputChange,
  handleKeyDown,
  SendImageChat,
  replyData,
  LoggedInUser,
  setReplyData,
  setMessageInput,
}) => {
  const inputFile = useRef(null);

  // const handleDocumentClick = () => {
  //   const fileInput = document.createElement("input");
  //   fileInput.type = "file";
  //   fileInput.accept =
  //     "application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"; // Define accepted document types
  //   fileInput.addEventListener("change", (event) => {
  //     const selected = event.target.files[0];
  //     // Handle file selection logic here
  //     console.log("Selected file:", selected);
  //     handleDocsSelect(selected, "document");
  //   });
  //   fileInput.click();
  // };
  const handleDocumentClick = () => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = "*/*"; // Allow all file types
    fileInput.multiple = true; // Enable multiple file selection

    fileInput.addEventListener("change", (event) => {
      const selected = event.target.files;
      // Handle file selection logic here
      console.log("Selected file:", selected);
      handleDocsSelect(selected, "document");
    });
    fileInput.click();
  };

  return (
    <>
      {replyData?.replyId && (
        <div
          className="replyContainer"
          // onClick={() => {
          //   scrollFunc(replyData?.replyId);
          // }}
        >
          <div
            style={{
              backgroundColor: "#1296B0",
              height: "4.17px",
              width: "99%",
              borderRadius: "8px",
              margin: "0px auto",
            }}
          ></div>
          <div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <div
                  className="profile-image"
                  style={{
                    width: "23px",
                    height: "23px",
                    marginLeft: "6px",
                    marginTop: "6px",
                  }}
                >
                  {/* Display profile image */}
                  <img
                    src={
                      replyData?.data?.from?.personNumber ===
                      LoggedInUser.personNumber
                        ? `${process.env.REACT_APP_.BASE_URL}/Core/Uploads/ProfileImageUploads/${LoggedInUser?.image}`
                        : "https://picsum.photos/40/40"
                    }
                    alt="Profile"
                  />
                </div>
                <div className="ReplyNameChat">
                  {replyData?.data?.from?.personNumber ===
                  LoggedInUser?.personNumber
                    ? LoggedInUser &&
                      // LoggedInUser?.name?.replace(/\s\(\d+\)$/, "")
                      "You"
                    : replyData?.data?.from?.name}
                </div>
              </div>
              <div style={{ marginRight: "5px", marginTop: "5px" }}>
                <svg
                  onClick={() => {
                    setReplyData({
                      replyId: null,
                      replyContent: "",
                      data: null,
                    });
                  }}
                  style={{ cursor: "pointer" }}
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="8.05644"
                    cy="8.66045"
                    r="7.94658"
                    fill="#BFBFBF"
                  />
                  <line
                    x1="11.52"
                    y1="5.40291"
                    x2="4.29839"
                    y2="12.6245"
                    stroke="white"
                    stroke-linecap="round"
                  />
                  <line
                    x1="0.5"
                    y1="-0.5"
                    x2="10.7129"
                    y2="-0.5"
                    transform="matrix(0.707107 0.707107 0.707107 -0.707107 4.59131 4.6958)"
                    stroke="white"
                    stroke-linecap="round"
                  />
                </svg>
              </div>
            </div>
          </div>

          <div className="ReplyContentChat">
            {replyData?.replyContent.length > 132
              ? replyData?.replyContent.substring(0, 132) + "..."
              : replyData?.replyContent}
          </div>
        </div>
      )}
      <div className="input-box">
        <div className="input-icons">
          <span className="icon">
            <Tooltip title="emoji">
              <IconButton
                onClick={() => {
                  setEmoji((prev) => !prev);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="21"
                  height="21"
                  viewBox="0 0 21 21"
                  fill="none"
                >
                  <path
                    d="M10.5701 19.3496C15.5406 19.3496 19.5701 15.3202 19.5701 10.3496C19.5701 5.37905 15.5406 1.34961 10.5701 1.34961C5.59951 1.34961 1.57007 5.37905 1.57007 10.3496C1.57007 15.3202 5.59951 19.3496 10.5701 19.3496Z"
                    stroke="#A2A2A2"
                    stroke-width="1.35"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M6.96997 12.1494C6.96997 12.1494 8.31997 13.9494 10.57 13.9494C12.82 13.9494 14.17 12.1494 14.17 12.1494"
                    stroke="#A2A2A2"
                    stroke-width="1.35"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <circle cx="7.4148" cy="7.87818" r="1.29029" fill="#A2A2A2" />
                  <circle
                    cx="13.7253"
                    cy="7.87818"
                    r="1.29029"
                    fill="#A2A2A2"
                  />
                </svg>
              </IconButton>
            </Tooltip>
          </span>
          {!replyData?.replyId && (
            <span className="icon">
              <Tooltip title="Attachment">
                <IconButton onClick={handleAttachmentClick}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="33"
                    height="33"
                    viewBox="0 0 33 33"
                    fill="none"
                  >
                    <circle cx="16.124" cy="16.6738" r="16" fill="white" />
                    <path
                      d="M16.124 8.14014L16.124 25.2084"
                      stroke="#A2A2A2"
                      stroke-width="1.5"
                      stroke-linecap="round"
                    />
                    <path
                      d="M24.658 16.6743L7.58973 16.6743"
                      stroke="#A2A2A2"
                      stroke-width="1.5"
                      stroke-linecap="round"
                    />
                  </svg>
                </IconButton>
              </Tooltip>
              {showOptions && (
                <div className="attachActivityHolder">
                  <div
                    className="headingFilterHeader"
                    onClick={() => handleDocumentClick(handleDocumentClick)}
                  >
                    <div className="attachIcon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="36"
                        height="36"
                        viewBox="0 0 36 36"
                        fill="none"
                      >
                        <circle
                          cx="18.0107"
                          cy="17.9713"
                          r="17.4626"
                          fill="#EBFDFC"
                        />
                        <path
                          d="M26.1578 12.146C25.9162 10.9381 24.8492 10.78 24.346 10.852V24.0952L23.4832 24.8286H22.6636H12.138C11.7239 25.6223 12.972 26.4822 13.6478 26.8129C16.4662 26.7841 22.4479 26.7439 23.8283 26.8129C25.2087 26.8819 25.9565 26.2953 26.1578 25.9933V23.8364C26.2584 20.4429 26.3993 13.354 26.1578 12.146Z"
                          fill="#EBFDFC"
                        />
                        <path
                          d="M14.9724 9.12354H22.9648C23.7383 9.12354 24.4258 9.75477 24.4258 10.4649V23.3263C24.4258 24.1943 23.6523 24.9044 22.707 24.9044H11.4488C10.5034 24.9044 9.72998 24.1943 9.72998 23.3263V13.4554"
                          stroke="#5BC4BF"
                          stroke-width="1.0072"
                        />
                        <path
                          d="M24.327 10.8296H24.8507C25.5938 10.8296 26.2545 11.4902 26.2545 12.2334V25.0222C26.2545 25.9305 25.5113 26.6737 24.6029 26.6737H13.7853C12.877 26.6737 12.1338 25.9305 12.1338 25.0222"
                          stroke="#5BC4BF"
                          stroke-width="1.0072"
                        />
                        <path
                          d="M13.5762 17.4014H21.1668"
                          stroke="#5BC4BF"
                          stroke-width="1.0072"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M13.5762 19.8511H21.1668"
                          stroke="#5BC4BF"
                          stroke-width="1.0072"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M13.5762 22.3018H21.1668"
                          stroke="#5BC4BF"
                          stroke-width="1.0072"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M19.3165 13.5115C19.896 13.5115 20.3863 13.0212 20.3863 12.4418C20.3863 11.8624 19.896 11.3721 19.3165 11.3721C18.7371 11.3721 18.2468 11.8624 18.2468 12.4418C18.2468 13.0212 18.6925 13.5115 19.3165 13.5115Z"
                          fill="#EBFDFC"
                          stroke="#5BC4BF"
                          stroke-width="1.0072"
                        />
                        <path
                          d="M21.2618 15.7488H17.3713V15.457C17.3713 14.3871 18.2467 13.5117 19.3166 13.5117C20.3865 13.5117 21.2618 14.3871 21.2618 15.457V15.7488Z"
                          fill="#EBFDFC"
                          stroke="#5BC4BF"
                          stroke-width="1.0072"
                        />
                        <path
                          d="M9.81836 13.4531L14.8253 9.19629V13.5742L9.81836 13.4531Z"
                          fill="#EBFDFC"
                        />
                        <path
                          d="M14.9356 9.12354V13.5611H9.72998L14.9356 9.12354Z"
                          stroke="#5BC4BF"
                          stroke-width="1.0072"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>

                    <h5>Docs</h5>
                  </div>
                  <div
                    className="headingFilterHeader"
                    onClick={handlePhotoClick}
                  >
                    {" "}
                    <div className="attachIcon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="36"
                        height="36"
                        viewBox="0 0 36 36"
                        fill="none"
                      >
                        <circle
                          cx="18.0107"
                          cy="17.8966"
                          r="17.4626"
                          fill="#EAF5FF"
                        />
                        <path
                          d="M27.1115 11.1611L11.9925 9.27131L10.6863 10.3829L10.2694 13.1622L22.4146 13.1622L24.62 14.7464L24.62 25.0109L26.3889 24.3069L27.9453 12.8009L27.1115 11.1611Z"
                          fill="#EAF5FF"
                        />
                        <path
                          d="M10.2135 13.19L10.4741 11.014C10.5993 9.96855 11.5479 9.22222 12.5935 9.34652L26.2652 10.9719C27.3114 11.0962 28.0587 12.0452 27.9343 13.0914L26.7161 23.3387C26.5947 24.3593 25.6866 25.1003 24.6623 25.0145L24.6198 25.0109"
                          stroke="#77A2C7"
                          stroke-width="1.0072"
                        />
                        <circle
                          cx="0.480442"
                          cy="0.480442"
                          r="0.984044"
                          transform="matrix(1 0 0 -1 17.1472 18.4722)"
                          stroke="#77A2C7"
                          stroke-width="1.0072"
                        />
                        <path
                          d="M20.4242 22.2619L24.2831 20.1821V25.1685C24.2831 25.8501 23.2808 26.4548 22.7796 26.672H9.5743C8.63213 26.5317 8.0959 25.678 7.94556 25.2688V22.9635C8.86433 22.4456 10.9274 21.2846 11.8295 20.7835C12.9571 20.157 13.4081 20.1821 14.3102 20.7835C15.2122 21.3848 16.1143 22.8883 16.8911 23.2892C17.5125 23.61 17.9686 23.5064 18.1189 23.4145L20.4242 22.2619Z"
                          fill="#EAF5FF"
                        />
                        <path
                          d="M24.513 19.998L18.7969 23.1168C18.7054 23.1667 18.61 23.2091 18.5125 23.2458C18.0046 23.4368 17.2497 23.7233 16.3575 22.9076C15.1292 21.7845 14.4623 20.1736 12.9311 20.1736C11.7812 20.1736 8.03704 22.9076 7.86157 22.9076"
                          stroke="#77A2C7"
                          stroke-width="1.0072"
                        />
                        <rect
                          x="7.44195"
                          y="12.9515"
                          width="17.3658"
                          height="14.0982"
                          rx="2.41127"
                          stroke="#77A2C7"
                          stroke-width="1.0072"
                        />
                      </svg>
                    </div>
                    <input
                      type="file"
                      id="file"
                      ref={inputFile}
                      style={{ display: "none" }}
                      multiple={true}
                    />
                    <h5>Photos</h5>
                  </div>
                </div>
              )}
            </span>
          )}
          <div>
            {selectedFile && (
              <div className="file-options">
                {selectedFile && fileType === "photo" && (
                  <ImageViewer src={src} setShowOptions={setShowOptions} />
                )}
                {/* {fileType === "document" && (
                      <div className="document-options">
            
                        <input
                          type="text"
                          placeholder="Add text input"
                        />
                        <button onClick={handleSend}>
                          Send
                        </button>
                        <button>Cancel</button>
                      </div>
                    )} */}
              </div>
            )}
          </div>
        </div>
        <Formik
          initialValues={{ text: "" }}
          onSubmit={(values) => {
            if (replyData?.replyId) {
              sendMessageText(messageInput, replyData?.replyId);
              setReplyData({
                replyId: null,
                replyContent: "",
                data: null,
              });
            }

            sendMessageText(messageInput);
            setMessageInput("");
          }}
        >
          {(formik) => (
            <Form
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <div style={{ flex: "1" }}>
                <InputCustom
                  name="text"
                  style={{ backgroundColor: "#F8F8F8", borderRadius: "8px" }}
                  formikWidth="100%"
                  value={
                    messageInput + selectedEmoji.map((emoji) => emoji).join("")
                  }
                  onKeyDown={handleKeyDown}
                  onChange={handleInputChange}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  maxWidth: "75px",
                  justifyContent: "space-evenly",
                }}
              >
                {messageInput.length > 0 && !replyData?.replyId && (
                  <Tooltip title="Schedule Message">
                    <IconButton>
                      <img src={ScheduleMessageChat} alt="" />
                    </IconButton>
                  </Tooltip>
                )}

                {(messageInput.length > 0 || replyData?.replyId) && (
                  <Tooltip title="Send">
                    <button
                      style={{
                        backgroundColor: "white",
                        border: "none",
                      }}
                      type="submit"
                    >
                      <IconButton>
                        <img src={SendImageChat} alt="" />
                      </IconButton>
                    </button>
                  </Tooltip>
                )}
              </div>
            </Form>
          )}
        </Formik>
        {messageInput.length === 0 && !replyData?.replyId && (
          <div className="input-icons">
            <span className="icon">
              <Tooltip title="Mic">
                <IconButton>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="21"
                    viewBox="0 0 16 21"
                    fill="none"
                  >
                    <path
                      d="M7.94606 13.3847C9.91624 13.3847 11.512 11.789 11.512 9.81876V4.91557C11.512 2.94538 9.91624 1.34961 7.94606 1.34961C5.97588 1.34961 4.38013 2.94538 4.38013 4.91557V9.81876C4.38013 11.789 5.97588 13.3847 7.94606 13.3847Z"
                      stroke="#A2A2A2"
                      stroke-width="1.49999"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M1.12622 8.16992V9.68546C1.12622 13.4475 4.18401 16.5054 7.94607 16.5054M7.94607 16.5054C11.7081 16.5054 14.7659 13.4475 14.7659 9.68546V8.16992M7.94607 16.5054V19.1798"
                      stroke="#A2A2A2"
                      stroke-width="1.49999"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M4.38037 19.3496H11.512"
                      stroke="#A2A2A2"
                      stroke-width="1.49999"
                      stroke-linecap="round"
                    />
                  </svg>
                </IconButton>
              </Tooltip>
            </span>
          </div>
        )}
      </div>
    </>
  );
};

export default InputSectionChat;
