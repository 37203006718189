import { format } from "date-fns";
import { useSelector } from "react-redux";
import DropdownArrowChatMesssage from "../../../Assets/DropdownArrowChatMesssage.svg";
import starWhite from "../../../Assets/starWhite.svg";
import starYellow from "../../../Assets/starYellow.svg";
import doubleTickGreen from "../../../Assets/doubleTickGreen.svg";
import DropdownArrowChatMesssageWhite from "../../../Assets/DropdownArrowChatMesssageWhite.svg";
import OutsideClickHandler from "../../../Components/OutsideClickHandler/OutsideClickHandler";
import { useEffect, useState } from "react";
import { scrollFunc } from "../ChatInterface";
import signalRService from "../../../Services/signalRService";
export const ChatMessage = ({
  message,
  timestamp,
  setReplyData,
  LoggedInUser,
  setForwardData,
  forwardData,
  replyData,
  handleContactClick,
  deleteMessage,
}) => {
  // const [chatWidth, setChatWidth] = useState(0);
  // useEffect(() => {
  //   if (replyData?.replyId) {
  //     setForwardData({
  //       isActive: false,
  //       msgId: [],
  //       userId: [],
  //       conversationId: [],
  //     });
  //   }
  // }, [replyData?.replyId]);
  // let divWidth;
  // useEffect(() => {
  //   divWidth = document.getElementById(`messageContent${message.id}`);
  // }, []);

  // useEffect(() => {
  //   const width = divWidth?.offsetWidth;
  //   setChatWidth(width);
  //   console.log(`messageContent${message.id}`, width);
  // }, [divWidth]);

  const [popupOpen, setPopupOpen] = useState({
    isActive: false,
    Id: 0,
  });
  const messageClass =
    message?.from?.personNumber === LoggedInUser.personNumber
      ? "sent"
      : "received";
  const selectBox = (id, conversId) => {
    setForwardData((prevState) => ({
      ...prevState,
      msgId: [...prevState.msgId, id],
      conversationId: [...prevState.conversationId, conversId],
    }));
  };
  const unSelectBox = (id, conversId) => {
    setForwardData((prevState) => ({
      ...prevState,
      msgId: prevState.msgId.filter((msgId) => msgId !== id),
      conversationId: prevState.conversationId.filter(
        (item) => item !== conversId
      ),
    }));
  };
  const toggleBookMark = async () => {
    await signalRService.invokeToggleStarMsg(
      LoggedInUser.personNumber,
      message.conversationId,
      message.id
    );
    handleContactClick(message.conversationId);
  };
  // console.log("object forwardData", forwardData);
  return (
    <div
      onClick={(e) => {
        forwardData?.isActive && forwardData?.msgId?.includes(message.id)
          ? unSelectBox(message.id, message.conversationId)
          : forwardData?.isActive &&
            selectBox(message.id, message.conversationId);
        e.stopPropagation();
      }}
      style={
        messageClass === "sent"
          ? {
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }
          : {
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }
      }
    >
      {forwardData?.isActive && forwardData?.msgId?.includes(message.id) ? (
        <svg
          // onClick={() => {
          //   unSelectBox(message.id);
          // }}
          width="19"
          height="19"
          viewBox="0 0 15 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="0.54248"
            y="1.22949"
            width="14"
            height="14"
            rx="2.8"
            fill="#1296B0"
            stroke="#1296B0"
            stroke-width="0.75"
          />
          <path
            d="M4.10742 8.52771L6.11076 10.5435L11.321 5.91602"
            stroke="white"
            stroke-linecap="round"
          />
        </svg>
      ) : forwardData?.isActive ? (
        <svg
          // onClick={() => {
          //   selectBox(message.id);
          // }}
          width="19"
          height="19"
          viewBox="0 0 15 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="0.54248"
            y="1.22949"
            width="14"
            height="14"
            rx="2.8"
            fill="white"
            stroke="#BABABA"
            stroke-width="0.75"
          />
        </svg>
      ) : (
        <div></div>
      )}
      <div style={{ width: "65%" }} className={`message ${messageClass}`}>
        <div className="proftime">
          <div className="profile-image">
            {/* Display profile image */}
            <img
              src={
                message?.from?.personNumber === LoggedInUser.personNumber
                  ? `${process.env.REACT_APP_.BASE_URL}/Core/Uploads/ProfileImageUploads/${LoggedInUser?.image}`
                  : "https://picsum.photos/40/40"
              }
              alt="Profile"
            />
          </div>
          <div className="timestamp">
            {message.time && format(new Date(message.time), "hh:mm a")}
          </div>
        </div>
        {message?.replyingToMessageId ? (
          <div
            className="message-content"
            style={
              messageClass === "sent"
                ? {
                    display: "flex",
                    borderRadius: "6px 0px 6px 6px",
                    position: "relative",
                  }
                : {
                    display: "flex",
                    borderRadius: "0px 6px 6px 6px",
                    position: "relative",
                  }
            }
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <div
                style={
                  messageClass === "sent"
                    ? {
                        backgroundColor: "#DFECEF",
                        color: "#1296B0",
                        borderRadius: "6px 0px 6px 6px",
                        display: "flex",
                        position: "relative",
                        padding: "10px",
                        marginBottom: "5px",
                        textAlign: "end",
                        justifyContent: "flex-end",
                        width: "100%",
                      }
                    : {
                        backgroundColor: "#DFECEF",
                        color: "#1296B0",
                        borderRadius: "0px 6px 6px 6px",
                        display: "flex",
                        position: "relative",
                        padding: "10px",
                        marginBottom: "5px",
                        textAlign: "start",
                        justifyContent: "flex-end",
                        width: "100%",
                      }
                }
              >
                {" "}
                <div
                  onClick={(e) => {
                    scrollFunc(message?.replyingToMessage?.id);
                    e.stopPropagation();
                  }}
                  style={
                    messageClass === "sent"
                      ? {
                          width: "calc(100% - 20px)",
                          cursor: "pointer",
                        }
                      : {
                          width: "calc(100% - 20px)",
                          cursor: "pointer",
                        }
                  }
                >
                  <div style={{ width: "100%" }}>
                    <p>
                      {message?.replyingToMessage?.from?.personNumber ===
                      LoggedInUser?.personNumber
                        ? LoggedInUser && (
                            // LoggedInUser?.name?.replace(/\s\(\d+\)$/, "")
                            <b>You</b>
                          )
                        : message?.replyingToMessage?.from?.name}
                    </p>
                    <p
                      style={
                        message?.replyingToMessage?.content?.length > 65
                          ? { width: "100%", overflowWrap: "break-word" }
                          : { width: "100%" }
                      }
                    >
                      {message?.replyingToMessage?.content}
                    </p>
                  </div>
                </div>
                <div
                  onClick={(e) => {
                    setPopupOpen((prev) => {
                      if (prev.isActive) {
                        if (prev.Id === (message.Id ?? message.id)) {
                          return { isActive: false, Id: 0 };
                        } else {
                          return {
                            isActive: true,
                            Id: message.Id ?? message.id,
                          };
                        }
                      } else {
                        return {
                          isActive: true,
                          Id: message.Id ?? message.id,
                        };
                      }
                    });
                    e.stopPropagation();
                  }}
                  style={{ width: "15px", height: "10px", cursor: "pointer" }}
                >
                  {/* <img
                  className="DropdownArrowChatDesign"
                  style={
                    popupOpen.isActive &&
                    (message.Id ?? message.id) === popupOpen.Id
                      ? { display: "block" }
                      : {}
                  }
                  src={DropdownArrowChatMesssage}
                  alt=""
                /> */}
                  <svg
                    className="DropdownArrowChatDesign"
                    style={
                      popupOpen.isActive &&
                      (message.Id ?? message.id) === popupOpen.Id
                        ? { display: "block", top: "11px" }
                        : { top: "11px" }
                    }
                    width="13"
                    height="7"
                    viewBox="0 0 13 7"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.4584 0.766602L6.38991 5.8351L1.32141 0.766602"
                      stroke="#1296B0"
                      stroke-width="1.25251"
                      stroke-linecap="round"
                    />
                  </svg>
                </div>
              </div>
              <div
                style={
                  message.content?.length > 55
                    ? {
                        width: "98%",
                        display: "flex",
                        flexDirection: "column",
                      }
                    : {
                        width: "98%",
                        display: "flex",
                      }
                }
              >
                <div
                  id={`messageContent${message.id}`}
                  style={
                    message?.content?.length > 65
                      ? { width: "100%", overflowWrap: "break-word" }
                      : { width: "100%" }
                  }
                >
                  {message.content}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <div
                    style={
                      message.content?.length > 55
                        ? {
                            display: "flex",
                            textAlign: "end",
                            width: "50px",
                            alignItems: "flex-end",
                            justifyContent: "flex-end", // backgroundColor: "blueviolet",
                          }
                        : {
                            width: "50px",
                            textAlign: "end",
                          }
                    }
                  >
                    {message?.hasBookmarked === true && (
                      <img
                        src={messageClass === "sent" ? starWhite : starYellow}
                        style={{ margin: "0px 5px 0px 5px" }}
                        alt="bookmark"
                      />
                    )}
                    <img src={doubleTickGreen} alt="read" />
                  </div>
                </div>
              </div>
            </div>

            <OutsideClickHandler
              key={message.id ?? message.Id}
              id={message.id ?? message.Id}
              onOutsideClick={(id) =>
                setPopupOpen((prev) => {
                  if (prev.isActive) {
                    if (prev.Id === id) {
                      return { isActive: false, Id: 0 };
                    } else {
                      return prev;
                    }
                  } else {
                    return prev;
                  }
                })
              }
            >
              <div
                className="chatActionsPopup"
                style={
                  popupOpen.isActive &&
                  (message.Id ?? message.id) === popupOpen.Id
                    ? {
                        display: "block",
                        position: "absolute",
                        zIndex: "6",
                        right: "-50px",
                        top: "26px",
                        color: "black",
                      }
                    : { display: "none" }
                }
              >
                <div
                  style={{ borderRadius: "6px 6px 0px 0px" }}
                  onClick={(e) => {
                    setReplyData({
                      replyId: popupOpen?.Id,
                      replyContent: message.content,
                      data: message,
                    });
                    setPopupOpen({ isActive: false, Id: 0 });
                    setForwardData({
                      isActive: false,
                      msgId: [],
                      userId: [],
                      conversationId: [],
                    });
                    e.stopPropagation();
                  }}
                >
                  Reply
                </div>
                <div>Info</div>
                <div
                  onClick={(e) => {
                    setForwardData({
                      isActive: true,
                      msgId: [message.id],
                      userId: [],
                      conversationId: [message?.conversationId],
                    });
                    setPopupOpen({ isActive: false, Id: 0 });
                    e.stopPropagation();
                  }}
                >
                  Forward Message
                </div>
                <div
                  onClick={() => {
                    toggleBookMark();
                    setPopupOpen({ isActive: false, Id: 0 });
                    setForwardData({
                      isActive: false,
                      msgId: [],
                      userId: [],
                      conversationId: [],
                    });
                  }}
                >
                  Star
                </div>
                <div
                  style={{ borderRadius: "0px 0px 6px 6px" }}
                  onClick={() => {
                    deleteMessage(
                      message.conversationId,
                      LoggedInUser?.personNumber,
                      [message.id]
                    );
                    setPopupOpen({ isActive: false, Id: 0 });

                    setForwardData({
                      isActive: false,
                      msgId: [],
                      userId: [],
                      conversationId: [],
                    });
                  }}
                >
                  Delete Message
                </div>
                {/* {messageClass === "sent" ? (
                  <>
        
                  </>
                ) : (
                  <>
                    <div
                      style={{ borderRadius: "6px 6px 0px 0px" }}
                      onClick={() => {
                        setReplyData({
                          replyId: popupOpen?.Id,
                          replyContent: message.content,
                          data: message,
                        });
                        setPopupOpen({ isActive: false, Id: 0 });
                      }}
                    >
                      Reply
                    </div>
                    <div>Forward</div>
                    <div>Star</div>
                    <div style={{ borderRadius: "0px 0px 6px 6px" }}>
                      Delete
                    </div>
                  </>
                )} */}
              </div>
            </OutsideClickHandler>
          </div>
        ) : (
          <div
            className="message-content"
            style={
              messageClass === "sent"
                ? {
                    display: "flex",
                    borderRadius: "6px 0px 6px 6px",
                    position: "relative",
                  }
                : {
                    display: "flex",
                    borderRadius: "0px 6px 6px 6px",
                    position: "relative",
                  }
            }
          >
            {/* Display message text */}
            {/* <p>{message.from.name}</p> */}
            <div
              style={
                message.content?.length > 55
                  ? {
                      width: "98%",
                      display: "flex",
                      flexDirection: "column",
                    }
                  : {
                      width: "98%",
                      display: "flex",
                    }
              }
            >
              <p
                id={`messageContent${message.id}`}
                style={
                  message?.content?.length > 65
                    ? { width: "100%", overflowWrap: "break-word" }
                    : { width: "100%" }
                }
              >
                {message.content}
              </p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <div
                  style={
                    message.content?.length > 55
                      ? {
                          display: "flex",
                          textAlign: "end",
                          width: "50px",
                          alignItems: "flex-end",
                          justifyContent: "flex-end", // backgroundColor: "blueviolet",
                        }
                      : {
                          width: "50px",
                          textAlign: "end",
                        }
                  }
                >
                  {message?.hasBookmarked === true && (
                    <img
                      src={messageClass === "sent" ? starWhite : starYellow}
                      style={{ margin: "0px 5px 0px 5px" }}
                      alt="bookmark"
                    />
                  )}
                  <img src={doubleTickGreen} alt="read" />
                </div>
              </div>
            </div>
            <div
              onClick={(e) => {
                setPopupOpen((prev) => {
                  if (prev.isActive) {
                    if (prev.Id === (message.Id ?? message.id)) {
                      return { isActive: false, Id: 0 };
                    } else {
                      return {
                        isActive: true,
                        Id: message.Id ?? message.id,
                      };
                    }
                  } else {
                    return {
                      isActive: true,
                      Id: message.Id ?? message.id,
                    };
                  }
                });
                e.stopPropagation();
              }}
              style={{ width: "15px", height: "10px", cursor: "pointer" }}
            >
              <img
                className="DropdownArrowChatDesign"
                style={
                  popupOpen.isActive &&
                  (message.Id ?? message.id) === popupOpen.Id
                    ? { display: "block" }
                    : {}
                }
                src={
                  messageClass === "sent"
                    ? DropdownArrowChatMesssageWhite
                    : DropdownArrowChatMesssage
                }
                alt=""
              />
            </div>
            <OutsideClickHandler
              key={message.id ?? message.Id}
              id={message.id ?? message.Id}
              onOutsideClick={(id) =>
                setPopupOpen((prev) => {
                  if (prev.isActive) {
                    if (prev.Id === id) {
                      return { isActive: false, Id: 0 };
                    } else {
                      return prev;
                    }
                  } else {
                    return prev;
                  }
                })
              }
            >
              <div
                className="chatActionsPopup"
                style={
                  popupOpen.isActive &&
                  (message.Id ?? message.id) === popupOpen.Id
                    ? {
                        display: "block",
                        position: "absolute",
                        zIndex: "6",
                        right: "-50px",
                        top: "26px",
                        color: "black",
                      }
                    : { display: "none" }
                }
              >
                <div
                  style={{ borderRadius: "6px 6px 0px 0px" }}
                  onClick={(e) => {
                    setReplyData({
                      replyId: popupOpen?.Id,
                      replyContent: message.content,
                      data: message,
                    });
                    setPopupOpen({ isActive: false, Id: 0 });
                    setForwardData({
                      isActive: false,
                      msgId: [],
                      userId: [],
                      conversationId: [],
                    });
                    e.stopPropagation();
                  }}
                >
                  Reply
                </div>
                <div>Info</div>
                <div
                  onClick={(e) => {
                    setForwardData({
                      isActive: true,
                      msgId: [message.id],
                      userId: [],
                      conversationId: [message?.conversationId],
                    });
                    setPopupOpen({ isActive: false, Id: 0 });
                    e.stopPropagation();
                  }}
                >
                  Forward Message
                </div>
                <div
                  onClick={() => {
                    toggleBookMark();
                    setPopupOpen({ isActive: false, Id: 0 });
                    setForwardData({
                      isActive: false,
                      msgId: [],
                      userId: [],
                      conversationId: [],
                    });
                  }}
                >
                  Star
                </div>
                <div
                  style={{ borderRadius: "0px 0px 6px 6px" }}
                  onClick={() => {
                    deleteMessage(
                      message.conversationId,
                      LoggedInUser?.personNumber,
                      [message.id]
                    );
                    setPopupOpen({ isActive: false, Id: 0 });

                    setForwardData({
                      isActive: false,
                      msgId: [],
                      userId: [],
                      conversationId: [],
                    });
                  }}
                >
                  Delete Message
                </div>
                {/* {messageClass === "sent" ? ( 
                

                  
                ) : (
                  <>
                    <div
                      style={{ borderRadius: "6px 6px 0px 0px" }}
                      onClick={() => {
                        setReplyData({
                          replyId: popupOpen?.Id,
                          replyContent: message.content,
                          data: message,
                        });
                        setPopupOpen({ isActive: false, Id: 0 });
                      }}
                    >
                      Reply
                    </div>
                    <div>Forward</div>
                    <div>Star</div>
                    <div style={{ borderRadius: "0px 0px 6px 6px" }}>
                      Delete
                    </div>
                  </>
                )}
                 */}
              </div>
            </OutsideClickHandler>
          </div>
        )}
      </div>
    </div>
  );
};
