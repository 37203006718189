/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { IconButton, Tooltip } from "@mui/material";
import { BoxView, Table } from "../../../Components/index";
import { useDispatch } from "react-redux";
import { showToast, isLoading } from "../../../Features";
import { postRequest, deleteWithPayloadRequest } from "../../../Services/axios";
import { useDeferredValue } from "react";
import { useMemo } from "react";
import { SelectCustom } from "../../../Components/FormComponent/SelectCustom";
import { confirmAlert } from "react-confirm-alert";
import { Loader } from "../../../Components/FormComponent/Loader";

const PayConfig = (props) => {
  useEffect(() => {
    document.title = `PeopleSol - Pay `;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const noOfRecords = [
    { label: "10", value: 10 },
    { label: "20", value: 20 },
    { label: "50", value: 50 },
    { label: "100", value: 100 },
  ];
  const [boxView, setBoxView] = useState(true);
  const [loading, setLoading] = useState(true);
  const [tbodyData, setTbodyData] = useState(false);
  const [count, setCount] = useState(false);
  const [recordsSelected, setRecordsSelected] = useState(noOfRecords[1]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [sortParam, setSortParam] = useState("Id");
  const [sortType, setSortType] = useState("descending");
  const [searchParam, setSearchParam] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [reqData, setReqData] = useState({
    isMetro: null,
    createdOn: null,
    lastUpdatedOn: null,
    dataTableRequest: {
      iDisplayLength: recordsSelected.value,
      iDisplayStart: 0,
      sSearch: searchParam,
      sorting: sortParam,
      sSortDir_0: sortType,
      isExport: false,
    },
  });

  const handleDelete = async (selectedRows) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await deleteWithPayloadRequest(
        `PayConfig/DeleteMultiple`,
        selectedRows
      );
      dispatch(showToast({ text: "success", severity: "success" }));
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "some error occured",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
      setSelectedRows([]);
      getPersonalData();
    }
  };

  const deleteSelected = () => {
    if (selectedRows.length === 1) {
      confirmAlert({
        title: "Confirm Delete",
        message: `Are you sure you want to delete this band?`,
        buttons: [
          {
            label: "Yes, confirm delete",
            onClick: () => handleDelete(selectedRows),
          },
          {
            label: "No, cancel delete",
            onClick: () => setSelectedRows([]),
          },
        ],
      });
    } else {
      confirmAlert({
        title: "Confirm Delete",
        message: `Are you sure you want to delete these ${selectedRows.length} bands?`,
        buttons: [
          {
            label: "Yes, confirm delete",
            onClick: () => handleDelete(selectedRows),
          },
          {
            label: "No, cancel delete",
            onClick: () => setSelectedRows([]),
          },
        ],
      });
    }
  };

  const calculateTotalPages = (count) => {
    if (count > 0) {
      let numberOfPages = parseInt(count / recordsSelected.value);
      if (count % recordsSelected.value !== 0) {
        setTotalPages(numberOfPages + 1);
      } else {
        setTotalPages(numberOfPages);
      }
    } else {
      setTotalPages(1);
    }
  };

  const getPersonalData = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest("PayConfig/List", reqData);
      if (result?.data?.summary?.count > 0) {
        setTbodyData(result?.data?.data);
      } else {
        setTbodyData(false);
      }
      setCount(result?.data?.summary?.count);
      calculateTotalPages(result?.data?.summary?.count);
    } catch (error) {
      setTbodyData(false);
      setCount(0);
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  useEffect(() => {
    getPersonalData();
  }, [reqData]);
  const paginationChanged = (e) => {
    setCurrentPage(1);
    setRecordsSelected(e[0]);
    if (e[0].value > recordsSelected.value) {
      if (count > recordsSelected.value) {
        setReqData({
          ...reqData,
          dataTableRequest: {
            ...reqData.dataTableRequest,
            iDisplayLength: e[0].value,
            iDisplayStart: 0,
          },
        });
      }
    } else {
      if (count > e[0].value) {
        setReqData({
          ...reqData,
          dataTableRequest: {
            ...reqData.dataTableRequest,
            iDisplayLength: e[0].value,
            iDisplayStart: 0,
          },
        });
      }
    }
  };
  const pageChangedNext = () => {
    setCurrentPage((prev) => prev + 1);
    setReqData({
      ...reqData,
      dataTableRequest: {
        ...reqData.dataTableRequest,
        iDisplayStart: currentPage * recordsSelected.value,
      },
    });
  };
  const pageChangedPrev = () => {
    setCurrentPage((prev) => prev - 1);
    setReqData({
      ...reqData,
      dataTableRequest: {
        ...reqData.dataTableRequest,
        iDisplayStart: (currentPage - 2) * recordsSelected.value,
      },
    });
  };
  const deferredText = useDeferredValue(searchParam);
  useMemo(() => {
    setCurrentPage(1);
    setReqData({
      ...reqData,
      dataTableRequest: {
        ...reqData.dataTableRequest,
        iDisplayStart: 0,
        sSearch: deferredText,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deferredText]);

  const getFormDetails = (id) => {

    navigate("payconfig/details", {
      state: { id },
    });
  };

  const handleEdit = (id) => {
    navigate("payconfig/create", {
      state: { id: id },
    });
  };
  const actionData = [
    {
      img: (
        <svg
          width="21"
          height="21"
          viewBox="0 0 21 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.15"
            d="M0.172119 4.84375C0.172119 2.63461 1.96298 0.84375 4.17212 0.84375H16.1721C18.3813 0.84375 20.1721 2.63461 20.1721 4.84375V16.8438C20.1721 19.0529 18.3813 20.8438 16.1721 20.8438H4.17212C1.96298 20.8438 0.172119 19.0529 0.172119 16.8438V4.84375Z"
            fill="#0DB53C"
          />
          <line
            x1="14.7295"
            y1="7.43701"
            x2="7.72949"
            y2="7.43701"
            stroke="#0DB53C"
            stroke-linecap="round"
          />
          <line
            x1="5.72949"
            y1="7.43701"
            x2="5.61462"
            y2="7.43701"
            stroke="#0DB53C"
            stroke-linecap="round"
          />
          <line
            x1="14.7295"
            y1="11.3437"
            x2="7.72949"
            y2="11.3437"
            stroke="#0DB53C"
            stroke-linecap="round"
          />
          <line
            x1="5.72949"
            y1="11.3437"
            x2="5.61462"
            y2="11.3438"
            stroke="#0DB53C"
            stroke-linecap="round"
          />
          <line
            x1="14.7295"
            y1="15.2505"
            x2="7.72949"
            y2="15.2505"
            stroke="#0DB53C"
            stroke-linecap="round"
          />
          <line
            x1="5.72949"
            y1="15.2505"
            x2="5.61462"
            y2="15.2505"
            stroke="#0DB53C"
            stroke-linecap="round"
          />
        </svg>
      ),
      action: "Pay Area Details",
      clickAction: (id) => getFormDetails(id),
    },
    {
      img: (
        <svg
          width="21"
          height="21"
          viewBox="0 0 21 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.15"
            d="M0.75 4.23941C0.75 2.03027 2.54086 0.23941 4.75 0.23941H16.75C18.9591 0.23941 20.75 2.03027 20.75 4.23941V16.2394C20.75 18.4485 18.9591 20.2394 16.75 20.2394H4.75C2.54086 20.2394 0.75 18.4485 0.75 16.2394V4.23941Z"
            fill="#FB7158"
          />
          <path
            d="M15.75 7.09126L13.8981 5.23941L6.49074 12.6468L5.75 15.2394L8.34259 14.4987L15.75 7.09126ZM12.4167 6.72089L14.2685 8.57274L12.4167 6.72089ZM6.49074 12.6468L8.34259 14.4987L6.49074 12.6468Z"
            stroke="#FB7158"
            strokeWidth="0.698594"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
      action: "Edit Pay Area",
      clickAction: (id) => handleEdit(id),
    },
  ];

  const sortUpdated = (heading) => {
    if (heading === sortParam) {
      if (sortType === "ascending") {
        setSortType("descending");
        setReqData({
          ...reqData,
          dataTableRequest: {
            ...reqData.dataTableRequest,
            sSortDir_0: "descending",
          },
        });
      } else {
        setSortType("ascending");
        setReqData({
          ...reqData,
          dataTableRequest: {
            ...reqData.dataTableRequest,
            sSortDir_0: "ascending",
          },
        });
      }
    } else {
      setSortType("ascending");
      setSortParam(heading);
      setReqData({
        ...reqData,
        dataTableRequest: {
          ...reqData.dataTableRequest,
          sorting: heading,
          sSortDir_0: "ascending",
        },
      });
    }
  };
  const addToSelectedRows = (id) => {
    let arr = [...selectedRows];
    if (arr.includes(id)) {
      arr.splice(arr.indexOf(id), 1);
      setSelectedRows(arr);
      return;
    }
    arr.push(id);
    setSelectedRows(arr);
  };
  const selectAll = () => {
    if (count > recordsSelected?.value) {
      if (selectedRows?.length === recordsSelected?.value) {
        setSelectedRows([]);
      } else {
        let arr = [];
        tbodyData?.map((item) => {
          return arr.push(item.Id ?? item.id);
        });
        setSelectedRows(arr);
      }
    } else {
      if (selectedRows?.length === count) {
        setSelectedRows([]);
      } else {
        let arr = [];
        tbodyData?.map((item) => {
          return arr.push(item.Id ?? item.id);
        });
        setSelectedRows(arr);
      }
    }
  };

  const location = useLocation();

  useEffect(() => {
    getPersonalData();
  }, [location.pathname]);

  return (
    <div className="moduleMainContainer">
    <div className="fullTableContainer noTab">
      <div className="tableHeaderContainer">
        {selectedRows.length > 0 ? (
          <button className="deletebtn" onClick={() => deleteSelected()}>
            <span className="deletebtnflex">
              <p>Delete</p>
              <span>{`(${selectedRows.length})`}</span>
            </span>
          </button>
        ) : (
          <button
            onClick={() => navigate("payconfig/create")}
            className="button primaryButton"
            type="submit"
          >
            + Create {props.title}
          </button>
        )}
        <div className="search-box" >
          <input
            className="search-text"
            type="text"
            placeholder="Search"
            onChange={(e) => setSearchParam(e.target.value)}
          />
          <a className="search-btn" >
            <svg
              width="17"
              height="17"
              viewBox="0 0 17 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M11.7514 11.1946L16.0236 15.4668C16.1737 15.6171 16.258 15.8209 16.258 16.0333C16.2579 16.2457 16.1734 16.4494 16.0232 16.5996C15.8729 16.7498 15.6691 16.8341 15.4567 16.834C15.2443 16.8339 15.0406 16.7495 14.8904 16.5992L10.6183 12.3269C9.34114 13.3161 7.73517 13.7816 6.12703 13.6288C4.5189 13.4759 3.02941 12.7161 1.96157 11.504C0.893729 10.2918 0.327755 8.71841 0.378784 7.10379C0.429812 5.48917 1.09401 3.95463 2.23626 2.81236C3.37851 1.67008 4.91301 1.00586 6.52758 0.954834C8.14216 0.903804 9.71554 1.46979 10.9276 2.53766C12.1397 3.60553 12.8995 5.09505 13.0524 6.70323C13.2053 8.3114 12.7406 9.91742 11.7514 11.1946ZM6.72885 12.0689C7.99247 12.0689 9.20433 11.5669 10.0978 10.6733C10.9914 9.77981 11.4933 8.56791 11.4933 7.30426C11.4933 6.04061 10.9914 4.82871 10.0978 3.93518C9.20433 3.04164 7.99247 2.53966 6.72885 2.53966C5.46523 2.53966 4.25336 3.04164 3.35985 3.93518C2.46633 4.82871 1.96436 6.04061 1.96436 7.30426C1.96436 8.56791 2.46633 9.77981 3.35985 10.6733C4.25336 11.5669 5.46523 12.0689 6.72885 12.0689Z"
                fill="#1296B0"
              />
            </svg>
          </a>
        </div>
        <Tooltip arrow title={boxView ? "List View" : "Tile View"} placement="top">
        <svg
          style={{ scale: "1.3", marginLeft: "6px" }}
          width="37"
          className="cursor"
          height="21"
          viewBox="0 0 37 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          onClick={() => setBoxView((prev) => !prev)}
        >
          {!boxView ? (
            <>
              <rect
                x="0.700684"
                y="0.71875"
                width="36"
                height="20"
                rx="3"
                fill="#D8F0F2"
              />
              <rect
                x="2.04883"
                y="2.29004"
                width="33.3027"
                height="16.8572"
                rx="2"
                fill="white"
              />
              <path
                d="M35.3516 4.29004C35.3516 3.18547 34.4561 2.29004 33.3516 2.29004H18.475V19.1473H33.3516C34.4561 19.1473 35.3516 18.2518 35.3516 17.1473V4.29004Z"
                fill="#1296B0"
              />
              <rect
                x="5.58203"
                y="5.96558"
                width="3.75384"
                height="3.75384"
                rx="0.2"
                stroke="#1296B0"
                stroke-width="0.75"
              />
              <rect
                x="11.6382"
                y="5.96558"
                width="3.75384"
                height="3.75384"
                rx="0.2"
                stroke="#1296B0"
                stroke-width="0.75"
              />
              <rect
                x="5.58203"
                y="11.718"
                width="3.75384"
                height="3.75384"
                rx="0.2"
                stroke="#1296B0"
                stroke-width="0.75"
              />
              <rect
                x="11.6382"
                y="11.718"
                width="3.75384"
                height="3.75384"
                rx="0.2"
                stroke="#1296B0"
                stroke-width="0.75"
              />
              <line
                x1="22.3384"
                y1="6.34375"
                x2="32.2264"
                y2="6.34375"
                stroke="white"
                stroke-width="0.75"
                stroke-linecap="round"
              />
              <line
                x1="22.3384"
                y1="10.7202"
                x2="32.2264"
                y2="10.7202"
                stroke="white"
                stroke-width="0.75"
                stroke-linecap="round"
              />
              <line
                x1="22.3384"
                y1="15.0969"
                x2="32.2264"
                y2="15.0969"
                stroke="white"
                stroke-width="0.75"
                stroke-linecap="round"
              />
            </>
          ) : (
            <>
              <rect
                x="0.604492"
                y="0.71875"
                width="36"
                height="20"
                rx="3"
                fill="#D8F0F2"
              />
              <rect
                x="1.95264"
                y="2.29004"
                width="33.3027"
                height="16.8572"
                rx="2"
                fill="white"
              />
              <path
                d="M1.95264 4.29004C1.95264 3.18547 2.84807 2.29004 3.95264 2.29004H18.8292V19.1473H3.95264C2.84807 19.1473 1.95264 18.2518 1.95264 17.1473V4.29004Z"
                fill="#1296B0"
              />
              <rect
                x="5.48584"
                y="5.96558"
                width="3.75384"
                height="3.75384"
                rx="0.2"
                stroke="white"
                stroke-width="0.75"
              />
              <rect
                x="11.542"
                y="5.96558"
                width="3.75384"
                height="3.75384"
                rx="0.2"
                stroke="white"
                stroke-width="0.75"
              />
              <rect
                x="5.48584"
                y="11.718"
                width="3.75384"
                height="3.75384"
                rx="0.2"
                stroke="white"
                stroke-width="0.75"
              />
              <rect
                x="11.542"
                y="11.718"
                width="3.75384"
                height="3.75384"
                rx="0.2"
                stroke="white"
                stroke-width="0.75"
              />
              <line
                x1="22.2422"
                y1="6.34375"
                x2="32.1302"
                y2="6.34375"
                stroke="#1296B0"
                stroke-width="0.75"
                stroke-linecap="round"
              />
              <line
                x1="22.2422"
                y1="10.7202"
                x2="32.1302"
                y2="10.7202"
                stroke="#1296B0"
                stroke-width="0.75"
                stroke-linecap="round"
              />
              <line
                x1="22.2422"
                y1="15.0969"
                x2="32.1302"
                y2="15.0969"
                stroke="#1296B0"
                stroke-width="0.75"
                stroke-linecap="round"
              />
            </>
          )}
        </svg>
        </Tooltip>
      </div>
      <>
        {loading ? (
          <Loader />
        ) : (
          <>
            {boxView ? (
              <BoxView
                tbodyData={tbodyData}
                actionData={actionData}
                headingTile="payArea"
                subTiles={["calculateMonthlySalaryBasedOn","firstPayrollFrom","paidOn","employeesCount"]}
                date="lastUpdatedOn"
              />
            ) : (
              <>
                <Table
                  selectAll={() => selectAll()}
                  tableFilterName="PayConfigTableFilter"
                  addToSelectedRows={(Id) => addToSelectedRows(Id)}
                  selectedRows={selectedRows}
                  tbodyData={tbodyData}
                  actionData={actionData}
                  headerAction={sortUpdated}
                  sortParam={sortParam}
                  sortType={sortType}
                />
              </>
            )}
            <div className="horizontalLine"></div>
            <div className="tableFooterContainer">
              <label>Total Record Count: </label>
              <span>{count}</span>
              <SelectCustom
                searchable={false}
                options={noOfRecords} dropdownPosition="top"
                 
                values={[recordsSelected]}
                onChange={(e) => paginationChanged(e)}
              />
              <label>Records Per Page</label>
              {currentPage > 1 ? (
                <IconButton
                  onClick={() => pageChangedPrev()}
                  color="primary"
                  className="pageChangerIcon pageChangerIconActive"
                >
                  <svg
                    width="8"
                    height="12"
                    viewBox="0 0 8 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                      stroke="#BFBFBF"
                      strokeLinecap="round"
                    />
                  </svg>
                </IconButton>
              ) : (
                <IconButton
                  disabled
                  color="primary"
                  className="pageChangerIcon"
                >
                  <svg
                    width="8"
                    height="12"
                    viewBox="0 0 8 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                      stroke="#BFBFBF"
                      strokeLinecap="round"
                    />
                  </svg>
                </IconButton>
              )}
              {currentPage} - {totalPages}
              {totalPages > currentPage ? (
                <IconButton
                  onClick={() => pageChangedNext()}
                  color="primary"
                  className={`pageChangerIcon pageChangerIconNext pageChangerIconActive`}
                >
                  <svg
                    width="8"
                    height="12"
                    viewBox="0 0 8 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                      stroke="#BFBFBF"
                      strokeLinecap="round"
                    />
                  </svg>
                </IconButton>
              ) : (
                <IconButton
                  disabled
                  color="primary"
                  className={`pageChangerIcon pageChangerIconNext`}
                >
                  <svg
                    width="8"
                    height="12"
                    viewBox="0 0 8 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                      stroke="#BFBFBF"
                      strokeLinecap="round"
                    />
                  </svg>
                </IconButton>
              )}
            </div>
          </>
        )}
      </>
    </div>
    </div>
  );
};
export { PayConfig };
