import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { FormCustomFormik } from "../../../Components/FormComponent/FormCustomFormik";
import { CreateLeaveBalanceComp } from "./FormComp/CreateLeaveBalanceComp";
import { useDispatch } from "react-redux";
import { showToast, isLoading } from "../../../Features";
import { getRequest, postRequest } from "../../../Services/axios";
import { useLocation } from "react-router-dom";

const CreateLeaveBalance = (props) => {
  const { state } = useLocation();
  useEffect(() => {
    document.title = `PeopleSol - Create Leave Balance `;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);

  const [detailsform, setDetailsform] = useState({});

  const data = state?.id;
  // console.log("data", data);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const getFormDetails = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(`LeaveBalances/Details/${data}`);
      setDetailsform(result.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  useEffect(() => {
    if (data) {
      getFormDetails();
    }
  }, []);

  const createPageData = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest("LeaveBalances/Create", values);
      dispatch(
        showToast({ text: "Successfully Created", severity: "success" })
      );
      returnPage();
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const Edit = async (values) => {
    try {
      const result = await postRequest("LeaveBalances/Edit", values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
      }
      returnPage();
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "some error occured",
          severity: "error",
        })
      );
    }
  };
  const initialValue = {
    employeeId: "",
    leaveTypeId: "",
    openingBalance: "",
  };
  const validationSchema = Yup.object({
    employeeId: Yup.string().required("Please Select Employee"),
    leaveTypeId: Yup.string().required("Please Select Leave Type"),
    openingBalance: Yup.number()
      .max(999, "Leave Balance must be less than a 999 ")
      .required("Please Enter Leave Balance "),
  });
  const onSubmit = (values) => {
    const edit = {
      ...values,

      id: detailsform?.leaveBalances?.id,
    };

    const create = {
      ...values,
      // id: 0,
      employeeId: values.employeeId,
    };

    data ? Edit(edit) : createPageData(create);
  };
  const navigate = useNavigate();
  const returnPage = () => {
    if (state?.returnUrl) navigate(state?.returnUrl);
    else navigate(-1);
  };
  return (
    <FormCustomFormik
      loading={loading}
      FormHeading={data ? "Edit Leave Balance" : "Create Leave Balance"}
      ButtonLabel={data ? "Update Leave Balance" : "Create Leave Balance"}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      initialvalue={
        data
          ? {
              ...initialValue,
              employeeId: detailsform?.leaveBalances?.employeeId,
              leaveTypeId: detailsform?.leaveBalances?.leaveTypeId,
              openingBalance: detailsform?.leaveBalances?.openingBalance,
            }
          : initialValue
      }
      returnPage={returnPage}
      propsDiv={<CreateLeaveBalanceComp data={detailsform} editID={data} />}
    />
  );
};
export { CreateLeaveBalance };
