import React from "react";
import { useState } from "react";
import SurveyPage from "./Components/SurveyPage";
import PollPage from "./Components/PollPage";
import { Tab, Tabs } from "@mui/material";
import "./Survey.css";
import { useNavigate } from "react-router-dom";

function Survey() {
  const [tabHeading, setTabHeading] = useState("survey");
  const navigate = useNavigate();
  function handleTabChange() {
    navigate("/home/social/polls");
    // if (tabHeading === "survey") {
    //   setTabHeading("survey");
    //   navigate("/home/social/survey");
    // } else {
    //   setTabHeading("polls");
    //   navigate("/home/social/polls");
    // }
    // setTabHeading((tabHeading) =>
    //   tabHeading === "survey" ? "polls" : "survey"
    // );
    // navigate("")
  }
  console.log("tabHeading", tabHeading);

  return (
    <div className="surveyContainer">
      <div className="surveyWrapper">
        <Tabs
          value={tabHeading}
          // OnClick={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          className="socialSurveyTabs"
        >
          <Tab
            value={"Survey"}
            label={"Survey"}
            className={`socialSurveyHeading socialSurveyActive`}
          ></Tab>
          <Tab
            value={"Polls"}
            label={"Polls"}
            className={`socialSurveyHeading `}
            onClick={handleTabChange}
          ></Tab>
          {}
        </Tabs>
      </div>
      {tabHeading === "survey" && <SurveyPage />}
      {tabHeading === "polls" && <PollPage />}
    </div>
  );
}

export default Survey;
