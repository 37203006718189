import React, { useState } from "react";
import OfficehousekeepingPic from "../../Assets/OfficehousekeepingPic.svg";
import OfficehotelsPic from "../../Assets/OfficehotelsPic.svg";
import OfficevehiclePic from "../../Assets/OfficevehiclePic.svg";
import OfficemeetingroomPic from "../../Assets/OfficemeetingroomPic.svg";
import PayrollcompanystructurePic from "../../Assets/PayrollcompanystructurePic.svg";
import PayrollworklocationsPic from "../../Assets/PayrollworklocationsPic.svg";
import PayrollstatutorycompliancesPic from "../../Assets/PayrollstatutorycompliancesPic.svg";
import PayrollpayrollcomponentsPic from "../../Assets/PayrollpayrollcomponentsPic.svg";
import PayrollpayrolltemplatePic from "../../Assets/PayrollpayrolltemplatePic.svg";
import PayrollpayrollconfigPic from "../../Assets/PayrollpayrollconfigPic.svg";
import PayrollpayareaPic from "../../Assets/PayrollpayareaPic.svg";
import GlobalsettingssystemPic from "../../Assets/GlobalsettingssystemPic.svg";
import GlobalsettingsmodulesettingsPic from "../../Assets/GlobalsettingsmodulesettingsPic.svg";
import GlobalsettingssettingsPic from "../../Assets/GlobalsettingssettingsPic.svg";
import GlobalsettingspermissionPic from "../../Assets/GlobalsettingspermissionPic.svg";
import GlobalsettingsrolesPic from "../../Assets/GlobalsettingsrolesPic.svg";
import GlobalsettingsnotificationsPic from "../../Assets/GlobalsettingsnotificationsPic.svg";
import ItsmdeskPic from "../../Assets/ItsmdeskPic.svg";
import ItsmexceptionPic from "../../Assets/ItsmexceptionPic.svg";
import ItsmchangePic from "../../Assets/ItsmchangePic.svg";
import ItsmassetPic from "../../Assets/ItsmassetPic.svg";
import ItsmreportsKPIPic from "../../Assets/ItsmreportsKPIPic.svg";
import HRMSIcon from "../../Assets/HRMSIcon.svg";
import SettingIcon from "../../Assets/SettingIcon.svg";
import PayrollIcon from "../../Assets/PayrollIcon.svg";
import ATSIcon from "../../Assets/ATSIcon.svg";
import PMSIcon from "../../Assets/PMSIcon.svg";
import LDIcon from "../../Assets/LDIcon.svg";
import OfficeAdminIcon from "../../Assets/OfficeAdminIcon.svg";
import ITSMIcon from "../../Assets/ITSMIcon.svg";
import { useRef } from "react";
import TravelIcon from "../../Assets/TravelIcon.svg";
import HomeIcon from "../../Assets/HomeIcon.svg";
import TaxIcon from "../../Assets/TaxIcon.svg";
import OrgStructurepic from "../../Assets/OrgStructurepic.svg";
import onboardingpic from "../../Assets/Onboardingpic.svg";
import Timepic from "../../Assets/Timepic.svg";
import ProjectTaskspic from "../../Assets/ProjectTaskspic.svg";
import HRDeskpic from "../../Assets/HRDeskpic.svg";
import Benefitspic from "../../Assets/Benefitspic.svg";
import Policiespic from "../../Assets/Policiespic.svg";
import Announcementspic from "../../Assets/Announcementspic.svg";
import Eventspic from "../../Assets/Eventspic.svg";
import Newspic from "../../Assets/Newspic.svg";
import Bannerpic from "../../Assets/Bannerpic.svg";
import ContactDirectorypic from "../../Assets/ContactDirectorypic.svg";
import Gallerypic from "../../Assets/Gallerypic.svg";
import TaxConfigpic from "../../Assets/TaxConfigpic.svg";
import Pipelinepic from "../../Assets/Pipelinepic.svg";
import Stagepic from "../../Assets/Stagepic.svg";
import Statuspic from "../../Assets/Statuspic.svg";
import Goalsettingpic from "../../Assets/Goalsettingpic.svg";
import PerformanceCyclepic from "../../Assets/PerformanceCyclepic.svg";
import Rewardspic from "../../Assets/Rewardspic.svg";
import Categoriespic from "../../Assets/Categoriespic.svg";
import Coursespic from "../../Assets/Coursespic.svg";
import Questionpic from "../../Assets/Questionpic.svg";
import Chapterspic from "../../Assets/Chapterspic.svg";
import Competencypic from "../../Assets/Competencypic.svg";
import Skillspic from "../../Assets/Skillspic.svg";
import TravelEligibilitypic from "../../Assets/TravelEligibilitypic.svg";
import ClaimTypepic from "../../Assets/ClaimTypepic.svg";
import TDSConfigPic from "../../Assets/TDSConfigPic.svg";
import PayslipTemplatePic from "../../Assets/PayslipTemplatePic.svg";
import CompetenciesIcon from "../../Assets/CompetenciesIcon.svg";

import "./Settings.css";
import { useNavigate } from "react-router-dom";
import { GroupBy } from "../../Utilities/GroupBy";
import { getRequest } from "../../Services/axios";
import { useEffect } from "react";
import { Loader } from "../../Components/FormComponent/Loader";
function Settings() {
  const [loading, setLoading] = useState(true);
  const [q, setQ] = useState("");
  const [searchTerm] = useState(["name", "module", "submodule"]);
  const [Items, setItems] = useState([]);
  const navigate = useNavigate();
  function search(items) {
    return items?.filter((item) => {
      return searchTerm?.some((newItem) => {
        return (
          item?.[newItem]
            ?.toString()
            ?.toLowerCase()
            ?.indexOf(q?.toLowerCase()) > -1
        );
      });
    });
  }

  useEffect(() => {
    document.title = `PeopleSol -  Settings`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);

  const getConfigData = async () => {
    try {
      setLoading(true);
      let result = await getRequest("Settings/GetConfigMenu");
      if (result) {
        let tempItems = [];
        result?.data?.map((item) => {
          let navigatePath = `/${item?.menuName
            ?.replaceAll(" ", "")
            ?.replaceAll("&", "")
            ?.toLowerCase()}master/${item?.subMenuName
            ?.replaceAll(" ", "")
            ?.replaceAll("&", "")
            ?.toLowerCase()}`;
          if (item?.featureName && item?.featureName !== "") {
            navigatePath =
              navigatePath +
              `/${item?.featureName
                ?.replaceAll(" ", "")
                ?.replaceAll("&", "")
                ?.toLowerCase()}`;
          }
          return tempItems.push({
            name: item?.featureDisplayName,
            isMenuActive: item?.isMenuActive,
            isSubMenuActive: item?.isSubMenuActive,
            isFeatureActive: item?.isFeatureActive,
            submodule: item?.subMenuDisplayName,
            module: item?.menuDisplayName,
            navigate: navigatePath,
            menuName: item?.menuName,
            subMenuName: item?.subMenuName,
            featureName: item?.featureName,
            subMenuOrder: item?.subMenuOrder,
            featureOrder: item?.featureOrder,
          });
        });
        setItems(tempItems);
      }
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getConfigData();
  }, []);

  let tempmodule = Items
    ? Object.values(GroupBy(search(Items), "module"))
    : null;
  let tempsubmodule = Items
    ? Object.values(GroupBy(search(Items), "submodule"))
    : null;
  // console.log("first tempmodule tempmodule tempmodule tempmodule", tempmodule);
  // console.log(
  //   "first tempsubmodule tempsubmodule tempsubmodule tempsubmodule",
  //   tempsubmodule
  // );
  const handleMenuIcon = (menuName) => {
    if (
      menuName?.replaceAll(" ", "").replaceAll("&", "").toLowerCase() === "home"
    )
      return HomeIcon;
    else if (
      menuName?.replaceAll(" ", "").replaceAll("&", "").toLowerCase() === "hrms"
    )
      return HRMSIcon;
    else if (
      menuName?.replaceAll(" ", "").replaceAll("&", "").toLowerCase() ===
      "payroll"
    )
      return PayrollIcon;
    else if (
      menuName?.replaceAll(" ", "").replaceAll("&", "").toLowerCase() === "tax"
    )
      return TaxIcon;
    else if (
      menuName?.replaceAll(" ", "").replaceAll("&", "").toLowerCase() === "ats"
    )
      return ATSIcon;
    else if (
      menuName?.replaceAll(" ", "").replaceAll("&", "").toLowerCase() === "pms"
    )
      return PMSIcon;
    else if (
      menuName?.replaceAll(" ", "").replaceAll("&", "").toLowerCase() === "ld"
    )
      return LDIcon;
    else if (
      menuName?.replaceAll(" ", "").replaceAll("&", "").toLowerCase() ===
      "office"
    )
      return OfficeAdminIcon;
    else if (
      menuName?.replaceAll(" ", "").replaceAll("&", "").toLowerCase() === "itsm"
    )
      return ITSMIcon;
    else if (
      menuName?.replaceAll(" ", "").replaceAll("&", "").toLowerCase() ===
      "travel"
    )
      return TravelIcon;
    else if (
      menuName?.replaceAll(" ", "").replaceAll("&", "").toLowerCase() ===
      "globalsettings"
    )
      return SettingIcon;
  };
  const handleSubMenuIcon = (menuId) => {
    if (menuId === "Org Structure") return OrgStructurepic;
    else if (menuId === "Onboarding") return onboardingpic;
    else if (menuId === "Time & Attendance") return Timepic;
    else if (menuId === "Project & tasks") return ProjectTaskspic;
    else if (menuId === "HR Desk") return HRDeskpic;
    else if (menuId === "Benefits") return Benefitspic;
    else if (menuId === "Policies") return Policiespic;
    else if (menuId === "Announcements") return Announcementspic;
    else if (menuId === "Events") return Eventspic;
    else if (menuId === "News") return Newspic;
    else if (menuId === "Notice") return Bannerpic;
    else if (menuId === "Gallery") return Gallerypic;
    else if (menuId === "Contact Directory") return ContactDirectorypic;
    else if (menuId === "Meeting Room") return OfficemeetingroomPic;
    else if (menuId === "Vehicle") return OfficevehiclePic;
    else if (menuId === "Hotels") return OfficehotelsPic;
    else if (menuId === "House Keeping") return OfficehousekeepingPic;
    else if (menuId === "Company Structure") return PayrollcompanystructurePic;
    else if (menuId === "Work Locations") return PayrollworklocationsPic;
    else if (menuId === "Statutory Compliances")
      return PayrollstatutorycompliancesPic;
    else if (menuId === "Payroll Components")
      return PayrollpayrollcomponentsPic;
    else if (menuId === "Payroll Template") return PayrollpayrolltemplatePic;
    else if (menuId === "Pay Config") return PayrollpayrollconfigPic;
    else if (menuId === "Pay Area") return PayrollpayareaPic;
    else if (menuId === "Exception") return ItsmexceptionPic;
    else if (menuId === "Change") return ItsmchangePic;
    else if (menuId === "Asset") return ItsmassetPic;
    else if (menuId === "IT Desk") return ItsmdeskPic;
    else if (menuId === "Tax Config") return TaxConfigpic;
    else if (menuId === "Pipeline") return Pipelinepic;
    else if (menuId === "Stage") return Stagepic;
    else if (menuId === "Status") return Statuspic;
    else if (menuId === "Goal setting") return Goalsettingpic;
    else if (menuId === "Performance Cycle") return PerformanceCyclepic;
    else if (menuId === "Rewards") return Rewardspic;
    else if (menuId === "Courses") return Categoriespic;
    else if (menuId === "Chapters") return Coursespic;
    else if (menuId === "Question") return Questionpic;
    else if (menuId === "Lessons ") return Chapterspic;
    else if (menuId === "Skills") return Skillspic;
    else if (menuId === "Competency") return CompetenciesIcon;
    else if (menuId === "Travel Eligibility") return TravelEligibilitypic;
    else if (menuId === "Claim Type") return ClaimTypepic;
    else if (menuId === "System") return GlobalsettingssystemPic;
    else if (menuId === "Module Settings")
      return GlobalsettingsmodulesettingsPic;
    else if (menuId === "Settings") return GlobalsettingssettingsPic;
    else if (menuId === "Permission") return GlobalsettingspermissionPic;
    else if (menuId === "Roles") return GlobalsettingsrolesPic;
    else if (menuId === "Notifications") return GlobalsettingsnotificationsPic;
    else if (menuId === "Reports and KPI") return ItsmreportsKPIPic;
    else if (menuId === "PaySlip Template") return PayslipTemplatePic;
    else if (menuId === "Payroll Notice Board") return PayslipTemplatePic;
    else if (menuId === "TDS Config") return TDSConfigPic;
  };

  const getModuleStyl = (menuName) => {
    if (
      menuName?.replaceAll(" ", "").replaceAll("&", "").toLowerCase() === "home"
    )
      return "home";
    else if (
      menuName?.replaceAll(" ", "").replaceAll("&", "").toLowerCase() === "hrms"
    )
      return "hrms";
    else if (
      menuName?.replaceAll(" ", "").replaceAll("&", "").toLowerCase() ===
      "payroll"
    )
      return "payroll";
    else if (
      menuName?.replaceAll(" ", "").replaceAll("&", "").toLowerCase() === "tax"
    )
      return "tax";
    else if (
      menuName?.replaceAll(" ", "").replaceAll("&", "").toLowerCase() === "ats"
    )
      return "ats";
    else if (
      menuName?.replaceAll(" ", "").replaceAll("&", "").toLowerCase() === "pms"
    )
      return "pms";
    else if (
      menuName?.replaceAll(" ", "").replaceAll("&", "").toLowerCase() === "ld"
    )
      return "ld";
    else if (
      menuName?.replaceAll(" ", "").replaceAll("&", "").toLowerCase() ===
      "office"
    )
      return "admin";
    else if (
      menuName?.replaceAll(" ", "").replaceAll("&", "").toLowerCase() === "itsm"
    )
      return "itsm";
    else if (
      menuName?.replaceAll(" ", "").replaceAll("&", "").toLowerCase() ===
      "travel"
    )
      return "travel";
    else if (
      menuName?.replaceAll(" ", "").replaceAll("&", "").toLowerCase() ===
      "globalsettings"
    )
      return "global";
    else return false;
  };
  const getSubModuleStyl = (menuName) => {
    if (
      menuName?.replaceAll(" ", "").replaceAll("&", "").toLowerCase() === "home"
    )
      return "homeHover";
    else if (
      menuName?.replaceAll(" ", "").replaceAll("&", "").toLowerCase() === "hrms"
    )
      return "hrmsHover";
    else if (
      menuName?.replaceAll(" ", "").replaceAll("&", "").toLowerCase() ===
      "payroll"
    )
      return "payrollHover";
    else if (
      menuName?.replaceAll(" ", "").replaceAll("&", "").toLowerCase() === "tax"
    )
      return "taxHover";
    else if (
      menuName?.replaceAll(" ", "").replaceAll("&", "").toLowerCase() === "ats"
    )
      return "atsHover";
    else if (
      menuName?.replaceAll(" ", "").replaceAll("&", "").toLowerCase() === "pms"
    )
      return "pmsHover";
    else if (
      menuName?.replaceAll(" ", "").replaceAll("&", "").toLowerCase() === "ld"
    )
      return "ldHover";
    else if (
      menuName?.replaceAll(" ", "").replaceAll("&", "").toLowerCase() ===
      "office"
    )
      return "officeHover";
    else if (
      menuName?.replaceAll(" ", "").replaceAll("&", "").toLowerCase() === "itsm"
    )
      return "itsmHover";
    else if (
      menuName?.replaceAll(" ", "").replaceAll("&", "").toLowerCase() ===
      "travel"
    )
      return "travelHover";
    else if (
      menuName?.replaceAll(" ", "").replaceAll("&", "").toLowerCase() ===
      "globalsettings"
    )
      return "globalHover";
    else return false;
  };
  const getHoverStyle = (menuName) => {
    if (
      menuName?.replaceAll(" ", "").replaceAll("&", "").toLowerCase() === "home"
    )
      return "homeHovers";
    else if (
      menuName?.replaceAll(" ", "").replaceAll("&", "").toLowerCase() === "hrms"
    )
      return "hrmsHovers";
    else if (
      menuName?.replaceAll(" ", "").replaceAll("&", "").toLowerCase() ===
      "payroll"
    )
      return "payrollHovers";
    else if (
      menuName?.replaceAll(" ", "").replaceAll("&", "").toLowerCase() === "tax"
    )
      return "taxHovers";
    else if (
      menuName?.replaceAll(" ", "").replaceAll("&", "").toLowerCase() === "ats"
    )
      return "atsHovers";
    else if (
      menuName?.replaceAll(" ", "").replaceAll("&", "").toLowerCase() === "pms"
    )
      return "pmsHovers";
    else if (
      menuName?.replaceAll(" ", "").replaceAll("&", "").toLowerCase() === "ld"
    )
      return "ldHovers";
    else if (
      menuName?.replaceAll(" ", "").replaceAll("&", "").toLowerCase() ===
      "office"
    )
      return "officeHovers";
    else if (
      menuName?.replaceAll(" ", "").replaceAll("&", "").toLowerCase() === "itsm"
    )
      return "itsmHovers";
    else if (
      menuName?.replaceAll(" ", "").replaceAll("&", "").toLowerCase() ===
      "travel"
    )
      return "travelHovers";
    else if (
      menuName?.replaceAll(" ", "").replaceAll("&", "").toLowerCase() ===
      "globalsettings"
    )
      return "globalHovers";
    else return false;
  };
  const ref = useRef(null);
  const handleHover = () => {
    ref.current.focus();
  };
  return (
    <div
      className="homeCardsHolderContainer settingCardHolder"
      style={{ height: "calc(100% - 6px)", marginTop: "6px" }}
    >
      {loading ? (
        <Loader />
      ) : (
        <>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <div className="search-box" onMouseEnter={handleHover}>
              <input
                ref={ref}
                className="search-text"
                type="text"
                placeholder="Search"
                onChange={(e) => setQ(e.target.value)}
              />
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a className="search-btn">
                <svg
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M11.7514 11.1946L16.0236 15.4668C16.1737 15.6171 16.258 15.8209 16.258 16.0333C16.2579 16.2457 16.1734 16.4494 16.0232 16.5996C15.8729 16.7498 15.6691 16.8341 15.4567 16.834C15.2443 16.8339 15.0406 16.7495 14.8904 16.5992L10.6183 12.3269C9.34114 13.3161 7.73517 13.7816 6.12703 13.6288C4.5189 13.4759 3.02941 12.7161 1.96157 11.504C0.893729 10.2918 0.327755 8.71841 0.378784 7.10379C0.429812 5.48917 1.09401 3.95463 2.23626 2.81236C3.37851 1.67008 4.91301 1.00586 6.52758 0.954834C8.14216 0.903804 9.71554 1.46979 10.9276 2.53766C12.1397 3.60553 12.8995 5.09505 13.0524 6.70323C13.2053 8.3114 12.7406 9.91742 11.7514 11.1946ZM6.72885 12.0689C7.99247 12.0689 9.20433 11.5669 10.0978 10.6733C10.9914 9.77981 11.4933 8.56791 11.4933 7.30426C11.4933 6.04061 10.9914 4.82871 10.0978 3.93518C9.20433 3.04164 7.99247 2.53966 6.72885 2.53966C5.46523 2.53966 4.25336 3.04164 3.35985 3.93518C2.46633 4.82871 1.96436 6.04061 1.96436 7.30426C1.96436 8.56791 2.46633 9.77981 3.35985 10.6733C4.25336 11.5669 5.46523 12.0689 6.72885 12.0689Z"
                    fill="#1296B0"
                  />
                </svg>
              </a>
            </div>
          </div>
          <div style={{ display: "flex", height: "fit-content" }}>
            <div className="homeDashboardCardHolder">
              {tempmodule.map((item, index) => {
                if (item[0].isMenuActive) {
                  return (
                    <div key={index} style={{ width: "50%" }}>
                      <div className="cardContainer">
                        <div className="cardDataContainer">
                          <a
                            href={item[0].navigate}
                            onClick={(e) => {
                              e.preventDefault();
                              navigate(item[0].navigate);
                            }}
                            style={{
                              textDecoration: "none",
                              width: "100%",
                            }}
                          >
                            <div
                              className={`headingContainer cursor settingsContainer ${getModuleStyl(
                                item[0].menuName
                              )} ${getSubModuleStyl(item[0].menuName)}`}
                            >
                              <img
                                className="mainMenuTileIcon"
                                src={handleMenuIcon(item[0].menuName)}
                                alt={item[0].module}
                              />
                              <h5>{item[0].module.toUpperCase()}</h5>
                            </div>
                          </a>
                          <div className="settingContainerBody">
                            {tempsubmodule?.map((items, indexx) => {
                              if (items[0].module === item[0].module) {
                                if (items[0]?.isSubMenuActive) {
                                  return (
                                    <div
                                      key={indexx}
                                      className={`settingListContainer`}
                                    >
                                      <a
                                        href={items[0].navigate}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          navigate(items[0].navigate);
                                        }}
                                        style={{
                                          textDecoration: "none",
                                          display: "flex",
                                          flex: "2",
                                          height: "fit-content",
                                          color: "var(--primary-text)",
                                        }}
                                      >
                                        <div
                                          style={{
                                            display: "flex",
                                            gap: "12px",
                                            height: "fit-content",
                                            fontWeight: "500",
                                            fontSize: "15px",
                                            cursor: "pointer",
                                          }}
                                        >
                                          <img
                                            src={handleSubMenuIcon(
                                              items[0].subMenuName
                                            )}
                                            alt={items[0].submodule}
                                          />
                                          <label className="cursor">
                                            {items[0].submodule}
                                          </label>
                                        </div>
                                      </a>
                                      <div
                                        style={{
                                          flex: "3",
                                        }}
                                      >
                                        {tempsubmodule?.map(
                                          (feature, index) => {
                                            if (
                                              feature[0]?.submodule ===
                                              items[0].submodule
                                            )
                                              return (
                                                <div
                                                  key={index}
                                                  style={{
                                                    display: "flex",
                                                    flexWrap: "wrap",
                                                  }}
                                                >
                                                  {(feature.length > 1 ||
                                                    q !== "") &&
                                                    feature?.map(
                                                      (name, index) => {
                                                        if (
                                                          name?.isFeatureActive
                                                        ) {
                                                          return (
                                                            <a
                                                              href={
                                                                name.navigate
                                                              }
                                                              onClick={(e) => {
                                                                e.preventDefault();
                                                                navigate(
                                                                  name.navigate
                                                                );
                                                              }}
                                                              style={{
                                                                textDecoration:
                                                                  "none",
                                                                fontWeight:
                                                                  "500",
                                                                fontSize:
                                                                  "14px",
                                                                width:
                                                                  "calc(50% - 12px)",
                                                                margin: "6px",
                                                                display: "flex",
                                                                cursor:
                                                                  "pointer",
                                                                borderRadius:
                                                                  "8px",
                                                                padding:
                                                                  "3px 6px",
                                                                color:
                                                                  "var(--primary-text)",
                                                              }}
                                                              className={getHoverStyle(
                                                                item[0].menuName
                                                              )}
                                                            >
                                                              <div
                                                                key={index}
                                                                style={{
                                                                  display:
                                                                    "flex",
                                                                }}
                                                              >
                                                                {name.name ? (
                                                                  name.name !==
                                                                  "" ? (
                                                                    <>
                                                                      <label className="cursor">
                                                                        •
                                                                      </label>
                                                                      <label
                                                                        className="cursor"
                                                                        style={{
                                                                          marginLeft:
                                                                            "6px",
                                                                        }}
                                                                      >
                                                                        {
                                                                          name.name
                                                                        }
                                                                      </label>
                                                                    </>
                                                                  ) : null
                                                                ) : null}
                                                              </div>
                                                            </a>
                                                          );
                                                        } else return <></>;
                                                      }
                                                    )}
                                                </div>
                                              );
                                            else return null;
                                          }
                                        )}
                                      </div>
                                    </div>
                                  );
                                } else return null;
                              } else {
                                return null;
                              }
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                } else return null;
              })}
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export { Settings };
